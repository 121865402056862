import whatsappIcon from "../assets/whatsapp.svg";
import emailIcon from "../assets/email.svg";
import twitterIcon from "../assets/twitter.svg";
import clipboardIcon from "../assets/clipboard.svg";
import messengerIcon from "../assets/messenger.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getArtist } from "./getArtist";


export const getLinkToShare = () => {
    const artistId = getArtist()
    let linkToShare = "";
    let referral = ""
    const data = JSON.parse(localStorage.getItem("userData"));
    const origin = window.location.href.replace("/success", "");
    if (data) {
        referral =
            data.user_data?.artists?.[artistId]?.referral;
        linkToShare = origin + "?ref=" + referral;
    } else if (!data) {
        referral = localStorage.getItem("anonymousId") || "";
        linkToShare = origin + "?ref=" + referral;
    }
    return linkToShare
}
const messengerShare = () => {
    let linkToShare = getLinkToShare()
    window.open(
        `http://www.facebook.com/dialog/send?app_id=578540233222013&link=${linkToShare}&redirect_uri=${window.location.origin}`,
        "_blank" // <- This is what makes it open in a new window.
    );
};
const whatsappShare = () => {
    let linkToShare = getLinkToShare()
    window.open(
        `https://api.whatsapp.com/send/?text=${linkToShare}`,
        "_blank" // <- This is what makes it open in a new window.
    );
};
const twitterShare = () => {
    let linkToShare = getLinkToShare()
    window.open(
        `https://twitter.com/messages/compose?text=${linkToShare}`,
        "_blank" // <- This is what makes it open in a new window.
    );
};
const emailShare = () => {
    let linkToShare = getLinkToShare()
    window.open(
        `mailto:?subject=Be part of this Family!&body=${linkToShare}`,
        "_blank" // <- This is what makes it open in a new window.
    );
};
const copyLink = async () => {
    let linkToShare = getLinkToShare()
    await navigator.clipboard.writeText(linkToShare);
    toast("Copied to Clipboard", { type: "success" });
};

export const shareOptions = [
    // { name: "Share to Instagram", icon: instaIcon, action: instagramShare },
    { name: "Share to Messenger", icon: messengerIcon, action: messengerShare },
    { name: "Share to WhatsApp", icon: whatsappIcon, action: whatsappShare },
    { name: "Share to Twitter", icon: twitterIcon, action: twitterShare },
    { name: "Share via Email", icon: emailIcon, action: emailShare },
    { name: "Copy Link", icon: clipboardIcon, action: copyLink },
];
export const ShareComponent = ({ text, icon, action }) => {
    return (
        <div onClick={action} className="share-component">
            <img src={icon} alt="share-icon" />
            {text}
            <ToastContainer />
        </div>
    );
};