import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import Button from "../../../../components/SignupProcess/Button/Button";
import "./InsightCard.scss";
import { ReactComponent as ArrowUp } from "../../../../assets/arrow-up.svg";
import { ReactComponent as HelpCircle } from "../../../../assets/help-circle.svg";
import { Tooltip } from "antd";

export default function InsightCard({
  title,
  tooltip,
  targetGoal,
  percent,
  compare,
  compareSign,
  smallSign,
  comparePreSign,
  description,
  leftDescription,
  rightDescription,
  button: { label, action },
  blank,
}) {
  return (
    <div className="insight-card">
      <div className="insight-card-heading">
        {title}
        {tooltip && (
          <Tooltip overlayInnerStyle={{ width: 340 }} title={tooltip}>
            <HelpCircle />
          </Tooltip>
        )}
      </div>
      <div className="insight-chart-data">
        <SemiCircleProgressBar
          percentage={blank ? 0 : percent}
          strokeWidth={28}
          diameter={280}
          stroke={"#5975FF"}
          background={"#E4E4E7"}
        />
        <b
          className={`main-circle-data ${
            !blank && (percent >= targetGoal ? "good" : "bad")
          }`}
        >
          {blank ? (
            "N/A"
          ) : (
            <>
              {comparePreSign && comparePreSign}
              {percent}
              {compareSign}
            </>
          )}
        </b>

        <span className="goal-data">
          Your Goal {comparePreSign ? comparePreSign : null}
          {targetGoal}
          {!comparePreSign && "%"}
        </span>
        {compare ? (
          <div className={`compare-data ${compare < 0 ? "down-arrow" : ""}`}>
            <span className="icon">
              <ArrowUp />
            </span>

            <span>
              {compare}
              {smallSign}
            </span>
          </div>
        ) : null}

        <div className="circle-data">
          <span>{leftDescription}</span>
          <span>{rightDescription}</span>
        </div>
      </div>
      <div
        className="insight-card-body"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="insight-card-actions">
        <Button label={label} action={action} border={"none"} height={"40px"} />
      </div>
    </div>
  );
}
