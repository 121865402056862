import React, { useState } from "react";
import "./CollectionCard.scss";
import { BiCube } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Modal, notification } from "antd";
import { AiOutlineDelete, AiOutlineStop } from "react-icons/ai";
import { RiAddLine, RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import { LuLink2 } from "react-icons/lu";
import { MdOutlineContentCopy } from "react-icons/md";
import { useHistory } from "react-router-dom";
import AddCollectibleModal from "../AddCollectibleModal/AddCollectibleModal";
import Button from "../../SignupProcess/Button/Button";

export default function CollectionCard({
  id,
  title,
  icon,
  status,
  items,
  description,
  action = null,
  deleteCollection = null,
  showDeleteCollectionModal = false,
  setShowDeleteCollectionModal,
  setShowEditModal,
}) {
  const history = useHistory();
  const [showCollectibleModal, setShowCollectibleModal] = useState(false);

  const dropdownitems = [
    {
      label: (
        <div
          className="tile-dropdown-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowCollectibleModal(true);
          }}
        >
          <RiAddLine />
          Add Collectible
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          className="tile-dropdown-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowEditModal({ id, title, icon, status, items, description });
          }}
        >
          <RiEdit2Line />
          Edit Collection
        </div>
      ),

      key: "2",
    },

    items?.length == 0 && {
      label: (
        <div
          className="tile-dropdown-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteCollectionModal(id);
          }}
        >
          <RiDeleteBinLine />
          Delete Collection
        </div>
      ),

      key: "3",
    },
  ];
  return (
    <>
      <div
        className="collection-card-wrapper"
        onClick={() =>
          action ? action() : history.push(`/dashboard/collections/${id}`)
        }
      >
        {status === "create" ? (
          <div className="create-collection">
            <BiCube size={66} color="#70707B" />
            <b>Create a new collection</b>
          </div>
        ) : (
          <div className="collection-data">
            <div className="collection-header">
              <img src={icon} />
              <b>{title}</b>
            </div>
            <div className="collection-footer">
              <div className="tags">
                <div className="tag" style={{ backgroundColor: "#fff" }}>
                  {items?.length} items
                </div>
              </div>
              <div className="more-options">
                <Dropdown
                  menu={{
                    items: dropdownitems,
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <BsThreeDotsVertical />
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        )}
      </div>
      <AddCollectibleModal
        isVisible={showCollectibleModal}
        toggleModal={() => setShowCollectibleModal(!showCollectibleModal)}
        collection_id={id}
      />
      <Modal
        open={showDeleteCollectionModal === id}
        className="playbook-success playbook-stop-modal"
        footer={null}
        maskStyle={{
          backgroundColor: "rgba(0,0,0,0.3)",
          backdropFilter: "blur(10px)",
        }}
        onCancel={() => setShowDeleteCollectionModal(false)}
      >
        <div className="icon-holder">
          <RiDeleteBinLine />
        </div>
        <h3>Delete Collection?</h3>
        <b>This can’t be undone.</b>
        <div className="modal-btns">
          <Button
            label={"CANCEL"}
            height="44px"
            border="none"
            background="#0000000F"
            textColor="#3F3F46"
            action={() => setShowDeleteCollectionModal(false)}
          />
          <Button
            label={"DELETE"}
            height="44px"
            border="none"
            background="#B42318"
            textColor="#fff"
            action={() => deleteCollection(id)}
          />
        </div>
      </Modal>
    </>
  );
}
