import { Tooltip } from "antd";
import moment from "moment";
import Carousel from "nuka-carousel/lib/carousel";
import React, { useEffect, useState } from "react";
import { FaCheck, FaUndo } from "react-icons/fa";
import { FiCheck, FiPlusCircle, FiSave, FiTrash2 } from "react-icons/fi";
import { artistUpdate } from "../../../../api/dmmeendpoints/artistUpdate";
import { getActivationStats } from "../../../../api/dmmeendpoints/getActivationStats";
import {
  getWhatsappFlowMessage,
  getWhatsappFlows,
  getWhatsappMessagesPerPhoneNumber,
} from "../../../../api/dmmeendpoints/getWhatsappFlows";
import chatCompleted from "../../../../assets/Dashboard/chat-completed.svg";
import chatStarted from "../../../../assets/Dashboard/chat-started.svg";
import clock from "../../../../assets/Dashboard/clock.svg";
import editIcon from "../../../../assets/Dashboard/edit.svg";
import chevron from "../../../../assets/chevron-right.svg";
import eye from "../../../../assets/conversations/eye.svg";
import user01 from "../../../../assets/conversations/users-01.svg";
import { ReactComponent as IosSMS } from "../../../../assets/ios-sms.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/whatsapp-1.svg";
import Button from "../../../../components/SignupProcess/Button/Button";
import StatusTags from "../../../../components/StatusTags/StatusTags";
import "./ActivationTiles.scss";
import MessageReplies from "../MessageReplies/MessageReplies";
import {
  getAllWhatsappFlows,
  updateWhatsappFlow,
} from "../../../../api/dmmeendpoints/whatsappFlowsRequests/whatsappFlows";

const filters = ["View all", "Active", "Drafts", "Archived"];

export default function ActivationTiles({
  setShowUpdater,
  setShowWhatsappBuilder,
}) {
  const [activeFilter, setActiveFilter] = useState(filters[0]);
  const [whatsappFlows, setWhatsappFlows] = useState([]);
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  const { bot_config, claimed_phone_number } = JSON.parse(
    localStorage.getItem("dbUser")
  );
  const [tiles, setTiles] = useState([]);

  const getActivationData = async () => {
    const { clicked, members_contacted, members_read, sent } =
      await getActivationStats({
        number: claimed_phone_number.replace("+", ""),
      });

    setTiles([
      {
        id: "1",
        title: "Welcome new contacts",
        startDate: moment().subtract(10, "days"),
        endDate: null,
        sent: sent || 0,
        eyes: members_contacted || 0,
        clicks: clicked || 0,
        replies: members_read || 0,
        status: bot_config?.customise_your_welcome_message
          ? "active"
          : "paused",
      },
    ]);
  };
  useEffect(() => {
    getActivationData();
    loadAndMergeWhatsappFlows();
  }, []);

  const loadAndMergeWhatsappFlows = async () => {
    const initialData = await getWhatsappFlows();
    const additionalData = await getAllWhatsappFlows();
    setWhatsappFlows([...initialData, ...additionalData]);
  };

  const Tile = ({
    avatar,
    sentTo = "all",
    title,
    message,
    images,
    sent,
    eyes,
    clicks,
    replies,
    date,
    setShowUpdater,
    status,
    id,
    flowid,
    flowId,
    members_contacted,
    members_read,
    ...rest
  }) => {
    // if (flowid) {
    //   getWhatsappFlowMessage(flowid);
    //   getWhatsappMessagesPerPhoneNumber(flowid, "+917428396005");
    // }

    const [seeReplies, setSeeReplies] = useState(null);
    const dbUser = JSON.parse(localStorage.getItem("dbUser"));
    const [realStatus, setRealStatus] = useState(
      dbUser?.flowNames?.[flowid || flowId]?.status ||
        (flowId ? "draft" : status ? status : "active")
    );
    const [isEditing, setIsEditing] = useState(false);
    const [currentName, setCurrentName] = useState(
      dbUser?.flowNames?.[flowid || id]?.title ||
        title ||
        flowid ||
        rest.flowName ||
        flowId
    );

    const [lightboxState, setLightboxState] = useState({
      visible: false,
      slideIndex: 0,
      images: [],
    });

    const updateNaming = async () => {
      if (isEditing) {
        // save name
        if (flowid || id) {
          await artistUpdate({
            object: {
              flowNames: {
                ...(dbUser.flowNames || {}),
                [flowid || id]: {
                  ...(dbUser.flowNames?.[flowid || id] || {}),
                  title: currentName,
                },
              },
            },
            artistId: dbUser["artist_id"],
          });
          localStorage.setItem(
            "dbUser",
            JSON.stringify({
              ...dbUser,
              flowNames: {
                ...(dbUser.flowNames || {}),
                [flowid || id]: {
                  ...(dbUser.flowNames?.[flowid || id] || {}),
                  title: currentName,
                },
              },
            })
          );
        } else if (flowId) {
          updateWhatsappFlow({
            flowId,
            name: currentName,
            description: rest.flowDescription,
            json: rest.flowJson,
          });
        }
      }
    };

    const archiveFlow = async (e) => {
      const currentFlowId = flowId || flowid;
      e.stopPropagation();
      if (currentFlowId) {
        await artistUpdate({
          object: {
            flowNames: {
              ...(dbUser.flowNames || {}),
              [currentFlowId]: {
                ...(dbUser.flowNames[currentFlowId] || {}),
                status:
                  realStatus === "archived"
                    ? flowId
                      ? "draft"
                      : "active"
                    : "archived",
              },
            },
          },
          artistId: dbUser["artist_id"],
        });
        localStorage.setItem(
          "dbUser",
          JSON.stringify({
            ...dbUser,
            flowNames: {
              ...(dbUser.flowNames || {}),
              [currentFlowId]: {
                ...(dbUser.flowNames[currentFlowId] || {}),
                status:
                  realStatus === "archived"
                    ? flowId
                      ? "draft"
                      : "active"
                    : "archived",
              },
            },
          })
        );
        setRealStatus(
          realStatus === "archived" ? (flowId ? "draft" : "active") : "archived"
        );
      }
    };

    return (
      <>
        <div
          className="activation-tile"
          onClick={() => {
            if (!isEditing) {
              if (id) {
                setShowUpdater(id);
              } else if (flowId) {
                setShowWhatsappBuilder({
                  flowId,
                  name: currentName,
                  description: rest.flowDescription,
                  json: rest.flowJson,
                });
              } else {
                setSeeReplies(flowid);
              }
            } else {
              setIsEditing(!isEditing);
              updateNaming();
            }
          }}
        >
          <div
            className="tile-header"
            onClick={(e) => {
              e.stopPropagation();
              if (isEditing) {
                setIsEditing(false);
                updateNaming();
              } else {
                setIsEditing(true);
              }
            }}
          >
            <div
              className={`avatar-stats ${
                isEditing ? "is-editing-avatar-stats" : ""
              }`}
            >
              {isEditing ? (
                <input
                  value={currentName}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setCurrentName(e.target.value)}
                />
              ) : (
                <span>{currentName}</span>
              )}
            </div>
            <div
              className="activation-edit"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(!isEditing);
                updateNaming();
              }}
            >
              {isEditing ? <FaCheck /> : <img src={editIcon} />}
            </div>
          </div>
          {(flowid || flowId) && (
            <div className="activation-delete" onClick={archiveFlow}>
              {realStatus === "archived" ? <FaUndo /> : <FiTrash2 />}
            </div>
          )}
          <div className="status-and-type">
            <div className={`conversation-type`}>
              {id ? <IosSMS /> : <Whatsapp />}
            </div>
            <StatusTags status={realStatus} />
            {/* since Nov 5, 2022 */}
          </div>
          <div className="tile-body-row">
            <div
              className={`tile-body`}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
          {images && (
            <div className="carousel">
              <Carousel
                renderCenterLeftControls={({
                  previousDisabled,
                  previousSlide,
                }) =>
                  !previousDisabled && (
                    <div
                      className="carousel-controls control-left"
                      onClick={(e) => {
                        e.stopPropagation();
                        previousSlide();
                      }}
                      disabled={previousDisabled}
                    >
                      <img src={chevron} />
                    </div>
                  )
                }
                renderCenterRightControls={({ nextDisabled, nextSlide }) =>
                  !nextDisabled && (
                    <div
                      className="carousel-controls"
                      onClick={(e) => {
                        e.stopPropagation();
                        nextSlide();
                      }}
                      disabled={nextDisabled}
                    >
                      <img src={chevron} />
                    </div>
                  )
                }
              >
                {images.map((image, i) => (
                  <div className="carousel-slide" key={i}>
                    <img
                      src={image}
                      onClick={(e) => {
                        e.stopPropagation();
                        setLightboxState({
                          ...lightboxState,
                          visible: true,
                          images: images,
                          slideIndex: i,
                        });
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
          {!flowId ? (
            date?.format("x") > moment().format("x") ? (
              <div className="tile-footer">
                <img src={clock} />
                <span>
                  Will send on {date?.format("MMMM DD, [at] hh:mm a")}
                </span>
              </div>
            ) : (
              <div className="tile-footer">
                <Tooltip color="#7A91FF" title={`Members Contacted`}>
                  <div className="tile-stat">
                    <img src={user01} />
                    {flowid ? members_contacted : sent}
                  </div>
                </Tooltip>
                <Tooltip color="#7A91FF" title={`Chats Read`}>
                  <div className="tile-stat">
                    <img src={eye} />
                    {flowid ? members_read : eyes}
                  </div>
                </Tooltip>
                <Tooltip color="#7A91FF" title={`Chats Started`}>
                  <div className="tile-stat">
                    <img src={chatStarted} />
                    {flowid ? rest["TRACK|STARTED"] || 0 : replies}
                  </div>
                </Tooltip>
                <Tooltip color="#7A91FF" title={`Chats Completed`}>
                  <div className="tile-stat">
                    <img src={chatCompleted} />
                    {flowid ? rest["TRACK|STARTED_ELSE"] || 0 : clicks}
                  </div>
                </Tooltip>
              </div>
            )
          ) : null}
        </div>
        <MessageReplies
          open={flowid && (seeReplies === flowid ? seeReplies : false)}
          data={{ flowid, members_contacted, members_read, ...rest }}
          setSeeReplies={setSeeReplies}
          isFlow
        />
      </>
    );
  };
  return (
    <div className="activation-with-actions">
      <div className="activation-actions">
        <Button
          label={"Create Flow"}
          height={36}
          border="none"
          leftIcon={<FiPlusCircle />}
          iconOrImage="icon"
          action={() => setShowWhatsappBuilder(true)}
        />
        <br />
        {filters.map((filter, i) => (
          <div
            key={i}
            onClick={() => setActiveFilter(filter)}
            className={`activation-filter ${
              filter === activeFilter ? "active-filter" : ""
            }`}
          >
            {filter}
          </div>
        ))}
      </div>
      <div className="activation-tiles">
        {[...tiles, ...whatsappFlows].map((tile, i) => {
          const status =
            dbUser.flowNames?.[tile.flowid || tile.flowId]?.status || "active";
          if (activeFilter !== filters[0]) {
            if (status && activeFilter.toLowerCase() === status.toLowerCase()) {
              return <Tile key={i} {...tile} setShowUpdater={setShowUpdater} />;
            }
          } else {
            return <Tile key={i} {...tile} setShowUpdater={setShowUpdater} />;
          }
        })}
      </div>
    </div>
  );
}
