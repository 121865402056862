import requestDM from '../requestDM'

export const getActivationStats = async ({ number }) => {
    var response;
    try {
        response = await requestDM({}, `/sms/activationstats?artist_number=${number}`, 'GET')
        return response
    } catch (error) {

        return { error }
    }
}