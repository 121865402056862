import React, { useState, useEffect, useContext, useCallback } from "react";
import "./VerifyOTP.scss";
import iindyLogo from "../../assets/Lightning.svg";
import OtpInput from "@keepertax/react-otp-input";
import { useHistory } from "react-router";
import { AppContext } from "../../contexts/AppContext";
import { sendSMS } from "../../api/twilio";
import Lottie from "react-lottie";
import animationData from "../../assets/tap-here.json";
import { isMobile } from "react-device-detect";

export default function VerifyOTP() {
  const [otp, setOtp] = useState(null);
  const [success, setSuccess] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const history = useHistory();

  const sendVerificationCode = useCallback(
    async (phoneNumber, verificationCode) => {
      setSendingMessage(true);
      await sendSMS(
        phoneNumber,
        `${verificationCode} is your mobile verification code.` +
          `\n@${window.location.host} #${verificationCode}`,
        authToken
      );

      setSendingMessage(false);
    },
    []
  );

  const changeOtp = async (otp) => {
    setOtp(otp);
    setSuccess(null);
    if (otp.length == 4) {
      if (verifyOTP(otp)) {
        setSuccess("success");
        setTimeout(async () => {
          history.push("/hotline-success", { phoneNumber });
        }, 1000);
      } else {
        setSuccess("fail");
        setTimeout(() => {
          setOtp("");
          document.getElementsByTagName("input")[0].focus();
        }, 1500);
      }
    }
  };

  const verifyOTP = (otp) => {
    if (otp.trim() === verificationCode) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    const phoneNumber = history.location.state.phoneNumber;
    setPhoneNumber(phoneNumber);
    const verificationCode = createVerificationCode();
    setVerificationCode(verificationCode);
    sendVerificationCode(phoneNumber, verificationCode);
  }, [history.location, sendVerificationCode]);

  const { authToken, user } = useContext(AppContext);
  const VERIFICATION_CODE_LENGTH = 4;
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  function createVerificationCode() {
    return getRandomInt(
      Math.pow(10, VERIFICATION_CODE_LENGTH - 1),
      Math.pow(10, VERIFICATION_CODE_LENGTH)
    ).toString();
  }
  function onSendNewCodeClick() {
    history.push(
      `/hotline?phoneNumber=${phoneNumber}&email=${user.user_data.email}&apcid=temp&change=true`
    );
  }

  // useEffect(() => {
  //   const mainContent =
  //     document.getElementsByClassName("verify-otp-wrapper")[0];
  //   if (window.innerWidth >= 600) {
  //     mainContent.style.height = window.innerHeight - 150 + "px";
  //   }
  //   window.addEventListener("resize", heightChange);
  //   return () => {
  //     window.removeEventListener("resize", heightChange);
  //   };
  // }, []);
  const heightChange = () => {
    if (window.innerWidth >= 600) {
      const mainContent =
        document.getElementsByClassName("verify-otp-wrapper")[0];
      mainContent.style.height = window.innerHeight - 150 + "px";
    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="verify">
      <div className="desktop-wrapper"></div>
      <div className={`verify-otp-wrapper ${success}`}>
        {/* <img alt="smile" src={iindyLogo} /> */}
        <h4>We text you a code to verify your number.</h4>
        <h6>Please enter it below</h6>
        <div className="relative-div">
          <OtpInput
            value={otp}
            onChange={changeOtp}
            numInputs={4}
            className={`otp-input`}
            isInputNum={true}
            shouldAutoFocus
            containerStyle={{ display: "flex", justifyContent: "flex-start" }}
            inputStyle={{
              width: "48px",
              height: "48px",
              borderRadius: "14px",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            separator={<div className="separator" />}
          />
          <div className="tap-here-anim">
            <Lottie options={defaultOptions} height={150} width={150} />
          </div>
        </div>
        <div onClick={onSendNewCodeClick} className="resend-code">
          Resend code
        </div>
      </div>
    </div>
  );
}
