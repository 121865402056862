import React from "react";
import chevronRight from "../../assets/chevron-right-gray.svg";
import "./IndicatorButton.scss";

export default function IndicatorButton({ action, type }) {
  return (
    <div className="indicator-button">
      <div className="icon-wrapper" onClick={action}>
        <img
          alt="back-next-icon"
          className={`${type == "Back" ? "rotate" : ""}`}
          src={chevronRight}
        />
      </div>
      <b>Tap {type}</b>
    </div>
  );
}
