import React, { useEffect, useState } from "react";
import frame from "../../../assets/frame.png";
import Upload from "rc-upload";
import "./HotlineBuilder.scss";
import TextElement from "../components/TextElement/TextElement";
import ImageSelector from "../components/ImageSelector/ImageSelector";
import LandingTemplate1 from "../../../screens/LandingTemplate1/LandingTemplate1";
import LinksEditor from "../components/LinksEditor/LinksEditor";

export default function HotlineBuilder() {
  const [hotlineData, setHotlineData] = useState({});
  const [activeFonts, setActiveFonts] = useState({});

  const getBase64 = (img) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(img);
    });
  };

  useEffect(() => {
    setHotlineData({
      ...hotlineData,
      textMe: {
        ...hotlineData.textMe,
        heading1: "JOIN THE WAITLIST",
        heading2: "I’ll text you when the time is right :)",
        btnTxt: "TEXT ME",
        logo: "https://via.placeholder.com/500x200?text=ADD%20YOUR%20LOGO",
        artistsLinks: {
          WhatsApp: "",
          Spotify: "",
          "Apple Music": "",
          Merch: "",
          YouTube: "",
          Events: "",
        },
      },
    });
    document
      .getElementsByClassName("landing-template-1-wrapper")[0]
      .style.setProperty(
        "background",
        `url(https://picsum.photos/1000)`,
        "important"
      );
  }, []);
  const backgroundImageUpd = async (e) => {
    setHotlineData({
      ...hotlineData,
      textMe: {
        ...hotlineData.textMe,
        // backgroundImgUrl: base64,
      },
    });
    const base64 = await getBase64(e);
    document
      .getElementsByClassName("landing-template-1-wrapper")[0]
      .style.setProperty("background", `url(${base64})`, "important");
    document
      .getElementsByClassName("landing-template-1-wrapper")[0]
      .style.setProperty("background-size", `cover`, "important");
    document
      .getElementsByClassName("landing-template-1-wrapper")[0]
      .style.setProperty("background-position", `center`, "important");
  };
  const logoUpload = async (e) => {
    const base64 = await getBase64(e);
    setHotlineData({
      ...hotlineData,
      textMe: {
        ...hotlineData.textMe,
        logo: base64,
      },
    });
  };
  const changeText = (key, value) => {
    setHotlineData({
      ...hotlineData,
      textMe: {
        ...hotlineData.textMe,
        [key]: value,
      },
    });
  };
  document.title = "iindy - Hotline Builder";

  const editLinks = (key, val) => {
    const oldData = { ...hotlineData.textMe.artistsLinks };
    oldData[key] = val;
    setHotlineData({
      ...hotlineData,
      textMe: {
        ...hotlineData.textMe,
        artistsLinks: { ...oldData },
      },
    });
  };

  return (
    <div className="hotline-builder">
      <div className="builder">
        <div className="page-title">Hotline Builder</div>
        <div className="images-upload-section">
          <ImageSelector
            label={"Background"}
            uploadFunction={backgroundImageUpd}
          />
          <ImageSelector label={"Logo"} uploadFunction={logoUpload} />
        </div>
        <TextElement
          label={"Heading 1 text"}
          className={"heading-1"}
          value={hotlineData?.textMe?.heading1}
          jsKey={"heading1"}
          placeholder={"welcome to"}
          changeText={changeText}
          activeFonts={activeFonts}
          setActiveFonts={setActiveFonts}
        />
        <br />
        <TextElement
          label={"Heading 2 text"}
          className={"heading-2"}
          value={hotlineData?.textMe?.heading2}
          jsKey={"heading2"}
          placeholder={"welcome to"}
          changeText={changeText}
          activeFonts={activeFonts}
          setActiveFonts={setActiveFonts}
        />
        <br />
        <TextElement
          label={"Button text"}
          className={"text-part"}
          value={hotlineData?.textMe?.btnTxt}
          jsKey={"btnTxt"}
          placeholder={"Btn text"}
          changeText={changeText}
          activeFonts={activeFonts}
          setActiveFonts={setActiveFonts}
        />
        <LinksEditor
          links={hotlineData?.textMe?.artistsLinks}
          editLinks={editLinks}
        />
      </div>
      <div className="preview">
        <div className="preview-container">
          <LandingTemplate1 data={{ ...hotlineData, ...hotlineData.textMe }} />
          <img src={frame} alt="iphone-device-frame" className={"frame"} />
        </div>
      </div>
    </div>
  );
}
