import tempImg from '../assets/temp-img.png'
import ticket from '../assets/ticket.png'
import wayneEmailLanding from '../assets/wayne-email-landing.png'
import wayneEmailSentBackground from '../assets/emailSentBackground.png'
import tomLogo from '../assets/tom-logo.svg'
import wayneLogo from '../assets/wayne-logo.png'
import hotlineSuccessImg from '../assets/tom-hotline-success.png'
import wayneHotlineSuccessImg from '../assets/wayne-hotline-success.png'
import wayneBackground from '../assets/wayne-background.png'


export const landing = {
    '-1': {
        mainColor: '#fff',
        artist: 'Welcome to the iiNDYVERSE',
        backgroundImg: tempImg,
        goldenTicketImg: ticket,
        favicon: '',
        mainText: 'Join the Wanye Snow family todayyy',
        subText: 'Sign up to my WhatsApp group to get exclusive assess to my work and events. ',
        pollSuccess: "Thank you for helping me decide what to \nchoose! 🙏🏽",
        pollSuccessBackground: 'https://d378gku4n4yrfm.cloudfront.net/000048110018.jpg',
        emailCampaign: {
            backgroundVideoUrl: 'https://d3e3j7juwu6cup.cloudfront.net/Video_2015.m4v',
            backgroundImg: wayneEmailLanding,
            emailSentBackground: wayneEmailSentBackground,
            artist: 'Wayne Snow',
            heading1: '@waynesnow',
            heading2: '#joinmyfam #exclusiveaccess',
            heading3: `You'll be the first to get exclusive access to events and loads more!`,
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you. It may be in your Spam folder.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
        },
        phoneCampaign: {
            btnTxt: 'SIGN UP',
            backgroundImgUrl: wayneBackground,
            hotlineSuccessImg: wayneHotlineSuccessImg,
            hotlineSuccessH1: `YOU'RE IN!`,
            hotlineSuccessH1Already: `Don't sweat, you're already in.`,
            hotlineSuccessH2: `I'll be in touch...`,
            hotlineSuccessH2Already: '',
            hotlineSuccessSMS1: 'Hi, Wayne Snow here! Welcome to my waitlist! I’ll text you when the time is right.',
            hotlineSuccessSMS2: 'In the meantime, here’s your personal link to get your friends in.',
            logo: wayneLogo,
            artist: 'Wayne Snow',
            heading1: 'JOIN THE WAITLIST',
            heading2: 'I’ll text you when the time is right :)',
            heading3: `You'll be the first to get exclusive access to events and load more!`,
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
        },
    },
    1: {
        mainColor: '#fff',
        artist: 'Wayne Snow',
        backgroundImg: tempImg,
        goldenTicketImg: ticket,
        favicon: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/wayne-favicon.ico?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHEaDGV1LWNlbnRyYWwtMSJHMEUCIE0pynBPmcfW0DxlNd%2FXxauFjdNSk8xtp9OAKi32%2Fm1SAiEAoTfJ5wwgDbWxJ2mtVUjs3eakUkXca9aRNtMxb00fNS0qhAMIuv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw2MzQyMjQ4NzU4MTMiDHxIagnghAvAhjHr1CrYAvkZ3BTbnLQ%2B%2FdthBNtqQ8vHBJnVaskehxZrO%2F7arEd1Szl84GbFWYZayrTqkbcGk7ngtjH%2Fpm0Y1D25NHIWnUVqHuTrIc4QP6UntrV9IJyXaYxf6RC%2BkJgAhQwrY3hc6Scqa5ciCshjXJig5IDOD20MjTdxepRn2%2BnXTsbbeLueJrH%2FafZYGMk0qU5HQ32jwwZgFxQ2MSSEVbdqU34JvBW7lLarCb4hq5ytv6vj4OlEVQQnPpk%2F0Une8KC%2F2ObB8a9Rs9OivTI5dXTOfMt89WE6kw5uO%2B7kssl2tx6KM6FvyToTvVQknBqQCDLY%2BgozZ9itQKgvBtlNkJdmz2FAf4fl%2F7P3QLTYTVAiLvGgL55y56UMrahTqFWpYS4AU7zwtVRoUp%2Bzxw4RkatHM6lGc73JcGzZcVB%2BVR5F4141Y4123v8SPGyjnQc92Gd64O5qQeECxgxtXE9LMMLc%2FpYGOrMCxyGrNIgQU1y4HuEmyIafzcndgo%2FrJ8LwigIn7CrnrXmIAf0vSUZvipRW7UBIbrISIAfC5jssK7Pg3RAUXsw%2FHdIXCsjTWHs%2BeE1ZzxdDC8G1DY7wUQXRc8XR27rfMLqzh86l5r6fhTIrVFh3nFOKM7B3AvlJcr%2BG5CZsR3EDZF2zYhXPfBERzrhApYA6vaFj9vUUsJEBwInW8nPqfziMEEJJ3jGzZdDj6Kv%2Bf1dpNP3RSDG1rIMbVFBio8sWEr6rKb%2FtunkQL3gD3me7%2FEVaHiYjZ3mUrz8VwKR7gYsKvj13E4iwWY6mEXRw1g8ov9VSuxrZ%2FhxPYvpaveO9%2BNGuTpNe0yILuCvmLtc0CNSxh%2BOZj2GtHGx0aO%2Bmwm2e2XrELn9%2FHNHYHEd5zJL89HhB8h0Ezw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220726T090543Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAZHKV7TES5PADEONQ%2F20220726%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Signature=316f66d981683f1b2f68a241b9ede8301e7e5776ff6e37f0c13ff07f15fbfe4b',
        mainText: 'Join the Wanye Snow family todayyy',
        subText: 'Sign up to my WhatsApp group to get exclusive assess to my work and events. ',
        pollSuccess: "Thank you for helping me decide what to \nchoose! 🙏🏽",
        pollSuccessBackground: 'https://d378gku4n4yrfm.cloudfront.net/000048110018.jpg',
        emailCampaign: {
            backgroundVideoUrl: 'https://d3e3j7juwu6cup.cloudfront.net/Video_2015.m4v',
            backgroundImg: wayneEmailLanding,
            emailSentBackground: wayneEmailSentBackground,
            artist: 'Wayne Snow',
            heading1: '@waynesnow',
            heading2: '#joinmyfam #exclusiveaccess',
            heading3: `You'll be the first to get exclusive access to events and loads more!`,
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you. It may be in your Spam folder.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
        },
        textMe: {
            btnTxt: 'WhatsApp Me to Join',
            textMeNumber: '+447700175695',
            textAutofill: 'Hi Wayne, I would like to get on your guest list',
            backgroundImgUrl: wayneBackground,
            hotlineSuccessImg: wayneHotlineSuccessImg,
            hotlineSuccessH1: `YOU'RE IN!`,
            hotlineSuccessH1Already: `Don't sweat, you're already in.`,
            hotlineSuccessH2: `I'll be in touch...`,
            hotlineSuccessH2Already: '',
            hotlineSuccessSMS1: 'Hi, HYD here! Welcome to my waitlist! I’ll text you when the time is right.',
            hotlineSuccessSMS2: 'In the meantime, here’s your personal link to get your friends in.',
            artist: 'Wayne Snow',
            logo: wayneLogo,
            heading1: 'JOIN THE WAITLIST',
            heading2: 'I’ll text you when the time is right :)',
            heading3: `You'll be the first to get exclusive access to events and load more!`,
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
            twilioFlowNumber: '+447700175695'
        },
    },
    2: {
        mainColor: '#E7E7BD',
        artist: 'Tom Misch',
        favicon: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/tom-favicon.png',
        logo: tomLogo,
        backgroundImg: tempImg,
        goldenTicketImg: ticket,
        mainText: 'Join the Wanye Snow family todayyy',
        subText: 'Sign up to my WhatsApp group to get exclusive assess to my work and events. ',
        // pollSuccess: `Thank you for helping me decide what to choose! 🙏🏽`,
        // pollSuccessBackground: 'https://d378gku4n4yrfm.cloudfront.net/000048110018.jpg',
        // phoneCampaign: {
        //     btnTxt: 'Jump In',
        //     backgroundVideoUrl: 'https://d3e3j7juwu6cup.cloudfront.net/small1.mp4',
        //     hotlineSuccessImg: hotlineSuccessImg,
        //     hotlineSuccessH1: 'You’re In The Hotline!',
        //     hotlineSuccessH1Already: `Don't sweat, you're already in.`,
        //     hotlineSuccessH2: 'Dilla Dog will hit you up soon.\n\nLove Tom x',
        //     hotlineSuccessH2Already: '',
        //     hotlineSuccessSMS1: 'Welcome to the hotline! Dilla Dog will hit you up soon...',
        //     hotlineSuccessSMS2: 'Use this link to get a friend in',
        //     artist: 'Tom Misch',
        //     heading1: 'Jump On My Hotline',
        //     emailSent: `Check your email! (*^｡^*)`,
        //     emailSentDescription: `We sent a verification link to you.`,
        //     getAccessHeading1: `Want guestlist tickets?`,
        //     getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
        //     getAccessHeading2: `#jumpotheguestlist`,
        //     getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
        // }
        textMe: {
            btnTxt: 'Jump In',
            textMeNumber: '+447588679743',
            textAutofill: 'Hey, I heard Dilla Dog has a private community Hotline. Get me in.',
            backgroundVideoUrl: 'https://d3e3j7juwu6cup.cloudfront.net/small1.mp4',
            hotlineSuccessImg: hotlineSuccessImg,
            hotlineSuccessH1: 'You’re In The Hotline!',
            hotlineSuccessH1Already: `Don't sweat, you're already in.`,
            hotlineSuccessH2: 'Dilla Dog will hit you up soon.\n\nLove Tom x',
            hotlineSuccessH2Already: '',
            hotlineSuccessSMS1: 'Welcome to the hotline! Dilla Dog will hit you up soon...',
            hotlineSuccessSMS2: 'Use this link to get a friend in',
            artist: 'Tom Misch',
            heading1: 'Jump On My Hotline',
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
            twilioFlowNumber: '+447588679743'
        },
    },
    4: {
        mainColor: '#E7E7BD',
        artist: 'HYD',
        favicon: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/hyd-logo.png',
        logo: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/HYD_LOGO_3.png',
        backgroundImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/hyd-background.jpeg',
        goldenTicketImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/hyd-background.jpeg',
        mainText: '',
        subText: '',
        textMe: {
            btnTxt: 'TEXT ME',
            textMeNumber: '+13236752866',
            textAutofill: 'I would like to join you.',
            backgroundImgUrl: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/HYD+Afar+Video.jpeg',
            hotlineSuccessImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/HYD+Afar+Video.jpeg',
            hotlineSuccessH1: 'WELCOME TO HEAVEN ON EARTH',
            hotlineSuccessH1Already: `DON'T SWEAT, YOU'RE ALREADY IN.`,
            hotlineSuccessH2: 'I’LL BE IN TOUCH',
            hotlineSuccessH2Already: '',
            hotlineSuccessSMS1: 'Hi, HYD here! Welcome to my waitlist! I’ll text you when the time is right.',
            hotlineSuccessSMS2: 'In the meantime, here’s your personal link to get your friends in.',
            artist: 'Tom Misch',
            heading1: 'MY DEAREST, ARE YOU READY TO JOIN HYD.EARTH?',
            heading2: '',
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
            twilioFlowNumber: '+447380327843'
        }
    },
    5: {
        mainColor: '#E7E7BD',
        artist: 'LIL MARIKO',
        favicon: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/lilmariko-logo.png',
        logo: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/lilmariko-logo.png',
        backgroundImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/277360566_364946405512757_4151230737821093414_n.jpeg',
        goldenTicketImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/277360566_364946405512757_4151230737821093414_n.jpeg',
        mainText: '',
        subText: '',
        phoneCampaign: {
            btnTxt: 'SUBMIT',
            backgroundImgUrl: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/277360566_364946405512757_4151230737821093414_n.jpeg',
            hotlineSuccessImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/277360566_364946405512757_4151230737821093414_n.jpeg',
            hotlineSuccessH1: 'Welcome to the sweat pit...',
            hotlineSuccessH1Already: `DON'T SWEAT, YOU'RE ALREADY IN.`,
            hotlineSuccessH2: 'i’ll be in touch',
            hotlineSuccessH2Already: '',
            hotlineSuccessSMS1: 'Hi, Lil Mariko here! Welcome to my waitlist! I’ll text you when the time is right.',
            hotlineSuccessSMS2: 'In the meantime, here’s your personal link to get your friends in.',
            artist: 'Tom Misch',
            heading1: 'my dearest, are you ready to join my simp club?',
            heading2: '',
            emailSent: `Check your email! (*^｡^*)`,
            emailSentDescription: `We sent a verification link to you.`,
            getAccessHeading1: `Want guestlist tickets?`,
            getAccessHeading1Already: `Dont’ sweat, you’re already in.`,
            getAccessHeading2: `#jumpotheguestlist`,
            getAccessHeading3: `Invite a friend and to say thx I’ll add you to a waiting list for guestlist spots!`,
        }
    },
    6: {
        mainColor: '#E7E7BD',
        artist: 'Yaeji',
        cio: 'yaeji',
        cioToken: 'NjU4ZjgyZGI1ZmVlYzYzZDcyN2Y6ODkwZTJkMzBmYzNjYjMwNzY3MzI=',
        pageTitle: 'Get the latest Yaeji updates',
        favicon: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/favicon.ico',
        emailCampaign: {
            btnTxt: 'SUBMIT',
            backgroundImgUrl: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/bkgrnd+main.png',
            desktopBackground: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/main.jpeg',
            hotlineSuccessImg: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/bkgrnd+main.png',
            artist: 'Yaeji',
            logo: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/logo-yaeji+1.png',
            heading1: 'SUBSCRIBE',
            heading1Success: `YOU'RE SUBSCRIBED`,
            heading2: 'Join Yaeji’s newsletter to get her latest news and updates.',
            heading3: 'Be the first to get exclusive access to events, merch and loads more!',
            whatsappLink: 'https://go.iindy.co/YaejiWhatsAppHotline',
            inviteLink: 'https://api.whatsapp.com/send/?text=Check%20out%20Yaeji%E2%80%99s%20WhatsApp%20hotline%20https%3A%2F%2Fgo.iindy.co%2FYaejiHotineInvite',
            whatsappButtonText: 'WHATSAPP',
            usButtonText: 'TEXT',
            usButtonLink: 'https://go.iindy.co/hTkjZ',
            smsInviteLink: `sms://?&body=Here's%20an%20invite%20to%20join%20Yaeji's%20hotline%20https://go.iindy.co/EWmXL`,

        }
    },
    7: {
        mainColor: '#e7e7bd',
        artist: 'Tom Misch',
        cio: 'tom-misch',
        cioToken: 'YmI3YzQ1YjM2Zjc4OWYzZjJlMTE6OWFmNWQ4ZTRjNTg5ZWQ3YjQ4Y2U=',
        pageTitle: 'Get the latest Tom Misch updates',
        favicon: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/tom-favicon.png',
        emailCampaign: {
            btnTxt: 'SUBMIT',
            backgroundImgUrl: 'https://d3e3j7juwu6cup.cloudfront.net/small1.mp4',
            desktopBackground: 'https://artists-favicons.s3.eu-west-2.amazonaws.com/main.jpeg',
            artist: 'Tom',
            logo: 'https://tommisch.com/tom-logo.svg',
            heading1: 'SUBSCRIBE',
            heading1Success: `YOU'RE SUBSCRIBED`,
            heading2: `Join Tom's newsletter to get his latest news and updates.`,
            heading3: 'Be the first to get exclusive access to new music updates, merch and more!!',
            whatsappLink: 'https://go.iindy.co/TomMischHotline',
            inviteLink: 'https://api.whatsapp.com/send/?text=Here%E2%80%99s%20an%20invite%20to%20join%20the%20Tom%20Misch%20hotline%20https://go.iindy.co/TomMischHotine_Invite',
            whatsappButtonText: 'WHATSAPP',
            usButtonText: 'TEXT',
            usButtonLink: 'https://go.iindy.co/FQN0L',
            smsInviteLink: `sms://?&body=Here%E2%80%99s%20an%20invite%20to%20join%20the%20Tom%20Misch%20hotline%20https://go.iindy.co/qofUe`,
        }
    },
    8: {
        mainColor: '#DABAFF',
        artist: 'Roosevelt',
        cio: 'roosevelt',
        cioToken: 'OWM5MGNlYWYwMWY1ZTg0ZDhlZmI6ODZkOWE5MTBmMDI2YzMxNDI2NTc=',
        pageTitle: 'Get early access to tickets, music and more',
        favicon: 'https://www.iamroosevelt.com/wp-content/uploads/2020/09/cropped-Roosevelt-Logo-3-32x32.png',
        emailCampaign: {
            btnTxt: 'SUBMIT',
            backgroundImgUrl: 'https://artist-videos.s3.eu-west-2.amazonaws.com/Roosevelt%2B-%2BOrdinary%2BLove%2BLEAD%2BPORTRAIT.jpg',
            desktopBackground: 'https://artist-videos.s3.eu-west-2.amazonaws.com/Roosevelt%2B-%2BOrdinary%2BLove%2BLEAD%2BPORTRAIT.jpg',
            artist: 'Roosevelt',
            logo: 'https://artist-videos.s3.eu-west-2.amazonaws.com/RV_logo_purple.png',
            heading1: 'SUBSCRIBE',
            heading1Success: `YOU'RE SUBSCRIBED`,
            heading2: `Join roosevelt's newsletter to get his latest news and updates.`,
            heading3: 'Be the first to get exclusive access to events, merch and loads more!',
            whatsappLink: 'https://go.iindy.co/roosevelthotline',
            inviteLink: 'https://api.whatsapp.com/send/?text=Here%E2%80%99s%20an%20invite%20to%20join%20the%20ROOSEVELT%20hotline%20https://go.iindy.co/RooseveltHotineInvite',
            whatsappButtonText: 'WHATSAPP',
            usButtonText: 'TEXT',
            usButtonLink: 'https://go.iindy.co/jdIWU',
            smsInviteLink: `sms://?&body=Here%E2%80%99s%20an%20invite%20to%20join%20the%20ROOSEVELT%20hotline%20https://go.iindy.co/LkjEZ`,
        }
    },

}