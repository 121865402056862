
import { getFullURL, backendApiCall } from "./backend";

const truID = require('@tru_id/tru-sdk-web')

async function createPhoneCheckAPI(phoneNumber, authToken, signal) {
  const url = getFullURL("/create-phone-check");

  const result = await backendApiCall(url, {
    method: "POST",
    body: JSON.stringify({
      phoneNumber: phoneNumber.replace("+", ""),
    }),
    authToken,
    signal,
  });

  if (!result.success) {
    return result;
  }

  try {
    let { identifier, checkID, checkURL } = result;

    if (result.checkPlatform === "tru") {
      await truID.openCheckUrl(checkURL);
    }
    if (result.checkPlatform === "o2") {
      const response = await fetch(checkURL, { signal });
      const responseJSON = await response.json();
      checkID = responseJSON.token;
      // const img = new Image()
      // img.style.height = 0
      // img.style.width = 0
      // img.setAttribute('referrerpolicy', 'no-referrer')
      // img.src = checkURL;
      // img.onload = () => {
      // };
      // document.body.appendChild(img);
    }

    return {
      success: true,
      identifier,
      checkID,
      checkURL,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
}

export function createPhoneCheck(phoneNumber, authToken) {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    promise: createPhoneCheckAPI(phoneNumber, authToken, signal),
  };
}

export function getPhoneCheckResult(identifier, checkID, authToken) {
  let url = getFullURL("/get-phone-check-result");

  url += `?identifier=${encodeURIComponent(identifier)}`;
  url += `&checkID=${encodeURIComponent(checkID)}`;

  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    promise: backendApiCall(url, { authToken, signal }),
  };
}
