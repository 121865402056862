import requestDM from '../requestDM'

export const artistUpdate = async ({ object, artistId }) => {
    var response;
    try {
        response = await requestDM({ ...object, artist_id: artistId }, `/dmme/artist`, 'PATCH')
        return response
    } catch (error) {

        return { error }
    }
}