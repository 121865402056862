export const replyPathwaysFormat = (data) => {
    let formatted = [];
    const recursive = (id) => {
        const elements = data[id]?.map((values) => {
            return {
                id: id,
                text: values.text,
                subElements: values.messages.length > 0 ? recursive(values.messages[0].id) : [],
            };
        });
        return elements;
    };

    formatted = recursive(Object.keys(data)[0]);
    return formatted;
}