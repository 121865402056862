import axios from 'axios'
import { landing } from '../config/Config'
import { getArtist } from './getArtist'
import request from '../api/request'


const username = 'AC6b23b91b120dfaa151c54561c1a19969';
const password = 'e7e93d58c511265afe4fcf2cda0d473b'

const artistId = getArtist()
const twilioFlowNumber = landing[artistId]?.['phoneCampaign']?.['twilioFlowNumber']

export const flowStart = async (phone) => {

    if (twilioFlowNumber) {
        try {
            request({ to: phone, from: twilioFlowNumber }, '/sms/conversation', 'create')
        } catch (error) {
            throw Error('Error happened while starting flow')
        }
    }
}