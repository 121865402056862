import React from "react";
import styles from "./ConfirmPopUp.module.scss";
import PotatoHead from "../../../assets/SignUpProcess/potato-head.svg";
import PapperHead from "../../../assets/SignUpProcess/papper-head.svg";
import rightArrow from "../../../assets/SignUpProcess/arrow-right.svg";
import copy from "../../../assets/SignUpProcess/copy.svg";
import Button from "../Button/Button";
import { useHistory, useLocation } from "react-router";
import { message, notification } from "antd";
import { customNotification } from "../../../helpers/customNotification";

export default function ConfirmPopUp({ visible, type, setVisible }) {
  const localUser = JSON.parse(localStorage.getItem("loginUser"));
  const history = useHistory();
  if (!visible) {
    return null;
  }
  return (
    <div className={styles.popUpWrapper}>
      <div className={styles.popUp + ` ${styles[type]}`}>
        <h1>
          {type === "error"
            ? "SOMETHING WENT WRONG"
            : "Dope! Here's your Hotline number."}
        </h1>
        {type !== "error" && (
          <div className={styles.phoneNrAndCopy}>
            <b className={styles.phoneNr}>{localUser.selectedNumber}</b>
            <img
              onClick={() => {
                navigator.clipboard.writeText(localUser.selectedNumber);
                customNotification("Number copied to clipboard");
              }}
              src={copy}
            />
          </div>
        )}
        {type === "error" ? (
          <h2>
            Please try again. And if it still doesn’t work just{" "}
            <a href="mailto:artists@iindy.co">contact us</a> and we’ll sort it
            out.{" "}
          </h2>
        ) : (
          <h2>
            But before you share it on your platforms, let’s create a
            personalised welcome experience for your fans.
          </h2>
        )}
        <img src={type === "error" ? PapperHead : PotatoHead} />
        <Button
          action={() => {
            if (type === "error") {
              setVisible(false);
            } else {
              history.push("/message-flow-builder"); //CHANGE BACK TO message-flow-builder
            }
          }}
          // rightIcon={type !== "error" && rightArrow}
          label={type === "error" ? `TRY AGAIN` : `CONTINUE`} //CHANGE BACK TO SETUP MY WELCOME MESSAGE
        />
      </div>
    </div>
  );
}
