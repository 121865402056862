import React, { useState, useEffect } from "react";
import "./PollProgressBar.scss";
import playIcon from "../../assets/play.svg";
import pauseIcon from "../../assets/pause.svg";

export default function PollProgressBar({
  data,
  current,
  setCurrent,
  navigate,
  paused,
  setpaused,
}) {
  const currentProgress =
    document.getElementsByClassName(`progress-cell`)[current];
  const togglePause = () => {
    setpaused(!paused);
  };
  useEffect(() => {
    if (currentProgress) {
      currentProgress.style.animationPlayState =
        paused === false ? "running" : `paused`;
    }
  }, [paused]);
  useEffect(() => {
    if (currentProgress) {
      currentProgress.addEventListener(
        "animationend",
        function () {
          navigate("next");
        },
        false
      );
      return () => {
        currentProgress.removeEventListener("animationend", function () {});
      };
    }
  }, [currentProgress]);
  return (
    <div className="poll-progressbar-wrapper">
      <div onClick={togglePause} className={"pause-button"}>
        <img alt="play-pause-icon" src={paused ? playIcon : pauseIcon} />
      </div>
      {data.map((el, i) => (
        <div
          style={{
            width: 95 / data.length + "%",
          }}
          onClick={() => setCurrent(i)}
          className={`poll-progressbar-cell-clickable ${
            i < current ? "previous" : ""
          } ${i == current ? "current" : ""}`}
          key={el.id}
        >
          <div
            className={`poll-progressbar-cell ${
              i < current ? "previous" : ""
            } ${i == current ? "current" : ""}`}
          >
            <div className={`progress-cell ${i}`}></div>
          </div>
        </div>
      ))}
    </div>
  );
}
