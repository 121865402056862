import React from "react";
import { copyToClipboard } from "../../../../helpers/copyToClipboard";
import { Modal, notification } from "antd";
import jumpman from "../../../../assets/Dashboard/jumpman.svg";
import { MdOutlineContentCopy } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import "./PlaybookStopModal.scss";
import Button from "../../../../components/SignupProcess/Button/Button";

export default function PlaybookStopModal({
  showStopModal,
  setShowStopModal,
  stopPlaybook,
  deletePlaybook,
}) {
  return (
    <Modal
      open={showStopModal}
      className="playbook-success playbook-stop-modal"
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(0,0,0,0.3)",
        backdropFilter: "blur(10px)",
      }}
      onCancel={() => setShowStopModal(false)}
    >
      <div className="icon-holder">
        <AiOutlineStop />
      </div>
      <h3>
        {showStopModal === "stop" ? "Stop playbook?" : "Delete playbook?"}
      </h3>
      <b>{showStopModal === "delete" && "This can’t be undone."}</b>
      <div className="modal-btns">
        <Button
          label={"CANCEL"}
          height="44px"
          border="none"
          background="#0000000F"
          textColor="#3F3F46"
          action={() => setShowStopModal(false)}
        />
        <Button
          label={showStopModal && showStopModal?.toUpperCase()}
          height="44px"
          border="none"
          background="#B42318"
          textColor="#fff"
          action={showStopModal === "stop" ? stopPlaybook : deletePlaybook}
        />
      </div>
    </Modal>
  );
}
