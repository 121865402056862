import requestDM from '../requestDM'
import { getUserByArtist } from './getUserByArtist';

export const sendMessage = async ({ title, message, artistId, totalMembers }) => {
    var response;
    const fans = [...totalMembers];
    const artistName = JSON.parse(localStorage.getItem('loginUser')).artistName
    const object = {
        title,
        message,
        artist_id: artistId,
        sendTo: fans,
        from: artistName
    }
    try {
        response = await requestDM(object, `/sms/send`, 'POST')
        return response
    } catch (error) {

        return []
    }
}