import React, { useState } from "react";
import "./ImportModal.scss";
import { Modal } from "antd";
import Button from "../../../../../components/SignupProcess/Button/Button";
import Papa from "papaparse";
import { GrCircleAlert, GrClose } from "react-icons/gr";
import { LuFileUp } from "react-icons/lu";
import jumpman from "../../../../../assets/Dashboard/jumpman.svg";
import { csvImport } from "../../../../../api/dmmeendpoints/csvImport";

export default function ImportModal({
  isOpen,
  setIsOpen,
  setSegmentData,
  setCheckedSegments,
  checkedSegments,
  segmentDataSt,
}) {
  const [uploaded, setUploaded] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(false);
  const [CSVData, setCSVData] = useState(false);
  const [importing, setImporting] = useState(false);
  const [innerModalOpen, setInnerModalOpen] = useState(false);

  const DragDropFile = ({ type = "image" }) => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);

    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      uploadFunction(e.dataTransfer.files);
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
      e.preventDefault();
      uploadFunction(e.target.files);
    };

    const uploadFunction = async (files) => {
      const file = files[0];
      const parsed = Papa.parse(file, {
        header: true,
        complete: function (results) {
          if (results.data?.[0]?.mobile_number) {
            for (let i = 0; i < results.data.length; i++) {
              const { mobile_number } = results.data[i];
              if (mobile_number.includes("E") || mobile_number.includes("e")) {
                setError(
                  "Sorry, We could not import these members. Please check the number formats and try again"
                );
                return;
              }
            }
            setError(false);
            setUploaded(file);
            setFileName(file.name.substring(0, file.name.lastIndexOf(".")));
            setCSVData(results.data);
          } else {
            setError("Missing header: mobile_number");
            setUploaded(null);
            setFileName(null);
          }
        },
      });
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef.current.click();
    };
    return (
      <form
        key={type}
        id="form-file-upload"
        className={`csv-import-form ${uploaded ? "smaller" : ""}`}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={"drag-active"}
        >
          <div
            className={`inner-csv-import ${uploaded ? "row-direction" : ""}`}
          >
            {uploaded ? (
              <div className="file-wrapper">
                <div className="file-icon">
                  <LuFileUp />
                </div>
                <div className="file-info">
                  <b>{uploaded.name}</b>
                  <p>{Number(uploaded.size / 1024).toFixed(2)} KB</p>
                </div>
              </div>
            ) : (
              <>
                <b>Add your members</b>
                <p>Import an existing list of phone numbers via CSV </p>
              </>
            )}
            <div className="upload-button">
              <Button
                label={uploaded ? "REPLACE FILE" : "IMPORT CSV"}
                action={onButtonClick}
                border={"none"}
                background="#ECECED"
              />
            </div>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
        {/* {uploaded && <img className={"uploaded-img"} src={uploaded} />} */}
      </form>
    );
  };
  const importSegment = async () => {
    setImporting(true);
    const { claimed_phone_number: artist_number, artist_id } = JSON.parse(
      localStorage.getItem("dbUser")
    );
    const newObj = {
      artist_number,
      artist_id,
      segment_name: fileName,
      CSVData,
    };
    const response = await csvImport(newObj);
    if (!response.error) {
      setInnerModalOpen(false);
      setIsOpen(false);
      const newObj = { ...checkedSegments };
      const array = Object.keys(checkedSegments);
      if (!Object.values(checkedSegments).includes(false)) {
        for (let i = 0; i < array.length; i++) {
          const el = array[i];
          newObj[el] = false;
        }
      }
      newObj[response.segment_id] = true;
      setSegmentData([...segmentDataSt, response]);
      setCheckedSegments(newObj);
      setUploaded(null);
      setImporting(false);
    }
  };
  return (
    <>
      <Modal
        key={"import-modal-csv"}
        title="Import"
        className="import-csv-modal"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={
          <div className="import-csv-footer">
            <a
              href={`https://dmme.s3.eu-west-2.amazonaws.com/sample/import_file_sample.csv`}
              download
            >
              DOWNLOAD SAMPLE CSV
            </a>
            <div className="import-csv-modal-buttons">
              <Button
                label={"CANCEL"}
                background="#ECECED"
                border="none"
                action={() => setIsOpen(false)}
              />
              <Button
                label={"IMPORT"}
                border="none"
                disabled={!uploaded}
                action={(e) => {
                  setFileName(
                    uploaded?.name?.substring(
                      0,
                      uploaded?.name?.lastIndexOf(".")
                    )
                  );
                  setInnerModalOpen(true);
                  setIsOpen(false);
                }}
              />
            </div>
          </div>
        }
      >
        <div className="modal-body">
          {error && (
            <div className="error-csv-import">
              <GrCircleAlert />
              <div>
                <b>Your CSV file wasn't uploaded</b>
                <p>{error}</p>
              </div>
              <GrClose onClick={() => setError(false)} />
            </div>
          )}
          <DragDropFile />
        </div>
      </Modal>
      <Modal
        key={"import-modal-name"}
        className="import-csv-modal import-csv-modal-name"
        open={innerModalOpen}
        onCancel={() => {
          setInnerModalOpen(false);
          setIsOpen(true);
        }}
        footer={
          <div className="import-csv-footer-name">
            <div className="import-csv-modal-buttons">
              <Button
                label={"CANCEL"}
                background="#ECECED"
                border="none"
                action={() => {
                  setIsOpen(true);
                  setInnerModalOpen(false);
                }}
              />
              <Button
                label={"CONFIRM"}
                border="none"
                disabled={!uploaded || !fileName || importing}
                action={importSegment}
              />
            </div>
          </div>
        }
      >
        <div className="modal-body segment-name-modal">
          <div className="logo">
            <img src={jumpman} />
          </div>
          <h1>Import Sorted!</h1>
          <p>Please name your segment for reference.</p>
          <input
            type="text"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            placeholder="Segment name"
          />
        </div>
      </Modal>
    </>
  );
}
