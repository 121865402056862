import React, { useEffect, useState } from "react";
import Cards from "./Cards/Cards";
import "./Insights.scss";
import rightArrow from "../../../assets/SignUpProcess/arrow-right.svg";
import Button from "../../../components/SignupProcess/Button/Button";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import PlatformsRecomended from "../../../assets/platforms-recomended.svg";
import InsightCard from "./InsightCard/InsightCard";
import TipsModal from "../../../components/TipsModal/TipsModal";
import {
  getEngagement,
  getFanbase,
} from "../../../api/dmmeendpoints/insightsEndpoints";
import { engagementCard, fanbaseCard, valueXFan } from "./HelperCards";
import { percIncrease } from "../../../helpers/percIncrease";
import { PopupModal } from "react-calendly";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { segmentData } from "../../../api/dmmeendpoints/segmentData";
import moment from "moment-timezone";
import CustomInsightsRange from "./CustomInsightsRange/CustomInsightsRange";

export const Insights = () => {
  const [visible, setVisible] = useState(false);
  const [insightCards, setInsightCards] = useState([]);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    start: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end: moment(),
  });

  const history = useHistory();

  const getTotalMembers = async () => {
    const { artist_id } = JSON.parse(localStorage.getItem("dbUser"));
    let members = [];
    const segmentInfo = await segmentData(artist_id);
    //
    let checkedInfo = {};

    members = [...members];

    for (let i = 0; i <= segmentInfo?.length; i++) {
      const el = segmentInfo[i];
      const innerMembers = [];
      if (el) {
        if (el.members.length > 0) {
          checkedInfo = { ...checkedInfo, [el.segment_id]: true };
        }
        for (let j = 0; j < el.members.length; j++) {
          const innerEl = el.members[j];
          innerMembers.push(
            (!innerEl?.metadata?.primary_mobile.includes("+") ? "+" : "") +
              innerEl?.metadata?.primary_mobile
          );
        }
        members = [...members, ...innerMembers];
      }
    }
    const uniqueNumbers = members.filter(
      (value, index, array) => array.indexOf(value) === index
    );

    return uniqueNumbers;
  };

  const getData = async () => {
    const engagements = await getEngagement(
      selectedOption.start_date,
      selectedOption.end_date
    );
    const fanbase = await getFanbase(
      selectedOption.start_date,
      selectedOption.end_date
    );
    const totalMembers = await getTotalMembers();
    const data = await Promise.all([engagements, fanbase]);
    const engagementsData = {
      ...data[0],
      firstPercent: Math.round(data[0]?.firstPercent),
      secondPercent: Math.round(data[0]?.secondPercent),
    };
    const fanbaseData = data[1];

    const engagementCardFinal = {
      ...engagementCard.normal,
      button: {
        label: "CREATE NEW MESSAGE",
        action: () => {
          history.push("/dashboard/community");
        },
      },
      smallSign: "%",
      percent: engagementsData.secondPercent || 0,
      compare:
        engagementsData.firstPercent === 0
          ? 0
          : engagementsData.secondPercent - engagementsData.firstPercent,
    };
    const fanbaseCardFinal = {
      ...fanbaseCard[fanbaseData?.firstFanBase === 0 ? "blank" : "normal"],
      percent: percIncrease(
        fanbaseData?.firstFanBase,
        fanbaseData?.secondFanBase
      ),
      compare: fanbaseData?.secondFanBase - fanbaseData?.firstFanBase,
      description: `
      <div class='description-inner-left'>
      <div class='label'>Total Members</div>
      <div class='value'>${totalMembers.length}</div>
      </div> `,
    };
    let cardsArray = [];
    cardsArray[0] =
      engagementsData?.firstPercent > 0 || engagementsData?.secondPercent > 0
        ? engagementCardFinal
        : {
            ...engagementCard.blank,
            button: {
              label: "SHOW TIPS",
              action: () => {
                setVisible(true);
              },
            },
          };
    cardsArray[1] =
      fanbaseData?.firstFanBase > 0 || fanbaseData?.secondFanBase > 0
        ? fanbaseCardFinal
        : fanbaseCard.blank;
    cardsArray[2] = {
      ...valueXFan.blank,
      button: {
        label: `LET'S CHAT`,
        action: () => {
          setIsCalendlyOpen(true);
        },
      },
    };
    setInsightCards(cardsArray);
  };

  useEffect(() => {
    if (selectedOption) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [selectedOption]);

  return (
    <>
      <div className="insights-wrapper">
        <h1>Insights</h1>
        <CustomInsightsRange
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <div className="insight-cards">
          {insightCards.map((item, i) => (
            <InsightCard key={i} {...item} />
          ))}
        </div>
      </div>
      <TipsModal
        isOpen={visible}
        toggleModal={setVisible}
        setIsCalendlyOpen={setIsCalendlyOpen}
      />
      <PopupModal
        url="https://calendly.com/d/yks-b38-rrh/chat-with-a-growth-expert"
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById("root")}
      />
    </>
  );
};
