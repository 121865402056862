import React, { useState, useEffect, useContext, useCallback } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { useHistory, useLocation } from "react-router";
import "./EmailSent.scss";
import axios from "axios";
import QueryString from "qs";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import { AppContext } from "../../contexts/AppContext";
import { sendSMS } from "../../api/twilio";
import { createPhoneCheck, getPhoneCheckResult } from "../../api/truPhoneCheck";
import DynamicInput from "../../components/DynamicInput/DynamicInput";
import mailIcon from "../../assets/mailIcon.svg";
import { updateUser } from "../../api/endpoints/createUser";
import chevronRight from "../../assets/chevron-right.svg";
import {
  getGoogleUser,
  googleLogin,
  loginUser,
  logoutUser,
  checkUser,
} from "../../magic";

const accountSid = "AC6b23b91b120dfaa151c54561c1a19969";
const authTokenWP = "e7e93d58c511265afe4fcf2cda0d473b";

export default function EmailSent({ data }) {
  const { emailSentBackground, emailSent, emailSentDescription } = data;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { authToken, user } = useContext(AppContext);

  const history = useHistory();
  const location = useLocation();
  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  useEffect(() => {
    document.title = data.artist;
  }, [document]);

  useEffect(() => {
    checkUser(isThereAnyUser);
  }, []);
  const isThereAnyUser = async (data) => {
    if (data.isLoggedIn) {
      history.push("/success");
    }
    setLoading(false);
  };
  useEffect(() => {
    handleLogins();
  }, [location]);
  const handleLogins = async () => {
    if (location.state.email) {
      setEmail(location.state.email);
      const loggedInUser = await loginUser(
        location.state.email,
        window.location.origin + "/success"
      );
      await updateUser({
        user: JSON.parse(localStorage.getItem("userData")),
        data: { email_verified: true },
      });
      history.push("/success");
    } else {
      history.push("/join");
    }
  };
  return (
    <div className="landing-email-sent-styles">
      <div
        style={{
          background: `url(${emailSentBackground})`,
        }}
        className="desktop-wrapper"
      ></div>
      <div className="landing-email-sent-background">
        {loading && (
          <div className="overlay-loading">
            <FadeLoader color={"#fff"} />
          </div>
        )}
        <div
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 35.08%, #000000 100%), url(${emailSentBackground}) no-repeat`,
          }}
          className="landing-email-sent-wrapper"
        >
          <div className="content-wrapper">
            <div className="text-wrapper">
              <h1>{emailSent}</h1>
              <h3>{emailSentDescription}</h3>
            </div>
            <div className="copyright" onClick={handleLogins}>
              <p>
                Resend the email<br></br>(I checked my Spam)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
