import React, { useState, useEffect, useContext } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getPollData, getPollVotes } from "../../api/endpoints/polls";
import { AppContext } from "../../contexts/AppContext";
import InstaProfile from "../../components/InstaProfile/InstaProfile";
import PollProgressBar from "../../components/PollProgressBar/PollProgressBar";
import Voter from "../../components/Voter/Voter";
import { nanoid } from "nanoid";
import "./Polls.scss";
import getParameterByName from "../../helpers/getQueryParams";
import { checkAPIUser } from "../../api/endpoints/createUser";
import { isMobile } from "react-device-detect";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import AbsoluteNavigator from "../../components/AbsoluteNavigator/AbsoluteNavigator";
import { Modal } from "react-responsive-modal";
import {
  getLinkToShare,
  ShareComponent,
  shareOptions,
} from "../../helpers/shareOptions";
import InstructionOverlay from "../../components/InstructionOverlay/InstructionOverlay";

export default function Polls({ data }) {
  const [apiData, setApiData] = useState([]);
  const [options, setOptions] = useState(null);
  const [alreadyVoted, setAlreadyVoted] = useState(null);
  const [alreadyVotedLength, setAlreadyVotedLength] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [navigationNeeded, setnavigationNeeded] = useState(false);
  const [current, setCurrent] = useState(0);
  const [pollId, setPollId] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [paused, setpaused] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(AppContext);
  const [userData, setUserData] = useState(null);
  const [ShowInstructionOverlay, setShowInstructionOverlay] = useState(false);
  const { pollSuccess, pollSuccessBackground } = data;

  useEffect(() => {
    const effectFunc = async () => {
      const email = getParameterByName("email");
      let apiUser = null;
      if (email) {
        apiUser = await checkAPIUser({ email });
        localStorage.setItem("userData", JSON.stringify(apiUser));
        setUserData(apiUser);
      } else {
        setUserData(
          user ||
            JSON.parse(localStorage.getItem("userData")) ||
            (localStorage.getItem("anonymousId") && {
              user_id: localStorage.getItem("anonymousId"),
            }) || {
              user_id: generateAnonymousId(),
            }
        );
      }
    };
    effectFunc();
  }, [location.search]);
  const generateAnonymousId = () => {
    const anonymousId = "anonymous-" + nanoid();
    localStorage.setItem("anonymousId", anonymousId);
    return anonymousId;
  };

  const setPercentages = (votes) => {
    const oldState = [...apiData];
    oldState[current].votes = votes;
    setApiData(oldState);
  };
  useEffect(() => {
    const { pathname } = location;
    const poll_id = pathname.split("/")[2];
    setPollId(poll_id);
    getPolls(poll_id);
  }, [userData]);
  const getPolls = async (poll_id) => {
    if (userData) {
      const apiData = await getPollData({ poll_id });
      const pollVotes = await getPollVotes({
        poll_id,
        user_id: userData?.user_id,
      });
      const percentages = pollVotes.percentageArr;
      setAlreadyVoted(pollVotes.votesArr);
      setAlreadyVotedLength(pollVotes.votesArr.length);
      setTimeout(() => {
        setOptions(apiData.options);
      }, 1200);
      const realData = Object.values(apiData?.story_data).map((item, i) => ({
        ...item,
        id: i,
        votes: {
          left: percentages[i].firstOptionCount,
          right: percentages[i].secondOptionCount,
        },
      }));
      setApiData([
        ...realData,
        {
          id: realData.length,
          image: pollSuccessBackground,
          votes: { left: 0, right: 0 },
        },
      ]);
    } else {
      // history.push("/");
      localStorage.setItem("redirect_to", location.pathname);
    }
  };
  const navigate = (action) => {
    setShowInstructionOverlay(false);
    if (action === "prev" && current - 1 >= 0) {
      setCurrent(current - 1);
    } else if (action === "next" && current + 1 <= apiData.length - 1) {
      setCurrent(current + 1);
    } else if (action === "next" && current + 1 > apiData.length - 1) {
      const newPath =
        location.pathname.charAt(location.pathname.length - 1) == "/"
          ? `${location.pathname}success`
          : `${location.pathname}/success`;
      // history.push(newPath);
    }
  };
  const update = () => {
    getPolls(pollId);
  };
  const showInstructionOverlay = () => {
    setpaused(true);
    setShowInstructionOverlay(true);
    setTimeout(() => {
      setShowInstructionOverlay(false);
      setpaused(false);
    }, 2500);
  };

  const handleSharing = async () => {
    const shareDetails = { url: getLinkToShare() };
    if (isMobile) {
      if (navigator.share) {
        try {
          await navigator.share(shareDetails).then(() => {});
        } catch (error) {}
      } else {
        showModal();
      }
    } else {
      showModal();
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="polls-desktop-wrapper">
      <AbsoluteNavigator
        action={"prev"}
        navigate={navigate}
        paused={paused}
        setpaused={setpaused}
        showInstructionOverlay={showInstructionOverlay}
      />
      <AbsoluteNavigator
        action={"next"}
        navigate={navigate}
        paused={paused}
        setpaused={setpaused}
        showInstructionOverlay={showInstructionOverlay}
      />
      <div className="right-navigator"></div>
      <div className="polls-wrapper">
        <PollProgressBar
          data={apiData}
          current={current}
          navigate={navigate}
          paused={paused}
          setpaused={setpaused}
          setCurrent={(i) => setCurrent(i)}
        />
        <InstaProfile />
        <h1 className={`${animating ? "animated" : ""}`}>
          {apiData?.[current]?.text}
        </h1>
        {apiData?.[current]?.image && (
          <img
            key={apiData?.[current]?.id + "_img"}
            className={`poll-pic ${animating ? "animated" : ""} ${
              current === apiData.length - 1 ? "full-bleed" : ""
            }`}
            src={apiData?.[current]?.image}
          />
        )}
        {current === apiData.length - 1 && <div className="img-overlay" />}
        {current === apiData.length - 1 && (
          <h1 className="success-h1">{pollSuccess}</h1>
        )}
        {current !== apiData.length - 1 ? (
          <Voter
            key={current + "_voter"}
            alreadyVoted={alreadyVoted}
            current={current}
            options={options}
            user={userData}
            pollId={pollId}
            percentages={apiData?.[current]?.votes}
            setPercentages={setPercentages}
            alreadyVotedLength={alreadyVotedLength}
            setAlreadyVotedLength={setAlreadyVotedLength}
            update={update}
            setnavigationNeeded={setnavigationNeeded}
          />
        ) : (
          <DynamicButton icon={""} text="ASK A FRIEND" action={handleSharing} />
        )}
        {ShowInstructionOverlay && <InstructionOverlay navigate={navigate} />}
      </div>
      <Modal open={isModalVisible} onClose={handleCancel} footer={null} center>
        <div className="share-modal-title">Share to...</div>
        {shareOptions.map((el, i) => {
          return (
            <ShareComponent
              key={i}
              text={el.name}
              icon={el.icon}
              action={el.action}
            />
          );
        })}
      </Modal>
    </div>
  );
}
