import React from "react";
import "./RaffleFormPreview.scss";
import { Modal } from "antd";
import RaffleForm from "../../../../../RaffleForm/RaffleForm";

export default function RaffleFormPreview({ isOpen, setIsOpen, raffleData }) {
  return (
    <Modal
      open={isOpen}
      title={null}
      footer={null}
      onCancel={() => setIsOpen(false)}
      className="raffle-form-preview"
    >
      <RaffleForm preview raffleData={raffleData} />
    </Modal>
  );
}
