import React, { useEffect, useState } from "react";
import "./WhatsappBuilder.scss";
import { LuArrowRightToLine } from "react-icons/lu";
import IPhone from "../../../../assets/PHONE.png";
import WhatsappUI from "./WhatsappUI/WhatsappUI";
import { TreeView } from "./Treeview/Treeview";

export default function WhatsappBuilder({
  flowName,
  flowToStoreData,
  setFlowToStoreData,
}) {
  const [height, setHeight] = useState(0);
  const [pathways, setPathways] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const getUpdatedPathways = (newState) => {
    setPathways(newState);
  };
  useEffect(() => {
    const img = document.getElementById("iphone-img");
    if (img) {
      new ResizeObserver((e) => {
        setHeight(e[0]?.contentRect?.height);
      })?.observe(img);
    }
    return () => {
      img.remove();
    };
  }, []);

  const getDataToStore = (data) => {
    setFlowToStoreData(data);
  };

  return (
    <div className="whatsapp-builder-wrapper">
      <div
        className={`whatsapp-sidebar ${
          sidebarOpen ? "whatsapp-sidebar-open" : ""
        }`}
      >
        {sidebarOpen && <b>PATHS</b>}
        <LuArrowRightToLine
          size={24}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />

        <div
          className="whatsapp-sidebar-paths"
          style={{ opacity: sidebarOpen ? 1 : 0, marginTop: 50 }}
        >
          <TreeView treeData={pathways} flowName={flowName} />
        </div>
      </div>
      <div className="whatsapp-section">
        <div className="whatsapp-UI-section" style={{ height }}>
          <img id={"iphone-img"} src={IPhone} alt="iphone-frame" />
          <WhatsappUI
            getUpdatedPathways={getUpdatedPathways}
            getDataToStore={getDataToStore}
            flowToStoreData={flowToStoreData}
          />
        </div>
      </div>
    </div>
  );
}
