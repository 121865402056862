import axios from "axios";
import QueryString from "qs";
import { getSupport } from "../../helpers/detectSupport";
import request from '../request'
import requestDM from '../requestDM'

export default async (phone, body, from) => {
    const messageData = {
        message: body,
        sendTo: [phone],
        from
    }
    await request(messageData, '/sms/send', 'create')
    return true;
};

export const newSmsSend = async (from, to, message) => {
    const messageData = {
        message: message,
        to,
        from,
        password: "mRK7BEGfBUW4Ndo7JwxgBy9ssevpxdiF3ZxqVAo9",
    }
    await requestDM(messageData, '/smsSender/send', 'post')
    return true;
}