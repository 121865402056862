import React, { useState, useEffect } from "react";
import "./DynamicInput.scss";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { LuArrowRight } from "react-icons/lu";

export default function DynamicInput({
  icon,
  placeholder,
  actionOnRegex,
  leftIcon,
  rightIcon,
  iconAction,
  name,
}) {
  const [inputValue, setInputValue] = useState("");
  const [valid, setValid] = useState(false);
  const emailREGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const updateInputValue = (e) => {
    setInputValue(e.target.value);
    if (emailREGEX.test(e.target.value)) {
      actionOnRegex(true, e.target.value);
    } else {
      actionOnRegex(false);
    }
  };
  useEffect(() => {
    if (emailREGEX.test(inputValue)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [inputValue]);

  const action = (e) => {
    e?.preventDefault();
    if (emailREGEX.test(inputValue)) {
      iconAction();
    } else {
      setValid(false);
    }
  };
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      action();
    }
  };

  return (
    <div
      class={`input-container ${inputValue ? "active" : ""} ${
        valid ? "" : "not-valid"
      }`}
    >
      {leftIcon ? (
        <div
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            onClick={action}
            className="icon-left"
            alt={`left-icon`}
            src={icon}
          />
        </div>
      ) : null}
      <input
        class="input-field"
        type="text"
        value={inputValue}
        placeholder={placeholder}
        name={name || placeholder}
        onKeyPress={handleEnterPress}
        onChange={(e) => updateInputValue(e)}
      />
      {rightIcon ? (
        <button
          className="right-icon-btn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: valid && "var(--tab-color)",
          }}
          onClick={action}
        >
          <LuArrowRight size={24} color={valid ? "#000000" : "#BCBCBF"} />
        </button>
      ) : (
        <div style={{ width: "40px" }} />
      )}
    </div>
  );
}
