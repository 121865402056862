
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAHUo0V48llyoQXVnvbiVPQzge8rno3q1M",
    authDomain: "auth.iindy.co",
    projectId: "iindy-artists",
    storageBucket: "iindy-artists.appspot.com",
    messagingSenderId: "467725622288",
    appId: "1:467725622288:web:3a747a6b2f4e64916c3e95",
    measurementId: "G-4P771LPYFC"
};

// Initialize Firebase 
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => {
    try {
        auth.signInWithPopup(provider)
    } catch (error) {

    }

}
export const signOutGoogle = async () => auth.signOut()