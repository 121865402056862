import React, { useEffect, useState } from "react";
import "./GetVerifiedRaffle.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/SignupProcess/Button/Button";
import Copyright from "../../components/Copyright/Copyright";
import { getContrastColor } from "../../helpers/getContrastColor";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { verifyMembership } from "../../api/dmmeendpoints/verifyOwnership";
import IOSLikeLoader from "../../components/IOSLikeLoader/IOSLikeLoader";
import { notification } from "antd";
import { ReactComponent as LighterHead } from "../../assets/Dashboard/lighter-head.svg";

export default function GetVerifiedRaffle() {
  const [config, setConfig] = useState({});
  const [number, setNumber] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [isNotAMember, setIsNotAMember] = useState(false);
  const history = useHistory();
  const params = useParams();
  useEffect(() => {
    if (history.location.state) {
      setConfig(history.location.state.config);
    } else {
      history.push(`/${params.id}`);
    }
  }, [history.location.state]);

  const verifyNumber = async () => {
    setLoadingState("loading");
    const data = await verifyMembership({
      number,
      artist_iindy_id: config.raffle_iindy_owner,
      redirect: `${window.location.origin}/${params.id}`,
    });
    if (!data.error) {
      setTimeout(() => {
        setLoadingState("success");
        setTimeout(() => {
          // setLoadingState(false);
        }, 2000);
      }, 2000);
    } else {
      setNumber(null);
      setLoadingState(false);
      setIsNotAMember(true);
    }
  };

  const { raffle_details, collectibles_needed } = config;
  const { raffle_page_appearance: appearance, access_page } =
    raffle_details || {};
  if (!config.raffle_details) {
    return null;
  }
  if (isNotAMember) {
    return (
      <div
        className="error-not-a-member"
        style={{
          backgroundColor: appearance.background_color,
          color: appearance.text_color,
          fill: appearance.text_color,
        }}
      >
        <LighterHead style={{ fill: "currentcolor" }} />
        <h1>Uhm, I can’t find you </h1>
        <b>
          If you think something is wrong, make sure you provided the right
          mobile number.
        </b>
        <div className="not-a-member-actions">
          {/* <Button
            border="none"
            action={() => {
              setIsNotAMember(false);
            }}
            background={appearance.button_color}
            textColor={appearance.button_text_color}
            label={"ASK TO JOIN"}
          /> */}
          <Button
            border="none"
            action={() => {
              setIsNotAMember(false);
            }}
            background={getContrastColor(appearance.background_color)}
            textColor={getContrastColor(appearance.text_color)}
            label={"GO BACK"}
          />
        </div>
        <Copyright />
      </div>
    );
  }

  return (
    <div
      className="get-verified-raffle"
      style={{
        backgroundColor: appearance.background_color,
        color: appearance.text_color,
      }}
    >
      <div className="get-verified-content">
        <img className="get-verified-img" src={raffle_details.raffle_image} />
        <h1>Are you a member?</h1>
        <b>
          Before you can continue, we need to send a verification link to your
          mobile number.
        </b>
        <PhoneNumber
          phoneNr={""}
          getPhoneNumber={setNumber}
          phoneInputPlaceholder={"Phone number"}
          dontShowLabel={true}
        />
        <Button
          border="none"
          action={verifyNumber}
          background={appearance.button_color}
          textColor={appearance.button_text_color}
          disabled={!number}
          label={"VERIFY"}
          height="44px"
        />
        <b
          className="copyright-text"
          style={{ color: getContrastColor(appearance.background_color) }}
        >
          By continuing, you agree to our{" "}
          <a
            style={{
              textDecorationColor: getContrastColor(
                appearance.background_color
              ),
            }}
            href="https://iindy.co/terms"
            target="_blank"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            style={{
              textDecorationColor: getContrastColor(
                appearance.background_color
              ),
            }}
            href="https://iindy.co/privacy"
            target="_blank"
          >
            privacy policy
          </a>
          .
        </b>
        <Copyright />
      </div>
      <IOSLikeLoader
        loadingState={loadingState}
        loadingText={"Sending message"}
        successText={"Message sent"}
      />
    </div>
  );
}
