import React, {
  useState,
  useEffect
} from 'react';

import {
  signInWithUsernameOnly
} from '../api/backend';


export const AppContext = React.createContext(null);

export function AppContextProvider(props) {
  const [user, setStateUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [referred_by, setReferredBy] = useState(null);
  const [showDelete, setShowDelete] = useState(false);


  // useEffect(() => {

  //   // signInWithUsernameOnly('eabac3cc-08b9-4dc1-b3fb-3f403dc2a64f')
  //   signInWithUsernameOnly('c0851871-c02c-4fb0-83a2-3a7331800ad6')
  //     .then(signInResult => {
  //       // ;

  //       if (signInResult.success) {
  //         setAuthToken(signInResult.token);
  //       }

  //     })

  // }, []);

  const setUser = (userData) => {
    setStateUser(userData)
    localStorage.setItem(
      "userData",
      JSON.stringify(userData)
    );
  }


  return (
    <AppContext.Provider

      value={{
        authToken,
        user,
        setUser,
        referred_by,
        setReferredBy,
        showDelete,
        setShowDelete
      }}>

      {props.children}
    </AppContext.Provider>
  );
}
