import React, { useEffect, useState } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import GoldenTicket from "../../components/GoldenTicket/GoldenTicket";
import { useHistory, useLocation } from "react-router";
import spotifyAppleBackground from "../../assets/spotifyAppleBackground.png";
import axios from "axios";
import QueryString from "qs";

import instaIcon from "../../assets/insta.svg";
import whatsappIcon from "../../assets/whatsapp.svg";
import emailIcon from "../../assets/email.svg";
import twitterIcon from "../../assets/twitter.svg";
import clipboardIcon from "../../assets/clipboard.svg";
import messengerIcon from "../../assets/messenger.svg";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./MediaServicesSuccess.scss";
import { customNotification } from "../../helpers/customNotification";

export default function MediaServicesSuccess() {
  const [appleUserToken, setAppleUserToken] = useState("...");
  const [spotifyTokenData, setSpotifyTokenData] = useState(null);
  const [spotifyCode, setSpotifyCode] = useState("...");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const linkToShare =
    "I've got you a guest list pass for Wayne Snow. Claim it now at waynesnow.iindy.co/guest-o3948riww";
  const messengerShare = () => {
    window.open(
      `http://www.facebook.com/dialog/send?app_id=578540233222013&link=${"waynesnow.iindy.co/guest-o3948riww"}&redirect_uri=https://waynesnow.iindy.co`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const whatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send/?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const twitterShare = () => {
    window.open(
      `https://twitter.com/messages/compose?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const emailShare = () => {
    window.open(
      `mailto:?subject=Be part of this Family!&body=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const copyLink = async () => {
    await navigator.clipboard.writeText(linkToShare);
    // customNotification("Copied to clipboard successfully!")
  };
  const shareOptions = [
    // { name: "Share to Instagram", icon: instaIcon, action: instagramShare },
    { name: "Share to Messenger", icon: messengerIcon, action: messengerShare },
    { name: "Share to WhatsApp", icon: whatsappIcon, action: whatsappShare },
    { name: "Share to Twitter", icon: twitterIcon, action: twitterShare },
    { name: "Share via Email", icon: emailIcon, action: emailShare },
    { name: "Copy Link", icon: clipboardIcon, action: copyLink },
  ];

  useEffect(() => {
    getSpotifyToken(window.location.href);
  }, [window.location.href]);
  const getSpotifyToken = (hash) => {
    if (hash.includes("code")) {
      const code = hash.substring(hash.indexOf("code=") + "code=".length);
      setSpotifyCode(code);
      axios
        .post("https://accounts.spotify.com/api/token", null, {
          params: {
            grant_type: "authorization_code",
            code: code,
            redirect_uri: process.env.REACT_APP_SPOTIFY_REDIRECT_URI,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic " +
              Buffer.from(
                process.env.REACT_APP_SPOTIFY_CLIENT_ID +
                  ":" +
                  process.env.REACT_APP_SPOTIFY_CLIENT_SECRET
              ).toString("base64"),
          },
        })
        .then((res) => {
          setSpotifyTokenData(res.data);
        });
    }
  };
  const getSpotifyNewAccessToken = () => {
    axios
      .post("https://accounts.spotify.com/api/token", null, {
        params: {
          grant_type: "refresh_token",
          refresh_token: spotifyTokenData.refresh_token,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic " +
            Buffer.from(
              process.env.REACT_APP_SPOTIFY_CLIENT_ID +
                ":" +
                process.env.REACT_APP_SPOTIFY_CLIENT_SECRET
            ).toString("base64"),
        },
      })
      .then((res) => {
        setSpotifyTokenData({
          ...spotifyTokenData,
          access_token: res.data.access_token,
        });
      });
  };
  const shareDetails = { title: "text", subtitle: "subtext" };
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {});
      } catch (error) {}
    } else {
      showModal();
    }
  };
  const history = useHistory();

  useEffect(() => {
    const phoneNumber = history.location.state
      ? history.location.state.phoneNumber && history.location.state.phoneNumber
      : null;
    if (phoneNumber) {
    }
  }, [history.location]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div
        style={{
          background: `linear-gradient(360deg, #14141A 59.48%, #2F6B00 100%)`,
        }}
        className="desktop-wrapper"
      ></div>
      <div
        style={{
          background: `linear-gradient(360deg, #14141A 59.48%, #2F6B00 100%)`,
        }}
        className="MediaServicesSuccess-wrapper"
      >
        {" "}
        <img
          src={spotifyAppleBackground}
          className="MediaServicesSuccess-background"
        />
        <h1 className="MediaServicesSuccess-heading"></h1>
        {/* <GoldenTicket /> */}
        <div className="MediaServicesSuccess-text-part">
          <h1 className="MediaServicesSuccess-explain-h1">
            All Set. <br />
            We’ll keep you updated.
          </h1>
          <h2 className="MediaServicesSuccess-explain-h2">
            &nbsp;
            {/* We will contact you via your preffered method to confirm if you made
            the list or not. */}
          </h2>
          <h3 className="MediaServicesSuccess-explain-h3">
            Get your friends to join you!
          </h3>
        </div>
        <div
          style={{
            marginTop: "50px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DynamicButton
            icon={"user"}
            text="Send to a friend"
            action={handleSharing}
          />
          <div className="copyright">
            <p>
              <a
                href={
                  spotifyTokenData
                    ? "https://www.spotify.com/us/account/apps/"
                    : ""
                }
                target="_blank"
              >
                Disconnect your {spotifyTokenData ? "Spotify" : ""} Account
              </a>
            </p>
          </div>
        </div>
      </div>

      <Modal open={isModalVisible} onClose={handleCancel} footer={null} center>
        <div className="share-modal-title">Share to...</div>
        {shareOptions.map((el, i) => {
          return (
            <ShareComponent
              key={i}
              text={el.name}
              icon={el.icon}
              action={el.action}
            />
          );
        })}
      </Modal>
    </>
  );
}
const ShareComponent = ({ text, icon, action }) => {
  return (
    <div onClick={action} className="share-component">
      <img src={icon} alt="share-icon" />
      {text}
    </div>
  );
};
