import React, { useState } from "react";
import Header from "../../components/SignupProcess/Header/Header";
import styles from "./MessageFlowBuilder.module.scss";
import minimise from "../../assets/SignUpProcess/minimise.svg";
import Switch from "react-switch";
import RenderFields from "../../components/SignupProcess/RenderFields/RenderFields";
import NavigationButtons from "../../components/SignupProcess/NavigationButtons/NavigationButtons";
import PhoneSimulator from "../../components/SignupProcess/PhoneSimulator/PhoneSimulator";
import { useEffect } from "react";
import SuccessModal from "./SuccessModal";
import { useHistory } from "react-router-dom";
import logoutIcon from "../../assets/SignUpProcess/logout.png";
import { useAuth0 } from "@auth0/auth0-react";
import { addBotConfig } from "../../api/dmmeendpoints/addBotConfig";
import { notification } from "antd";
import { checkIfArtistExists } from "../../api/dmmeendpoints/checkIfUserExists";
import { sanitizeFileName } from "../../helpers/sanitizeFilename";
import { newSeriesAtom, nftCollectionsAtom } from "../../atoms/newSeries";
import { useAtom } from "jotai";
import { getNewSeriesId } from "../../helpers/getNewSeriesId";
import { debounce } from "../../helpers/debounce";
import { artistUpdate } from "../../api/dmmeendpoints/artistUpdate";
import { customNotification } from "../../helpers/customNotification";

function MessageFlowBuilder({
  updating = false,
  getUpdatedValues = () => {},
  isLoading = false,
  stopWhenLoading = false,
  shouldShowRestore = false,
}) {
  const { logout, user } = useAuth0();
  const localUser = JSON.parse(localStorage.getItem("loginUser"));
  const dbData = JSON.parse(localStorage.getItem("dbUser"));
  const [newSeries, setNewSeries] = useAtom(newSeriesAtom);

  const [minimised, setMinimised] = useState(true);
  const [visible, setVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [, setNftCollections] = useAtom(nftCollectionsAtom);
  const [messagesToShow, setMessagesToShow] = useState([
    [
      { message: "Hello there", type: "string", from: "me", field: "" },
      {
        message:
          dbData.unsaved_bot_config?.welcome_message ||
          "Welcome to my hotline, a space for you to get special access to all things {{artist_name}}. To get access just reply with {{optin_phrase}}.",
        type: "string",
        field: "welcome_message",
        from: "them",
      },
      {
        message: dbData.unsaved_bot_config?.optin_phrase || "GET ME IN",
        type: "string",
        from: "me",
        field: "optin_phrase",
      },
    ],
    [
      {
        message:
          dbData.unsaved_bot_config?.contact_message ||
          "Cool! Tap this link to save my contact info...",
        type: "string",
        from: "them",
        field: "contact_message",
      },
      {
        type: "contact_info",
        from: "them",
        field: "contact_message",
      },
    ],
    [
      {
        message:
          "Thanks, I’ve got something for you. Tap this link to claim a special reward. ",
        type: "string",
        from: "them",
        field: "special-message",
      },
      {
        type: "audio-upload",
        from: "them",
        field: "special-share",
      },
    ],
    [
      {
        message:
          "Thanks for joining my hotline, Get your friends in using this link.",
        type: "string+referral",
        from: "them",
        field: "referral-link-message",
      },
    ],
    [],
  ]);
  const [steps, setSteps] = useState([
    {
      title: "Customise your welcome message",
      header: "Create your welcome message",
      enabled: true,
      fields: [
        {
          title: "Customise Message*",
          type: "textbox",
          field: "welcome_message",
          value: messagesToShow[0][1].message,
        },
        {
          title: "Edit Default {{artist_name}}",
          type: "text",
          field: "artist_name",
          value: localUser?.artistName || "",
        },
        {
          title: "Edit Default {{optin_phrase}}*",
          type: "text",
          value: messagesToShow[0][2].message,
          field: "optin_phrase",
        },
        { title: "Add a Tag", type: "tags", field: "tags" },
      ],
    },
    {
      title: "Share your contact info",
      header: "Share your contact info",
      miniLabel: "Share your info so your fans know it’s you messaging them",
      enabled: false,
      fields: [
        {
          title: "Message",
          type: "textbox",
          field: "contact_message",
          value: `Cool! Tap this link to save my contact info...`,
        },
        {
          title: "Contact info",
          type: "contact-info",
          fields: [
            { title: "Instagram", type: "text", field: "instagram" },
            { title: "TikTok", type: "text", field: "tiktok" },
            { title: "Twitter", type: "text", field: "twitter" },
            { title: "Website", type: "text", field: "website" },
            { title: "Email", type: "text", field: "email" },
          ],
        },
      ],
    },
    {
      title: "Reward your fans",
      enabled: true,
      fields: [
        {
          title: "Message",
          type: "textbox",
          field: "special-message",
          value:
            "Thanks, I’ve got something for you. Tap this link to claim a special reward. ",
        },
        {
          title: "Upload a media file",
          type: "audio-upload",
          field: "special_share",
        },
      ],
    },
    {
      title: "Grow your community via referrals",
      enabled: false,
      fields: [
        {
          title: "Edit Default Message",
          type: "textbox",
          field: "referral-link-message",
          value:
            "Thanks for joining my hotline, Get your friends in using this link.",
        },
      ],
    },
    {
      title: "Capture insight",
      enabled: false,
      fields: [
        {
          title: "Message",
          type: "text",
          field: "capture_message",
          value:
            "What’s one thing you would like from being a member of this hotline  that you can’t get anywhere else?",
        },
      ],
    },
  ]);

  const MiniHeader = ({ label, miniLabel, button = null }) => {
    return (
      <div className={styles.miniHeader}>
        <b className={styles.label}>{label}</b>
        {miniLabel && (
          <span
            className={[
              styles.miniLabel,
              ` ${activeStep === 2 ? styles.blueMiniLabel : null}`,
            ]}
          >
            {miniLabel}
          </span>
        )}
        {button && button()}
      </div>
    );
  };
  const MinimiseBtn = () => {
    return (
      <img
        className={styles.minimiseIcon}
        src={minimise}
        onClick={toggleMinimise}
      />
    );
  };

  const toggleMinimise = () => {
    setMinimised(!minimised);
  };
  useEffect(() => {
    const el = document.getElementById("scroll-container");
    el.scrollTop = el.scrollHeight;
  }, [activeStep]);
  const publish = async () => {
    const botData = JSON.parse(localStorage.getItem("botData"));
    const userId = user.sub;
    await addBotConfig({ data: botData, artistId: userId });
    await artistUpdate({
      object: {
        nft_collections:
          JSON.parse(localStorage.getItem("nft_collections")) || [],
      },
      artistId: userId,
    });
    checkIfArtistExists(user.sub);
    history.push("/dashboard/community");
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };
  const history = useHistory();

  const logOut = async () => {
    localStorage.clear();
    await logout();
    setTimeout(() => {
      history.push("/welcome");
    }, 2000);
  };
  useEffect(() => {
    const isLogged = localStorage.getItem("loginUser");
    if (!isLogged) {
      history.push("/welcome");
    } else {
      const dbUser = JSON.parse(localStorage.getItem("dbUser"));
      if (dbUser?.bot_config && !updating) {
        history.push("/dashboard/community");
      }
    }
  }, []);
  useEffect(() => {
    const newObj = {
      welcome_message: steps[0].fields[0].value
        ?.replace("{{optin_phrase}}", steps[0].fields[2].value)
        ?.replace("{{artist_name}}", steps[0].fields[1].value),
      artist_name: steps[0].fields[1].value,
      optin_phrase: steps[0].fields[2].value,
      tags: steps[0].fields[3].value || [],
      contact_message: steps[1].fields[0].value,
      contact_info: {
        profile_picture: steps[1].fields[1].value,
        instagram: steps[1].fields[1]?.fields[0].value,
        tiktok: steps[1].fields[1]?.fields[1].value,
        twitter: steps[1].fields[1]?.fields[2].value,
        website: steps[1].fields[1]?.fields[3].value,
        email: steps[1].fields[1]?.fields[4].value,
      },
      active_nft_collection_id: "welcome_bot_nft_collection",
      active_nft_series_id:
        getNewSeriesId(
          JSON.parse(localStorage?.getItem("dbUser"))?.bot_config
            ?.active_nft_series_id
        ) || "welcome_bot_nft_series_1",
      special_message: steps[2].fields[0].value,
      referral_link_message:
        steps[3].fields[0].value +
        ` https://${steps[0].fields[1].value
          .replaceAll(" ", "")
          .toLowerCase()}.iindy.co`,
      capture_message: steps[4].fields[0].value,
      [steps[0].title.replaceAll(" ", "_").toLowerCase()]: steps[0].enabled,
      [steps[1].title.replaceAll(" ", "_").toLowerCase()]: steps[1].enabled,
      [steps[2].title.replaceAll(" ", "_").toLowerCase()]: steps[2].enabled,
      [steps[3].title.replaceAll(" ", "_").toLowerCase()]: steps[3].enabled,
      [steps[4].title.replaceAll(" ", "_").toLowerCase()]: steps[4].enabled,
    };

    if (updating) {
      getUpdatedValues(newObj);
    } else {
      localStorage.setItem("botData", JSON.stringify(newObj));
    }
  }, [steps, sessionStorage.getItem(steps[2].fields[1].value), newSeries]);

  useEffect(() => {
    const { unsaved_bot_config, bot_config } = {
      ...JSON.parse(localStorage.getItem("dbUser")),
    };
    if (updating) {
      setSteps([
        {
          title: "Customise your welcome message",
          header: "Create your welcome message",
          enabled: unsaved_bot_config.customise_your_welcome_message ?? true,
          fields: [
            {
              title: "Customise Message*",
              type: "textbox",
              field: "welcome_message",
              value: unsaved_bot_config?.welcome_message,
            },
            {
              title: "Edit Default {{artist_name}}",
              type: "text",
              field: "artist_name",
              value: unsaved_bot_config?.artist_name || "",
            },
            {
              title: "Edit Default {{optin_phrase}}*",
              type: "text",
              value: unsaved_bot_config?.optin_phrase,
              field: "optin_phrase",
            },
            {
              title: "Add a Tag",
              type: "tags",
              field: "tags",
              value: unsaved_bot_config?.tags || [],
            },
          ],
        },
        {
          title: "Share your contact info",
          header: "Share your contact info",
          miniLabel:
            "Share your info so your fans know it’s you messaging them",
          enabled: unsaved_bot_config.share_your_contact_info ?? true,
          fields: [
            {
              title: "Message",
              type: "textbox",
              field: "contact_message",
              value: unsaved_bot_config?.contact_message,
            },
            {
              title: "Contact info",
              type: "contact-info",
              value: unsaved_bot_config?.contact_info?.profile_picture,
              fields: [
                {
                  title: "Instagram",
                  type: "text",
                  field: "instagram",
                  value: unsaved_bot_config?.contact_info?.instagram,
                },
                {
                  title: "TikTok",
                  type: "text",
                  field: "tiktik",
                  value: unsaved_bot_config?.contact_info?.tiktok,
                },
                {
                  title: "Twitter",
                  type: "text",
                  field: "twitter",
                  value: unsaved_bot_config?.contact_info?.["twitter"],
                },
                {
                  title: "Website",
                  type: "text",
                  field: "website",
                  value: unsaved_bot_config?.contact_info?.website,
                },
                {
                  title: "Email",
                  type: "text",
                  field: "email",
                  value: unsaved_bot_config?.contact_info?.email,
                },
              ],
            },
          ],
        },
        {
          title: "Reward your fans",
          enabled: unsaved_bot_config?.reward_your_fans ?? true,
          nftName: unsaved_bot_config?.["collectible_name"],
          nftDescription: unsaved_bot_config?.["collectible_description"],
          fields: [
            {
              title: "Edit default text",
              type: "textbox",
              field: "special-message",
              value: unsaved_bot_config?.["special_message"],
            },
            {
              title: "Share",
              type: "audio-upload",
              field: "special_share",
              value: unsaved_bot_config?.["collectible_asset"],
            },
          ],
        },
        {
          title: "Grow your community via referrals",
          enabled: unsaved_bot_config.grow_your_community_via_referrals ?? true,

          fields: [
            {
              title: "Edit Default Message",
              type: "textbox",
              field: "referral-link-message",
              value: unsaved_bot_config?.["referral_link_message"]?.substring(
                0,
                unsaved_bot_config?.["referral_link_message"].indexOf(" http")
              ),
            },
          ],
        },
        {
          title: "Capture insight",
          enabled: unsaved_bot_config.capture_insight ?? true,

          fields: [
            {
              title: "Message",
              type: "text",
              field: "capture_message",
              value: unsaved_bot_config?.["capture_message"],
            },
          ],
        },
      ]);
    }
  }, [updating, newSeries]);
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));
  useEffect(() => {
    if (!updating) {
      const botData = JSON.parse(localStorage.getItem("botData"));
      localStorage.setItem(
        "botData",
        JSON.stringify({
          ...botData,
          active_nft_series_id: "welcome_bot_nft_series_1",
        })
      );
    }
  }, [updating]);

  useEffect(() => {
    const dbUserCopy = { ...dbUser };
    let newSeriesSt = { ...newSeries };
    if (
      ((dbUser?.bot_config?.active_nft_series_id &&
        dbUser?.bot_config?.active_nft_series_id ===
          dbUser?.unsaved_bot_config?.active_nft_series_id) ||
        (!dbUser?.unsaved_bot_config?.active_nft_series_id && updating)) &&
      !newSeriesSt.id
    ) {
      newSeriesSt.id = getNewSeriesId(
        dbUser?.bot_config?.active_nft_series_id || null
      );

      setNewSeries({
        ...newSeriesSt,
      });
    }

    let series = [...(dbUserCopy?.nft_collections?.[0]?.series || [])];
    const indexToModify = !series?.[0]?.id
      ? 0
      : series.findIndex(
          (serie) =>
            serie.id ===
            (newSeriesSt?.id ||
              getNewSeriesId(dbUser?.bot_config?.active_nft_series_id || null))
        ) > -1
      ? series.findIndex(
          (serie) =>
            serie.id ===
            (newSeriesSt?.id ||
              getNewSeriesId(dbUser?.bot_config?.active_nft_series_id || null))
        )
      : series.length;
    series[indexToModify] = {
      ...(indexToModify === series.length && series[indexToModify - 1]),
      ...newSeriesSt,
      edition_count: -1,
      claim_cap_per_fan: 1,
      id:
        newSeriesSt?.id ||
        getNewSeriesId(dbUser.bot_config?.active_nft_series_id || null),
    };

    const nft_collections = [
      {
        collection_id: "welcome_bot_nft_collection",
        collection_name: `${dbUser.artist_name} Welcome Collection`,
        collection_description:
          "This is the collection of NFTs that are given to users when they join the welcome bot",
        series,
      },
    ];
    localStorage.setItem("nft_collections", JSON.stringify(nft_collections));
    localStorage.setItem(
      "dbUser",
      JSON.stringify({
        ...dbUser,
        unsaved_bot_config: {
          ...dbUser.unsaved_bot_config,
          active_nft_series_id: newSeries.id,
        },
      })
    );
    setNftCollections(nft_collections);
  }, [newSeries]);
  useEffect(() => {
    // artistUpdate({
    //   artistId: dbUser.artist_id,
    //   object: {
    //     unsaved_bot_config: {
    //       ...dbUser.unsaved_bot_config,
    //       active_nft_series_id: newSeries.id,
    //     },
    //   },
    // });
    // ;
    // checkIfArtistExists(dbUser.work_email);
  }, [newSeries.id]);

  return (
    <div className={styles.wrapper}>
      {!updating && (
        <Header
          title={"YOUR NUMBER: " + dbData?.claimed_phone_number}
          // button={"SIGN OUT"}
          copyIcon
          copyFunction={() => {
            navigator.clipboard.writeText(dbData?.claimed_phone_number);
            customNotification("Number copied to clipboard");
          }}
          // btnAction={logout}
        />
      )}
      <div className={styles.row}>
        <div
          className={
            styles.leftCol + ` ${minimised ? styles.leftMinimised : ""}`
          }
        >
          <MiniHeader label={"Steps"} button={MinimiseBtn} />
          {steps.map((step, i) => {
            return (
              <div
                onClick={() => setActiveStep(i)}
                className={
                  styles.step + ` ${i == activeStep ? styles.active : ""}`
                }
                key={i}
              >
                <b>{i + 1}</b>
                <div>{step.title}</div>
              </div>
            );
          })}
          {!updating && (
            <div>
              {minimised ? (
                <div onClick={logOut} className={styles.signOutIcon}>
                  <img src={logoutIcon} />
                </div>
              ) : (
                <b onClick={logOut} className={styles.signOut}>
                  Sign out
                </b>
              )}
            </div>
          )}
        </div>
        <div
          className={
            styles.middleCol + ` ${minimised ? styles.middleMinimised : ""}`
          }
        >
          <MiniHeader
            label={steps[activeStep].header || steps[activeStep].title}
            miniLabel={steps[activeStep].miniLabel}
            button={() =>
              activeStep > 0 && (
                <div className={styles.switchComponent}>
                  <Switch
                    onChange={(value) => {
                      const oldState = [...steps];
                      oldState[activeStep].enabled = value;
                      setSteps(oldState);
                    }}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={"#F3EC00"}
                    onHandleColor={"#000"}
                    checked={steps[activeStep].enabled}
                    className={`react-switch ${
                      steps[activeStep].enabled ? "checked" : ""
                    }`}
                  />
                  <b>{steps[activeStep].enabled ? "Enabled" : "Disabled"}</b>
                </div>
              )
            }
          />
          <RenderFields
            setSteps={setSteps}
            steps={steps}
            activeStep={activeStep}
            messagesToShow={messagesToShow}
            setMessagesToShow={setMessagesToShow}
            updating={updating}
            stopWhenLoading={stopWhenLoading}
            isLoading={isLoading}
            shouldShowRestore={shouldShowRestore}
          />
          {!updating && (
            <NavigationButtons
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              publish={publish}
            />
          )}
        </div>
        <div className={styles.rightCol}>
          <PhoneSimulator
            artistName={steps[0].fields[1].value}
            artistPic={steps[1].fields[1].value}
            voiceNote={steps[2].fields[1].value}
            messagesToShow={messagesToShow}
            activeStep={activeStep}
            steps={steps}
            updating={updating}
          />
        </div>
      </div>
      <SuccessModal visible={visible} setVisible={setVisible} />
    </div>
  );
}
export default React.memo(MessageFlowBuilder);
