export const noArtistUrl = ['demo.', 'localhost', 'console.', '.amplifyapp.com', 'unlock.', 'staging.']


export const getArtist = () => {
    const url = window.location.href
        ;
    let artistId;
    let contains = false
    for (let i = 0; i < noArtistUrl.length; i++) {
        const element = noArtistUrl[i];
        if (url.includes(element)) {
            contains = true;
        }
    }
    switch (true) {
        case contains:
            artistId = '-1';
            import('../styles/HotlineBuilder.css').then((css) => { })
            break;
        case url.includes('hyd'):
            artistId = '4';
            import('../styles/HYD.css').then((css) => { })
            break;
        case url.includes('localhost'):
            artistId = '2';
            import('../styles/TomMisch.css').then((css) => { })
            break;
        case url.includes('waynesnow'):
            artistId = '1';
            import('../styles/WayneSnow.css').then((css) => { })
            break;
        case url.includes('kidsinconflict'):
            artistId = '3';
            import('../styles/WayneSnow.css').then((css) => { })
            break;
        case url.includes('lilmariko'):
            artistId = '5';
            import('../styles/LILMARIKO.css').then((css) => { })
            break;

        case url.includes('yaeji') || url.includes('ngrok'):
            artistId = '6';
            import('../styles/Yaeji.css').then((css) => { })
            break;
        case url.includes('tommisch'):
            artistId = '7';
            import('../styles/NewTom.css').then((css) => { })
            break;
        case url.includes('roosevelt'):
            artistId = '8';
            import("../styles/Roosevelt.css").then((css) => { })
            break;
        default:
            artistId = '5'
            import('../styles/LILMARIKO.css').then((css) => { })
            break;
    }
    ;
    return artistId;
}
