import React, { useContext, useMemo, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { LuPause, LuPlay, LuSearch } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { artistUpdate } from "../../../../../api/dmmeendpoints/artistUpdate";
import ConfirmDeleteModal from "../../../../../components/ConfirmModal/ConfirmModal";
import { AppContext } from "../../../../../contexts/AppContext";
import "./SegmentDropdown.scss";

export default function SegmentDropdown({
  setShowDropdown,
  totalMembers,
  setTotalMembers,
  segmentDataSt,
  setSegmentData,
  isTotalChecked,
  setIsTotalChecked,
  checkedSegments,
  setCheckedSegments,
  searchVal,
  setSearchVal,
  loading,
  isStopped,
}) {
  const { showDelete, setShowDelete } = useContext(AppContext);
  const [selectedSgmId, setSelectedSgmId] = useState(null);
  const [selectedSgmName, setSelectedSgmName] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // const series = JSON.parse(
  //   localStorage.getItem("dbUser")
  // )?.nft_collections?.[0].series.filter((serie) => serie.series_asset_s3_path);

  // const filteredSeries = useMemo(
  //   () => [
  //     ...new Map(
  //       series?.map((item) => [item["series_asset_path"], item])
  //     ).values(),
  //   ],
  //   [series]
  // );
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  const updateCheckedSegments = (id, value) => {
    const newObj = { ...checkedSegments, [id]: value };
    setCheckedSegments(newObj);
    if (Object.values(newObj).includes(false)) {
      setIsTotalChecked(false);
    } else {
      setIsTotalChecked(true);
    }
  };

  const playPause = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPlaying(!isPlaying);
    const audioPl = document.getElementById("dropdown-audio");
    audioPl.paused ? audioPl.play() : audioPl.pause();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchVal(value);
  };

  const dataToRender = useMemo(
    () =>
      segmentDataSt.filter((sgm) =>
        sgm.segment_name.toLowerCase().includes(searchVal.toLowerCase())
      ),
    [segmentDataSt, searchVal, showDelete]
  );

  const onDeleteHandler = async () => {
    const archived_segments = [
      ...(dbUser.archived_segments || []),
      `${selectedSgmId}-${selectedSgmName}`,
    ];
    await artistUpdate({
      object: { archived_segments },
      artistId: dbUser?.artist_id,
    });
    localStorage.setItem(
      "dbUser",
      JSON.stringify({
        ...dbUser,
        archived_segments: archived_segments,
      })
    );
    setShowDelete(false);
  };

  return (
    <>
      <div className="raffle-segment-dropdown">
        <h3>Grant access to</h3>
        <p>{totalMembers.length} members</p>
        <div className="search-box">
          <div className="input-icon">
            <LuSearch />
          </div>
          <input
            placeholder="Search"
            value={searchVal}
            onChange={handleInputChange}
          />
        </div>
        <div className="select-all">
          <b>Select all</b>
          <input
            disabled={!!isStopped}
            type="checkbox"
            checked={isTotalChecked}
            onChange={(e) => {
              setIsTotalChecked(e.target.checked);
              let newObj = {};
              for (let i = 0; i < Object.keys(checkedSegments).length; i++) {
                const el = Object.keys(checkedSegments)[i];
                newObj = { ...newObj, [el]: e.target.checked };
              }
              setCheckedSegments(newObj);
            }}
          />
        </div>
        <div className="segment-dropdown-body">
          {dataToRender?.map((sgm, i) => {
            let formattedSgmName = sgm?.segment_name
              .replace(/\(Version \d+\)/, "")
              .replace(/\s/g, "");
            if (
              !dbUser?.archived_segments?.includes(
                `${sgm.segment_id}-${formattedSgmName}`
              )
            ) {
              return (
                <div
                  key={`segment-${sgm.segment_id}+${i}`}
                  className="segment-dropdown-body-element"
                  onClick={() => {
                    const formattedSgmName = sgm?.segment_name
                      .replace(/\(Version \d+\)/, "")
                      .replace(/\s/g, "");

                    updateCheckedSegments(
                      sgm?.segment_id,
                      !checkedSegments[sgm?.segment_id]
                    );
                  }}
                >
                  <div className="left-part">
                    <div className="icon-part">
                      {sgm?.metadata.seriesAssetType !== undefined ? (
                        sgm?.metadata?.seriesAssetType.includes("audio") ? (
                          <div className="audio-with-thumbnail">
                            <img
                              src={
                                sgm?.metadata?.seriesThumbnailAssetPath || ""
                              }
                            />
                            <audio
                              id={"dropdown-audio"}
                              src={sgm?.metadata?.seriesAssetPath}
                            />
                            {isPlaying ? (
                              <LuPause color="#fff" onClick={playPause} />
                            ) : (
                              <LuPlay color="#fff" onClick={playPause} />
                            )}
                          </div>
                        ) : sgm?.metadata?.seriesAssetType.includes("video") ? (
                          <video
                            src={sgm?.metadata?.seriesAssetPath}
                            autoPlay={true}
                          />
                        ) : (
                          <img src={sgm?.metadata?.seriesAssetPath} />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="segment-info">
                      <b>{sgm.segment_name}</b>
                      <div className="segment-meta">
                        <div className="segment-type">
                          {sgm.segment_id === "bot-fans"
                            ? "Bot Fans"
                            : JSON.parse(localStorage.getItem("dbUser"))
                                .artist_name}{" "}
                          Welcome
                        </div>
                        {/* Hardcoded for now */}
                        <div className="segment-members">
                          <BsFillPersonFill />
                          {sgm.members.length}&nbsp;members
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="archive-input-part">
                    <RiDeleteBinLine
                      className="archive-badge"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const formattedSgmName = sgm?.segment_name
                          .replace(/\(Version \d+\)/, "")
                          .replace(/\s/g, "");
                        setSelectedSgmId(sgm?.segment_id);
                        setSelectedSgmName(formattedSgmName);
                        setShowDelete(true);
                      }}
                    />
                    <input
                      disabled={!!isStopped}
                      type="checkbox"
                      checked={checkedSegments?.[sgm.segment_id]}
                      onChange={(e) =>
                        updateCheckedSegments(sgm.segment_id, e.target.checked)
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <ConfirmDeleteModal
          title="Delete segment?"
          content="This can't be undone but don't worry, no members in this segment will be deleted."
          onDeleteAction={onDeleteHandler}
        />
      </div>
      <div
        className="backdrop-dropdown"
        onClick={() => setShowDropdown(false)}
      />
    </>
  );
}
