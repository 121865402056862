import { useEffect, useState } from "react";
import { getContrastColor } from "../../helpers/getContrastColor";
import "./AnimatedListNft.scss";

const AnimatedListNft = ({ collectibles_needed, appearance }) => {
  const [isImageError, setIsImageError] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(
        (prevIndex) => (prevIndex + 1) % collectibles_needed?.length
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [collectibles_needed?.length]);

  return (
    <ul
      className="nft-mainContainer"
      style={{ padding: 0, listStyleType: "none" }}
    >
      <span>unlocking with ...</span>
      {collectibles_needed?.map((nft, index) => (
        <>
          <li
            className={`nftItem ${index === activeIndex ? "active" : ""}`}
            key={index}
          >
            <div
              className={`nft-container  ${
                collectibles_needed.length > 1 ? "scrollable" : ""
              }`}
            >
              <div
                className={`nft-card`}
                style={{
                  backgroundColor: getContrastColor(
                    appearance.background_color
                  ),
                }}
              >
                {isImageError ? (
                  <video
                    src={nft.asset_image}
                    controls={true}
                    autoPlay={true}
                  />
                ) : (
                  <img
                    alt="collection-asset"
                    src={nft.asset_image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      setIsImageError(true);
                    }}
                  />
                )}
                <b>{nft.asset_name}</b>
              </div>
            </div>
          </li>
        </>
      ))}
    </ul>
  );
};

export default AnimatedListNft;
