import React from "react";
import Button from "../../../components/SignupProcess/Button/Button";
import "./ConfirmBroadcastModal.scss";
import closeIcon from "../../../assets/SignUpProcess/x.svg";

export default function ConfirmBroadcastModal({
  isOpen,
  setIsOpen,
  members,
  submitMessage,
}) {
  if (isOpen) {
    return (
      <div className="confirm-broadcast-modal-wrapper">
        <div className="confirm-broadcast-modal">
          <h3>Confirm send</h3>
          <div>This message will be sent to {members} members.</div>
          <div className="cb-modal-footer">
            <Button
              label={"CANCEL"}
              background={"#00000033"}
              action={() => setIsOpen(false)}
            />
            <Button label={"SEND"} action={submitMessage} />
          </div>
          <img src={closeIcon} onClick={() => setIsOpen(false)} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
