import React from "react";
import styles from "./Header.module.scss";
import IindyIcon from "../../../assets/SignUpProcess/Logo.svg";
import Button from "../Button/Button";
import copy from "../../../assets/SignUpProcess/copy.svg";
import { useAuth0 } from "@auth0/auth0-react";

export default function Header({
  title,
  button = null,
  copyIcon = false,
  btnAction,
  copyFunction,
}) {

  return (

    <div className={styles.wrapper}>
      <img src={IindyIcon} className="header-icon" />
      <b onClick={copyFunction}>
        {title}
        {copyIcon && <img src={copy} />}
      </b>
      {button && <Button label={button} action={btnAction} noBackground />}
    </div>
  );
}