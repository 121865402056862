import React, { useEffect, useState } from "react";
import "./RaffleForm.scss";
import { sampleConfig } from "../helpers/sampleConfig";
import { getContrastColor } from "../helpers/getContrastColor";
import { VscVerifiedFilled } from "react-icons/vsc";
import { FiChevronDown } from "react-icons/fi";
import { Checkbox, Select, notification } from "antd";
import Button from "../components/SignupProcess/Button/Button";
import { useHistory } from "react-router-dom";
import { getCookies } from "../helpers/getCookies";
import jwt from "jwt-decode";
import { useParams } from "react-router-dom";
import IOSLikeLoader from "../components/IOSLikeLoader/IOSLikeLoader";
import { submitEntry } from "../api/dmmeendpoints/playbook";
import { validateEmail } from "../helpers/validateEmail";
import { ReactComponent as Jumpman } from "../assets/Dashboard/jumpman.svg";
import Copyright from "../components/Copyright/Copyright";
import { hasWhiteSpace } from "../helpers/hasWhiteSpace";
import { hexToRgbA } from "../helpers/hexToRgba";
import { customNotification } from "../helpers/customNotification";
import { BiErrorAlt } from "react-icons/bi";

export default function RaffleForm({ preview, raffleData }) {
  const [loadingState, setLoadingState] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [parsedSt, setParsed] = useState({});
  const history = useHistory();
  const [config, setConfig] = useState(history?.location?.state?.config || {});
  const [error, setError] = useState(false);
  const [raffleFormData, setRaffleFormData] = useState({});
  useEffect(() => {
    if (history.location.state && !preview) {
      setConfig(history?.location?.state?.config);
    } else if (!preview) {
      setError(true);
    }
  }, [history.location.state]);

  const { raffle_details, collectibles_needed } = config;
  const {
    raffle_page_appearance: appearance,
    access_page,
    raffle_image,
    raffle_item_title,
    raffle_description,
    raffle_event_name,
    raffle_items,
    raffle_questions,
  } = raffle_details || {};

  const contrastColor = getContrastColor(
    appearance ? appearance?.text_color : "#000000"
  );
  const fields = [
    { name: "Email", id: "email", placeholder: "Email" },
    { name: "Name", id: "name", placeholder: "Name" },
    {
      name: "Phone number",
      id: "phone-number",
      placeholder: "Phone number",
      disabled: true,
      icon: VscVerifiedFilled,
    },
  ];
  const cookies = getCookies();
  let decoded;
  try {
    decoded = !preview && jwt(cookies.iindySessionToken);
  } catch (error) {
    setError(true);
  }

  useEffect(() => {
    if (decoded && !raffleFormData["phone-number"]) {
      setRaffleFormData({
        ...raffleFormData,
        "phone-number": decoded?.metadata?.fan_mobile_number || decoded.sub,
      });
    }
  }, [decoded]);
  const params = useParams();
  useEffect(() => {
    const parsed = {
      raffle_submitter_iindy_id:
        decoded.metadata?.fan_mobile_number || decoded.sub,
      submitter_emailid: raffleFormData.email,
      submitter_name: raffleFormData.name,
      submitter_phone_number: raffleFormData["phone-number"],
      playbook_key: params.id,
      raffle_event_name: raffle_event_name,
      selected_option: [
        {
          option_name: raffle_event_name,
          value: raffleFormData.selectedOption,
        },
      ],
      answers: [
        {
          question: raffle_questions?.[0].question, // would you be able to pick this up in store?
          answer: raffleFormData[raffle_questions?.[0].question + "-answer"],
        },
      ],
    };
    setParsed(parsed);
  }, [raffleFormData]);

  const validate = () => {
    let isValid = true;
    if (!raffleFormData.email) {
      isValid = false;
      customNotification("Please enter your email", <BiErrorAlt />);
    } else if (raffleFormData.email && !validateEmail(raffleFormData.email)) {
      isValid = false;
      customNotification("The email you provided is not valid", <BiErrorAlt />);
    } else if (!raffleFormData.name) {
      isValid = false;
      customNotification("Please enter your name", <BiErrorAlt />);
    } else if (!raffleFormData.selectedOption) {
      isValid = false;
      customNotification(
        "Please choose an option from the dropdown",
        <BiErrorAlt />
      );
    } else if (
      raffle_questions?.[0].required &&
      !raffleFormData[raffle_questions?.[0].question + "-answer"]
    ) {
      isValid = false;
      customNotification(
        `Please select an answer for the question: ${raffle_questions[0].question}`,
        <BiErrorAlt />
      );
    }

    return isValid;
  };

  const submitRaffle = async () => {
    const isValid = validate();
    if (isValid) {
      setLoadingState("loading");
      const data = await submitEntry(parsedSt);
      if (
        data.error === "You have already submitted an entry for this raffle"
      ) {
        history.push("/already-submitted", { raffle_id: params.id });
      } else if (data) {
        setTimeout(() => {
          setLoadingState("success");
          setTimeout(() => {
            setShowSuccess(true);
          }, 2000);
        }, 2000);
      } else {
        setLoadingState(null);
        customNotification("Error when submitting raffle");
      }
    }
  };

  useEffect(() => {
    if (preview && raffleData) {
      setConfig({ ...raffleData });
    }
  }, [preview, raffleData]);

  if (error || !raffle_details) {
    return <h2 style={{ color: "#fff" }}>Error</h2>;
  }
  if (showSuccess) {
    return (
      <div
        className="success-raffle-entry"
        style={{
          backgroundColor: appearance.background_color,
          color: appearance.text_color,
          fill: appearance.text_color,
        }}
      >
        <Jumpman style={{ fill: "#5975FF" }} />
        <h1>Your entry is in!</h1>
        <b>Good luck. We’ll be in touch if you are a winner!</b>
        <Copyright />
      </div>
    );
  }

  return (
    <div
      className="raffle-form-wrapper"
      style={{
        backgroundColor: appearance.background_color,
        color: appearance.text_color,
      }}
    >
      <div className="raffle-items">
        <div className="header-image">
          <img className={"raffle-img"} src={raffle_image} />
        </div>

        <h1>{raffle_item_title}</h1>
        <div
          className="raffle-description"
          dangerouslySetInnerHTML={{ __html: raffle_description }}
        />
        <hr style={{ borderColor: contrastColor }} />
        {fields.map((field, i) => (
          <div
            key={i}
            className={`raffle-field ${
              field.disabled ? "disabled-raffle" : ""
            }`}
          >
            <label>{field.name}</label>
            <div className="field-container">
              <input
                className="simple-input"
                placeholder={field.placeholder}
                value={raffleFormData[field.id]}
                onChange={(e) => {
                  if (!field.disabled) {
                    const value =
                      field.id === "email"
                        ? e.target.value.replaceAll(" ", "")
                        : e.target.value;
                    setRaffleFormData({
                      ...raffleFormData,
                      [field.id]: value,
                    });
                  }
                }}
                style={{
                  backgroundColor: appearance.input_background_color,
                  color: appearance.text_color,
                  "--placeholder-color": hexToRgbA(appearance.text_color, 0.7),
                }}
              />
              {field.icon && <field.icon />}
            </div>
            {field.id === "phone-number" && (
              <b>Verified phone number can’t be changed</b>
            )}
          </div>
        ))}
        <div className={`raffle-field`}>
          <label>{raffle_event_name}</label>
          <div className="field-container">
            <Select
              className="dropdown-raffle"
              placeholder={"Choose"}
              style={{
                backgroundColor: appearance.input_background_color,
                color: appearance.text_color,
                "--placeholder-color": hexToRgbA(appearance.text_color, 0.7),
              }}
              value={raffleFormData.selectedOption}
              suffixIcon={<FiChevronDown color={appearance.text_color} />}
              onChange={(value) => {
                setRaffleFormData({ ...raffleFormData, selectedOption: value });
              }}
              options={raffle_items.map((item) => ({
                value: item.option_name,
                label: item.option_name,
              }))}
            />
          </div>
        </div>
        <br />
        {raffle_questions?.map((question, i) => (
          <div key={i} className={`raffle-field`}>
            <label>{question.question}</label>
            <div className="question-options">
              {question.options.map((option, i) => (
                <Checkbox
                  key={i}
                  style={{
                    color: appearance.text_color,
                  }}
                  checked={
                    raffleFormData[question.question + "-answer"] === option
                  }
                  onChange={(e) => {
                    setRaffleFormData({
                      ...raffleFormData,
                      [question.question + "-answer"]: e.target.checked
                        ? option
                        : false,
                    });
                  }}
                >
                  {option}
                </Checkbox>
              ))}
            </div>
          </div>
        ))}
        <br />
        <hr style={{ borderColor: contrastColor }} />
        <Button
          border="none"
          action={submitRaffle}
          background={appearance.button_color}
          textColor={appearance.button_text_color}
          label={appearance.button_text}
        />
      </div>
      <IOSLikeLoader
        loadingState={loadingState}
        loadingText={"Submitting raffle"}
        successText={"Success"}
      />
    </div>
  );
}
