import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { votePoll } from "../../api/endpoints/polls";
import "./Voter.scss";

export default function Voter({
  alreadyVoted,
  current,
  options,
  user,
  pollId,
  percentages,
  setPercentages,
  update,
  alreadyVotedLength,
  setAlreadyVotedLength,
  setnavigationNeeded,
}) {
  const [clicked, setclicked] = useState(null);
  const leftPercentage = Math.round(
    (percentages?.left / (percentages?.left + percentages?.right)) * 100
  );
  var leftWidth = leftPercentage,
    rightWidth = 100 - leftWidth;
  if (leftPercentage > 0 && leftPercentage <= 20) {
    leftWidth = 20;
    rightWidth = 100 - leftWidth;
  } else if (leftPercentage > 80 && leftPercentage < 100) {
    leftWidth = 80;
    rightWidth = 100 - leftWidth;
  }

  const rightPercentage = Math.round(
    (percentages?.right / (percentages?.left + percentages?.right)) * 100
  );
  const vote = async (leftOrRight) => {
    if (!clicked) {
      setAlreadyVotedLength(alreadyVotedLength + 1);
      if (leftOrRight === "left") {
        setPercentages({
          left: percentages.left + 1,
          right: percentages.right,
        });
      } else if (leftOrRight === "right") {
        setPercentages({
          left: percentages.left,
          right: percentages.right + 1,
        });
      }
      setclicked(leftOrRight);
      const afterVoteData = await votePoll({
        data: { [user.user_id]: leftOrRight == "left" ? 0 : 1 },
        poll_id: pollId,
        story_id: current,
      });
      setnavigationNeeded(true);
      update();
    }
  };
  useEffect(() => {
    if (alreadyVoted) {
      const foundEl = alreadyVoted?.find((el) => el.story_id == current);
      if (foundEl) {
        setclicked(
          foundEl.vote == 0 ? "left" : foundEl.vote == 1 ? "right" : null
        );
      } else {
        setclicked(null);
      }
    }
  }, [current, alreadyVoted]);

  return (
    <div className={`voter-wrapper ${!options ? "voter-loading" : ""}`}>
      <div
        style={{
          display: clicked && rightPercentage == 100 && "none",
        }}
        className={`voter-option left ${clicked == "left" ? "clicked" : ""} ${
          clicked ? "column" : ""
        }`}
        onClick={() => {
          !clicked && vote("left");
        }}
      >
        <div>{options?.[0]}</div>
        <div>{clicked && leftPercentage + "%"}</div>
      </div>
      <div
        style={{
          display: clicked && leftPercentage == 100 && "none",
        }}
        onClick={() => {
          !clicked && vote("right");
        }}
        className={`voter-option right ${clicked == "right" ? "clicked" : ""} ${
          clicked ? "column" : ""
        }`}
      >
        <div>{options?.[1]}</div>
        <div>{clicked && rightPercentage + "%"}</div>
      </div>
    </div>
  );
}
