import React, { useState } from "react";
import "./CollectionIdCard.scss";
import { BiPause, BiPlay, BiPlus, BiShareAlt, BiTrash } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import ModelViewer from "../../ModelViewer/ModelViewer";
import { useHistory } from "react-router-dom";
import { Dropdown, Modal } from "antd";
import Button from "../../SignupProcess/Button/Button";
import { AiOutlineSend } from "react-icons/ai";
import { LuLink2 } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import ShareHotlineLink from "../../../screens/Dashboard/Playbook/ShareHotlineLink/ShareHotlineLink";
import { generateCollectibleLink } from "../../../api/v2endpoints/generalCollectibleLink";
import ShareCollectible from "../../../screens/Dashboard/Playbook/ShareCollectible/ShareCollectible";

export default function CollectionIdCard({
  status,
  id,
  series_name,
  series_description,
  series_asset_path,
  series_asset_type,
  series_thumbnail_asset_path,
  edition_count = -1,
  action = null,
  deleteCollectible,
}) {
  const asset_link = series_asset_path;
  const mimeType = series_asset_type;
  const [isPaused, setIsPaused] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [showModalCollId, setShowModalCollId] = useState(false);
  const [collectibleLink, setCollectibleLink] = useState("");
  const history = useHistory();

  const share = async () => {
    const link = await generateLink();
    navigator.clipboard.writeText(link);
    await history.push("/dashboard/community", { linkToShare: link });
  };

  const generateLink = async () => {
    const auth0id = JSON.parse(localStorage.getItem("dbUser"))?.artist_id;
    const pathnameSplit = history.location.pathname.split("/");
    const collection_id = pathnameSplit[pathnameSplit.length - 1];

    const data = await generateCollectibleLink({
      auth0id,
      collection_id: collection_id,
      collectible_id: id,
    });
    setCollectibleLink(data.data.url);
    return data.data.url;
  };

  const playPause = (e) => {
    e.stopPropagation();
    const video = document.getElementById(
      `nft-${mimeType.includes("video") ? "video" : "audio"}-${id}`
    );
    if (video.paused) {
      video.play();
      setIsPaused(false);
    } else {
      video.pause();
      setIsPaused(true);
    }
  };
  if (status === "create") {
    return (
      <div className="add-collectible" onClick={() => action && action()}>
        <div className="icon-holder">
          <BiPlus size={40} />
        </div>
        <b>Add a Collectible</b>
      </div>
    );
  }

  const items = [
    {
      label: (
        <div
          className="tile-dropdown-item"
          onClick={() => {
            generateLink();
            setShowModalCollId(true);
          }}
        >
          <LuLink2 />
          Generate link
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="tile-dropdown-item" onClick={share}>
          {
            <>
              <AiOutlineSend /> Send collectible
            </>
          }
        </div>
      ),
      key: "1",
    },
  ];

  //
  const profilePic = JSON.parse(localStorage.getItem("dbUser"))?.bot_config
    ?.contact_info?.profile_picture;
  return (
    <div className="collectionid-card-wrapper">
      <div key={id} className={"nft-card-wrapper"}>
        <div className="nft-card-artwork-container">
          {mimeType?.includes("video") ? (
            <video
              id={`nft-video-${id}`}
              className={"nft-img"}
              src={asset_link}
              muted
              loop
            />
          ) : mimeType?.includes("model") ? (
            <ModelViewer src={series_asset_path} />
          ) : (
            <>
              <img
                id={`nft-video-${id}`}
                className={"nft-img"}
                src={
                  mimeType?.includes("image")
                    ? asset_link
                    : series_thumbnail_asset_path ||
                      profilePic ||
                      "https://go.iindy.co/LGi2J"
                }
                loop
              />
              {!mimeType?.includes("image") && (
                <audio id={`nft-audio-${id}`} src={asset_link} loop />
              )}
            </>
          )}
          {(mimeType?.includes("video") || mimeType?.includes("audio")) && (
            <div className={"play-btn"} onClick={playPause}>
              {isPaused ? (
                <BiPlay className="play-btn-img" color="#fff" />
              ) : (
                <BiPause className="play-btn-img" color="#fff" />
              )}
            </div>
          )}
        </div>
        <div className={"nft-card-footer"}>
          <div className={"nft-title"}>{series_name}</div>
          <div className={"nft-edition"}>
            Supply {edition_count > 0 ? edition_count : "Unlimited"}
          </div>
          <div className="more-options">
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Dropdown
                menu={{
                  items,
                }}
                placement="topLeft"
              >
                <button className="share-btn">
                  <BiShareAlt size={24} />
                </button>
              </Dropdown>
            </a>
          </div>
          {/* <button className="share-btn" onClick={share}>
            <BiShareAlt size={24} />
          </button> */}
        </div>
        <button className="delete-btn" onClick={() => setShowDelete(id)}>
          <BiTrash size={24} />
        </button>
      </div>
      <Modal
        open={showDelete === id}
        className="playbook-success playbook-stop-modal"
        footer={null}
        maskStyle={{
          backgroundColor: "rgba(0,0,0,0.3)",
          backdropFilter: "blur(10px)",
        }}
        onCancel={() => setShowDelete(false)}
      >
        <div className="icon-holder">
          <RiDeleteBinLine />
        </div>
        <h3>Delete Collectible?</h3>
        <b>This can’t be undone.</b>
        <div className="modal-btns">
          <Button
            label={"CANCEL"}
            height="44px"
            border="none"
            background="#0000000F"
            textColor="#3F3F46"
            action={() => setShowDelete(false)}
          />
          <Button
            label={"DELETE"}
            height="44px"
            border="none"
            background="#B42318"
            textColor="#fff"
            action={() => deleteCollectible(id) && setShowDelete(false)}
          />
        </div>
      </Modal>
      <ShareCollectible
        isOpen={showModalCollId}
        setIsOpen={setShowModalCollId}
        collectibleLink={collectibleLink}
        // number={number}
      />
    </div>
  );
}
