import React, { useEffect, useState } from "react";
import Button from "../../components/SignupProcess/Button/Button";
import Header from "../../components/SignupProcess/Header/Header";
import InputBox from "../../components/SignupProcess/InputBox/InputBox";
import Illustrations from "../../assets/SignUpProcess/chair.svg";

import styles from "./JoinOurWaitlist.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router";
import { Auth0Context, useAuth0 } from "@auth0/auth0-react";
import animationData from "../../assets/loading-circle.json";
import Lottie from "react-lottie";
import { useContext } from "react";

export default function JoinOurWaitlist() {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    isLoading,
    loginWithPopup,
  } = useAuth0();
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  // const login = () => {
  //   if (loginData.email.toLowerCase() === "ownership@iindyverse.com") {
  //     if (loginData.fullName && loginData.companyName && loginData.artistName) {
  //       history.push("/get-your-number");
  //       localStorage.setItem("loginUser", JSON.stringify(loginData));
  //     } else {
  //       toast("Please enter all the fields", { type: "info" });
  //     }
  //   } else {
  //     toast("Wrong Email", { type: "error" });
  //   }
  // };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className={styles.wrapper}>
      <Header title={""} />
      <div className={styles.row}>
        <div className={styles.signupForm}>
          <h1>Looks like you’re not on our Early Access list.</h1>
          <h6>
            To get access please ,join our Waitlist, and we’ll reach out when
            <br />
            the time is right.
          </h6>
          <div className={styles.inputs}>
            <div className={styles.options}>
              <Button
                label={"JOIN OUR WAITLIST"}
                action={() => {
                  window.open(
                    "https://iindyverse.typeform.com/to/NMMG6sKx",
                    "_blank"
                  );
                }}
              />
              or
              <a
                className={styles.logoutEmail}
                href="#"
                onClick={() => logout()}
              >
                Try with a different email
              </a>
            </div>
            <br />
            <b>
              By continuing, you agree to our{" "}
              <a target={"_blank"} href="https://iindy.co/terms">
                terms
              </a>{" "}
              and{" "}
              <a target={"_blank"} href="https://iindy.co/privacy">
                privacy policy
              </a>
              .
            </b>
          </div>
        </div>
        <div className={styles.illustration}>
          <img alt="artwork" src={Illustrations} />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
