import React from "react";
import Button from "../Button/Button";
import styles from "./NavigationButtons.module.scss";
import arrowRight from "../../../assets/SignUpProcess/arrow-right.svg";
import arrowLeft from "../../../assets/SignUpProcess/arrow-left.svg";
import { notification } from "antd";
import { isNftValid as isNftValidAtom } from "../../../atoms/newSeries";
import { useAtom } from "jotai";
import { customNotification } from "../../../helpers/customNotification";
import { BiErrorAlt } from "react-icons/bi";

export default function NavigationButtons({
  steps,
  activeStep,
  setActiveStep,
  publish,
}) {
  const [isNftValid] = useAtom(isNftValidAtom);
  return (
    <div className={styles.navBtnBack}>
      <div className={styles.navBtn}>
        {activeStep > 0 && (
          <Button
            noBackground
            leftIcon={arrowLeft}
            action={() => setActiveStep(activeStep - 1)}
          />
        )}
        <Button
          label={activeStep === steps.length - 1 ? "PUBLISH" : "NEXT"}
          rightIcon={activeStep === steps.length - 1 ? null : arrowRight}
          disabled={!isNftValid}
          action={() => {
            if (activeStep === steps.length - 1) {
              if (steps[0].fields[0].value && steps[0].fields[2].value) {
                publish();
              } else if (!steps[0].fields[0].value) {
                customNotification(
                  "Welcome message is a required field!",
                  <BiErrorAlt />,
                  "Please enter your welcome message"
                );
              } else if (!steps[0].fields[2].value) {
                customNotification(
                  "Optin phrase is a required field!",
                  <BiErrorAlt />,
                  "Please enter your optin phrase"
                );
              }
            } else {
              setActiveStep(activeStep + 1);
            }
          }}
        />
      </div>
    </div>
  );
}
