import { Switch } from "antd";
import "./SwitchComponent.scss";

const SwitchComponent = ({
  data,
  labelName,
  switchOnChange,
  opactiyChange,
}) => {
  return (
    <div className="switch-container">
      <div
        className={
          "switchComponent" +
          // +
          // (data.edition_count === -1 ? " enabled-switch" : "")
          (data === -1 ? " enabled-switch" : "")
        }
      >
        <Switch
          onChange={switchOnChange}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor={"#F3EC00"}
          onHandleColor={"#000"}
          checked={data === -1}
          className={`react-switch`}
        />
        <span className={`switch-label${opactiyChange}`}>
          &nbsp;{labelName || ""}
        </span>
      </div>
    </div>
  );
};

export default SwitchComponent;
