import React, { useEffect, useState } from "react";
import "./Playbook.scss";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import PlaybookTile from "./PlaybookTile/PlaybookTile";
import { ReactComponent as RaffleIcon } from "../../../assets/Dashboard/list_alt.svg";
import { ReactComponent as ShopifyIcon } from "../../../assets/Dashboard/shopify.svg";
import { ReactComponent as DiscordIcon } from "../../../assets/Dashboard/discord.svg";
import { ReactComponent as GroupAddIcon } from "../../../assets/Dashboard/group_add.svg";
import CreateRaffle from "./CreateRaffle/CreateRaffle";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Modal, notification } from "antd";
import jumpman from "../../../assets/Dashboard/jumpman.svg";
import Loading from "../../../SignupProcess/Loading/Loading";
import { MdOutlineContentCopy } from "react-icons/md";
import { copyToClipboard } from "../../../helpers/copyToClipboard";
import EmptyPlaybook from "./EmptyPlaybook/EmptyPlaybook";
import { getPlaybooks } from "../../../api/dmmeendpoints/playbook";
import PlaybookSuccessModal from "./PlaybookSuccessModal/PlaybookSuccessModal";
import { getUnlockLink } from "../../../helpers/getUnlockLink";

export default function Playbook() {
  const [activeTab, setActiveTab] = useState(0);
  const [fullLoading, setFullLoading] = useState(false);
  const [activeTiles, setActiveTiles] = useState([]);
  const [creatingPlaybook, setCreatingPlaybook] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const tabs = ["Explore", "My Playbooks"];
  const getActivePlaybooks = async () => {
    setFullLoading(true);
    const data = await getPlaybooks();
    const parsed = data?.playbooks?.playbooks
      ?.filter((obj) => obj.metadata.status !== "deleted")
      .map((pb) => ({
        title: pb.metadata.raffle_details.raffle_name,
        type: "Access",
        icon: RaffleIcon,
        color: "#F3EC00",
        badgeColor: "#FFFA5A",
        badgeTextColor: "#6E4E00",
        enabled: true,
        path: "create-raffle",
        submission_count: pb.submission_count || 0,
        createComponent: CreateRaffle,
        status: pb.metadata.status,
        link: `${getUnlockLink()}${pb.playbook_key}`,
        metadata: { ...pb },
      }));
    setActiveTiles(parsed);
    setFullLoading(false);
  };
  useEffect(() => {
    if (history.location.pathname === "/dashboard/playbooks/my-playbooks") {
      getActivePlaybooks();
      setActiveTab(1);
      setCreatingPlaybook(false);
    } else if (history.location.pathname === "/dashboard/playbooks/explore") {
      setActiveTab(0);
      setCreatingPlaybook(false);
    }
    if (
      history.location.pathname.includes("/explore/create-raffle") ||
      history.location.pathname.includes("/my-playbook/create-raffle")
    ) {
      setActiveTab(0);
      setCreatingPlaybook("create-raffle");
    }
    if (history.location.pathname === "/dashboard/playbooks") {
      history.replace("/dashboard/playbooks/explore");
    }
  }, [history.location.search, history.location.pathname]);
  useEffect(() => {
    if (location?.state?.showSuccessModal) {
      !location?.state?.doNotAddToList &&
        setActiveTiles([
          ...activeTiles,
          {
            title: "Early access to a raffle",
            type: "Access",
            icon: RaffleIcon,
            color: "#F3EC00",
            badgeColor: "#FFFA5A",
            badgeTextColor: "#6E4E00",
            enabled: true,
            path: "create-raffle",
            createComponent: CreateRaffle,
            status: "preview",
            link: location?.state?.link,
          },
        ]);

      setShowSuccessModal(location.state);
    }
  }, [location.state]);
  const tilesToExplore = [
    {
      title: "Early access to a raffle",
      type: "Access",
      icon: RaffleIcon,
      color: "#F3EC00",
      badgeColor: "#FFFA5A",
      badgeTextColor: "#6E4E00",
      enabled: true,
      path: "create-raffle",
      createComponent: CreateRaffle,
    },
    {
      title: "Exclusive access to a product",
      type: "Access",
      icon: ShopifyIcon,
      color: "#5975FF",
      badgeColor: "#494CFD",
      badgeTextColor: "#E4E4E7",
      enabled: false,
    },
    {
      title: "Access to Private Discord channel",
      type: "Access",
      icon: DiscordIcon,
      color: "#000000",
      badgeColor: "#26272B",
      badgeTextColor: "#D1D1D6",
      enabled: false,
    },
    {
      title: "Reaches a spend threshold",
      type: "Airdrop",
      icon: ShopifyIcon,
      color: "#000000",
      badgeColor: "#26272B",
      badgeTextColor: "#D1D1D6",
      enabled: false,
    },
    {
      title: "Buys a product",
      type: "Airdrop",
      icon: ShopifyIcon,
      color: "#F3EC00",
      badgeColor: "#FFFA5A",
      badgeTextColor: "#6E4E00",
      enabled: false,
    },
    {
      title: "Invites a friend",
      type: "Airdrop",
      icon: GroupAddIcon,
      color: "#5975FF",
      badgeColor: "#494CFD",
      badgeTextColor: "#E4E4E7",
      enabled: false,
    },
  ];
  if (creatingPlaybook) {
    const FoundComponent = tilesToExplore.find(
      (tile) => tile.path === creatingPlaybook
    ).createComponent;
    return <FoundComponent setCreatingPlaybook={setCreatingPlaybook} />;
  }

  return (
    <div className="playbook-wrapper">
      <h1>Playbooks</h1>
      <div className="tabs-header">
        {tabs.map((tab, i) => (
          <div
            key={i}
            onClick={() => {
              setActiveTab(i);
              if (i === 1) {
                history.replace("/dashboard/playbooks/my-playbooks");
              } else {
                history.replace("/dashboard/playbooks/explore");
              }
            }}
            className={`tab ${i == activeTab ? "active-tab" : ""}`}
          >
            {tab}
          </div>
        ))}
      </div>
      {activeTab === 0 && (
        <div className="explore-wrapper">
          {tilesToExplore.map((props) => (
            <PlaybookTile
              {...props}
              preview={true}
              setCreatingPlaybook={setCreatingPlaybook}
            />
          ))}
        </div>
      )}
      {activeTab === 1 &&
        (fullLoading ? (
          <Loading />
        ) : (
          <div className="explore-wrapper">
            {activeTiles.map((props) => (
              <PlaybookTile
                {...props}
                setCreatingPlaybook={setCreatingPlaybook}
                getActivePlaybooks={getActivePlaybooks}
              />
            ))}
            {activeTiles.length === 0 && <EmptyPlaybook />}
          </div>
        ))}
      <PlaybookSuccessModal
        activeTab={activeTab}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
    </div>
  );
}
