import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./PhoneNumber.scss";
import getParameterByName from "../../helpers/getQueryParams";

export default function PhoneNumber({
  phoneNr,
  getPhoneNumber,
  phoneInputPlaceholder,
  dontShowLabel = false,
}) {
  const [value, setValue] = useState();
  const [countryCode, setCountryCode] = useState("GB");
  const [countryCallingCode, setCountryCallingCode] = useState("44");

  useEffect(() => {
    setValue(phoneNr);
  }, [phoneNr]);
  useEffect(() => {
    if (countryCode) {
      setCountryCallingCode(getCountryCallingCode(countryCode));
    }
  }, [countryCode]);
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_code);
      })
      .catch((error) => {
        setCountryCode("GB");
      });
  }, [axios]);
  useEffect(() => {
    let phoneNumber = getParameterByName("phoneNumber")?.replace(" ", "+");
    setValue(phoneNumber);
    getPhoneNumber(phoneNumber, countryCallingCode);
  }, [window.location.search]);

  return (
    <div className="phone-number-wrapper">
      <span className="country-code">({countryCallingCode})</span>
      {!dontShowLabel && phoneInputPlaceholder}
      <PhoneInput
        placeholder={phoneInputPlaceholder || "Your phone number"}
        defaultCountry={countryCode}
        value={value}
        onCountryChange={(val) => {
          setCountryCode(val);
        }}
        onChange={(phone) => {
          setValue(phone);
          getPhoneNumber(phone, countryCallingCode);
        }}
      />
    </div>
  );
}
