import React from "react";
import "./Lightbox.scss";
import chevron from "../../assets/chevron-right.svg";
import close from "../../assets/close.svg";
import Carousel from "nuka-carousel";

export default function Lightbox({
  visible,
  slideIndex,
  images,
  setLightboxState,
}) {
  if (visible) {
    return (
      <div
        className="lightbox-wrapper"
        onClick={() => setLightboxState({ slideIndex, images, visible: false })}
      >
        <div
          className="lightbox-close"
          onClick={() =>
            setLightboxState({ slideIndex, images, visible: false })
          }
        >
          <img src={close} />
        </div>
        <div className="carousel">
          <Carousel
            slideIndex={slideIndex}
            renderCenterLeftControls={({ previousDisabled, previousSlide }) =>
              !previousDisabled && (
                <div
                  className="carousel-controls control-left"
                  onClick={(e) => {
                    e.stopPropagation();
                    previousSlide();
                  }}
                  disabled={previousDisabled}
                >
                  <img src={chevron} />
                </div>
              )
            }
            renderCenterRightControls={({ nextDisabled, nextSlide }) =>
              !nextDisabled && (
                <div
                  className="carousel-controls"
                  onClick={(e) => {
                    e.stopPropagation();
                    nextSlide();
                  }}
                  disabled={nextDisabled}
                >
                  <img src={chevron} />
                </div>
              )
            }
          >
            {images.map((image) => (
              <div className="carousel-slide">
                <img src={image} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
