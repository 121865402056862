import React from 'react'
import './StatusTags.scss'

export default function StatusTags({ status }) {

    return (
        <div className='status-tags-container'>
            <b className={`status-tags ${status}`}>{status.charAt(0).toUpperCase() + status.substring(1)}</b>
        </div>

    )
}
