import AWS from "aws-sdk";
import { shlink } from "../api/endpoints/shlink";
import { sanitizeFileName } from "./sanitizeFilename";
import axios from "axios";
import FormData from 'form-data';
import { baseURL } from "../api/requestDM";



export const s3Upload = (file, progressST, setProgress, index, shortUrls, setShortUrls) => {
    const token = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email`))?.body?.access_token
    const form = new FormData();
    const updatedFile = new File([file], sanitizeFileName(file.name), { type: file.type });
    form.append('file', updatedFile);

    axios.post(`${baseURL}apiv2/customer/assets/upload`, form, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        }, onUploadProgress: (progress) => {
            if (progressST && setProgress) {
                let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
                const progressState = [...progressST]
                progressState[index] = percentCompleted
                setProgress(progressState);
            }
        }
    }).then(async ({ data }) => {
        const url = data.result.https_path;
        const cloudfrontConvert = url.replace('dmme.s3.eu-west-2.amazonaws.com', 'd3l1f10i52zmk.cloudfront.net');
        const { longUrl, shortUrl } = await shlink(cloudfrontConvert)
        localStorage.setItem('shortUrl', longUrl)
        setShortUrls([...shortUrls, shortUrl])
        return { longUrl, shortUrl }

    }).catch(err => { })

}
export const s3UploadBak = (file, progressST, setProgress, index, shortUrls, setShortUrls) => {
    const upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: "dmme/files",
            Key: sanitizeFileName(file.name),
            Body: file,
        },
    });
    upload.on("httpUploadProgress", (progress) => {
        const totalProgress = Math.floor((progress.loaded / progress.total) * 100)
        const progressState = [...progressST]
        progressState[index] = totalProgress
        setProgress(progressState)
            ;
    });
    const promise = upload.promise();
    promise.then(async (data) => {
        const cloudfrontConvert = data.Location.replace('dmme.s3.eu-west-2.amazonaws.com', 'd3l1f10i52zmk.cloudfront.net');
        const { longUrl, shortUrl } = await shlink(cloudfrontConvert)
        localStorage.setItem('shortUrl', longUrl)
        setShortUrls([...shortUrls, shortUrl])

        return { longUrl, shortUrl }
    })
    promise.catch(e => {
        ;
    })

}