export function getContrastColor(color) {
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const red = parseInt(rgb[1], 16);
    const green = parseInt(rgb[2], 16);
    const blue = parseInt(rgb[3], 16);

    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    const threshold = 0.5;
    const lightGrayThreshold = 0.1; // Threshold for determining very dark colors

    if (luminance > threshold) {
        const grayLevel = (luminance + 1) / 2;
        const grayValue = Math.floor(grayLevel * 255);
        const grayColor = '#' + grayValue.toString(16).padStart(2, '0').repeat(3);
        return grayColor;
    } else {
        if (luminance < lightGrayThreshold) {
            // For very dark colors, return a slightly lighter gray color
            const grayValue = Math.floor(0.2 * 255);
            const grayColor = '#' + grayValue.toString(16).padStart(2, '0').repeat(3);
            return grayColor;
        } else {
            const darkenAmount = 0.2;
            const darkRed = Math.max(0, Math.floor(red * (1 - darkenAmount)));
            const darkGreen = Math.max(0, Math.floor(green * (1 - darkenAmount)));
            const darkBlue = Math.max(0, Math.floor(blue * (1 - darkenAmount)));
            const darkColor = '#' + darkRed.toString(16).padStart(2, '0') + darkGreen.toString(16).padStart(2, '0') + darkBlue.toString(16).padStart(2, '0');
            return darkColor;
        }
    }
}



// export function getContrastColor(hex) {
//     if (hex.indexOf('#') === 0) {
//         hex = hex.slice(1);
//     }
//     // convert 3-digit hex to 6-digits.
//     if (hex.length === 3) {
//         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//     }
//     if (hex.length !== 6) {
//         throw new Error('Invalid HEX color.');
//     }
//     // invert color components
//     var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
//         g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
//         b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
//     // pad each with zeros and return
//     return '#' + padZero(r) + padZero(g) + padZero(b);
// }

// function padZero(str, len) {
//     len = len || 2;
//     var zeros = new Array(len).join('0');
//     return (zeros + str).slice(-len);
// }