import React, { useState, useEffect, useContext, useCallback } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { useHistory, useLocation } from "react-router";
import "./LandingTemplate1.scss";
import axios from "axios";
import QueryString from "qs";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import { AppContext } from "../../contexts/AppContext";
import { sendSMS } from "../../api/twilio";
import { createPhoneCheck, getPhoneCheckResult } from "../../api/truPhoneCheck";
import { checkUser, getGoogleUser, googleLogin } from "../../magic";
import DynamicInput from "../../components/DynamicInput/DynamicInput";
import mailIcon from "../../assets/mailIcon.svg";
import chevronRight from "../../assets/chevron-right.svg";
import createUser, {
  checkAPIUser,
  checkAPIUserHotline,
  createUserHotline,
  updateUser,
} from "../../api/endpoints/createUser";
import { signInWithGoogle, signOutGoogle } from "../../firebase/firebase";
import { isMobile, browserName } from "react-device-detect";
import firebase from "firebase/compat/app";
import MuteUnmute from "../../components/MuteUnmute/MuteUnmute";
import getParameterByName from "../../helpers/getQueryParams";
import { getArtist } from "../../helpers/getArtist";
import { landing } from "../../config/Config";
import ArtistsLinks from "../../components/ArtistsLinks/ArtistsLinks";

export default function LandingTemplate1({ data }) {
  const {
    backgroundVideoUrl,
    backgroundImgUrl,
    mainColor,
    logo,
    btnTxt,
    textMeNumber,
    textAutofill,
    heading1,
    heading2,
    artistsLinks,
    phoneInputPlaceholder,
  } = data;
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");
  const [obj, setObj] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isWhatsapp, setWhatsApp] = useState(false);
  const [isNotWhatsApp, setNotWhatsApp] = useState(false);
  const [error, seterror] = useState(false);
  const [verified, setVerified] = useState(false);
  const [checkState, setCheckState] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [stateCheckId, setStateCheckId] = useState("");
  const [checkURL, setCheckURL] = useState("");
  const { authToken, setUser, user, referred_by } = useContext(AppContext);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [doNotSend, setDoNotSend] = useState(true);
  const artistId = getArtist();
  const artistJSON = landing[artistId];

  const history = useHistory();
  const location = useLocation();
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    const effectFunc = async () => {
      const email = getParameterByName("email");
      const apcid = getParameterByName("apcid");
      const phone_number = getParameterByName("phone_number");

      if (!email || !apcid || !phone_number) {
        // history.push("/");
        setLoading(false);
      } else {
        const userData = phone_number
          ? await checkAPIUserHotline({ phone_number })
          : await checkAPIUser({ email });
        if (userData != "continue") {
          setUser(userData);
          if (
            userData.user_data.phone_verified &&
            getParameterByName("change") !== "true"
          ) {
            history.push("/hotline-success", {
              phoneNumber: userData.user_data.phone_number,
              dontSendSMS: true,
            });
          }
        } else {
          history.push("/hotline");
        }
        setLoading(false);
      }
    };

    effectFunc();
  }, [location.search]);

  const VERIFY_TIMEOUT_MS = 5000;
  let createChecker, resultChecker;
  let checkVerifyTimeTimeout;
  let isVerifying = false;
  let verifyProcessStartTimestamp = null;

  let verifyingHasTimedOut = false;

  async function validatePhoneCheck(identifier, checkId, checkURL) {
    if (verifyingHasTimedOut) {
      return;
    }

    resultChecker = getPhoneCheckResult(identifier, checkId, authToken);
    const getPhoneCheckResultResult = await resultChecker.promise;

    if (verifyingHasTimedOut) {
      return;
    }

    clearTimeout(checkVerifyTimeTimeout);
    setLoading(false);
    isVerifying = false;

    if (getPhoneCheckResultResult.success) {
      const { status, match } = getPhoneCheckResultResult;

      // setCheckState(status)
      setCheckState(status !== "ACCEPTED" ? status : "");

      if (status === "ACCEPTED") {
        return validatePhoneCheck(identifier, checkId, checkURL);
      }

      if (status.toLowerCase() === "PENDING".toLowerCase()) {
        return;
      }

      // setVerified(status === "ACCEPTED" || status === "COMPLETED");

      setVerified(match);

      if (match) {
        var updatedUser = await updateUser({
          user,
          data: { phone_number: phoneNumber, phone_verified: false },
        });
        // setUser(updatedUser);
        history.push("/hotline-success", { phoneNumber });
      } else if (status === "ERROR" || (status === "COMPLETED" && !match)) {
        var updatedUser = await updateUser({
          user,
          data: { phone_number: phoneNumber, phone_verified: false },
        });
        // setUser(updatedUser);
        history.push("/verify", { phoneNumber });
      }
    } else {
      setCheckState("Failed");

      history.push("/verify", { phoneNumber });
    }
  }

  const checkNumber = async (phone, countryCode) => {
    if (phone && countryCode && phone.length - countryCode.length >= 10) {
      const isWhatsapp = true;
      setPhoneNumber(phone);
      if (isWhatsapp) {
        setNotWhatsApp(false);
        setWhatsApp(true);
      } else {
        setWhatsApp(false);
        setNotWhatsApp(true);
      }
    } else {
      setWhatsApp(false);
      setNotWhatsApp(false);
    }
  };

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setObj({ ...obj, network_data: data });
      })
      .catch((error) => {
        setObj({ ...obj, network_data: null });
      });
  }, [axios]);
  const checkPhoneNrExists = async () => {
    const response = await checkAPIUserHotline({ phone_number: phoneNumber });
    var doNotSendSt = doNotSend;
    if (response === "continue") {
      setDoNotSend(false);
      doNotSendSt = false;
      const createResponse = await createUserHotline({
        phone_number: phoneNumber,
        phone_verified: false,
        network_data: obj.network_data,
        artists: {
          [artistId]: {
            artist_name: artistJSON.artist,
            referred_by: localStorage.getItem("referred_by") || "",
            referral_count: 0,
          },
        },
      });
      setUser(createResponse.response);
      return { ...createResponse.response, doNotSend: doNotSendSt };
    }
    return {
      ...response,
      doNotSend: response.subscribeToArtist ? false : true,
    };
  };
  async function handleClick() {
    if (btnTxt.includes("WhatsApp")) {
      window.open(`https://wa.me/447700175695?text=SNOWDOME`, "_blank");
    } else {
      window.open(`sms:${textMeNumber}?&body=${textAutofill}`);
    }
  }
  useEffect(() => {
    if (backgroundVideoUrl) {
      heightChange();
      const phoneVideo = document.getElementById("video-phone");
      const video = document.getElementById("video");
      if (window.innerWidth >= 600 && !isMobile) {
        phoneVideo.pause();
        phoneVideo.style.display = "none";
        video.src = backgroundVideoUrl;
        video.play();
      } else {
        phoneVideo.src = backgroundVideoUrl;
        phoneVideo.style.display = "block";
        phoneVideo.style.position = "fixed";
        phoneVideo.src = backgroundVideoUrl;
        phoneVideo.play();

        video.pause();
      }

      window.addEventListener("resize", heightChange);
      return () => {
        window.removeEventListener("resize", heightChange);
      };
    }
  }, []);
  const heightChange = () => {
    const phoneVideo = document.getElementById("video-phone");
    const video = document.getElementById("video");
    if (window.innerWidth != width) {
      setWidth(window.innerWidth);
      if (window.innerWidth >= 600 && !isMobile) {
        phoneVideo.pause();
        phoneVideo.style.display = "none";
        video.play();
      } else {
        video.pause();
        phoneVideo.style.display = "block";
        phoneVideo.style.position = "fixed";
        phoneVideo.src = backgroundVideoUrl;
        phoneVideo.play();
      }
    }
  };
  // useEffect(() => {
  //   const cssChange = document.getElementsByClassName(
  //     "landing-template-1-wrapper"
  //   )[0];
  //   if (browserName == "Instagram") {
  //     cssChange.style.height = "100%";
  //   } else {
  //     cssChange.style.height = "87%";
  //   }
  // }, [browserName]);

  return (
    <div className="landing-template-1-styles">
      {backgroundVideoUrl && (
        <div className="mute-unmute-wrapper">{/* <MuteUnmute /> */}</div>
      )}
      <div
        style={
          backgroundImgUrl && {
            background: `url(${backgroundImgUrl}) center`,
          }
        }
        className="desktop-wrapper"
      >
        {backgroundVideoUrl && (
          <video
            playsInline
            autoPlay
            loop
            muted={true}
            preload="auto"
            id="video"
            type="text/html"
            width="100%"
            data-wf-ignore="true"
          ></video>
        )}
      </div>
      {loading && (
        <div className="overlay-loading">
          <FadeLoader color={"#fff"} />
        </div>
      )}
      <div className="landing-template-1-background">
        <div
          className="landing-template-1-wrapper"
          style={
            backgroundImgUrl && {
              background: `url(${backgroundImgUrl}) no-repeat center`,
            }
          }
        >
          {backgroundVideoUrl && (
            <video
              playsInline
              autoPlay
              muted={true}
              loop={true}
              preload="auto"
              style={{ height: "100vh", objectFit: "cover" }}
              id="video-phone"
              type="text/html"
              width="100%"
              // src="https://d3e3j7juwu6cup.cloudfront.net/Video_2015.m4v"
            ></video>
          )}
          <img src={logo} alt="artist-logo" className="artist-logo" />
          <h1 className="heading-1">{heading1}</h1>
          {heading2 && <h2 className="heading-2">{heading2}</h2>}
          <div className="content-wrapper">
            <div className="buttons-wrapper">
              <DynamicButton
                text={btnTxt}
                type={"sms"}
                small
                action={handleClick}
                backgroundColor={mainColor}
                retry={() => {
                  setPhoneNumber(null);
                  seterror(false);
                }}
              ></DynamicButton>
              {artistsLinks && <ArtistsLinks links={artistsLinks} />}
            </div>
            {/* <div className="text-wrapper"></div> */}
            <div className="copyright">
              <p>
                By continuing, you agree to our{" "}
                <a href="https://iindy.co/terms" target="_blank">
                  Terms
                </a>{" "}
                and{" "}
                <a href="https://iindy.co/privacy" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
