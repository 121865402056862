import React from "react";
import "./EventQuestion.scss";
import { BiCaretDownCircle } from "react-icons/bi";
import { GrClose } from "react-icons/gr";

export default function EventQuestion({
  isStopped,
  raffleData,
  setRaffleData,
}) {
  const updateOption = (i, val) => {
    let raffleEventOptions = [...raffleData.raffleEventOptions];
    raffleEventOptions[i]
      ? (raffleEventOptions[i].option = val)
      : raffleEventOptions.push({ option: "", quantity: null });
    setRaffleData({ ...raffleData, raffleEventOptions: raffleEventOptions });
  };
  const updateQuantity = (i, val) => {
    let raffleEventOptions = [...raffleData.raffleEventOptions];
    raffleEventOptions[i].quantity = val;
    setRaffleData({ ...raffleData, raffleEventOptions: raffleEventOptions });
  };
  const removeOption = (i) => {
    let raffleEventOptions = [...raffleData.raffleEventOptions];
    raffleEventOptions.splice(i, 1);
    setRaffleData({ ...raffleData, raffleEventOptions: raffleEventOptions });
  };
  return (
    <div className="event-question">
      <div className="title-section">
        <BiCaretDownCircle />
        <input
          disabled={!!isStopped}
          className="title-input"
          value={raffleData.raffleEventTitle}
          placeholder="Add Title  Here (e.g. Size,  Event Name)"
          onChange={(e) =>
            setRaffleData({ ...raffleData, raffleEventTitle: e.target.value })
          }
        />
      </div>
      <div className="alternatives">
        {raffleData.raffleEventOptions.map(({ option, quantity }, i) => (
          <div className="alternative" key={`alternative_${i}`}>
            <b>{i + 1}.</b>
            <input
              disabled={!!isStopped}
              placeholder={`Option ${i + 1}`}
              className="alternative-input"
              onChange={(e) => updateOption(i, e.target.value)}
              value={option}
            />
            <input
              disabled={!!isStopped}
              type="number"
              placeholder={`Quantity Available`}
              className="alternative-input"
              onChange={(e) => updateQuantity(i, e.target.value)}
              value={quantity}
            />
            <button onClick={() => (!isStopped ? removeOption(i) : "")}>
              <GrClose />
            </button>
          </div>
        ))}
        <div className="alternative to-add">
          <b>{raffleData.raffleEventOptions.length + 1}.</b>
          <button
            onClick={() =>
              !isStopped
                ? updateOption(raffleData.raffleEventOptions.length, "")
                : ""
            }
            className="alternative-input"
          >
            Add option
          </button>
        </div>
      </div>
    </div>
  );
}
