import { notification } from "antd";
import PlatformsRecomended from "../../../assets/platforms-recomended.svg";
import { customNotification } from "../../../helpers/customNotification";

export const engagementCard = {
    normal: {
        title: "Engagement",
        tooltip:
            "Engagement rate is the percentage of unique clicks per message sent.",
        targetGoal: 45,
        percent: 50,
        compare: 3.8,
        compareSign: "%",
        smallSign: '%',

        description:
            "<span>On avg,  a Hotline gets 45% engagement rates. compared to just 10% for email, and 2.4% on IG.</span>",
        leftDescription: "0%",
        rightDescription: "100%",
    },
    blank: {
        title: "Engagement",
        tooltip:
            "Engagement rate is the percentage of unique clicks per message sent.",
        targetGoal: 45,
        percent: 50,
        compare: 0,
        compareSign: "%",
        smallSign: '%',
        description:
            "<span>On avg,  a Hotline gets 45% engagement rates. compared to just 10% for email, and 2.4% on IG.</span>",
        leftDescription: "0%",
        rightDescription: "100%",
        blank: true,
    }
}
export const fanbaseCard = {
    normal: {
        title: "Member Growth",
        targetGoal: 20,
        percent: 10,
        compare: 20,
        compareSign: "%",
        smallSign: '',
        description:
            "<div class='description-inner-left'><div class='label'>Total Members</div><div class='value'>200</div></div><div class='description-inner-right'><div class='label'>12 month projection</div><div class='value'>628</div></div>",
        leftDescription: "Low",
        rightDescription: "High",
        button: {
            label: "SHARE MY NUMBER",
            action: () => {
                const number = JSON.parse(localStorage.getItem('dbUser')).claimed_phone_number;
                navigator.clipboard.writeText(number);
                customNotification("Number copied to clipboard")
            },
        },
    },
    blank: {
        title: "Member Growth",
        targetGoal: 20,
        percent: 10,
        compare: 0,
        compareSign: "%",
        description:
            "Share your Hotline number on your socials and aim to sign up 1,000 members in the first 30 days. ",
        leftDescription: "Low",
        rightDescription: "High",
        button: {
            label: "SHARE MY NUMBER",
            action: () => {
                const number = JSON.parse(localStorage.getItem('dbUser')).claimed_phone_number;
                navigator.clipboard.writeText(number);
                customNotification("Number copied to clipboard")
            }
        },
        blank: true,
    }
}
export const valueXFan = {
    normal: {
        title: "Value per fan",
        tooltip:
            "Value per member = Total revenue in the last 12 months divided by your total members. This metric estimates the average value per member.",
        targetGoal: 50,
        percent: 25,
        compare: "",
        compareSign: "",
        comparePreSign: "$",
        description:
            "<div class='description-inner-left'><div class='label'>Total value</div><div class='value'>$5,000</div></div><div class='description-inner-right'><div class='label'>12 month projection</div><div class='value'>$15,700</div></div>",
        leftDescription: "Low",
        rightDescription: "High",
        button: {
            label: "LET’S CHAT",
            action: () => { },
        },
    },
    blank: {
        title: "Value per fan",
        tooltip:
            "Value per member = Total revenue in the last 12 months divided by your total members. This metric estimates the average value per member.",
        targetGoal: 50,
        percent: 25,
        compare: "",
        compareSign: "",
        comparePreSign: "$",
        description: `<div class='recomend-descr'>Connect your accounts to see the potential value of your community members.<div class='recomend'>Recommended platforms<div class='platforms'><img src='${PlatformsRecomended}'/></div></div></div>`,
        leftDescription: "Low",
        rightDescription: "High",
        button: {
            label: "LET’S CHAT",
            action: () => { },
        },
        blank: true,
    }
}