import React, { useState } from "react";
import { BsReply } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { IoGitMergeOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";

import "./Bubble.scss";

export default function Bubble({
  id,
  from = "Send as myself",
  message,
  options = [],
  setReplyOptionsModal = null,
  setTextToEdit,
  deleteBubble,
  setIsEditing,
  bubbleMessages,
  setBubbleMessages,
  index,
  activePathway,
  setActivePathway,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const bubbleOptions = [
    {
      label: "Edit text",
      icon: FiEdit3,
      onClick: () => {
        setShowEditModal(false);
        setTextToEdit(message);
        setIsEditing(id);
      },
    },
    {
      label: "Reply Pathways",
      icon: IoGitMergeOutline,
      onClick: () => {
        setReplyOptionsModal(id);
      },
    },
    {
      label: "Delete",
      icon: RiDeleteBin6Line,
      color: "#F04438",
      onClick: () => {
        setShowEditModal(false);
        deleteBubble(id);
      },
    },
  ];

  const onReplyClick = (e, option, i) => {
    e.stopPropagation();
    setActivePathway([id, option.text]);

    const newBubbles = [...bubbleMessages].slice(0, index + 1);

    setBubbleMessages([
      ...newBubbles,
      { id: id + 1, message: option.text, from: "Send as Receiver" },
      ...option.messages,
    ]);
    const pathwayToClick = document.getElementById(
      `sidebar-pathway-option-${id}-${i}`
    );
    if (pathwayToClick) {
      pathwayToClick.click();
    }
  };
  return (
    <>
      <div
        className={`whatsapp-bubble-row ${
          from === "Send as Receiver" ? "whatsapp-bubble-row-receiver" : ""
        }`}
      >
        <div
          className={`whatsapp-bubble ${
            from === "Send as Receiver" ? "whatsapp-bubble-receiver" : ""
          } ${showEditModal === id ? "bubble-with-zindex" : ""}`}
          onClick={() => {
            from !== "Send as Receiver" && setShowEditModal(id);
          }}
        >
          <div className="whatsapp-message">{message}</div>
          {options.map((option, i) => (
            <div
              className={`whatsapp-message-options ${
                activePathway[0] === id &&
                activePathway[1] === option.text &&
                "active-whatsapp-option"
              }`}
              id={`whatsapp-reply-option-${id}-${i}`}
              onClick={(e) => onReplyClick(e, option, i)}
            >
              <div>
                <BsReply />
                {option.text}
              </div>
            </div>
          ))}
        </div>
        {showEditModal === id && (
          <div
            className={`bubble-options-modal ${
              index >= 2 ? "bubble-options-modal-positioned-top" : ""
            }`}
          >
            {bubbleOptions.map((option) => (
              <div
                className="options-modal-row"
                style={{ color: option.color || "black" }}
                onClick={option.onClick || null}
              >
                {option.label}
                <option.icon />
              </div>
            ))}
          </div>
        )}
      </div>
      {showEditModal === id && (
        <div
          className="edit-modal-backdrop"
          onClick={() => setShowEditModal(false)}
        />
      )}
    </>
  );
}
