import ReactGA from "react-ga4";

export const TrackGAEvent = (
    action,
    label = "click",
    category = 'Artist Landing page',
) => {
    ReactGA.event({
        label,
        category,
        action: `${action}_${label}`,
    },)
};