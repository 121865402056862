import React, { useEffect, useState } from "react";
import { GLTFModel, AmbientLight, DirectionLight } from "react-3d-viewer";

import "./ModelViewer.scss";
// https://go.iindy.co/y5Kbf"

export default function ModelViewer({ src }) {
  const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setRotation({ ...rotation, y: rotation.y + 0.05 });
    }, 50);
    return () => {
      clearInterval(rotationInterval);
    };
  }, [rotation]);

  return (
    <div
      className="model-viewer"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <GLTFModel
        src={src}
        rotation={rotation}
        position={{ x: 0, y: 0, z: 0 }}
        enableRotation
      >
        <AmbientLight color={0xffffff} />
        <DirectionLight color={0xffffff} position={{ x: 100, y: 0, z: 10 }} />
      </GLTFModel>
    </div>
  );
}
