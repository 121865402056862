import { nanoid } from 'nanoid';
import requestDM from '../../requestDM'

export const createWhatsappFlow = async ({ name, description, json }) => {
    const formattedBody = {
        'flowId': nanoid(5),
        'flowName': name,
        'flowDescription': description || '',
        'flowJson': json
    }
    var response;
    try {
        response = await requestDM(formattedBody, `/apiv2/customer/whatsapp/iindyFlows`, 'POST')
        return response
    } catch (error) {
        return { error }
    }
}

export const updateWhatsappFlow = async ({ flowId, name, description, json }) => {
    const formattedBody = {
        'flowId': flowId,
        'flowName': name,
        'flowDescription': description || '',
        'flowJson': json
    }
    var response;
    try {
        response = await requestDM(formattedBody, `/apiv2/customer/whatsapp/iindyFlows/${flowId}`, 'PUT')
        return response
    } catch (error) {
        return { error }
    }
}

export const getAllWhatsappFlows = async () => {
    var response;
    try {
        response = await requestDM(null, `/apiv2/customer/whatsapp/iindyFlows`, 'GET')
        return response.result || []
    } catch (error) {
        return []
    }
}

export const getWhatsappFlowById = async (id) => {
    var response;
    try {
        response = await requestDM(null, `/apiv2/customer/whatsapp/iindyFlows/${id}`, 'GET')
        return response.result
    } catch (error) {
        return { error }
    }
}