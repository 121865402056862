import axios from 'axios'
import { landing } from '../../config/Config'
import { signOutGoogle } from '../../firebase/firebase'
import { getArtist } from '../../helpers/getArtist'
import request from '../request'
const artistId = getArtist()
const artistJSON = landing[artistId]


export default async function createUser(data) {
    var response

    const checkUserRegistration = await checkAPIUser(data)
    if (checkUserRegistration === 'continue') {
        try {
            response = await request(data, '/user', 'create')
            return { success: response }
        } catch (error) {
            return { error }
        }
    }
    else if (checkUserRegistration.subscribeToArtist) {
        return { success: checkUserRegistration.subscribeToArtist }
    } else {
        return { success: { ...checkUserRegistration, alreadyRegistered: true } }
    }


}
export const checkAPIUser = async (data) => {
    const queryString = `/user/check?email=${data.email}&artist_id=${artistId}`
    var response
    try {
        response = await request(null, queryString, 'index')
        if (response.artistSubscribed == false && response.userExists == false) {
            return 'continue'
        }
        else if (response.artistSubscribed == false && response.user_id) {
            const subRes = await subscribeToArtist(response)
            return { 'subscribeToArtist': subRes }
        } else {
            return { ...response, }
        }
    } catch (error) {
        localStorage.clear()
        window.location = '/'
        signOutGoogle()

    }
}
export const checkAPIUserHotline = async (data) => {
    const queryString = `/user/check?phone_number=${data.phone_number}&artist_id=${artistId}`
    var response
    try {
        response = await request(null, queryString, 'index')
        if (response.artistSubscribed == false && response.userExists == false) {
            return 'continue'
        }
        else if (response.artistSubscribed == false && response.user_id) {
            const subRes = await subscribeToArtistHotline(response, false)
            return { 'subscribeToArtist': subRes }
        } else {
            return { ...response, }
        }
    } catch (error) {
        localStorage.clear()
        window.location = '/'
        signOutGoogle()

    }
}
export const createUserHotline = async (data) => {
    var response;
    try {
        response = await request(data, '/user/hotline', 'create')
        return { response }
    } catch (error) {
        localStorage.clear()
        window.location = '/hotline'

    }
}
const subscribeToArtist = async (data, verified = true) => {
    const subData = {
        [artistId]: {
            "referred_by": localStorage.getItem('referred_by') || '',
            "artist_name": artistJSON.artist,
            "referral_count": 0,
            "verified": verified
        },
        'firstname': data?.user_data?.firstname || null,
        "email": data?.user_data?.email || null,
    }
    var response
    try {
        response = await request(subData, `/user/subscribetoartist/${data?.user_id}`, 'update')
        return response
    } catch (error) {

        localStorage.clear()
        window.location = '/'
        signOutGoogle()
        return false
    }
}
const subscribeToArtistHotline = async (data, verified = true) => {
    const subData = {
        [artistId]: {
            "referred_by": localStorage.getItem('referred_by') || '',
            "artist_name": artistJSON.artist,
            "referral_count": 0,
            "verified": verified
        },
        'phone_number': data?.user_data?.phone_number || null,
    }
    var response
    try {
        response = await request(subData, `/user/subscribetoartist/${data?.user_id}`, 'update')
        return response
    } catch (error) {

        localStorage.clear()
        window.location = '/'
        signOutGoogle()
        return false
    }

}
export const updateUser = async ({ user, data }) => {
    var response
    try {
        response = await request(data, `/user/${user.user_id}`, 'update')
        return response.Attributes
    } catch (error) {

        return false
    }
}