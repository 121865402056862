import { notification } from "antd"
// import "../App.scss"

export const customNotification = (message = "", icon = null, description = "", className = "") => {
    notification.info({
        message: (
            <>
                <div className={`${icon === null ? "alignRight-msg" : ''}`}>
                    {message}
                </div>
            </>
        ),
        className: (description ? "custom_notification_withDesc" : className ? className : "custom_notification"),
        style: {
            zIndex: 9999,
            borderRadius: 12,
            backgroundColor: "#5975FF",
            color: "#fff",
            height: "auto"
        },
        closeIcon: <div />,
        description: (<>
            <div className={`${icon === null ? "alignRight-descp" : ''}`}>
                {description}
            </div>
        </>),
        icon: (
            icon === null ? <div className="disable-icon"></div> :
                (<div className={`${icon === null ? "" : 'copy-notif-icon'}`}>
                    {icon}
                </div>)
        ),
    })
}