import React, { useState, useEffect, useContext, useCallback } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { useHistory, useLocation } from "react-router";
import "./LandingEmail.scss";
import axios from "axios";
import QueryString from "qs";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import { AppContext } from "../../contexts/AppContext";
import { sendSMS } from "../../api/twilio";
import { createPhoneCheck, getPhoneCheckResult } from "../../api/truPhoneCheck";
import { checkUser, getGoogleUser, googleLogin } from "../../magic";
import DynamicInput from "../../components/DynamicInput/DynamicInput";
import mailIcon from "../../assets/mailIcon.svg";
import chevronRight from "../../assets/chevron-right.svg";
import createUser from "../../api/endpoints/createUser";
import { signInWithGoogle, signOutGoogle } from "../../firebase/firebase";
import firebase from "firebase/compat/app";
import MuteUnmute from "../../components/MuteUnmute/MuteUnmute";
import { isMobile, browserName } from "react-device-detect";
import { getArtist } from "../../helpers/getArtist";
import { landing } from "../../config/Config";
import { cioStore } from "../../api/customer.io";
import Copyright from "../../components/Copyright/Copyright";
import whatsappIcon from "../../assets/whatsapp.png";
import Button from "../../components/SignupProcess/Button/Button";
import { FaWhatsapp } from "react-icons/fa";
import { BiMessageSquareDetail, BiShare, BiShareAlt } from "react-icons/bi";
import { LuSend } from "react-icons/lu";
import { BsChatDots } from "react-icons/bs";
import { TrackGAEvent } from "../../helpers/googleAnalytics";

const accountSid = "AC6b23b91b120dfaa151c54561c1a19969";
const authTokenWP = "e7e93d58c511265afe4fcf2cda0d473b";

export default function LandingEmail({ data }) {
  const {
    logo,
    backgroundImgUrl,
    desktopBackground,
    backgroundVideoUrl,
    heading1,
    heading1Success,
    heading2,
    heading3,
    artist,
    whatsappLink,
    whatsappButtonText,
    inviteLink,
    usButtonText,
    usButtonLink,
    smsInviteLink,
  } = data;

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");
  const [obj, setObj] = useState({});
  const { authToken, setUser, user, referred_by } = useContext(AppContext);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [activeTab, setActiveTab] = useState("hotline");
  const [networkData, setNetworkData] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const [isUS, setIsUS] = useState(false);

  const history = useHistory();
  const location = useLocation();
  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        if (data.country === "US") {
          setIsUS(true);
        }
        setNetworkData(data);
      })
      .catch((error) => {
        setNetworkData({});
      });
  }, [axios]);

  useEffect(() => {
    heightChange();
    // const phoneVideo = document.getElementById("video-phone");
    // const video = document.getElementById("video");
    // if (window.innerWidth >= 600 && !isMobile) {
    //   phoneVideo.pause();
    //   phoneVideo.style.display = "none";
    //   video.src = backgroundVideoUrl;
    //   video.play();
    // } else {
    //   phoneVideo.src = backgroundVideoUrl;
    //   phoneVideo.style.display = "block";
    //   phoneVideo.style.position = "fixed";
    //   phoneVideo.src = backgroundVideoUrl;
    //   phoneVideo.play();

    //   video.pause();
    // }

    window.addEventListener("resize", heightChange);
    return () => {
      window.removeEventListener("resize", heightChange);
    };
  }, []);
  const heightChange = () => {
    if (window.innerWidth != width) {
      setWidth(window.innerWidth);
      if (window.innerWidth >= 600 && !isMobile) {
        // phoneVideo.pause();
        // phoneVideo.style.display = "none";
        // video.play();
      } else {
        // video.pause();
        // phoneVideo.style.display = "block";
        // phoneVideo.style.position = "fixed";
        // phoneVideo.src = backgroundVideoUrl;
        // phoneVideo.play();
      }
    }
  };
  useEffect(() => {
    const cssChange = document.getElementsByClassName(
      "landing-email-wrapper"
    )[0];
    if (browserName == "Instagram") {
      cssChange.style.height = "100%";
    } else {
      cssChange.style.height =
        "-webkit-calc(100% - 65px); /* google, safari */";
    }
  }, [browserName]);
  const artistId = getArtist();
  const artistJSON = landing[artistId];

  const whatsappHotline = (e) => {
    e?.preventDefault();
    TrackGAEvent(`${data?.artist.toLowerCase()}_whatsapp`);
    window.open(whatsappLink, "_blank");
    setSubscribed("whatsapp");
  };

  const usSMSHotline = (e) => {
    e?.preventDefault();
    TrackGAEvent(`${data?.artist.toLowerCase()}_us_sms_hotline`);
    window.open(usButtonLink, "_blank");
    setSubscribed("whatsapp");
  };
  const shareToFriend = (e) => {
    e?.preventDefault();
    TrackGAEvent(`${data?.artist.toLowerCase()}_friend_invite_whatsapp`);
    window.open(inviteLink, "_blank");
  };
  const shareToFriendSMS = (e) => {
    e?.preventDefault();
    TrackGAEvent(`${data?.artist.toLowerCase()}_us_friend_invite_sms`);
    window.open(smsInviteLink, "_blank");
  };

  return (
    <div className="landing-email-styles">
      <div
        style={{
          // background: `url(${desktopBackground})`,
          backgroundPosition: 0,
        }}
        className="desktop-wrapper"
      ></div>
      {loading && (
        <div className="overlay-loading">
          <FadeLoader color={"#fff"} />
        </div>
      )}
      <div className="landing-email-background">
        <div
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 35.08%, #000000 300%), url(${backgroundImgUrl}) no-repeat`,
            backgroundSize: "100% 100%",
          }}
          className="landing-email-wrapper"
        >
          {backgroundImgUrl.includes("mp4") && (
            <video
              className="video-background-email"
              src={backgroundImgUrl}
              muted
              autoPlay
              loop
              playsInline
            />
          )}
          <div className="overlay-gradient" />
          <img src={logo} alt="artist-logo" className="artist-logo" />
          <div
            className="new-landing-content"
            style={{
              marginTop: isUS && activeTab === "hotline" ? "220px" : "270px",
            }}
          >
            <h1 className="heading-1">
              {!subscribed ? heading1 : heading1Success}
            </h1>
            {!subscribed && (
              <div className="tabs">
                <div
                  className={`tab ${
                    activeTab === "hotline" ? "active-landing" : ""
                  }`}
                  onClick={() => {
                    TrackGAEvent(`${data?.artist.toLowerCase()}_hotline`);
                    setActiveTab("hotline");
                  }}
                >
                  Hotline
                </div>
                <div
                  className={`tab ${
                    activeTab === "email" ? "active-landing" : ""
                  }`}
                  onClick={() => {
                    TrackGAEvent(`${data?.artist.toLowerCase()}_email`);
                    setActiveTab("email");
                  }}
                >
                  Email
                </div>
              </div>
            )}
          </div>

          <form className="content-wrapper">
            <div className="buttons-wrapper">
              {subscribed !== "whatsapp" && (heading2 || heading3) && (
                <h2 className="heading-2">
                  {activeTab === "email" ? heading2 : heading3}
                </h2>
              )}
              {activeTab === "email" ? (
                <DynamicInput
                  actionOnRegex={(valid, value) => {
                    setValid(valid);
                    setEmail(value);
                  }}
                  iconAction={async () => {
                    if (valid) {
                      cioStore(
                        email,
                        networkData,
                        artistJSON.cio,
                        artistJSON.cioToken
                      );
                      setSubscribed("email-true");
                      setActiveTab("hotline");
                      TrackGAEvent(`${data?.artist.toLowerCase()}_email_entry`);
                    }
                  }}
                  placeholder={"ENTER YOUR EMAIL"}
                  name={"email"}
                  rightIcon
                  icon={chevronRight}
                />
              ) : subscribed === "whatsapp" ? (
                <Button
                  background={!isUS && "#4BC85A"}
                  textColor={!isUS && "#fff"}
                  label={`INVITE YOUR FRIENDS`}
                  border="none"
                  leftIcon={
                    <LuSend
                      size={20}
                      style={{ transform: "rotate(45deg)", marginRight: 8 }}
                    />
                  }
                  iconOrImage="icon"
                  action={isUS ? shareToFriendSMS : shareToFriend}
                />
              ) : (
                <div className="us-button-holder">
                  {isUS && usButtonText && (
                    <Button
                      label={`${usButtonText.toUpperCase()} ${
                        artist.length >= 7 ? "ME" : artist.toUpperCase()
                      }`}
                      leftIcon={<BsChatDots size={20} />}
                      iconOrImage="icon"
                      action={usSMSHotline}
                    />
                  )}
                  <Button
                    background="#4BC85A"
                    textColor="#fff"
                    label={`${whatsappButtonText.toUpperCase()} ${
                      artist.length >= 7 ? "ME" : artist.toUpperCase()
                    }`}
                    leftIcon={<FaWhatsapp size={20} />}
                    iconOrImage="icon"
                    action={whatsappHotline}
                    border="none"
                  />
                </div>
              )}
            </div>

            {subscribed !== "whatsapp" && (
              <div className="copyright">
                <p>
                  By continuing, you agree to our{" "}
                  <a href="https://iindy.co/terms" target="_blank">
                    Terms
                  </a>{" "}
                  and{" "}
                  <a href="https://iindy.co/privacy" target="_blank">
                    Privacy Policy
                  </a>
                </p>
              </div>
            )}
            <div className="copyright-container">
              <Copyright />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
