import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import { FaRegWindowMaximize } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { HiColorSwatch } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";
import { MdListAlt, MdOutlineModeEditOutline } from "react-icons/md";
import { RxCube } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import Loading from "../../../../SignupProcess/Loading/Loading";
import {
  addPlaybooks,
  updatePlaybooks,
} from "../../../../api/dmmeendpoints/playbook";
import { getNFTSegments } from "../../../../api/dmmeendpoints/segmentData";
import { ReactComponent as StepComplete } from "../../../../assets/Dashboard/step-complete.svg";
import ChevronLeft from "../../../../assets/SignUpProcess/chevron-left.svg";
import Button from "../../../../components/SignupProcess/Button/Button";
import { genFQCN } from "../../../../helpers/genFQCN";
import { getUnlockLink } from "../../../../helpers/getUnlockLink";
import { s3Upload } from "../../../../helpers/s3Upload";
import AccessPagePreview from "./AccessPagePreview/AccessPagePreview";
import "./CreateRaffle.scss";
import RaffleForm from "./RaffleForm/RaffleForm";
import RaffleFormPreview from "./RaffleFormPreview/RaffleFormPreview";
import SegmentDropdown from "./SegmentDropdown/SegmentDropdown";

export default function CreateRaffle({ setCreatingPlaybook }) {
  const [showPicker, setShowPicker] = useState(false);
  const [workingOn, setWorkingOn] = useState("access-rules");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [totalMembers, setTotalMembers] = useState([]);
  const [segmentDataSt, setSegmentData] = useState([]);
  const [isTotalChecked, setIsTotalChecked] = useState(false);
  const [checkedSegments, setCheckedSegments] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [nftSegments, setNftSegments] = useState([]);
  const [raffleData, setRaffleData] = useState({
    title: "Name your playbook",
    name: "Name of your raffle",
    accessTitle: "Unlock Access",
    accessDescription:
      "Add all the details you want to add about the raffle here or leave it blank and let your fans figure it out.",
    backgroundColor: "#000000",
    inputBackgroundColor: "#333333",
    textColor: "#f9f9f9",
    btnTextColor: "#000000",
    btnColor: "#fff81d",
    btnTitle: "Submit",
    raffleDetails: EditorState.createEmpty(),
    raffleEventTitle: "",
    raffleEventOptions: [
      { option: "", quantity: null },
      { option: "", quantity: null },
    ],
    raffleCustomTitle: "",
    raffleCustomOptions: [{ option: "" }, { option: "" }],
    enableCustomQuestion: true,
    isCustomQuestionRequired: true,
    autoClose: false,
    autoCloseTime: null,
  });
  const [showAccessPreview, setShowAccessPreview] = useState(false);
  const [showFormPreview, setShowFormPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (Object.values(checkedSegments).includes(false)) {
      setIsTotalChecked(false);
    } else {
      setIsTotalChecked(true);
    }
  }, [checkedSegments]);

  const history = useHistory();
  const isStopped = history?.location?.state?.stoppedChecked?.stopped;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    setLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    s3Upload(
      file,
      {},
      () => {},
      0,
      [],
      (url) => {
        setRaffleData({ ...raffleData, accessImage: url[0] });
      }
    );
    setLoading(false);
    return isJpgOrPng && isLt2M;
  };
  useEffect(() => {
    if (
      raffleData.btnTitle &&
      raffleData.accessDescription &&
      raffleData.accessImage
    ) {
      setStep2(true);
    } else {
      setStep2(false);
    }
    if (
      !raffleData.raffleFormImg ||
      !raffleData.name ||
      !raffleData.raffleDetails ||
      !raffleData.raffleEventTitle ||
      raffleData.raffleEventOptions.findIndex(
        ({ option, quantity }) => !option || !quantity
      ) > -1 ||
      (raffleData.enableCustomQuestion &&
        (!raffleData.raffleCustomTitle ||
          raffleData.raffleCustomOptions.findIndex(({ option }) => !option) >
            -1)) ||
      (raffleData.autoClose && !raffleData.autoCloseTime)
    ) {
      setStep3(false);
    } else {
      setStep3(true);
    }
  }, [raffleData]);
  const publishRaffle = async () => {
    const data = {
      playbook_type: "raffle",
      playbook_config: parsedRaffle,
    };
    const create = await addPlaybooks(data);
    const successObj = {
      title: "Your Playbooks is live!",
      description:
        "Only holders of the following collectible: [List of collectibles] will have access to this raffle. ",
      link: `${getUnlockLink()}${create.playbook.playbook_key}`,
      showSuccessModal: true,
    };
    setCreatingPlaybook(null);
    history.push("/dashboard/playbooks/my-playbooks", successObj);
  };
  const updateRaffle = async () => {
    const data = {
      playbook_type: "raffle",
      playbook_config: parsedRaffle,
    };
    const update = await updatePlaybooks(data, raffleData.playbook_key);
    const successObj = {
      title: "Your Playbooks is updated!",
      description:
        "Only holders of the following collectible: [List of collectibles] will have access to this raffle. ",
      link: `${getUnlockLink()}${update.playbook.playbook_key}`,
      showSuccessModal: true,
      doNotAddToList: true,
    };
    setCreatingPlaybook(null);
    history.push("/dashboard/playbooks/my-playbooks", successObj);
  };
  const userId = JSON.parse(localStorage.getItem("dbUser")).artist_id;
  const series = useMemo(
    () => [
      ...JSON.parse(
        localStorage.getItem("dbUser")
      )?.nft_collections?.[0].series.filter(
        (serie) => serie.series_asset_s3_path
      ),
      ...nftSegments?.map((sgm) => ({
        claim_cap_per_fan: sgm.series.claimCapPerFan,
        id: sgm.series.seriesId,
        series_asset_path: sgm.series.seriesAssetPath,
        series_asset_s3_path: sgm.series.seriesAssetS3Path,
        series_asset_type: sgm.series.seriesAssetType,
        series_thumbnail_asset_path: sgm.series.seriesThumbnailAssetPath,
        series_thumbnail_s3_path: sgm.series.seriesThumbnailS3Path,
      })),
    ],
    [nftSegments]
  );

  const appearanceFields = [
    { id: "backgroundColor", name: "Background color", type: "color" },
    {
      id: "inputBackgroundColor",
      name: "Input field background",
      type: "color",
    },
    { id: "textColor", name: "Text color", type: "color" },
    { id: "btnTextColor", name: "Button text color", type: "color" },
    { id: "btnColor", name: "Button color", type: "color" },
    { id: "btnTitle", name: "Button title", type: "text" },
  ];
  const filteredSeries = useMemo(
    () => [...series?.map((item) => item)].filter((a) => checkedSegments[a.id]),
    [series, checkedSegments]
  );

  useEffect(() => {
    if (!history.location.state?.metadata) {
      const calcChecked = {};
      for (let i = 0; i < segmentDataSt.length; i++) {
        const { segment_id } = segmentDataSt[i];
        calcChecked[segment_id] = false;
      }
      setCheckedSegments(calcChecked);
    }
  }, [segmentDataSt]);

  const parsedRaffle = useMemo(() => {
    return {
      raffle_iindy_owner: userId,
      collectibles_needed: filteredSeries.map((serie) => ({
        asset_image: serie.series_asset_path,
        asset_name: serie.series_name,
        id: serie.id,
        fqcn: genFQCN(userId, "welcome_bot_nft_collection", serie.id),
      })), // array of collectibles needed in fan's wallet to submit the raffle form,
      collectible_operator: "or", // AND or OR
      raffle_details: {
        access_page: {
          access_page_title: raffleData.accessTitle,
          access_page_description: raffleData.accessDescription,
          access_page_image: raffleData.accessImage,
          access_page_button_text: "VERIFY OWNERSHIP",
          access_page_button_link: null,
        },
        raffle_page_appearance: {
          background_color: raffleData.backgroundColor,
          input_background_color: raffleData.inputBackgroundColor,
          text_color: raffleData.textColor,
          button_color: raffleData.btnColor,
          button_text_color: raffleData.btnTextColor,
          button_text: raffleData.btnTitle,
        },
        raffle_name: raffleData.title,
        raffle_description: draftToHtml(
          convertToRaw(raffleData.raffleDetails?.getCurrentContent())
        ),
        raffle_image: raffleData.raffleFormImg,
        raffle_item_title: raffleData.name,
        raffle_event_name: raffleData.raffleEventTitle,
        raffle_items: raffleData.raffleEventOptions.map((option) => ({
          option_name: option.option,
          quantity_available: option.quantity,
        })),
        ...(raffleData.enableCustomQuestion
          ? {
              raffle_questions: [
                {
                  question: raffleData.raffleCustomTitle,
                  question_type: "checkbox", // text, radio, checkbox, select
                  options: raffleData.raffleCustomOptions.map(
                    (option) => option.option
                  ),
                  required: raffleData.isCustomQuestionRequired,
                },
              ],
            }
          : null),
        raffle_config: {
          auto_close: raffleData.autoClose,
          auto_close_date: raffleData.autoCloseTime,
        },
      },
    };
  }, [raffleData, checkedSegments, filteredSeries]);
  useEffect(() => {
    if (history?.location?.state && history?.location?.state?.metadata) {
      const parsedRaffle = history?.location?.state?.metadata?.metadata;
      let editorState = null;
      const blocksFromHTML = convertFromHTML(
        parsedRaffle?.raffle_details?.raffle_description || "<p/>"
      );
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      editorState = EditorState.createWithContent(state);
      const accessPage = parsedRaffle.raffle_details.access_page;
      const rafflePageAppearance =
        parsedRaffle.raffle_details.raffle_page_appearance;
      const raffleData = {
        playbook_key: history.location.state.metadata.playbook_key,
        accessTitle: accessPage.access_page_title,
        accessDescription: accessPage.access_page_description,
        accessImage: accessPage.access_page_image,
        backgroundColor: rafflePageAppearance.background_color,
        inputBackgroundColor: rafflePageAppearance.input_background_color,
        textColor: rafflePageAppearance.text_color,
        btnColor: rafflePageAppearance.button_color,
        btnTextColor: rafflePageAppearance.button_text_color,
        btnTitle: rafflePageAppearance.button_text,
        title: parsedRaffle.raffle_details.raffle_name,
        raffleDetails: editorState,
        raffleFormImg: parsedRaffle.raffle_details.raffle_image,
        name: parsedRaffle.raffle_details.raffle_item_title,
        raffleEventTitle: parsedRaffle.raffle_details.raffle_event_name,
        raffleEventOptions: parsedRaffle.raffle_details.raffle_items.map(
          (item) => ({
            option: item.option_name,
            quantity: item.quantity_available,
          })
        ),
        enableCustomQuestion:
          parsedRaffle.raffle_details.raffle_questions &&
          parsedRaffle.raffle_details.raffle_questions.length > 0,
        raffleCustomTitle: parsedRaffle.raffle_details.raffle_questions
          ? parsedRaffle.raffle_details.raffle_questions[0].question
          : "",
        raffleCustomOptions: parsedRaffle.raffle_details.raffle_questions
          ? parsedRaffle.raffle_details.raffle_questions[0].options.map(
              (option) => ({ option })
            )
          : [],
        isCustomQuestionRequired: parsedRaffle.raffle_details.raffle_questions
          ? parsedRaffle.raffle_details.raffle_questions[0].required
          : false,
        autoClose: parsedRaffle.raffle_details.raffle_config.auto_close,
        autoCloseTime:
          parsedRaffle.raffle_details.raffle_config.auto_close_date,
      };
      const filteredSeries = [
        ...new Map(
          series?.map((item) => [item["series_asset_path"], item])
        ).values(),
      ];

      const calcChecked = {};

      for (let i = 0; i < filteredSeries.length; i++) {
        const { id } = filteredSeries[i];

        calcChecked[id] = false;
      }

      parsedRaffle.collectibles_needed.map((coll) => {
        calcChecked[coll.id] = true;
      });
      setCheckedSegments(calcChecked);
      setRaffleData(raffleData);
    }
  }, [history.location.state]);

  const getAllSegments = async () => {
    let members = [];
    setLoading(true);
    const nftSegmentInfo = (await getNFTSegments()) || [];
    setNftSegments(nftSegmentInfo);
    const calcChecked = {};
    const preselected =
      history.location?.state?.metadata?.metadata?.collectibles_needed?.map(
        (sgm) => sgm.id
      ) || [];
    const modifySegmentsData = nftSegmentInfo?.map((item) => {
      calcChecked[item.series.seriesId] = preselected.includes(
        item.series.seriesId
      )
        ? true
        : false;

      return {
        members: item.members.map((phoneNumber) => ({
          metadata: { primary_mobile: phoneNumber },
        })),
        metadata: item.series,
        segment_id: `${item.series.seriesId}`,
        segment_name: `${item?.series.seriesName || "Blank"} (Version ${
          item?.series.seriesId?.split("_")[
            item?.series.seriesId?.split("_").length - 1
          ]
        })`,
        segment_owner_iindy_id: item.series.seriesId,
      };
    });
    setCheckedSegments(calcChecked);
    const segmentNewData = modifySegmentsData;
    let checkedInfo = {};
    members = [...members];

    for (let i = 0; i <= segmentNewData.length; i++) {
      const el = segmentNewData[i];
      const innerMembers = [];
      if (el) {
        if (el.members.length > 0) {
          checkedInfo = { ...checkedInfo, [el.segment_id]: true };
        }
        for (let j = 0; j < el.members.length; j++) {
          const innerEl = el.members[j];
          innerMembers.push(
            (!innerEl?.metadata?.primary_mobile.includes("+") ? "+" : "") +
              innerEl?.metadata?.primary_mobile
          );
        }
        members = [...members, ...innerMembers];
      }
    }
    setSegmentData(segmentNewData);
    const uniqueNumbers = members?.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    setTotalMembers(uniqueNumbers);
    setLoading(false);
  };
  useEffect(() => {
    getAllSegments();
  }, []);
  if (loading) {
    return (
      <div className="playbook-loading">
        <Loading />
      </div>
    );
  }

  return (
    <div
      className="create-raffle-wrapper"
      onClick={() => {
        setIsEditingTitle(false);
        if (showPicker) {
          setShowPicker(false);
        }
      }}
    >
      <div className="create-raffle-header">
        <Button
          leftIcon={ChevronLeft}
          label={"BACK"}
          background={"#ECECED"}
          border="none"
          action={() => history.goBack()}
        />
        {!!isStopped ? (
          ""
        ) : (
          <Button
            label={history.location.state?.metadata ? "UPDATE" : "PUBLISH"}
            border="none"
            action={
              history.location.state?.metadata ? updateRaffle : publishRaffle
            }
            disabled={
              !(Object.values(checkedSegments).includes(true) && step2 && step3)
            }
          />
        )}
      </div>
      <div className="raffle-editor">
        <div className="raffle-title">
          {isEditingTitle ? (
            <input
              value={raffleData.title}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) =>
                setRaffleData({ ...raffleData, title: e.target.value })
              }
              disabled={!!isStopped}
            />
          ) : !isStopped ? (
            raffleData.title
          ) : (
            `${raffleData.title} (read-only)`
          )}
          {!isStopped && (
            <div className="edit-icon">
              <MdOutlineModeEditOutline
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditingTitle(!isEditingTitle);
                }}
              />
            </div>
          )}
        </div>
        <div
          onClick={() => setWorkingOn("access-rules")}
          className={`section access-rules ${
            workingOn === "access-rules" ? "enabled" : ""
          }`}
        >
          <div className="top-part">
            <div className="icon">
              <RxCube />
            </div>
            <div className="title-description">
              <h3>Set Access Rules</h3>
              <h5>Select which collectible holders will get access.</h5>
            </div>
          </div>
          <div className="selector-wrapper">
            <div
              className="chip-holder"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <div className="left-part">
                {!Object.values(checkedSegments).includes(true) ? (
                  <div>Select collectible</div>
                ) : (
                  Object.keys(checkedSegments)
                    .filter((sgm) => checkedSegments[sgm])
                    .map((sgm, i) => {
                      const segmentName = segmentDataSt?.find(
                        (allSgm) => allSgm.segment_id + "" === sgm + ""
                      )?.metadata?.seriesName;
                      // const formatted =
                      //   segmentName?.length > 12
                      //     ? segmentName.substring(0, 12) + "..."
                      //     : segmentName;
                      if (i <= 1) {
                        return (
                          <b key={i} className="tag">
                            {`${segmentName || "Blank"} (Version ${
                              sgm?.split("_")[sgm?.split("_").length - 1]
                            })`}
                            <GrClose
                              onClick={(e) => {
                                // if (!isStopped) {
                                e.stopPropagation();
                                e.preventDefault();
                                const newObj = {
                                  ...checkedSegments,
                                  [sgm]: false,
                                };
                                if (!Object.values(newObj).includes(true)) {
                                  const innerNew = {};
                                  for (
                                    let i = 0;
                                    i < Object.keys(newObj).length;
                                    i++
                                  ) {
                                    const el = Object.keys(newObj)[i];
                                    innerNew[el] = true;
                                  }
                                  setCheckedSegments(innerNew);
                                  setIsTotalChecked(true);
                                } else {
                                  setCheckedSegments(newObj);
                                }
                                // }
                              }}
                            />
                          </b>
                        );
                      }
                    })
                )}
                {Object.keys(checkedSegments).filter(
                  (sgm) => checkedSegments[sgm]
                ).length > 2 && (
                  <b className="tag">
                    +{" "}
                    {Object.keys(checkedSegments).filter(
                      (sgm) => checkedSegments[sgm]
                    ).length - 2}{" "}
                    more
                  </b>
                )}
              </div>
              <FiChevronDown />
            </div>
            {showDropdown && (
              <SegmentDropdown
                setShowDropdown={setShowDropdown}
                totalMembers={totalMembers}
                setTotalMembers={setTotalMembers}
                segmentDataSt={segmentDataSt}
                setSegmentData={setSegmentData}
                isTotalChecked={isTotalChecked}
                setIsTotalChecked={setIsTotalChecked}
                checkedSegments={checkedSegments}
                setCheckedSegments={setCheckedSegments}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                loading={loading}
                setLoading={setLoading}
                isStopped={isStopped}
              />
            )}
          </div>
          {Object.values(checkedSegments).includes(true) && (
            <div className="step-complete">
              <StepComplete />
            </div>
          )}
        </div>
        <div
          onClick={() => {
            setWorkingOn(
              workingOn === "appearance-costumisation"
                ? ""
                : "appearance-costumisation"
            );
          }}
          className={`section appearance-costumisation ${
            workingOn === "appearance-costumisation" ? "enabled" : ""
          }`}
        >
          <div className="top-part">
            <div className="icon">
              <HiColorSwatch />
            </div>
            <div className="title-description">
              <h3>Appearance</h3>
              <h5>Add your own branding</h5>
            </div>
          </div>
          {workingOn === "appearance-costumisation" && (
            <div className="appearance-costumisation-info">
              <div
                className="appearance-editor"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appearance-fields">
                  {appearanceFields.map((field, i) => (
                    <div key={i} className="appearance-field">
                      <b>{field.name}</b>
                      <div className="field-type">
                        {field.type === "color" ? (
                          <div
                            className="color-picker"
                            onClick={() =>
                              !isStopped
                                ? setShowPicker(
                                    showPicker === field.id ? false : field.id
                                  )
                                : ""
                            }
                          >
                            <div
                              className="color-circle"
                              style={{ backgroundColor: raffleData[field.id] }}
                            />
                            {raffleData[field.id]}
                            {showPicker === field.id && (
                              <div
                                className="color-picker-sketch"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <SketchPicker
                                  id={field.id}
                                  color={raffleData[field.id]}
                                  onChange={({ hex }) =>
                                    setRaffleData({
                                      ...raffleData,
                                      [field.id]: hex,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <input
                            disabled={!!isStopped}
                            onFocus={() => setShowPicker(false)}
                            className="raffle-input"
                            onChange={(e) =>
                              setRaffleData({
                                ...raffleData,
                                [field.id]: e.target.value,
                              })
                            }
                            value={raffleData[field.id]}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="appearance-field">
                    <b>Button preview</b>
                    <Button
                      label={raffleData.btnTitle}
                      border="none"
                      background={raffleData.btnColor}
                      textColor={raffleData.btnTextColor}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => setWorkingOn("access-page")}
          className={`section access-page ${
            workingOn === "access-page" ? "enabled" : ""
          }`}
        >
          <div className="top-part">
            <div className="icon">
              <FaRegWindowMaximize />
            </div>
            <div className="title-description">
              <h3>Customise Access page</h3>
              <h5>
                Tell your fans what they can get if they’re able to unlock
                access.
              </h5>
            </div>
          </div>
          {workingOn === "access-page" && (
            <div
              className="access-page-info"
              onClick={(e) => e.stopPropagation()}
            >
              <h3>Add an image </h3>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                disabled={!!isStopped}
              >
                {raffleData.accessImage ? (
                  <img
                    src={raffleData.accessImage}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
                {raffleData.accessImage && !isStopped && (
                  <div
                    className={"remove-access-img"}
                    onClick={(e) => {
                      if (!isStopped) {
                        e.stopPropagation();
                        e.preventDefault();
                        setRaffleData({ ...raffleData, accessImage: null });
                      }
                    }}
                  >
                    <IoCloseOutline />
                  </div>
                )}
              </Upload>
              <input
                className="access-title"
                placeholder="Unlock Access"
                value={raffleData.accessTitle}
                disabled={!!isStopped}
                onChange={(e) => {
                  if (e.target.value.length <= 120) {
                    setRaffleData({
                      ...raffleData,
                      accessTitle: e.target.value,
                    });
                  }
                }}
              />
              <b className="max-20">Max 120 characters</b>
              <textarea
                value={raffleData.accessDescription}
                placeholder="Add all the details you want to add about the raffle here or leave it blank and let your fans figure it out. Add all the details you want to add about the raffle here or leave it blank and let your fans figure it out."
                onChange={(e) =>
                  setRaffleData({
                    ...raffleData,
                    accessDescription: e.target.value,
                  })
                }
                disabled={!!isStopped}
              />
            </div>
          )}

          {step2 && workingOn !== "access-page" ? (
            <div className="step-complete">
              <StepComplete />
            </div>
          ) : (
            <div className="step-complete">
              <Button
                label={"Preview"}
                height="44px"
                background="#E4E4E7"
                border="none"
                action={(e) => {
                  e.stopPropagation();
                  setShowAccessPreview(true);
                }}
              />
            </div>
          )}
        </div>
        <div
          onClick={() => setWorkingOn("raffle-form")}
          className={`section raffle-form ${
            workingOn === "raffle-form" ? "enabled" : ""
          }`}
        >
          <div className="top-part">
            <div className="icon">
              <MdListAlt />
            </div>
            <div className="title-description">
              <h3>Edit Raffle Form</h3>
              <h5>Create and design raffle form</h5>
            </div>
          </div>
          {workingOn === "raffle-form" && (
            <RaffleForm
              raffleData={raffleData}
              setRaffleData={setRaffleData}
              showPicker={showPicker}
              setShowPicker={setShowPicker}
              isStopped={isStopped}
            />
          )}
          {step3 && workingOn !== "raffle-form" ? (
            <div className="step-complete">
              <StepComplete />
            </div>
          ) : (
            <div className="step-complete">
              <Button
                label={"Preview"}
                height="44px"
                background="#E4E4E7"
                border="none"
                action={(e) => {
                  e.stopPropagation();
                  setShowFormPreview(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <AccessPagePreview
        isOpen={showAccessPreview}
        setIsOpen={setShowAccessPreview}
        raffleData={parsedRaffle}
      />
      <RaffleFormPreview
        isOpen={showFormPreview}
        setIsOpen={setShowFormPreview}
        raffleData={parsedRaffle}
      />
    </div>
  );
}
