import requestDM from '../requestDM'

export const getThreadMessages = async (id) => {
    var response;
    try {
        response = await requestDM({}, `/sms/threadmessages?thread_owner_iindy_id=${id.split('|')[1]}`, 'GET')
        return response
    } catch (error) {

        return []
    }
}