import axios from "axios";

export const cioStore = async (email, networkData, artist, cioToken = 'MDI4MTlhYjk3NTVjYTk0MWQyNjQ6NzQ0ZDAzZGE4OTM5OWRhNGExNGI=') => {
    const data = await axios.post(`https://track-eu.customer.io/api/v1/forms/${artist}-hotline-data/submit`, JSON.stringify({
        data: {
            email: email.toLowerCase(),
            ip: networkData.ip,
            country: networkData.country_name,
            region: networkData.region,
            city: networkData.city,
            origin: window.location.origin
        }
    }), {
        headers: {
            'Authorization': `Basic ${cioToken}`,
            'content-type': 'application/json'
        },
    });

    return data
}