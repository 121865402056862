import { DatePicker } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import Button from "../../../../components/SignupProcess/Button/Button";
import "./CustomInsightsRange.scss";
import { DateIcon, DateIconBlc } from "../../../../assets/Dashboard";

const CustomInsightsRange = ({ selectedOption, setSelectedOption }) => {
  const [rangeData, setRangeData] = useState([]);
  const [activeRange, setActiveRange] = useState("7 days");
  const [open, setOpen] = useState(false);
  const oneMonthsAgo = moment().subtract(1, "months");

  const dropdownItems = [
    // {
    //   key: "1",
    //   label: "All time",
    //   value: moment().subtract(360, "year").format("YYYY-MM-DD"),
    // },
    {
      key: "2",
      label: "30 days",
      value: moment().subtract(30, "days").format("YYYY-MM-DD"),
    },
    {
      key: "3",
      label: "7 days",
      value: moment().subtract(7, "days").format("YYYY-MM-DD"),
    },
    {
      key: "4",
      label: "24-hrs",
      value: moment().subtract(1, "days").format("YYYY-MM-DD"),
    },
  ];

  //   const rangeStyle = {
  //     background: activeRange === "Custom Range" ? "#5975FF" : "",
  //     color: activeRange === "Custom Range" ? "#FFF" : "",
  //   };

  return (
    <div className="custom-insights-range">
      {dropdownItems?.map((ins) => (
        <Button
          height="40px"
          border={activeRange === ins.label ? "#000" : ""}
          borderColor={activeRange === ins.label ? false : "#000"}
          textColor={activeRange === ins.label ? "#fff" : ""}
          background={activeRange === ins.label ? "#5975FF" : ""}
          label={ins?.label}
          noBackground={activeRange !== ins.label ? true : false}
          key={ins?.key}
          action={() => {
            setActiveRange(ins?.label);
            setSelectedOption({
              start_date: moment(ins?.value),
              end_date: moment(),
            });
            setRangeData([null, null]);
          }}
        />
      ))}
      <DatePicker.RangePicker
        open={open}
        placeholder={activeRange === "Custom Range" ? ["", ""] : ["Custom", ""]}
        className={
          activeRange === "Custom Range"
            ? "custom-rangePicker-active"
            : "custom-rangePicker"
        }
        onClick={() => {
          setActiveRange("Custom Range");
          setOpen(true);
        }}
        renderExtraFooter={() => {
          return (
            <div className="rangePicker-footerBtn">
              <div className="rangePicker-footerBtn-left">
                <span className="footer-date-display">
                  {moment(selectedOption.start_date).format("MMM DD, YYYY")}
                </span>
                -
                <span className="footer-date-display">
                  {moment(selectedOption.end_date).format("MMM DD, YYYY")}
                </span>
              </div>
              <div className="rangePicker-footerBtn-right">
                <Button
                  label={"Cancel"}
                  border="none"
                  action={() => {
                    setSelectedOption([null, null]);
                    setRangeData([null, null]);
                    setActiveRange("All time");
                    setOpen(false);
                  }}
                  background={"#E4E4E7"}
                  textColor={"#3F3F46"}
                />
                <Button
                  label={"Apply"}
                  border="none"
                  action={() => {
                    setSelectedOption({
                      start_date: rangeData[0],
                      end_date: rangeData[1],
                    });
                    setOpen(false);
                  }}
                />
              </div>
            </div>
          );
        }}
        disabledDate={(current) => {
          let customDate = moment().add(1, "day").format("YYYY-MM-DD");
          return current > moment(customDate, "YYYY-MM-DD");
        }}
        value={rangeData}
        defaultPickerValue={[oneMonthsAgo, moment()]}
        allowClear={false}
        onChange={(value) => {
          if (value !== null) {
            setRangeData(value);
          }
        }}
        suffixIcon={<DateIcon />}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </div>
  );
};

export default CustomInsightsRange;
