import requestDM from '../requestDM'
import { getUserByArtist } from './getUserByArtist';

export const segmentData = async (id) => {
    var response;
    const getOtherFans = await getUserByArtist(id);
    const membersFormat = [];
    for (let i = 0; i < getOtherFans.length; i++) {
        const el = getOtherFans[i];
        membersFormat.push({ metadata: { primary_mobile: el } })

    }
    const otherFansSegment = {
        "segment_id": `bot-fans`,
        "segment_name": "Hotline Members",
        "segment_owner_iindy_id": "auth0|64882c5115e596b13bbf2323",
        "metadata": null,
        "members": membersFormat
    }
    try {

        response = await requestDM(null, `/dmme/segments?artist_id=${id.split('|')[1]}`, 'GET')
        return [otherFansSegment, ...response,]
    } catch (error) {

        return { error }
    }
}

export const getNFTSegments = async () => {
    var response
    try {
        response = await requestDM(null, `/apiv2/customer/segments`, 'GET')
        return response[0].segments.map(sgm => ({ ...sgm, series: { ...sgm.series, seriesName: sgm.series.seriesName?.replace('The Raven', 'Raven') } }))
    } catch (error) {
        return false
    }
}