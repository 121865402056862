import { objToQuery } from '../../helpers/objToQuery';
import requestDM from '../requestDM'
import moment from 'moment'

export const getEngagement = async (first_date = moment().subtract(7, 'days'), second_date = moment()) => {
    if (first_date === null || first_date === undefined) {
        first_date = moment().subtract(7, 'days');
    }
    const obj = { artist_number: JSON.parse(localStorage.getItem('dbUser'))?.claimed_phone_number?.replace('+', ''), thread_owner_iindy_id: JSON.parse(localStorage.getItem('dbUser'))?.artist_id?.replace('auth0|', ''), first_date: moment(first_date).format('YYYY-MM-DD'), second_date: moment(second_date).format('YYYY-MM-DD') };

    var response;
    try {
        response = await requestDM({}, `/dmme/engagement${objToQuery(obj)}`, 'GET')
        return response
    } catch (error) {

    }
}
export const getFanbase = async (first_date, second_date) => {

    if (first_date === null || first_date === undefined) {
        first_date = moment().subtract(7, 'days');
    }
    const obj = { segment_owner_iindy_id: JSON.parse(localStorage.getItem('dbUser'))?.artist_id?.replace('auth0|', ''), artist_number: JSON.parse(localStorage.getItem('dbUser'))?.claimed_phone_number?.replace('+', ''), first_date: moment(first_date).format('YYYY-MM-DD'), second_date: moment(second_date).format('YYYY-MM-DD') };
    var response;
    try {
        response = await requestDM({}, `/dmme/fanbase${objToQuery(obj)}`, 'GET')
        return response
    } catch (error) {

    }
}