import React, { useContext, useEffect, useState } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import GoldenTicket from "../../components/GoldenTicket/GoldenTicket";
import { useHistory, useLocation } from "react-router";
import ticket from "../../assets/ticket.png";
import axios from "axios";
import QueryString from "qs";
import facebookIcon from "../../assets/facebook.svg";
import instaIcon from "../../assets/insta.svg";
import whatsappIcon from "../../assets/whatsapp.svg";
import emailIcon from "../../assets/email.svg";
import twitterIcon from "../../assets/twitter.svg";
import clipboardIcon from "../../assets/clipboard.svg";
import messengerIcon from "../../assets/messenger.svg";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { FadeLoader } from "react-spinners";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

import "./HotlineSuccess.scss";
import {
  getGoogleUser,
  checkUser,
  logoutUser,
  loginWithCredentials,
} from "../../magic";
import { updateUser } from "../../api/endpoints/createUser";
import { AppContext } from "../../contexts/AppContext";
import sendTwilioSMS from "../../api/endpoints/sendTwilioSMS";
import { getArtist } from "../../helpers/getArtist";
import { landing } from "../../config/Config";
import { flowStart } from "../../helpers/flowStart";

export default function HotlineSuccess({ data }) {
  const {
    hotlineSuccessImg,
    hotlineSuccessH1,
    hotlineSuccessH1Already,
    hotlineSuccessH2Already,
    hotlineSuccessH2,
    hotlineSuccessH3,
    hotlineSuccessSMS1,
    hotlineSuccessSMS2,
  } = data;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const linkToShare =
    "I've got you a guest list pass for Wayne Snow. Claim it now at waynesnow.iindy.co/guest-o3948riww";
  const messengerShare = () => {
    window.open(
      `http://www.facebook.com/dialog/send?app_id=578540233222013&link=${"waynesnow.iindy.co/guest-o3948riww"}&redirect_uri=https://waynesnow.iindy.co`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const whatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send/?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const twitterShare = () => {
    window.open(
      `https://twitter.com/messages/compose?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const emailShare = () => {
    window.open(
      `mailto:?subject=Be part of this Family!&body=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const copyLink = async () => {
    await navigator.clipboard.writeText(linkToShare);
    // notification.hotlinesuccess({ message: "Copied to clipboard hotlinesuccessfully!" });
  };
  const shareOptions = [
    // { name: "Share to Instagram", icon: instaIcon, action: instagramShare },
    { name: "Share to Messenger", icon: messengerIcon, action: messengerShare },
    { name: "Share to WhatsApp", icon: whatsappIcon, action: whatsappShare },
    { name: "Share to Twitter", icon: twitterIcon, action: twitterShare },
    { name: "Share via Email", icon: emailIcon, action: emailShare },
    { name: "Copy Link", icon: clipboardIcon, action: copyLink },
  ];

  const shareDetails = { title: "text", subtitle: "subtext" };
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {});
      } catch (error) {}
    } else {
      showModal();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  useEffect(() => {
    const phoneNumber = history.location.state
      ? history.location.state.phoneNumber && history.location.state.phoneNumber
      : null;
    if (phoneNumber) {
    }
  }, [history.location]);
  // useEffect(() => {
  //   const mainContent = document.getElementsByClassName("hotlinesuccess-wrapper")[0];
  //   if (window.innerWidth >= 600) {
  //     mainContent.style.height = window.innerHeight - 150 + "px";
  //   }
  //   window.addEventListener("resize", heightChange);
  //   return () => {
  //     window.removeEventListener("resize", heightChange);
  //   };
  // }, []);
  const heightChange = () => {
    if (window.innerWidth >= 600) {
      const mainContent = document.getElementsByClassName(
        "hotlinesuccess-wrapper"
      )[0];
      mainContent.style.height = window.innerHeight - 150 + "px";
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { authToken, setUser, user, referred_by } = useContext(AppContext);

  const artistId = getArtist();
  const artistJSON = landing[artistId];
  const twilioFlowNumber = artistJSON?.["phoneCampaign"]?.["twilioFlowNumber"];

  useEffect(() => {
    const effectFunction = async () => {
      if (location?.state?.phoneNumber) {
        if (!location?.state?.dontSendSMS && user) {
          if (twilioFlowNumber) {
            flowStart(location.state.phoneNumber);
          } else {
            await sendTwilioSMS(
              location.state.phoneNumber,
              hotlineSuccessSMS1,
              artistJSON.artist
            );
          }
          var updatedUser = await updateUser({
            user,
            data: {
              phone_verified: true,
              artists: {
                ...user.user_data.artists,
                [artistId]: {
                  ...user.user_data.artists[artistId],
                  referred_by: localStorage.getItem("referred_by") || "",
                  verified: true,
                },
              },
            },
          });
          if (twilioFlowNumber) {
          } else {
            await sendTwilioSMS(
              location.state.phoneNumber,
              `${hotlineSuccessSMS2} ${window.location.origin}/${user?.user_data?.artists?.[artistId]?.referral}?sid=hotline.`,
              artistJSON.artist
            );
          }
        }
      } else {
        if (!user?.user_data?.phoneNumber) {
          history.push("/hotline");
        }
      }
    };
    effectFunction();
  }, [location.state]);

  return (
    <>
      <div
        style={{
          background: `url(${hotlineSuccessImg}) no-repeat center`,
        }}
        className="desktop-wrapper back-position"
      ></div>
      {/* TESTING ONLY  */}
      <button style={{ display: "none" }} onClick={() => logoutUser()}>
        Logout
      </button>
      {/* TESTING ONLY  */}
      <div className="hotlinesuccess-wrapper">
        {loading && (
          <div className="overlay-loading">
            <FadeLoader color={"#fff"} />
          </div>
        )}

        {/* <GoldenTicket /> */}
        <div className="hotlinesuccess-text-part">
          <h1 className="hotlinesuccess-explain-h1">
            {location?.state?.dontSendSMS
              ? hotlineSuccessH1Already
              : hotlineSuccessH1}
          </h1>
          <h2 className="hotlinesuccess-explain-h2">
            {location?.state?.dontSendSMS
              ? hotlineSuccessH2Already
              : hotlineSuccessH2}
          </h2>
          <h2 className="hotlinesuccess-explain-h2">{hotlineSuccessH3}</h2>
        </div>
        <div
          onClick={() => {
            const userData =
              user || JSON.parse(localStorage.getItem("userData"));
            if (userData) {
              history.push(
                `/hotline?apcid=temp&phone_number=${userData?.user_data?.phone_number}&change=true`
              );
            } else {
              history.push("/");
            }
          }}
          className="copyright"
        >
          <p>Change my number</p>
        </div>
      </div>
      <Modal open={isModalVisible} onClose={handleCancel} footer={null} center>
        <div className="share-modal-title">Share to...</div>
        {shareOptions.map((el, i) => {
          return (
            <ShareComponent
              key={i}
              text={el.name}
              icon={el.icon}
              action={el.action}
            />
          );
        })}
      </Modal>
      {window.location.origin.includes("kids") && (
        <div
          className={"tab-close"}
          onClick={() => {
            window.close();
          }}
        >
          <CloseIcon />
        </div>
      )}
    </>
  );
}
const ShareComponent = ({ text, icon, action }) => {
  return (
    <div onClick={action} className="share-component">
      <img src={icon} alt="share-icon" />
      {text}
    </div>
  );
};
