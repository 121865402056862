import React, { useEffect, useMemo, useState } from "react";
import "./PhoneSimulator.scss";
import suggestion from "../../../assets/SignUpProcess/suggestion.svg";
import input from "../../../assets/SignUpProcess/input.svg";
import iChevronLeft from "../../../assets/SignUpProcess/i-chevron-left.svg";
import iVideoCall from "../../../assets/SignUpProcess/i-video-call.svg";
import StatusBar from "../../../assets/SignUpProcess/StatusBar.svg";
import PlayMsg from "../../../assets/SignUpProcess/play-msg.svg";
import VoiceRecorder from "../../../assets/SignUpProcess/voice-recorder.png";
import Handlebars from "handlebars";
import { shlink } from "../../../api/endpoints/shlink";
import { useAtom } from "jotai";
import { nftCollectionsAtom } from "../../../atoms/newSeries";

function PhoneSimulator({
  artistName,
  messagesToShow,
  activeStep,
  artistPic,
  steps,
  updating,
}) {
  const [smsLink, setSmsLink] = useState("");
  const phoneNumber =
    JSON.parse(localStorage.getItem("dbUser")).claimed_phone_number || null;
  const arrayToRender = [];
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));
  const botData = JSON.parse(localStorage.getItem("botData"));
  const [nft_collections, setNftCollections] = useState([]);
  const { unsaved_bot_config, bot_config } = dbUser;
  const localNFTCollection = localStorage.getItem("nft_collections");
  const [nftCollectionsAtomData] = useAtom(nftCollectionsAtom);

  useEffect(() => {
    setNftCollections(
      JSON.parse(localNFTCollection)?.[0]?.series?.find(
        (a) =>
          a.id ===
          (updating
            ? unsaved_bot_config?.active_nft_series_id
            : "welcome_bot_nft_series_1")
      ) || []
    );
  }, [
    unsaved_bot_config?.active_nft_series_id,
    updating,
    nftCollectionsAtomData,
  ]);

  for (let i = 0; i < messagesToShow.length; i++) {
    const el = messagesToShow[i];
    if (i <= activeStep && steps[i].enabled) {
      const welcomeMSGIndex = el.findIndex(
        (e) => e.field === "welcome_message"
      );
      const welcomeMSG = el[welcomeMSGIndex]?.message;
      if (welcomeMSG) {
        const template = Handlebars.compile(welcomeMSG);
        const data = {
          optin_phrase: steps[i].fields.find(
            (field) => field.field === "optin_phrase"
          ).value,
          artist_name: steps[i].fields.find(
            (field) => field.field === "artist_name"
          ).value,
        };

        let compiled = welcomeMSG;
        try {
          compiled = template(data);
        } catch (error) {}
        el[welcomeMSGIndex].hbr = compiled;
      }
      arrayToRender.push(...el);
    }
  }

  const artistImg = artistPic || "https://go.iindy.co/LGi2J";

  useEffect(() => {
    const shortUrlFunc = async () => {
      if (phoneNumber) {
        const { shortUrl } = await shlink(
          `sms://${phoneNumber}?&body=I got an invite to join your community`
        );
        setSmsLink(shortUrl);
      }
    };
    shortUrlFunc();
  }, []);

  return (
    <div className="phone-sim">
      <div className="imessage-top-part">
        <img src={StatusBar} className="status" />
        <div className="row">
          <img src={iChevronLeft} />
          <div className={"avatar"}>
            <img src={artistImg} />
            <span>{artistName}</span>
          </div>
          <img src={iVideoCall} />
        </div>
      </div>
      <div id="scroll-container" className="scroll-container">
        <div className="imessage">
          {arrayToRender.map((msg, i) =>
            msg.type === "contact_info" ? (
              <p key={i} className={`from-${msg.from} contact-info`}>
                {artistName}
                <img src={artistImg} />
              </p>
            ) : msg.type === "audio-upload" ? (
              <p key={i} className={`from-${msg.from} audio-upload`}>
                <div className="nft-preview">
                  {nft_collections?.series_asset_type?.includes("video") ? (
                    <video
                      controls
                      autoPlay
                      alt=""
                      src={nft_collections?.series_asset_path}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = artistImg;
                      }}
                    />
                  ) : (
                    <img
                      alt=""
                      src={nft_collections?.series_asset_path}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = artistImg;
                      }}
                    />
                  )}
                  <div className="nft-preview-text">
                    {nft_collections?.series_name}
                    <span>go.iindy.co</span>
                  </div>
                </div>
              </p>
            ) : (
              <p key={i} className={`from-${msg.from}`}>
                {msg.hbr || msg.message}
                {msg.type === "string+referral" && (
                  <span className="link">&nbsp;{smsLink}</span>
                )}
              </p>
            )
          )}
        </div>
      </div>
      <div className="input">
        <img src={input} />
      </div>
      <div className="suggestion">
        <img src={suggestion} />
      </div>
      <div className="imessage-bottom-part">
        <div className="home-indicator" />
      </div>
    </div>
  );
}

export default React.memo(PhoneSimulator);
