
// const BASE_URL = process.env.NODE_ENV === 'production'
//   ? 'https://yvpnss3uf6.execute-api.eu-west-2.amazonaws.com/prod/v1'
//   : 'http://localhost:8000/v1';

// const BASE_URL = process.env.NODE_ENV === 'production'
//   ? 'https://yvpnss3uf6.execute-api.eu-west-2.amazonaws.com/prod/v1'
//   : 'https://f979262e3899.ngrok.io/v1';

// protected backend
const BASE_URL = 'https://yvpnss3uf6.execute-api.eu-west-2.amazonaws.com/prod/v1';

// open backend
// const BASE_URL = 'https://yvpnss3uf6.execute-api.eu-west-2.amazonaws.com/prod/v1';

// const BASE_URL = 'https://37457f79f24c.ngrok.io/v1';


export async function backendApiCall(url, options) {

  try {
    const usedOptions = (options || {});

    usedOptions.headers = {
      'Content-Type': 'application/json',
      ...(usedOptions.headers || {})
    };

    if (usedOptions.authToken) {
      usedOptions.headers.Authorization = usedOptions.authToken;
    }


    const response = await fetch(url, usedOptions);


    let responseJSON = {}

    try {
      responseJSON = await response?.json();
    } catch (error) {
    }



    if (!response.ok) {
      if (responseJSON.message) {
        throw new Error(responseJSON.message);
      } else if (response.statusText) {
        throw new Error(response.statusText);
      }
    }


    return {
      success: true,
      ...responseJSON
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }

}


export function getFullURL(path) {
  return BASE_URL + path;
}


export function signInWithUsernameOnly(username) {
  const url = getFullURL('/sign-in-with-username-only');

  return backendApiCall(url, {
    method: 'POST',
    body: JSON.stringify({
      username
    })
  });


}
