import React, { useState, useEffect } from "react";
import "./ReplyListItem.scss";

import FlagComponent from "../../../../../components/Flag/Flag";
import { Badge } from "antd";
import moment from "moment-timezone";
import { chatDateParse } from "../../../../../helpers/chatDateParse";

export default function ReplyListItem({
  number,
  message,
  isActive,
  setActiveChat,
  noOfReplies,
  displayName,
  date,
}) {
  return (
    <div
      className={`replies-list-item ${
        isActive ? "replies-list-item-active" : ""
      }`}
      id={`number-list-item-${number}`}
      onClick={() => setActiveChat(number)}
    >
      <FlagComponent phoneNumber={number} />
      <div className="replies-list-item-content">
        <div className="replies-list-item-number">{displayName || number}</div>
        <div className="replies-list-item-message">{message}</div>
      </div>
      {date ? (
        <div className="replies-list-item-time">{chatDateParse(date)}</div>
      ) : (
        <Badge className={"replies-list-item-badge"}>{noOfReplies}</Badge>
      )}
    </div>
  );
}
