import requestDM from '../requestDM'

export const generateCollectibleLink = async ({ auth0id, collection_id, collectible_id, customSlug = "" }) => {
    var response;
    const data = {
        "fqcn": `${auth0id}^${collection_id}^${collectible_id}`,
        "redirect_to": process.env.REACT_APP_BASE_URL.includes('demo') ? "https://demo-collection.iindy.co/claim" : "https://collection.iindy.co/claim",//if prod it should redirect to collection.iindy.co/claim
        "customSlug": customSlug
    }
    try {
        response = await requestDM(data, `/apiv2/customer/collectible/generateClaimLinkForCollectible`, 'POST')
        return response
    } catch (error) {

        return { error }
    }
}