import React, { useEffect, useState } from "react";
import Button from "../../components/SignupProcess/Button/Button";
import Header from "../../components/SignupProcess/Header/Header";
import InputBox from "../../components/SignupProcess/InputBox/InputBox";
import Illustrations from "../../assets/profile-illustration.svg";
import styles from "./ProfileDetails.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router";
import { Auth0Context, useAuth0 } from "@auth0/auth0-react";
import animationData from "../../assets/loading-circle.json";
import Lottie from "react-lottie";
import { useContext } from "react";
import { checkIfArtistExists } from "../../api/dmmeendpoints/checkIfUserExists";
import { createArtist } from "../../api/dmmeendpoints/createArtist";

export default function ProfileDetails() {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    isLoading,
    loginWithPopup,
  } = useAuth0();
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  // TODO: LIST OF ALLOWED EMAILS
  // const login = () => {
  //   if (loginData.email.toLowerCase() === "ownership@iindyverse.com") {
  //     if (loginData.fullName && loginData.companyName && loginData.artistName) {
  //       history.push("/get-your-number");
  //       localStorage.setItem("loginUser", JSON.stringify(loginData));
  //     } else {
  //       toast("Please enter all the fields", { type: "info" });
  //     }
  //   } else {
  //     toast("Wrong Email", { type: "error" });
  //   }
  // };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  useEffect(() => {
    localStorage.setItem("loginUser", JSON.stringify(loginData));
  }, [loginData]);

  useEffect(() => {
    const effectFunc = async () => {
      if (user) {
        setLoading(true);
        const response = await checkIfArtistExists(user.email);
        if (response?.whitelisted === false) {
          history.push("/join-our-waitlist");
        } else {
          const { config_json } = response || {};
          if (config_json) {
            setLoginData({
              ...loginData,
              artistName: config_json.artist_name,
              companyName: config_json.company_name,
              fullName: config_json.full_name,
            });
            setFirstName(
              config_json?.full_name
                ? config_json?.full_name?.split(" ")?.[0]
                : ""
            );
            setLastName(
              config_json?.full_name
                ? config_json?.full_name?.split(" ")?.[1]
                : ""
            );
            localStorage.setItem("dbUser", JSON.stringify(config_json));
            if (!config_json.claimed_phone_number) {
              history.push("/get-your-number");
            } else if (!config_json.bot_config) {
              history.push("/message-flow-builder");
            } else {
              history.push("/dashboard/community");
            }
          }
        }
        setLoading(false);
      }
    };
    effectFunc();
  }, [user, isLoading]);

  const submitArtist = async () => {
    setLoading(true);
    const response = await createArtist({
      work_email: user.name,
      artist_id: user.sub,
      artist_name: loginData.artistName,
      full_name: loginData.fullName,
      company_name: loginData.companyName,
    });
    const { config_json } = await checkIfArtistExists(user.email);
    if (config_json) {
      setLoginData({
        ...loginData,
        artistName: config_json.artist_name,
        companyName: config_json.company_name,
        fullName: config_json.full_name,
      });
      if (!config_json.claimed_phone_number) {
        history.push("/get-your-number");
      } else if (!config_json.bot_config) {
        history.push("/message-flow-builder");
      } else {
        history.push("/dashboard/community");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoginData({ ...loginData, fullName: `${firstName} ${lastName}` });
  }, [firstName, lastName]);

  if (isLoading || loading) {
    return (
      <div className={`${styles.wrapper} ${styles.loadingWrapper}`}>
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Header title={""} button={'Logout'} btnAction={logout}/>
      <div className={styles.row}>
        <div className={styles.signupForm}>
          <h1>
            Add your <br />
            profile details
          </h1>
          <h6>and we'll get you up and running.</h6>
          <div className={styles.inputs}>
            <div className={styles.fullName}>
              <div className={styles.firstName}>
                <label>First name</label>
                <InputBox
                  label={"First name"}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>
              <div className={styles.lastName}>
                <label>Last name</label>
                <InputBox
                  label={"Last name"}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
            </div>

            <label>Artist name</label>
            <InputBox
              label={`Enter your artist name`}
              onChange={(e) =>
                setLoginData({ ...loginData, artistName: e.target.value })
              }
              value={loginData.artistName}
            />
            <label>Company</label>
            <InputBox
              label={"Enter your company"}
              onChange={(e) =>
                setLoginData({ ...loginData, companyName: e.target.value })
              }
              value={loginData.companyName}
            />
            <Button
              label={"CONTINUE"}
              disabled={
                !firstName ||
                !lastName ||
                !loginData.fullName ||
                !loginData.artistName
              }
              action={submitArtist}
            />
          </div>
        </div>
        <div className={styles.illustration}>
          <img alt="artwork" src={Illustrations} />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
