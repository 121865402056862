import React, { useEffect, useState } from "react";
import "../ClosedAccount/ClosedAccount.scss";
import Button from "../../components/SignupProcess/Button/Button";
import Illustrations from "../../assets/SignUpProcess/Illustrations.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { Modal } from "antd";

const ClosedAccount = () => {
  const [openDeleteAccModal, setOpenDeleteAccModal] = useState(false);
  const { user, isLoading, logout } = useAuth0();

  const history = useHistory();

  useEffect(() => {
    const checkIfExists = async () => {
      if (history?.location?.state?.status === "closed-account") {
        setOpenDeleteAccModal(true);
      }
    };
    checkIfExists();
  }, [user, isLoading]);

  const closeHandle = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
    setOpenDeleteAccModal(false);
    history.replace();
  };

  return (
    <Modal
      open={openDeleteAccModal}
      footer={false}
      closable={false}
      centered
      className="delete-account-modal"
    >
      <div className="wrapper">
        <div className="row">
          <div className="descText">
            <h1>Looks like this account is deleted.</h1>
            <h6>Try with a different email.</h6>
            <div className="inputs">
              <div className="options">
                <Button label={"Close"} action={closeHandle} />
              </div>
              <br />
            </div>
          </div>
          <div className="illustration">
            <img alt="artwork" src={Illustrations} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClosedAccount;
