import React, { useState, useEffect } from "react";
import PollProgressBar from "../../PollProgressBar/PollProgressBar";
import "./TutorialPopUp.scss";
import rightArrow from "../../../assets/SignUpProcess/arrow-right.svg";
import spikey1 from "../../../assets/SignUpProcess/spikey-1.svg";
import spikey2 from "../../../assets/SignUpProcess/spikey-2.svg";
import spikey3 from "../../../assets/SignUpProcess/spikey-3.svg";
import spikey4 from "../../../assets/SignUpProcess/spikey-4.svg";

export default function TutorialPopUp() {
  const [current, setCurrent] = useState(0);
  const [shouldShow, setShouldShow] = useState(false);
  const [data, setData] = useState([
    {
      id: 0,
      heading: "Welcome to the iiNDYVERSE",
      subHeading: `We're here to give your direct fan engagement superpowers by making it easy to reward your fans and build a loyal, high value community. `,
      logo: spikey1,
      button: "Next",
      action: "next",
    },
    {
      id: 1,
      heading: "Take more control of your fan relationships",
      subHeading:
        "Using our Hotline tool your fans can join your own private community by just sending you a DM. From there, you set the rules (instead of algorithms) and we automate the rest. ",
      logo: spikey2,
      button: "Next",
      action: "next",
    },
    {
      id: 2,
      heading: "Let’s supercharge your fan base! ",
      subHeading: `Our customer achieve up to 9X increase in engagement rates. So let’s get you set up with a dedicated Hotline number, and work on helping you achieve your goals!`,
      logo: spikey3,
      button: "finish",
      action: "finish",
    },
  ]);
  const navigate = (action) => {
    if (action === "finish") {
      markTutorialFinished();
    } else if (action === "prev" && current - 1 >= 0) {
      setCurrent(current - 1);
    } else if (action === "next" && current + 1 <= data.length - 1) {
      setCurrent(current + 1);
    } else if (action === "next" && current + 1 > data.length - 1) {
    }
  };
  const RightButton = () => {
    return (
      <button
        onClick={() => navigate(data[current].action)}
        className={"arrowBtn"}
      >
        <b>{data[current].button}</b>
        <img alt="go" src={rightArrow} />
      </button>
    );
  };
  const markTutorialFinished = () => {
    // localStorage.setItem("pop-up-tutorial-finish", "true");
    setShouldShow(false);
  };
  useEffect(() => {
    const value = localStorage.getItem("pop-up-tutorial-finish");
    if (value === "true") {
      setShouldShow(false);
    } else {
      setShouldShow(true);
    }
  }, []);

  if (!shouldShow) {
    return null;
  }
  return (
    <div className={"popUpWrapper"}>
      <div className={"popUp"}>
        <PollProgressBar
          data={data}
          current={current}
          setCurrent={setCurrent}
          navigate={navigate}
        />
        <h1>{data[current].heading}</h1>
        <img className="spikey" src={data[current].logo} />
        <h2>{data[current].subHeading}</h2>

        <RightButton />
      </div>
    </div>
  );
}
