import React, { useEffect, useState } from "react";
import Button from "../../components/SignupProcess/Button/Button";
import Header from "../../components/SignupProcess/Header/Header";
import InputBox from "../../components/SignupProcess/InputBox/InputBox";
import Illustrations from "../../assets/SignUpProcess/Illustrations.svg";
import styles from "./Welcome.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router";
import { Auth0Context, useAuth0 } from "@auth0/auth0-react";
import animationData from "../../assets/loading-circle.json";
import Lottie from "react-lottie";
import { useContext } from "react";
import ClosedAccount from "../../screens/ClosedAccount/ClosedAccount";

export default function Welcome() {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    isLoading,
    loginWithPopup,
  } = useAuth0();
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  // const login = () => {
  //   if (loginData.email.toLowerCase() === "ownership@iindyverse.com") {
  //     if (loginData.fullName && loginData.companyName && loginData.artistName) {
  //       history.push("/get-your-number");
  //       localStorage.setItem("loginUser", JSON.stringify(loginData));
  //     } else {
  //       toast("Please enter all the fields", { type: "info" });
  //     }
  //   } else {
  //     toast("Wrong Email", { type: "error" });
  //   }
  // };
  useEffect(() => {
    let idleTimeout;
    if (isLoading) {
      idleTimeout = setTimeout(() => {
        logout({ returnTo: window.location.origin });
        localStorage.clear();
      }, 5000);
    }
    return () => {
      clearTimeout(idleTimeout);
    };
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated && !history.location.state) {
      history.push("/profile-details");
    }
  }, [isAuthenticated, user, isLoading]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  if ((isLoading || isAuthenticated) && !history.location.state?.status) {
    return (
      <div className={`${styles.wrapper} ${styles.loadingWrapper}`}>
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Header title={""} />
      <div className={styles.row}>
        <div className={styles.signupForm}>
          <h1>
            Get early <br />
            access
          </h1>
          <h6>
            You've been invited to get exclusive access to iiNDYVERSE. <br />
            Please click continue to get started.
          </h6>
          <div className={styles.inputs}>
            <Button
              label={"CONTINUE"}
              action={() => loginWithRedirect({ screen_hint: "signup" })}
            />
            <b>
              By continuing, you agree to our{" "}
              <a target={"_blank"} href="https://iindy.co/terms">
                terms
              </a>{" "}
              and{" "}
              <a target={"_blank"} href="https://iindy.co/privacy">
                privacy policy
              </a>
              .
            </b>
          </div>
        </div>
        <div className={styles.illustration}>
          <img alt="artwork" src={Illustrations} />
        </div>
        <ToastContainer />
      </div>
      <ClosedAccount />
    </div>
  );
}
