import React from "react";
import "./MessageImage.scss";
import closeIcon from "../../assets/close.svg";
import { Progress } from "antd";

export default function MessageImage({ imgs, progressSt }) {
  return (
    <div className="broadcast-images">
      {imgs.map(({ img, progress }, i) => (
        <div className="broadcast-image">
          <img src={img} />
          <div className="broadcast-indicator">
            {progressSt[i] === 100 ? (
              <img src={closeIcon} />
            ) : (
              <Progress
                type="circle"
                width={20}
                percent={progressSt[i]}
                strokeWidth={15}
                trailColor={"rgba(255, 255, 255, 0.4)"}
                strokeColor={"#fff"}
                format={() => null}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
