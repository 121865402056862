import React from "react";
import "./AccessPagePreview.scss";
import { Modal } from "antd";
import UnlockAccess from "../../../../../UnlockAccess/UnlockAccess";

export default function AccessPagePreview({ isOpen, setIsOpen, raffleData }) {
  return (
    <Modal
      open={isOpen}
      title={null}
      footer={null}
      onCancel={() => setIsOpen(false)}
      className="access-page-preview"
    >
      <UnlockAccess
        preview
        raffleData={{
          ...raffleData,
          artistImg: JSON.parse(localStorage.getItem("dbUser"))?.bot_config
            ?.contact_info?.profile_picture,
        }}
      />
    </Modal>
  );
}
