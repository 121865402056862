import React, { useEffect, useState } from "react";
import "../MessageTiles.scss";
import { Avatar, Tooltip } from "antd";
import moment from "moment";
import Carousel from "nuka-carousel/lib/carousel";
import clicksIcon from "../../../../../assets/Dashboard/clicks.svg";
import clock from "../../../../../assets/Dashboard/clock.svg";
import eyeIcon from "../../../../../assets/Dashboard/eye.svg";
import repliesIcon from "../../../../../assets/Dashboard/replies.svg";
import sentIcon from "../../../../../assets/Dashboard/send.svg";
import chevron from "../../../../../assets/chevron-right.svg";
import Lightbox from "../../../../../components/Lightbox/Lightbox";
import { getPlaybookById } from "../../../../../api/dmmeendpoints/playbook";
import { BiLockAlt } from "react-icons/bi";
import MessageReplies from "../../MessageReplies/MessageReplies";

export default function Tile(props) {
  const [lightboxState, setLightboxState] = useState({
    visible: false,
    slideIndex: 0,
    images: [],
  });
  const {
    avatar,
    sentTo = "all",
    title,
    message,
    images,
    raffleIds,
    sent,
    eyes,
    clicks,
    replies,
    date,
    id,
    metadata,
  } = props;

  const [raffleData, setRaffleData] = useState([]);
  const [seeReplies, setSeeReplies] = useState(false);

  const getRaffleData = async () => {
    const data = [];
    for (let i = 0; i < raffleIds.length; i++) {
      const el = raffleIds[i];
      const response = await getPlaybookById(el);

      data.push(response?.playbook_obj?.playbook);
    }
    setRaffleData(data);
  };
  useEffect(() => {
    if (raffleIds) {
      getRaffleData();
    }
  }, [raffleIds]);
  const isScheduled = date?.format("x") > moment().format("x");

  return (
    <>
      <div
        className="tile"
        onClick={() => {
          // setVisible(true);
          // setPopUpImages(images);
          if (!isScheduled && replies > 0) {
            setSeeReplies(props);
          }
        }}
      >
        <div className="tile-header">
          <div className="avatar-and-stats">
            <Avatar src={avatar} style={{ width: 48, height: 48 }} />
            <div className="avatar-stats">
              <span>{title}</span>
              <div>
                {sentTo == "all" &&
                  `Sent on: ${
                    (isScheduled ? "Will send on " : "") +
                    date?.format("MMMM DD, [at] hh:mm a")
                  }`}
              </div>
            </div>
          </div>
          {replies ? (
            <div
              onClick={() => {
                replies > 0 && setSeeReplies(props);
              }}
              className="tile-replies"
            >
              {replies} replies
            </div>
          ) : null}
        </div>
        <div className="tile-body-row">
          <div
            className={`tile-body`}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
        {(images || raffleIds) && (
          <div className="carousel">
            <Carousel
              renderCenterLeftControls={({ previousDisabled, previousSlide }) =>
                !previousDisabled && (
                  <div
                    className="carousel-controls control-left"
                    onClick={(e) => {
                      e.stopPropagation();
                      previousSlide();
                    }}
                    disabled={previousDisabled}
                  >
                    <img src={chevron} />
                  </div>
                )
              }
              renderCenterRightControls={({ nextDisabled, nextSlide }) =>
                !nextDisabled && (
                  <div
                    className="carousel-controls"
                    onClick={(e) => {
                      e.stopPropagation();
                      nextSlide();
                    }}
                    disabled={nextDisabled}
                  >
                    <img src={chevron} />
                  </div>
                )
              }
            >
              {images &&
                images?.map((image, i) => (
                  <div className="carousel-slide">
                    <img
                      src={image}
                      onClick={(e) => {
                        e.stopPropagation();
                        setLightboxState({
                          ...lightboxState,
                          visible: true,
                          images: images,
                          slideIndex: i,
                        });
                      }}
                    />
                  </div>
                ))}
              {raffleData &&
                raffleData?.map((data, i) => (
                  <div key={data?.playbook_key} className="carousel-slide">
                    <div
                      className="raffle-preview"
                      style={{
                        background:
                          data?.metadata?.raffle_details?.raffle_page_appearance
                            ?.background_color,
                        color:
                          data?.metadata?.raffle_details?.raffle_page_appearance
                            ?.text_color,
                      }}
                    >
                      <div className="asset-container">
                        <img
                          className="raffle-img"
                          alt=""
                          src={
                            data?.metadata?.raffle_details?.access_page
                              ?.access_page_image
                          }
                        />
                        <div className="lock-icon-container">
                          <BiLockAlt />
                        </div>
                      </div>

                      <b
                        style={{
                          color:
                            data?.metadata?.raffle_details
                              ?.raffle_page_appearance?.text_color,
                        }}
                      >
                        {
                          data?.metadata?.raffle_details?.access_page
                            ?.access_page_title
                        }
                      </b>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        )}
        {date?.format("x") > moment().format("x") ? (
          <div className="tile-footer">
            <img src={clock} />
            <span>Will send on {date?.format("MMMM DD, [at] hh:mm a")}</span>
          </div>
        ) : (
          <div className="tile-footer">
            <Tooltip color="#7A91FF" title={`Sent to ${sent.length} members`}>
              <div className="tile-stat">
                <img src={sentIcon} />
                {sent.length}
              </div>
            </Tooltip>
            <Tooltip color="#7A91FF" title={`Seen by ${sent.length} members`}>
              {/* using sent until this is fixed */}

              <div className="tile-stat">
                <img src={eyeIcon} />
                {sent.length}
                {/* using sent until this is fixed */}
              </div>
            </Tooltip>
            <Tooltip
              color="#7A91FF"
              title={`Links opened by ${clicks} members`}
            >
              <div className="tile-stat">
                <img src={clicksIcon} />
                {clicks}
              </div>
            </Tooltip>
            <Tooltip
              color="#7A91FF"
              title={`This broadcast has ${replies} replies`}
            >
              <div
                className="tile-stat"
                onClick={() => replies > 0 && setSeeReplies(props)}
              >
                <img src={repliesIcon} />
                {replies}
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <Lightbox {...lightboxState} setLightboxState={setLightboxState} />
      <MessageReplies
        open={seeReplies.id === id ? seeReplies : false}
        setSeeReplies={setSeeReplies}
      />
    </>
  );
}
