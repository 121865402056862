import React from "react";
import { ReactComponent as Logomark } from "../../assets/Logomark.svg";
import "./Copyright.scss";

export default function Copyright() {
  return (
    <div className="powered-by" style={{ color: "currentcolor" }}>
      <b className="powered-by-b">Powered by</b>
      <Logomark className="powered-by-svg" />
    </div>
  );
}
