import React from "react";
import { landing } from "../../config/Config";
import { getArtist } from "../../helpers/getArtist";
import "./InstaProfile.scss";

export default function InstaProfile() {
  const artistId = getArtist();
  const artistJSON = landing[artistId];
  return (
    <div className="insta-profile-wrapper">
      <img
        src={
          "https://iindy-polls.s3.eu-west-2.amazonaws.com/Wayne-Snow-1010x1263.webp"
        }
      />
      <h5>{artistJSON.artist?.replace(" ", "")}</h5>
    </div>
  );
}
