import moment from 'moment';

export function chatDateParse(date) {
    const currentDate = moment();
    const targetDate = moment(date);
    const diffInDays = currentDate.diff(targetDate, 'days');

    if (diffInDays === 0) {
        // Today: Return the hour
        return targetDate.format('HH:mm');
    } else if (diffInDays === 1) {
        // Yesterday: Return 'Yesterday'
        return 'Yesterday';
    } else if (diffInDays <= 6) {
        // This week: Return the week day (Monday-Sunday)
        return targetDate.format('dddd');
    } else {
        // Not this week: Return the date/month/year
        return targetDate.format('DD/MM/YYYY');
    }
}