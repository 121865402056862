import React from "react";
import styles from "./Footer.module.scss";

export default function Footer({ noBackground = false }) {
  return (
    <div
      id="footer"
      className={
        styles.wrapper + " " + `${noBackground ? styles.noBackground : null}`
      }
    >
      <div className={styles.left}>
        Made with 🖤&nbsp;&nbsp;in the iiNDYVERSE
      </div>
      <div className={styles.right}>
        <a target={"_blank"} href="https://iindy.co/terms">
          Terms & conditions
        </a>
        <a target={"_blank"} href="https://iindy.co/privacy">
          Privacy policy
        </a>
      </div>
    </div>
  );
}
