import React, { useContext, useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { LuFileUp, LuSearch } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { artistUpdate } from "../../../../../api/dmmeendpoints/artistUpdate";
import { ReactComponent as Jumpman } from "../../../../../assets/Dashboard/jumpman.svg";
import ConfirmDeleteModal from "../../../../../components/ConfirmModal/ConfirmModal";
import { AppContext } from "../../../../../contexts/AppContext";
import NftSegmentDropdown from "./NftSegmentDropdown/NftSegmentDropdown";
import "./SegmentDropdown.scss";

export default function SegmentDropdown({
  setShowDropdown,
  totalMembers,
  setTotalMembers,
  segmentDataSt,
  setSegmentData,
  isTotalChecked,
  setIsTotalChecked,
  checkedSegments,
  setCheckedSegments,
  searchVal,
  setSearchVal,
  nftSegmentsData,
  segmentNewData,
}) {
  const { setShowDelete } = useContext(AppContext);
  const [selectedSgmId, setSelectedSgmId] = useState(null);
  const [selectedSgmName, setSelectedSgmName] = useState(null);

  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  const updateCheckedSegments = (id, value) => {
    const newObj = { ...checkedSegments, [id]: value };
    setCheckedSegments(newObj);
    if (Object.values(newObj).includes(false)) {
      setIsTotalChecked(false);
    } else {
      setIsTotalChecked(true);
    }
  };
  const onDeleteHandler = async () => {
    const archived_segments = [
      ...(dbUser.archived_segments || []),
      `${selectedSgmId}-${selectedSgmName}`,
    ];
    await artistUpdate({
      object: { archived_segments },
      artistId: dbUser?.artist_id,
    });
    localStorage.setItem(
      "dbUser",
      JSON.stringify({
        ...dbUser,
        archived_segments: archived_segments,
      })
    );
    setShowDelete(false);
  };

  useEffect(() => {
    const values = Object.values(checkedSegments);
    if (!values.includes(true)) {
      setTotalMembers([]);
    }
    const keys = Object.keys(checkedSegments);
    let members = [];
    for (let i = 0; i < keys.length; i++) {
      const el = keys[i];
      if (checkedSegments[el]) {
        const innerMembers = segmentNewData?.find(
          (sgm) => sgm.segment_id.toString() === el
        ).members;
        for (let j = 0; j < innerMembers.length; j++) {
          const innerEl = innerMembers[j];
          members.push(
            (!innerEl?.metadata?.primary_mobile.includes("+") ? "+" : "") +
              innerEl?.metadata?.primary_mobile
          );
        }
      }
    }
    const uniqueNumbers = members.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    setTotalMembers(uniqueNumbers);
  }, [checkedSegments]);

  return (
    <>
      <div className="segment-dropdown">
        <h3>Send to</h3>
        <p>{totalMembers.length} members</p>
        <div className="search-box">
          <div className="input-icon">
            <LuSearch />
          </div>
          <input
            placeholder="Search"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </div>
        <div className="select-all">
          <b>Select all</b>
          <input
            type="checkbox"
            checked={isTotalChecked}
            onChange={(e) => {
              setIsTotalChecked(e.target.checked);
              let newObj = {};
              for (let i = 0; i < Object.keys(checkedSegments).length; i++) {
                const el = Object.keys(checkedSegments)[i];
                newObj = { ...newObj, [el]: e.target.checked };
              }
              setCheckedSegments(newObj);
            }}
          />
        </div>
        <div className="segment-dropdown-body">
          {segmentDataSt?.map((sgm, i) => {
            const formattedSgmName = sgm?.segment_name
              .replace(/\(Version \d+\)/, "")
              .replace(/\s/g, "");
            if (
              !dbUser?.archived_segments?.includes(
                `${sgm.segment_id}-${formattedSgmName}`
              )
            ) {
              return sgm?.members?.length > 0 &&
                (searchVal ? sgm.segment_name.includes(searchVal) : true) ? (
                sgm.segment_id !== "bot-fans" &&
                typeof sgm.segment_id !== "number" ? (
                  <NftSegmentDropdown
                    key={i}
                    sgm={sgm}
                    updateCheckedSegments={updateCheckedSegments}
                    checkedSegments={checkedSegments}
                    setSelectedSgmId={setSelectedSgmId}
                    setSelectedSgmName={setSelectedSgmName}
                  />
                ) : (
                  <div
                    key={`segment-${sgm.segment_id}`}
                    className="segment-dropdown-body-element"
                    onClick={() => {
                      updateCheckedSegments(
                        sgm.segment_id,
                        !checkedSegments[sgm.segment_id]
                      );
                    }}
                  >
                    <div className="left-part">
                      <div className="icon-part">
                        {sgm.segment_id === "bot-fans" ? (
                          <Jumpman className="bot-fans" />
                        ) : (
                          <LuFileUp />
                        )}
                      </div>
                      <div className="segment-info">
                        <b>{sgm.segment_name}</b>
                        <div className="segment-meta">
                          <div className="segment-type">
                            {sgm.segment_id === "bot-fans"
                              ? "Bot Fans"
                              : "Imported"}
                          </div>
                          {/* Hardcoded for now */}
                          <div className="segment-members">
                            <BsFillPersonFill />
                            {
                              sgm.members.filter(
                                (value, index, array) =>
                                  array.findIndex(
                                    (member) =>
                                      member.metadata.primary_mobile ===
                                      value.metadata.primary_mobile
                                  ) === index
                              ).length
                            }
                            {sgm.members.length > 1 ? " members" : " member"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="archive-input-part">
                      {sgm.segment_id !== "bot-fans" && (
                        <RiDeleteBinLine
                          className="archive-badge"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const formattedSgmName = sgm?.segment_name
                              .replace(/\(Version \d+\)/, "")
                              .replace(/\s/g, "");
                            setSelectedSgmId(sgm?.segment_id);
                            setSelectedSgmName(formattedSgmName);
                            setShowDelete(true);
                          }}
                        />
                      )}
                      <input
                        type="checkbox"
                        checked={checkedSegments?.[sgm.segment_id]}
                        onChange={(e) =>
                          updateCheckedSegments(
                            sgm.segment_id,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  </div>
                )
              ) : null;
            }
          })}
        </div>
      </div>

      <div
        className="backdrop-dropdown"
        onClick={() => setShowDropdown(false)}
      />
      <ConfirmDeleteModal
        title="Delete segment?"
        content="This can't be undone but don't worry, no members in this segment will be deleted."
        onDeleteAction={onDeleteHandler}
      />
    </>
  );
}
