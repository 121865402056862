import React, { useEffect, useMemo, useState } from "react";
import "./CollectionId.scss";
import Button from "../../../../components/SignupProcess/Button/Button";
import { BiChevronLeft } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import CollectionIdCard from "../../../../components/CollectionComponents/CollectionIdCard/CollectionIdCard";
import AddCollectibleModal from "../../../../components/CollectionComponents/AddCollectibleModal/AddCollectibleModal";
import { useAtom } from "jotai";
import { nftCollectionsAtom } from "../../../../atoms/newSeries";
import { artistUpdate } from "../../../../api/dmmeendpoints/artistUpdate";
import { checkIfArtistExists } from "../../../../api/dmmeendpoints/checkIfUserExists";

export default function CollectionId() {
  const [nftCollectionsAtomData, setNftCollectionsAtomData] =
    useAtom(nftCollectionsAtom);

  const history = useHistory();
  const [showCollectibleModal, setShowCollectibleModal] = useState(false);

  const profilePic = JSON.parse(localStorage.getItem("dbUser"))?.bot_config
    ?.contact_info?.profile_picture;

  //

  const storedData = (
    nftCollectionsAtomData ||
    JSON.parse(localStorage.getItem("dbUser"))?.nft_collections
  )?.map((el) => ({
    id: el?.collection_id,
    title: el?.collection_name,
    icon: el?.icon || profilePic,
    status: el?.status || "Live",
    items: el?.series,
  }));
  //

  const data = useMemo(
    () =>
      storedData.find(
        (coll) =>
          history.location.pathname.substring(
            history.location.pathname.lastIndexOf("/") + 1
          ) === coll.id
      ),
    [storedData]
  );

  useEffect(() => {
    if (!data && history.location.pathname.includes("/collections")) {
      history.push("/dashboard/collections");
    }
  }, [data]);

  const series = data?.items;

  const nft_collections = useMemo(
    () =>
      nftCollectionsAtomData ||
      JSON.parse(localStorage.getItem("dbUser")).nft_collections,
    [localStorage.getItem("dbUser")]
  );

  const deleteCollectible = async (id) => {
    const userObject = JSON.parse(localStorage.getItem("dbUser"));
    const selectedCollectionId = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );

    const updatedNftCollections = [...nft_collections];
    const seriesPerCollectionIndex = nft_collections?.findIndex(
      (coll) => coll.collection_id === selectedCollectionId
    );
    updatedNftCollections?.[seriesPerCollectionIndex]?.series.splice(
      updatedNftCollections?.[seriesPerCollectionIndex]?.series.findIndex(
        ({ id: searchId }) => id === searchId
      ),
      1
    );
    //
    const newUser = { ...userObject, nft_collections: updatedNftCollections };
    setNftCollectionsAtomData(updatedNftCollections);
    await artistUpdate({ object: newUser, artistId: userObject.artist_id });
    await checkIfArtistExists(userObject.work_email);
  };
  if (!data) {
    return;
  }

  return (
    <div className="collection-id-wrapper">
      <Button
        border="transparent"
        background={"#ECECED"}
        label={"My Collections"}
        leftIcon={<BiChevronLeft size={24} />}
        iconOrImage="icon"
        action={() => history.goBack()}
      />
      <div className="collectionid-header">
        <img src={data.icon} alt="icon-collection" />
        <div className="collectionid-description">
          <b>{data.title}</b>
          <span>{data.items.length} items</span>
        </div>
      </div>
      <div className="card-container">
        <CollectionIdCard
          status={"create"}
          action={() => {
            setShowCollectibleModal(true);
          }}
        />
        {series?.map((serie, i) => (
          <CollectionIdCard
            key={i}
            deleteCollectible={deleteCollectible}
            {...serie}
          />
        ))}
      </div>
      <AddCollectibleModal
        isVisible={showCollectibleModal}
        toggleModal={() => setShowCollectibleModal(!showCollectibleModal)}
      />
    </div>
  );
}
