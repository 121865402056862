import requestDM from '../requestDM'

export const csvImport = async (object) => {
    var response;
    try {
        response = await requestDM(object, `/dmme/csvsegment`, 'POST')
        return response
    } catch (error) {

        return { error }
    }
}