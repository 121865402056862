/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from "react";
import styles from "./RenderFields.module.scss";
import "./RenderFields.scss";
import { TagsInput } from "react-tag-input-component";
import Button from "../Button/Button";
import uploadPic from "../../../assets/SignUpProcess/upload-pic.svg";
import uploadCloud from "../../../assets/upload.svg";
import playArrow from "../../../assets/play_arrow.svg";
import pauseArrow from "../../../assets/pause_arrow.svg";
import { getBase64 } from "../../../helpers/getBase64";
import { s3Upload } from "../../../helpers/s3Upload";
import Switch from "react-switch";
import { Line } from "rc-progress";
import { getMimeType, mimeTypes } from "../../../helpers/getMimeType";
import { sanitizeFileName } from "../../../helpers/sanitizeFilename";
import ModelViewer from "../../ModelViewer/ModelViewer";
import _ from "lodash";
import {
  newSeriesAtom,
  isNftValid as isNftValidAtom,
} from "../../../atoms/newSeries";
import { useAtom } from "jotai";
import { getNewSeriesId } from "../../../helpers/getNewSeriesId";

import { artistUpdate } from "../../../api/dmmeendpoints/artistUpdate";
import { debounce } from "lodash";
import { notification } from "antd";
import { BiStopCircle } from "react-icons/bi";
import { AiOutlineStop } from "react-icons/ai";
import { customNotification } from "../../../helpers/customNotification";

function RenderFields({
  setSteps,
  steps,
  activeStep,
  messagesToShow,
  setMessagesToShow,
  updating = false,
  isLoading = false,
  stopWhenLoading = false,
  shouldShowRestore,
}) {
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));
  const botData = JSON.parse(localStorage.getItem("botData"));
  const { unsaved_bot_config, bot_config } = dbUser;
  const nft_collections = useMemo(
    () =>
      JSON.parse(localStorage.getItem("nft_collections"))?.[0]?.series?.find(
        (a) =>
          a.id ===
          (updating
            ? unsaved_bot_config?.active_nft_series_id
            : "welcome_bot_nft_series_1")
      ) || [],

    [
      botData,
      unsaved_bot_config?.active_nft_series_id,
      updating,
      localStorage.getItem("nft_collections"),
    ]
  );

  const [newSeries, setNewSeries] = useAtom(newSeriesAtom);

  const [, setIsNftValid] = useAtom(isNftValidAtom);

  const [uploaded, setUploaded] = useState(null);
  const [uploadError, setUploadError] = useState({});
  const [fileData, setFileData] = useState(null);
  const [fileState, setFileState] = useState(
    unsaved_bot_config?.collectible_asset
  );
  const [fileType, setFileType] = useState(
    getMimeType(unsaved_bot_config?.collectible_asset_s3_path) || ""
  );
  useEffect(() => {
    setFileType(
      getMimeType(unsaved_bot_config?.collectible_asset_s3_path) || ""
    );
  }, [unsaved_bot_config]);

  const [isUploading, setIsUploading] = React.useState(
    nft_collections?.series_asset_type ? true : false
  );
  const [progress, setProgress] = useState(
    nft_collections?.series_asset_type ? [100] : [0]
  );
  const [progressProfile, setProgressProfile] = useState([100]);

  const [isNFT, setIsNFT] = useState(
    nft_collections?.series_asset_type ? true : false
  );
  useEffect(() => {
    if (nft_collections?.series_asset_type) {
      setProgress([100]);
    }
  }, [nft_collections?.series_asset_type]);

  const fields = useMemo(
    () => steps?.[activeStep]?.fields,
    [steps?.[activeStep]?.fields, activeStep]
  );
  const updateValues = (index, value, field, activeStepProp = activeStep) => {
    const oldState = [...steps];
    const oldMessages = [...messagesToShow];
    const activeOldMessages = [...oldMessages[activeStep]];
    if (oldMessages && activeOldMessages) {
      const fieldIndex = activeOldMessages.findIndex((f, i) => {
        if (f.field != undefined) {
          return f.field == field.field;
        } else {
          return -1;
        }
      });

      if (fieldIndex > -1) {
        activeOldMessages[fieldIndex] = {
          ...activeOldMessages[fieldIndex],
          message: value,
        };
        oldMessages[activeStepProp] = activeOldMessages;
        setMessagesToShow(oldMessages);
      }
    }
    oldState[activeStepProp].fields[index].value = value;
    setSteps(oldState);
  };
  const updateInnerValues = (index, innerIndex, value) => {
    const oldState = [...steps];
    oldState[activeStep].fields[index].fields[innerIndex].value = value;
    setSteps(oldState);
  };

  const DragDropFile = ({
    type = "image",
    field,
    update,
    steps,
    activeStepProp,
  }) => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);

    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      uploadFunction(e.dataTransfer.files);
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
      e.preventDefault();
      uploadFunction(e.target.files);
    };
    const uploadType = steps[activeStepProp].fields[field].type;

    const getImgRes = (file, realFile) => {
      const img = new Image();
      img.src = file;
      if (realFile.type !== "image/jpeg" && realFile.type !== "image/png") {
        setUploadError({ ...uploadError, [uploadType]: "imagetype" });
      } else {
        img.onload = async function () {
          setProgressProfile([0]);

          // removing restriction
          // if (this.width > 400 || this.height > 400) {
          // setUploadError({ ...uploadError, [uploadType]: "resolution" });
          // } else {

          setUploadError({});
          setUploaded(file);
          s3Upload(
            realFile,
            progressProfile,
            setProgressProfile,
            0,
            shortUrls,
            setShortUrls
          );
        };
      }
    };
    let shortUrls = [];
    const setShortUrls = (data) => {
      update(data[0]);
    };

    const uploadFunction = async (files) => {
      if (uploadType === "contact-info") {
        const base64 = await getBase64(files[0]);
        type === "image" && getImgRes(base64, files[0]);
        setFileData(files[0]);
      } else {
        audioUploader(files[0]);
      }
      // update(base64);
    };
    const audioUploader = (file) => {
      if (file.type === "audio/mpeg") {
        setFileData(file);
        setUploaded(file);
        s3Upload(file, null, () => {}, 1, shortUrls, setShortUrls);
      }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef.current.click();
    };
    useEffect(() => {
      setUploaded(
        steps[activeStepProp].fields[field].type == "contact-info" ||
          steps[activeStepProp].fields[field].type == "audio-upload"
          ? steps[activeStepProp].fields[field].value
          : null
      );
      setIsUploading(steps[activeStepProp].fields[field].value ? true : false);
    }, [steps]);
    return (
      <form
        key={"nft-upload"}
        id="form-file-upload-nft"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={
            dragActive
              ? "drag-active"
              : `${uploadError[uploadType] && "upload-error"}`
          }
        >
          <div className="inner-nft">
            {(isUploading || updating) && uploaded ? (
              <>
                <div className="picture-part">
                  <img src={uploaded} />
                </div>
                <div className="loader-part">
                  {progressProfile[0] === 100 && uploadPic ? (
                    <Button
                      label={"CHANGE FILE"}
                      background={"#E4E4E7"}
                      border="none"
                      action={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        inputRef.current.click();
                      }}
                    />
                  ) : (
                    <>
                      <h3>Uploading...</h3>
                      <div className="progress-and-number">
                        <div className="progress">
                          <Line
                            percent={progressProfile[0]}
                            strokeWidth={3}
                            trailWidth={3}
                            trailColor="#E4E4E7"
                            strokeColor="#18181B"
                          />
                        </div>
                        {progressProfile[0]}%
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="left-part">
                <div className="icon-bck">
                  <img
                    className={
                      "icon-img" +
                      ` ${uploaded && !uploadError[uploadType] ? "radius" : ""}`
                    }
                    src={uploadCloud}
                  />
                </div>
                <h3>Upload Image</h3>
                <span className="upl-type-label">
                  Click to upload or drag and drop a JPG, PNG. Max size is 5mb,
                  Recommended size: 350 x 350
                </span>
              </div>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
        {/* {uploaded && <img className={"uploaded-img"} src={uploaded} />} */}
        {stopWhenLoading && isLoading && (
          <div
            className="absolute-container"
            onClick={() =>
              customNotification(
                "Wait for changes to be synced before trying to upload",
                <AiOutlineStop />,
                "",
                "wrong-media"
              )
            }
          />
        )}
      </form>
    );
  };
  const DragDropFileNFTToMemoise = ({ field, update, steps, activeStep }) => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [audioPlaying, setAudioPlaying] = useState(false);

    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      uploadFunction(e.dataTransfer.files);
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
      e.preventDefault();
      uploadFunction(e.target.files);
    };
    const uploadType = steps[activeStep].fields[field].type;
    let shortUrls = [];
    const setShortUrls = (data) => {
      const longUrl = localStorage.getItem("shortUrl");
      update(data[0]);
      const assetType = getMimeType(longUrl) || "image";
      setNewSeries({
        ...newSeries,
        series_asset_path: data[0],
        series_asset_s3_path: `files/${sanitizeFileName(
          longUrl?.substring(longUrl.indexOf("files/") + 6)
        )}`,
        series_thumbnail_s3_path: steps?.[1]?.fields?.[1]?.value,
        series_thumbnail_asset_path: steps?.[1]?.fields?.[1]?.value,
        series_asset_type: assetType,
      });
    };

    const uploadFunction = async (files) => {
      uploader(files[0]);
    };
    const uploader = async (file) => {
      if (file.size > 25 * 1e6) {
        customNotification(
          `File size too big. Max size is 25MB`,
          <AiOutlineStop />,
          "",
          "wrong-media"
        );
      } else if (!Object.values(mimeTypes).includes(file.type)) {
        customNotification(
          `Sorry, that  media  file type is not supported`,
          <AiOutlineStop />,
          "",
          "wrong-media"
        );
      } else {
        setProgress([0]);
        setIsUploading(true);
        // setNewSeries({
        //   ...newSeries,
        //   id: getNewSeriesId(dbUser?.unsaved_bot_config?.active_nft_series_id),
        //   series_thumbnail_s3_path: steps?.[1]?.fields?.[1]?.value,
        //   series_thumbnail_asset_path: steps?.[1]?.fields?.[1]?.value,
        // });
        setUploaded(file);
        s3Upload(file, progress, setProgress, 0, shortUrls, setShortUrls);
      }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef.current.click();
    };
    useEffect(() => {
      setUploaded(
        steps[activeStep].fields[field].type == "contact-info" ||
          steps[activeStep].fields[field].type == "audio-upload"
          ? steps[activeStep].fields[field].value
          : null
      );
      setIsUploading(steps[activeStep].fields[field].value ? true : false);
    }, [steps]);
    useEffect(() => {
      //
      if (
        updating &&
        !newSeries.series_asset_path &&
        nft_collections.series_asset_path
      ) {
        setNewSeries(nft_collections);
      }
    }, [nft_collections, updating]);

    return (
      <>
        <form
          key={"nft-upload"}
          id="form-file-upload-nft"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={false}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={
              dragActive
                ? "drag-active"
                : `${uploadError[uploadType] && "upload-error"}`
            }
          >
            <div className="inner-nft">
              {isUploading || newSeries?.series_asset_path ? (
                <>
                  <div className="picture-part">
                    {newSeries?.series_asset_type?.includes("image") ? (
                      <img src={newSeries?.series_asset_path} />
                    ) : newSeries?.series_asset_type?.includes("video") ? (
                      <video
                        src={newSeries?.series_asset_path}
                        controls
                        autoPlay
                        muted
                        loop
                      />
                    ) : newSeries?.series_asset_type?.includes("audio") ? (
                      <>
                        <img
                          src={
                            steps?.[1]?.fields?.[1]?.value ||
                            "https://go.iindy.co/LGi2J"
                          }
                        />
                        <audio
                          id="audio-player"
                          src={newSeries?.series_asset_path}
                        />
                        {progress[0] === 100 && (
                          <div
                            className="audio-play-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAudioPlaying(!audioPlaying);
                              const audioPl =
                                document.getElementById("audio-player");
                              audioPl.paused ? audioPl.play() : audioPl.pause();
                            }}
                          >
                            <img src={audioPlaying ? pauseArrow : playArrow} />
                          </div>
                        )}
                      </>
                    ) : newSeries?.series_asset_type?.includes("model") ||
                      fileType?.includes("model") ? (
                      <ModelViewer src={newSeries?.series_asset_path} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="loader-part">
                    {progress[0] === 100 && newSeries?.series_asset_path ? (
                      <Button
                        label={"CHANGE FILE"}
                        background={"#E4E4E7"}
                        border="none"
                        action={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          inputRef.current.click();
                        }}
                      />
                    ) : (
                      <>
                        <h3>Uploading...</h3>
                        <div className="progress-and-number">
                          <div className="progress">
                            <Line
                              percent={progress[0]}
                              strokeWidth={3}
                              trailWidth={3}
                              trailColor="#E4E4E7"
                              strokeColor="#18181B"
                            />
                          </div>
                          {progress[0]}%
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="left-part">
                  <div className="icon-bck">
                    <img
                      className={
                        "icon-img" +
                        ` ${
                          uploaded && !uploadError[uploadType] ? "radius" : ""
                        }`
                      }
                      src={uploadCloud}
                    />
                  </div>
                  <h3>Upload a media file</h3>
                  <span className="upl-type-label">
                    Click to upload or drag and drop a JPG, PNG, GIF, SVG, MOV,
                    or MP4 file. Max size is 25MB
                  </span>
                </div>
              )}
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
          {/* {uploaded && <img className={"uploaded-img"} src={uploaded} />} */}
          {stopWhenLoading && isLoading && (
            <div
              className="absolute-container"
              onClick={() =>
                customNotification(
                  `Wait for changes to be synced before trying to upload`,
                  <AiOutlineStop />,
                  "",
                  "wrong-media"
                )
              }
            />
          )}
        </form>
        <div
          className={
            "switchComponent" +
            (newSeries.isNft || nft_collections.isNft ? " enabled-switch" : "")
          }
        >
          <Switch
            onChange={(value) => {
              setNewSeries({
                ...newSeries,
                id: getNewSeriesId(dbUser?.bot_config?.active_nft_series_id),
                isNft: value,
              });
            }}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={"#F3EC00"}
            onHandleColor={"#000"}
            checked={newSeries.isNft || nft_collections.isNft}
            className={`react-switch ${
              newSeries.isNft || nft_collections.isNft ? "checked" : ""
            }`}
          />
          <div>
            <b>Share as a digital collectible</b>
            <br />
            <b>
              Owners of collectibles can unlock exclusive access to merch,
              events & more.
            </b>
          </div>
        </div>
      </>
    );
  };
  const DragDropFileNFT = React.memo(DragDropFileNFTToMemoise);

  useEffect(() => {
    if (nft_collections.isNft) {
      if (
        nft_collections.series_name &&
        nft_collections.series_description &&
        nft_collections.isNft
      ) {
        setIsNftValid(true);
      } else {
        setIsNftValid(false);
      }
    } else {
      setIsNftValid(true);
    }
  }, [nft_collections]);

  if (fields) {
    const nft_collections = JSON.parse(
      localStorage.getItem("nft_collections")
    )?.[0]?.series;
    const nft_collectionsLength = nft_collections?.length;

    const isCollectibleUpdated = !_.isEqual(
      { ...nft_collections?.[nft_collectionsLength - 2], id: null },
      { ...nft_collections?.[nft_collectionsLength - 1], id: null }
    );

    const calculatedVersion =
      parseInt(newSeries.id?.split("_")[newSeries.id?.split("_")?.length - 1]) -
      (isCollectibleUpdated ? 0 : 1);
    return (
      <div
        className={
          styles.fields +
          ` ${steps[activeStep].enabled ? "" : "disabled-fields"}`
        }
      >
        {activeStep === 0 && (
          <div key={"profile-pic"} className={styles.field}>
            <label style={{ marginTop: "0px" }}>
              Add you Logo or profile pic
            </label>
            <DragDropFile
              value={steps[1].value}
              update={(e) => {
                updateValues(1, e, fields[1], 1);
              }}
              steps={steps}
              activeStepProp={1}
              field={1}
            />
          </div>
        )}
        {fields.map((field, i) => {
          if (field.type === "textbox") {
            return (
              <div key={field.field + "" + i} className={styles.field}>
                <label>{field.title} </label>
                <textarea
                  id={field.field}
                  key={field + " text-area " + i}
                  className={styles.textarea}
                  onChange={(e) => {
                    updateValues(i, e.target.value, field);
                  }}
                  disabled={!steps[activeStep].enabled}
                  value={field.value}
                  rows={3}
                />
                {field.field === "referral-link-message" && (
                  <b className={styles.moreInfo}>
                    Referral links increase signs up by 20% on average
                  </b>
                )}
              </div>
            );
          } else if (field.type === "text") {
            return (
              <div key={field.field + "" + i} className={styles.field}>
                <label>{field.title}</label>
                <input
                  type={"text"}
                  value={field.value || ""}
                  disabled={!steps[activeStep].enabled}
                  onChange={(e) => updateValues(i, e.target.value, field)}
                />
              </div>
            );
          } else if (field.type === "tags") {
            return (
              <div
                key={field.field + "" + i}
                className={styles.field + " tags"}
              >
                <label>{field.title}</label>

                <TagsInput
                  value={field?.value}
                  onChange={(e) => updateValues(i, e, field)}
                  name="tags"
                  placeHolder="enter tag"
                  disabled={!steps[activeStep].enabled}
                />
              </div>
            );
          } else if (field.type === "contact-info") {
            return (
              <div key={field.field + "" + i} className={styles.field}>
                <h5>{field.title}</h5>
                {field.fields.map((insideField, j) => (
                  <div key={insideField + "" + j} className={styles.innerField}>
                    <label>{insideField.title}</label>
                    <input
                      type={"text"}
                      disabled={!steps[activeStep].enabled}
                      value={insideField.value || ""}
                      onChange={(e) =>
                        updateInnerValues(i, j, e.target.value, field)
                      }
                    />
                  </div>
                ))}
              </div>
            );
          } else if (field.type === "audio-upload") {
            return (
              <div
                key={field.field + "" + i}
                className={styles.field + ` nft-upload`}
              >
                <label>{field.title}</label>
                <DragDropFileNFT
                  value={steps[activeStep].value}
                  update={(e) => {
                    updateValues(i, e, field);
                  }}
                  steps={steps}
                  activeStep={activeStep}
                  field={i}
                  disabled={!steps[activeStep].enabled}
                />
                <br />
              </div>
            );
          }
        })}
        {(newSeries.isNft || nft_collections?.isNft) && activeStep === 2 && (
          <div className="nft-upload">
            <div key={"-nft-name"} className={styles.field}>
              <label className="nft-version-label">{`${
                newSeries.series_name ? newSeries.series_name : ""
              } (version ${
                calculatedVersion === 0 ? 1 : calculatedVersion
              })`}</label>
              <label>Collectible name*</label>
              <input
                type={"text"}
                value={newSeries?.series_name || ""}
                disabled={!steps[activeStep].enabled}
                onChange={(e) => {
                  setNewSeries({ ...newSeries, series_name: e.target.value });
                }}
              />
            </div>
            <br />
            <div key={"-nft-descr"} className={styles.field + " nft-descr"}>
              <label>Description*</label>
              <textarea
                id={"nft-descr"}
                key={"nft-desct" + " text-area-nft-description "}
                className={styles.textarea}
                onChange={(e) => {
                  setNewSeries({
                    ...newSeries,
                    series_description: e.target.value,
                  });
                }}
                disabled={!steps[activeStep].enabled}
                value={newSeries?.series_description || ""}
                rows={3}
              />
            </div>
            <span className="nft-descr-span">
              The description will be included on the Collectible’s page
              underneath its image.
            </span>
          </div>
        )}
      </div>
    );
  }
  return null;
}
export default React.memo(RenderFields);
