export const getUnlockLink = () => {
    const url = window.location.origin;
    let unlckDomain;

    if (url.includes('console.iindy')) {
        unlckDomain = 'https://unlock.iindy.co/';
    } else if (url.includes('demo.iindy')) {
        unlckDomain = 'https://demo.unlock.iindy.co/';
    } else {
        unlckDomain = url + '/';
    }

    return unlckDomain
}