import { getCookies } from '../../helpers/getCookies'
import request from '../requestDM'

export const getPlaybooks = async () => {
    var response
    try {
        response = await request(null, `/playbook/get`, 'get')
        return response
    } catch (error) {

        return false
    }
}
export const getPlaybookById = async (id) => {
    var response
    try {
        response = await request(null, `/playbook/get/fan/${id}`, 'get')
        return response
    } catch (error) {

        return false
    }
}
export const addPlaybooks = async (data) => {
    var response
    try {
        response = await request(data, `/playbook/create`, 'post')
        return response
    } catch (error) {

        return false
    }
}
export const updatePlaybooks = async (data, id) => {
    var response
    try {
        response = await request(data, `/playbook/edit/${id}`, 'put')
        return response
    } catch (error) {

        return false
    }
}
export const stopPlaybooks = async (id) => {
    var response
    try {
        response = await request(null, `/playbook/stop/${id}`, 'get')
        return response
    } catch (error) {

        return false
    }
}
export const deletePlaybooks = async (id) => {
    var response
    try {
        response = await request(null, `/playbook/${id}`, 'delete')
        return response
    } catch (error) {

        return false
    }
}
export const sendPlaybookResults = async (id) => {
    var response
    try {
        response = await request(null, `/playbook/send_raffle_results/${id}`, 'get')
        return response
    } catch (error) {
        return 'error'
    }
}

export const submitEntry = async (data) => {
    const cookies = getCookies()
    const customToken = cookies?.iindySessionToken
    var response
    try {
        response = await request(data, `/playbook/submit_entry`, 'post', customToken)
        return response
    } catch (error) {
        if (error.response.data.err_msg === 'Raffle submission already exists for this fan') {
            return { error: 'You have already submitted an entry for this raffle' }
        }
        return false
    }
}