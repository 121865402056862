import React, { useState, useEffect } from "react";
import whatsappIcon from "../../assets/whatsapp.png";
import userIcon from "../../assets/user.png";
import spotifyIcon from "../../assets/spotify.png";
import appleMusicIcon from "../../assets/appleMusic.png";
import googleIcon from "../../assets/googleIcon.svg";
import mailIcon from "../../assets/mailIcon.svg";
import "./DynamicButton.scss";

export default function DynamicButton({
  type = "primary",
  text,
  isError,
  retry,
  action,
  icon,
  disabled,
  small,
  backgroundColor = null,
  color = "black",
  fontFamily = null,
}) {
  const [iconFile, setIconFile] = useState(null);
  useEffect(() => {
    let selectedIcon = "";
    switch (icon) {
      case "whatsapp":
        selectedIcon = whatsappIcon;
        break;
      case "user":
        selectedIcon = userIcon;
        break;
      case "spotifyIcon":
        selectedIcon = spotifyIcon;
        break;
      case "appleMusic":
        selectedIcon = appleMusicIcon;
        break;
      case "google":
        selectedIcon = googleIcon;
        break;
      case "mail":
        selectedIcon = mailIcon;
        break;
      default:
        break;
    }
    setIconFile(selectedIcon);
  }, [icon]);
  return (
    <>
      <div
        onClick={() => {
          if (!disabled) {
            if (isError) {
              action("sms");
            } else {
              action("whatsapp");
            }
          }
        }}
        style={{ backgroundColor: backgroundColor, color: color }}
        className={`dynamic-button ${type && type} ${
          disabled ? "disabled" : ""
        } ${small ? "small" : ""}`}
      >
        {icon && !isError ? (
          <div
            className="icon-part"
            style={{
              marginLeft:
                (icon == "spotifyIcon" && "-40.5px") ||
                (icon == "mail" && "-40.5px"),
            }}
          >
            <img alt={`${icon}-icon`} src={iconFile} />
          </div>
        ) : (
          <div className="icon-part"></div>
        )}
        <div
          style={{
            marginLeft:
              (icon == "spotifyIcon" && "35.5px") ||
              (icon == "mail" && "35.5px"),
            fontFamily: fontFamily,
          }}
          className={`text-part ${!icon ? "no-icon" : ""}`}
        >
          {isError ? "Text me" : text}
        </div>
        <div />
      </div>
      {!disabled && icon == "whatsapp" && (
        <div
          onClick={() => {
            if (isError) {
              retry();
            } else {
              action("sms");
            }
          }}
          className="text-me"
        >
          {isError ? "Retry WhatsApp" : "Text me instead"}
        </div>
      )}
    </>
  );
}
