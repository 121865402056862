import React, { useState, useEffect, useContext, useCallback } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { useHistory, useLocation } from "react-router";
import "./WhatsYourEmail.scss";
import axios from "axios";
import QueryString from "qs";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import { AppContext } from "../../contexts/AppContext";
import { sendSMS } from "../../api/twilio";
import { createPhoneCheck, getPhoneCheckResult } from "../../api/truPhoneCheck";
import { getGoogleUser, googleLogin } from "../../magic";
import DynamicInput from "../../components/DynamicInput/DynamicInput";

const accountSid = "AC6b23b91b120dfaa151c54561c1a19969";
const authTokenWP = "e7e93d58c511265afe4fcf2cda0d473b";

export default function WhatsYourEmail({ data }) {
  const { backgroundImg, mainText, subText } = data;
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isWhatsapp, setWhatsApp] = useState(false);
  const [isNotWhatsApp, setNotWhatsApp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [checkState, setCheckState] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [stateCheckId, setStateCheckId] = useState("");
  const [checkURL, setCheckURL] = useState("");
  const [error, seterror] = useState(false);
  const [verifier, setVerifier] = useState("");
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [email, setEmail] = useState(null);
  const { authToken } = useContext(AppContext);

  const history = useHistory();
  const location = useLocation();
  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  useEffect(() => {
    document.title = data.artist;
  }, [document]);

  useEffect(() => {
    const googleProfile = localStorage.getItem("googleProfile");
    if (googleProfile && location.search.includes("?verifier")) {
      history.push("/ticket");
    }
  }, []);

  return (
    <>
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0.0%, #000000 100%), url(${backgroundImg}) no-repeat`,
        }}
        className="desktop-wrapper"
      ></div>
      <div className="landing-background">
        {loading && (
          <div className="overlay-loading">
            <FadeLoader color={"#fff"} />
          </div>
        )}
        <div
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 35.08%, #000000 100%), url(${backgroundImg}) no-repeat`,
          }}
          className="landing-wrapper"
        >
          <div className="text-wrapper">
            <h1>{data.whatsYourEmailCampaignTexts.mainText}</h1>
            <h2>{data.whatsYourEmailCampaignTexts.subText}</h2>
          </div>
          <div className="buttons-wrapper">
            <DynamicInput
              icon={"mail"}
              placeholder={"your@email.com"}
              actionOnRegex={(bool = true, email = null) => {
                setEmail(email);
                setContinueEnabled(bool);
              }}
            />
            <DynamicButton
              icon={""}
              text="Continue"
              disabled={!continueEnabled}
              action={() => {
                history.push("/email-sent?email=" + email);
              }}
            />
          </div>
        </div>
        <div className="copyright">
          <p>
            By continuing, you agree to our{" "}
            <a href="#" target="_blank">
              Terms
            </a>{" "}
            and{" "}
            <a href="#" target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
