import React, { useEffect, useState } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import GoldenTicket from "../../components/GoldenTicket/GoldenTicket";
import { useHistory, useLocation } from "react-router";
import ticket from "../../assets/ticket.png";
import axios from "axios";
import QueryString from "qs";
import facebookIcon from "../../assets/facebook.svg";
import instaIcon from "../../assets/insta.svg";
import whatsappIcon from "../../assets/whatsapp.svg";
import emailIcon from "../../assets/email.svg";
import twitterIcon from "../../assets/twitter.svg";
import clipboardIcon from "../../assets/clipboard.svg";
import messengerIcon from "../../assets/messenger.svg";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { FadeLoader } from "react-spinners";

import "./Success.scss";
import {
  getGoogleUser,
  checkUser,
  logoutUser,
  loginWithCredentials,
} from "../../magic";
import { customNotification } from "../../helpers/customNotification";

export default function Success() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const linkToShare =
    "I've got you a guest list pass for Wayne Snow. Claim it now at waynesnow.iindy.co/guest-o3948riww";
  const messengerShare = () => {
    window.open(
      `http://www.facebook.com/dialog/send?app_id=578540233222013&link=${"waynesnow.iindy.co/guest-o3948riww"}&redirect_uri=https://waynesnow.iindy.co`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const whatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send/?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const twitterShare = () => {
    window.open(
      `https://twitter.com/messages/compose?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const emailShare = () => {
    window.open(
      `mailto:?subject=Be part of this Family!&body=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const copyLink = async () => {
    await navigator.clipboard.writeText(linkToShare);
    // customNotification("Copied to clipboard successfully!");
  };
  const shareOptions = [
    // { name: "Share to Instagram", icon: instaIcon, action: instagramShare },
    { name: "Share to Messenger", icon: messengerIcon, action: messengerShare },
    { name: "Share to WhatsApp", icon: whatsappIcon, action: whatsappShare },
    { name: "Share to Twitter", icon: twitterIcon, action: twitterShare },
    { name: "Share via Email", icon: emailIcon, action: emailShare },
    { name: "Copy Link", icon: clipboardIcon, action: copyLink },
  ];

  const shareDetails = { title: "text", subtitle: "subtext" };
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {});
      } catch (error) {}
    } else {
      showModal();
    }
  };
  const accountSid = "AC6b23b91b120dfaa151c54561c1a19969";
  const authTokenWP = "e7e93d58c511265afe4fcf2cda0d473b";
  const history = useHistory();

  useEffect(() => {
    const phoneNumber = history.location.state
      ? history.location.state.phoneNumber && history.location.state.phoneNumber
      : null;
    if (phoneNumber) {
    }
  }, [history.location]);
  // useEffect(() => {

  const heightChange = () => {
    if (window.innerWidth >= 600) {
      const mainContent = document.getElementsByClassName("success-wrapper")[0];
      mainContent.style.height = window.innerHeight - 150 + "px";
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    checkForUser();
  }, [location]);

  const checkForUser = async () => {
    const profile = await getGoogleUser();
    if (profile) {
      setLoading(false);
      localStorage.setItem("googleProfile", JSON.stringify(profile));
    } else {
      // await loginWithCredentials();
      checkUser(isThereAnyUser);
    }
  };
  const isThereAnyUser = (data) => {
    setLoading(false);
    if (data.isLoggedIn) {
      localStorage.setItem("emailProfile", JSON.stringify(data));
    } else {
      history.push("/join");
    }
  };
  return (
    <>
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0.0%, #000000 100%), url(${ticket}) no-repeat`,
        }}
        className="desktop-wrapper"
      ></div>
      {/* TESTING ONLY  */}
      <button style={{ display: "none" }} onClick={() => logoutUser()}>
        Logout
      </button>
      {/* TESTING ONLY  */}
      <div
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 35.08%, rgba(0, 0, 0, 0)  100%), url(${ticket})`,
        }}
        className="success-wrapper"
      >
        {loading && (
          <div className="overlay-loading">
            <FadeLoader color={"#fff"} />
          </div>
        )}
        <h1 className="success-heading">You’re all set! </h1>
        {/* <GoldenTicket /> */}
        <div className="success-text-part">
          <h1 className="success-explain-h1">
            You’ve got a guestlist ticket to give to a friend!
          </h1>
          <h2 className="success-explain-h2">
            This guestlist ticket lets you invite one friend to get special
            access to Wayne Snow’s listening party. Once you send it to someone,
            it’s gone!
          </h2>
        </div>
        <DynamicButton
          icon={"user"}
          text="Send to a friend"
          action={handleSharing}
        />
      </div>
      <Modal open={isModalVisible} onClose={handleCancel} footer={null} center>
        <div className="share-modal-title">Share to...</div>
        {shareOptions.map((el, i) => {
          return (
            <ShareComponent
              key={i}
              text={el.name}
              icon={el.icon}
              action={el.action}
            />
          );
        })}
      </Modal>
    </>
  );
}
const ShareComponent = ({ text, icon, action }) => {
  return (
    <div onClick={action} className="share-component">
      <img src={icon} alt="share-icon" />
      {text}
    </div>
  );
};
