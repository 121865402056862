import React from "react";
import styles from "./InputBox.module.scss";

export default function InputBox({
  label,
  value,
  onChange,
  leftIcon = false,
  rightButton = false,
}) {
  const rightcomponent = { rightButton: rightButton };
  return (
    <div className={styles.wrapper + ` ${leftIcon ? styles.withLeftIcon : ""}`}>
      {leftIcon && (
        <div className={styles.leftIcon}>
          <img alt="left-icon" src={leftIcon} />
        </div>
      )}
      <input placeholder={label} value={value} onChange={onChange} />
      {rightButton && <rightcomponent.rightButton />}
    </div>
  );
}
