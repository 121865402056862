import requestDM from '../requestDM'

export const getAvailableNumbers = async (country, contains) => {
    var response;
    try {
        response = await requestDM({}, `/dmme/getavailablenumbers?countryCode=${country}&contains=${contains.replaceAll(' ', '')}`, 'GET')
        return response
    } catch (error) {

        return []
    }
}