import requestDM from '../requestDM'

export const checkIfArtistExists = async (email) => {
    var response;
    try {
        response = await requestDM({}, `/dmme/checkifartistexists?work_email=${email}`, 'GET')
        const data = response?.config_json ? response : response.length >= 0 ? response[0]?.config_json : {}
        const parsedData = { ...data, unsaved_bot_config: { ...data?.unsaved_bot_config, welcome_message: data?.unsaved_bot_config?.welcome_message?.replace(data?.unsaved_bot_config?.artist_name, '{{artist_name}}')?.replace(data?.unsaved_bot_config?.optin_phrase, '{{optin_phrase}}') } }
        localStorage.setItem('dbUser', JSON.stringify(parsedData))
        return response?.config_json ? response : response.length >= 0 ? response[0] : response
    } catch (error) {

        return {}
    }
}