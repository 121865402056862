import React, { useEffect, useState } from "react";
import { copyToClipboard } from "../../../../helpers/copyToClipboard";
import { Modal, Tooltip, notification } from "antd";
import sushiman from "../../../../assets/sushi-man.svg";
import {
  MdArrowRight,
  MdContentCopy,
  MdOutlineContentCopy,
} from "react-icons/md";
import "./ShareCollectible.scss";
import { FiArrowRight, FiDownload, FiLink2 } from "react-icons/fi";
import { shlink as apiSHLINK } from "../../../../api/endpoints/shlink";
import QRCode from "qrcode";
import { artistUpdate } from "../../../../api/dmmeendpoints/artistUpdate";
import { customNotification } from "../../../../helpers/customNotification";

export default function ShareCollectible({
  isOpen,
  setIsOpen,
  number,
  collectibleLink,
}) {
  const [prefill, setPrefill] = useState("Hi, I found your hotline.");
  const [shareState, setShareState] = useState("link");
  const [shlink, setShlink] = useState("");
  const [generatedQR, setGeneratedQR] = useState("");
  const [buttonState, setButtonState] = useState("Copy");
  useEffect(() => {
    if (collectibleLink) {
      setShlink(collectibleLink);
      generateQR(collectibleLink);
    }
  }, [collectibleLink]);

  const generateQR = async (text) => {
    try {
      const qr = await QRCode.toDataURL(text, {
        quality: 1,
        width: 400,
        margin: 1,
      });
      setGeneratedQR(qr);
      return qr;
    } catch (err) {
      console.error(err);
      return "";
    }
  };
  const generateShLink = async () => {};

  const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopyTClipboard = () => {
    setButtonState("Copied");
    copyToClipboard(shlink);
    setTimeout(() => {
      setButtonState("Copy");
    }, 2000);
    customNotification("Copied to clipboard!", <MdOutlineContentCopy />);
  };

  return (
    <Modal
      open={isOpen}
      className="share-hotline"
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(0,0,0,0.3)",
        backdropFilter: "blur(10px)",
      }}
      onCancel={() => {
        window.history.replaceState({}, document.title);
        setIsOpen(false);
      }}
    >
      <img src={sushiman} />
      <h3>
        {shareState === "compose" ? "Create a shareable link" : "Here you go!"}
      </h3>
      <b>
        {shareState === "compose"
          ? "Add a pre-filled message if you like:"
          : ""}
      </b>
      {shareState !== "compose" && (
        <div className="share-tabs">
          <div
            className={`share-tab ${
              shareState === "link" ? "active-share-tab" : ""
            }`}
            onClick={() => setShareState("link")}
          >
            Link
          </div>
          <div
            className={`share-tab ${
              shareState === "qrcode" ? "active-share-tab" : ""
            }`}
            onClick={() => setShareState("qrcode")}
          >
            QR code
          </div>
        </div>
      )}
      {shareState === "qrcode" ? (
        <div className="qr-part">
          <img src={generatedQR} />
          <button
            className="qr-download"
            onClick={() => downloadURI(generatedQR, "hotline-qr")}
          >
            <FiDownload />
          </button>
        </div>
      ) : (
        <div className="link-and-copy">
          <input
            value={shareState === "compose" ? prefill : shlink}
            onChange={(e) => setPrefill(e.target.value)}
            disabled={shareState !== "compose"}
          />
          {shareState === "compose" ? (
            <button className="next-btn" onClick={generateShLink}>
              <FiArrowRight />
            </button>
          ) : (
            <Tooltip title={buttonState}>
              <button className="copy-btn" onClick={handleCopyTClipboard}>
                <MdContentCopy />
              </button>
            </Tooltip>
          )}
        </div>
      )}
    </Modal>
  );
}
