import React, { useContext } from "react";
import Button from "../SignupProcess/Button/Button";
import "./ConfirmModal.scss";
import { AppContext } from "../../contexts/AppContext";
import { RiDeleteBinLine } from "react-icons/ri";
import { Modal } from "antd";

const ConfirmModal = ({
  title = "Are you sure you want to delete?",
  content = "This can’t be undone.",
  onDeleteAction,
  mainButtonText = "DELETE",
  cancelButtonText = "CANCEL",
}) => {
  const { showDelete, setShowDelete } = useContext(AppContext);

  const handleDelete = () => {
    if (onDeleteAction) {
      onDeleteAction();
    }
    // setShowDelete(false);
  };

  return (
    <Modal
      open={showDelete}
      className="playbook-success playbook-stop-modal "
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(0,0,0,0.3)",
        backdropFilter: "blur(10px)",
      }}
      onCancel={() => setShowDelete(false)}
    >
      <div className="icon-holder">{<RiDeleteBinLine />}</div>
      <h3>{title}</h3>
      <b>{content}</b>
      <div className="modal-btns">
        <Button
          label={cancelButtonText}
          height="44px"
          border="none"
          background="#0000000F"
          textColor="#3F3F46"
          action={() => setShowDelete(false)}
        />
        <Button
          label={mainButtonText}
          height="44px"
          border="none"
          background="#B42318"
          textColor="#fff"
          action={handleDelete}
        />
      </div>
    </Modal>
  );
};

export default ConfirmModal;
