import React, { useState, useRef } from "react";
import FontPicker from "font-picker-react";
import "./TextElement.scss";

export default function TextElement({
  label,
  className,
  value,
  jsKey,
  placeholder,
  setActiveFonts,
  activeFonts,
  changeText,
}) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ffffff");

  const colorPickerRef = useRef();
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  return (
    <div className="text-el-builder">
      <label>{label}</label>
      <br />
      <div className="row">
        <input
          className="text-inp"
          placeholder={placeholder}
          value={value}
          onChange={(e) => changeText(jsKey, e.target.value)}
        />
        <FontPicker
          pickerId={jsKey}
          apiKey="AIzaSyAHUo0V48llyoQXVnvbiVPQzge8rno3q1M"
          activeFontFamily={activeFonts[jsKey]}
          limit={200}
          onChange={(nextFont) => {
            document.getElementsByClassName(className)[0].style.fontFamily =
              nextFont.family;
            setActiveFonts({ ...activeFonts, [jsKey]: nextFont.family });
          }}
        />
        <div
          className="color-abs"
          onClick={() => {
            colorPickerRef.current.click();
          }}
        >
          <input
            ref={colorPickerRef}
            className="color-picker"
            type={"color"}
            value={selectedColor}
            onChange={(e) => {
              document.getElementsByClassName(className)[0].style.color =
                e.target.value;
              setSelectedColor(e.target.value);
            }}
          />
          <div className="img-abs">
            <img
              className="color-img"
              src="https://static.thenounproject.com/png/24950-200.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
