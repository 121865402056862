import axios from "axios";
export const baseURL = `${process.env.REACT_APP_BASE_URL}`;
// PROD https://yp8vs4qfw8.execute-api.eu-west-2.amazonaws.com/prod
// DEV https://otvj8nlu5k.execute-api.eu-west-2.amazonaws.com/dev

const customAxios = axios.create({
    timeout: 130000,
    headers: { "Content-Type": "application/json" },
    validateStatus: (status) => {
        return (status >= 200 && status < 300) || status == 422;
    }
});

customAxios.interceptors.request.use(
    function (config) {
        const token = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email`))?.body?.access_token


        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
export default function HandleRequest(data, resourceName, action, customToken = null) {
    customAxios.defaults.baseURL = baseURL;

    var config = requestBuilder(data, resourceName, action);
    if (customToken) {
        customAxios.interceptors.request.use(
            function (config) {

                if (customToken) {
                    config.headers.Authorization = `Bearer ${customToken}`
                }
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }
    const request = customAxios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
    return request;
}
function requestBuilder(payload, resourceName, action) {
    var method = action;

    var request = {
        method: action,
        url: resourceName,
        data: payload
    };
    if (method === "get" || method === 'delete') {
        delete request.data;
    }

    return request;
}


