import { useContext } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { AppContext } from "../../../../../../contexts/AppContext";

const NftSegmentDropdown = ({
  sgm,
  updateCheckedSegments,
  checkedSegments,
  setSelectedSgmId,
  setSelectedSgmName,
}) => {
  const { setShowDelete } = useContext(AppContext);

  return (
    <>
      {Object.keys(sgm?.members).length > 0 && sgm.metadata !== null ? (
        <div
          key={`segment-${sgm.segment_id}`}
          className="segment-dropdown-body-element"
          onClick={() => {
            updateCheckedSegments(
              sgm.segment_id,
              !checkedSegments[sgm?.segment_id]
            );
          }}
        >
          <div className="left-part">
            <div className="icon-part">
              <img src={sgm.metadata.seriesAssetPath} />
            </div>
            <div className="segment-info">
              <b>{`${sgm?.segment_name}`} </b>
              <div className="segment-meta">
                <div className="segment-type">
                  {sgm?.segment_id === "bot-fans"
                    ? "Bot Fans"
                    : JSON.parse(localStorage.getItem("dbUser"))
                        .artist_name}{" "}
                  Welcome
                </div>
                {/* Hardcoded for now */}
                <div className="segment-members">
                  <BsFillPersonFill />
                  {sgm?.members.length}
                  {sgm?.members.length > 1 ? " members" : " member"}
                </div>
              </div>
            </div>
          </div>
          <div className="archive-input-part">
            <RiDeleteBinLine
              className="archive-badge"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const formattedSgmName = sgm?.segment_name
                  .replace(/\(Version \d+\)/, "")
                  .replace(/\s/g, "");
                setSelectedSgmId(sgm?.segment_id);
                setSelectedSgmName(formattedSgmName);
                setShowDelete(true);
              }}
            />
            <input
              type="checkbox"
              checked={checkedSegments?.[sgm?.segment_id]}
              onChange={(e) =>
                updateCheckedSegments(sgm?.segment_id, e.target.checked)
              }
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NftSegmentDropdown;
