import { getCookies } from '../../helpers/getCookies'
import request from '../requestDM'


export const verifyOwnership = async (tokens, id) => {
    const cookies = getCookies()
    const customToken = cookies?.iindySessionToken

    const data = {
        "gating_nft_fqcns": tokens,
        "issuer_iindy_id": id,
        "op": "or"
    }
    var response
    try {
        response = await request(data, `/nft/are_tokens_from_collection_available_in_fans_wallet`, 'post', customToken)
        return response
    } catch (error) {

        return { error }
    }
}

export const verifyMembership = async ({ number, artist_iindy_id, redirect }) => {
    const data = {
        "fan_mobile_number": number,
        "artist_iindy_id": artist_iindy_id,
        "target_redirect_url": redirect,
        "metadata": {
            "purpose": "raffle_submission_authentication"
        }

    }
    var response
    try {
        response = await request(data, `/v/sms/send_login_link`, 'post')
        return response
    } catch (error) {

        return { error }
    }
}