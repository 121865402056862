import React from "react";
import { artistUpdate } from "../../../../api/dmmeendpoints/artistUpdate";
import { ReactComponent as CloseIcon } from "../../../../assets/close.svg";
import { ReactComponent as RestoreIcon } from "../../../../assets/Dashboard/restore.svg";
import Button from "../../../../components/SignupProcess/Button/Button";

import "./RestoreModal.scss";

export default function RestoreModal({
  showRestoreModal,
  setShowRestoreModal,
}) {
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));
  const updateUnsavedBot = async () => {
    delete dbUser?.bot_config?.contact_card_link;
    await artistUpdate({
      object: { unsaved_bot_config: { ...dbUser.bot_config } },
      artistId: dbUser.artist_id,
    });
    setShowRestoreModal(false);
    localStorage.setItem(
      "dbUser",
      JSON.stringify({
        ...dbUser,
        unsaved_bot_config: { ...dbUser.bot_config },
      })
    );
    window.location.reload();
  };
  if (showRestoreModal) {
    return (
      <div className="restore-backdrop">
        <div className="restore-modal">
          <div
            className="close-icon"
            onClick={() => setShowRestoreModal(false)}
          >
            <CloseIcon />
          </div>
          <div className="restore-icon">
            <RestoreIcon />
          </div>
          <h4>Are you sure?</h4>
          <h6>
            This will revert your changes to the last published Activation flow.
          </h6>
          <div className="restore-modal-action">
            <Button
              label={"CANCEL"}
              background={"#0000000F"}
              textColor={"#3F3F46"}
              borderColor={"transparent"}
              action={() => {
                setShowRestoreModal(false);
              }}
            />
            <Button
              label={"RESTORE"}
              background={"#B42318"}
              textColor={"#fff"}
              borderColor={"transparent"}
              action={updateUnsavedBot}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
}
