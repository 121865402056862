import { getArtist } from '../../helpers/getArtist'
import request from '../request'
const artistId = getArtist()

export default async function checkReferralString(referralString) {
    var response
    try {
        response = await request(null, `/user/checkreferralstring/${artistId}?referral=${referralString}`, 'index')
        return response
    } catch (error) {

        return false
    }

}

// "people": [
//     {
//       "fields": {
//           "str::email": "harmon15y_g113s@tigpe.com",//emaili i userit qe po regjistrohet ose emaili i userit qe do te mari emal suksesi
//           "str::first": "Lipsum",//emri -||-
//           "str::last": "Dolor",//mbiemri -||-
//           "bol::p": true, //gjithmone true qe te ket email permission
//           "str:cm:activity":"welcome", //kte do e dergosh vetem ne rastin kur regjistrohet useri me pas ske pse e dergon me
//           "tme:cm:referalsuccessdate":"2022-03-09T09:30:00.698Z" //kjo eshte date time qe do ti ruhet userit qe ka ber sukses refferal
//       }//kjo date time duhet kthyer ne UTC qe te mos jet ora para mbrapa se ashtu e do autopilot qe te bej llogarite e veta
//     }//mund te dergosh me shume se nje people object qe psh dhe te ruash userin e sapo regjistruar deh te behet update i referal success dates i userit tjeter
//   ]