import React, { useEffect, useMemo, useState } from "react";
import "./AddCollectibleModal.scss";
import { Modal, Switch, Upload, message, notification } from "antd";
import Button from "../../SignupProcess/Button/Button";
import { s3Upload } from "../../../helpers/s3Upload";
import { getMimeType, mimeTypes } from "../../../helpers/getMimeType";
import { sanitizeFileName } from "../../../helpers/sanitizeFilename";
import uploadPic from "../../../assets/SignUpProcess/upload-pic.svg";
import uploadCloud from "../../../assets/upload.svg";
import playArrow from "../../../assets/play_arrow.svg";
import pauseArrow from "../../../assets/pause_arrow.svg";
import ModelViewer from "../../ModelViewer/ModelViewer";
import { Line } from "rc-progress";
import { artistUpdate } from "../../../api/dmmeendpoints/artistUpdate";
import { nftCollectionsAtom } from "../../../atoms/newSeries";
import { useAtom } from "jotai";
import { checkIfArtistExists } from "../../../api/dmmeendpoints/checkIfUserExists";
import { customNotification } from "../../../helpers/customNotification";
import SwitchComponent from "../../Switch/SwitchComponent";
import { object } from "prop-types";

export default function AddCollectibleModal({
  isVisible,
  toggleModal,
  collection_id = null,
}) {
  const [nftCollectionsAtomData, setNftCollectionsAtomData] =
    useAtom(nftCollectionsAtom);

  const [data, setData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [progress, setProgress] = useState([]);

  const closeModal = () => {
    setData({});
    setProgress([]);
    setIsUploading(false);
    setUploaded(false);
    toggleModal();
  };

  const DragDropFileNFT = ({ field, update, steps, activeStep }) => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [audioPlaying, setAudioPlaying] = useState(false);

    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      uploadFunction(e.dataTransfer.files);
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
      e.preventDefault();
      uploadFunction(e.target.files);
    };
    const uploadType = "image";
    let shortUrls = [];
    const setShortUrls = (urls) => {
      const longUrl = localStorage.getItem("shortUrl");

      update(urls[0]);
      const assetType = getMimeType(longUrl) || "image";
      setData({
        ...data,
        series_asset_path: urls[0],
        series_asset_s3_path: `files/${sanitizeFileName(
          longUrl?.substring(longUrl.indexOf("files/") + 6)
        )}`,
        series_asset_type: assetType,
      });
    };

    const uploadFunction = async (files) => {
      uploader(files[0]);
    };
    const uploader = async (file) => {
      if (file.size > 25 * 1e6) {
        customNotification("`File size too big`", null, "Max size is 25mb", "");
      } else if (!Object.values(mimeTypes).includes(file.type)) {
        customNotification(
          "Sorry, that media type is not yet supported.",
          null,
          `Please upload JPG, PNG, GIF, SVG, GLTF, GLB, MOV, or MP4 file. Max size is 25mb`
        );
      } else {
        setProgress([0]);
        setIsUploading(true);
        setUploaded(file);
        s3Upload(file, progress, setProgress, 0, shortUrls, setShortUrls);
      }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef.current.click();
    };
    const uploadThumbnail = (e) => {
      const file = e.target.files[0];
      s3Upload(file, null, null, 0, [], (urls) => {
        setData({ ...data, series_thumbnail_asset_path: urls[0] });
      });
    };

    const profilePic = JSON.parse(localStorage.getItem("dbUser"))?.bot_config
      ?.contact_info?.profile_picture;
    //

    return (
      <>
        <form
          key={"nft-upload"}
          id="form-file-upload-nft"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={false}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={
              dragActive
                ? "drag-active"
                : `${uploadError[uploadType] && "upload-error"}`
            }
          >
            <div className="inner-nft">
              {isUploading || updating || data?.series_asset_path ? (
                <>
                  <div className="picture-part">
                    {data?.series_asset_type?.includes("image") ? (
                      <img src={data?.series_asset_path} />
                    ) : data?.series_asset_type?.includes("video") ? (
                      <video
                        src={data?.series_asset_path}
                        controls
                        autoPlay
                        muted
                        loop
                      />
                    ) : data.series_asset_type?.includes("audio") ? (
                      <>
                        <img
                          src={
                            data?.series_thumbnail_asset_path ||
                            profilePic ||
                            "https://go.iindy.co/LGi2J"
                          }
                        />
                        <audio
                          id="audio-player"
                          src={data?.series_asset_path}
                        />
                        {progress[0] === 100 && (
                          <div
                            className="audio-play-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAudioPlaying(!audioPlaying);
                              const audioPl =
                                document.getElementById("audio-player");
                              audioPl.paused ? audioPl.play() : audioPl.pause();
                            }}
                          >
                            <img src={audioPlaying ? pauseArrow : playArrow} />
                          </div>
                        )}
                      </>
                    ) : data?.series_asset_type?.includes("model") ||
                      data.series_asset_type?.includes("model") ? (
                      <ModelViewer src={data?.series_asset_path} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="loader-part">
                    {progress[0] === 100 && data?.series_asset_path ? (
                      <>
                        <Button
                          label={"CHANGE FILE"}
                          background={"#E4E4E7"}
                          border="none"
                          action={() => inputRef.current.click()}
                        />
                        {data?.series_asset_type.includes("audio") && (
                          <div
                            onMouseEnter={() => {
                              const el =
                                document.getElementById("label-file-upload");
                              el.classList.add("no-hover");
                            }}
                            onMouseLeave={() => {
                              const el =
                                document.getElementById("label-file-upload");
                              el.classList.remove("no-hover");
                            }}
                          >
                            <Button
                              label={"CHANGE CARD"}
                              background={"#E4E4E7"}
                              border="none"
                              action={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                document
                                  .getElementById("thumbnail-input")
                                  .click();
                              }}
                            />
                            <input
                              id="thumbnail-input"
                              type="file"
                              onChange={uploadThumbnail}
                            />
                            <b>
                              You can change the image on the card displayed for
                              your Collectible. (PNG, JPG, or GIF)
                            </b>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <h3>Uploading...</h3>
                        <div className="progress-and-number">
                          <div className="progress">
                            <Line
                              percent={progress[0]}
                              strokeWidth={3}
                              trailWidth={3}
                              trailColor="#E4E4E7"
                              strokeColor="#18181B"
                            />
                          </div>
                          {progress[0]}%
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="left-part">
                  <div className="icon-bck">
                    <img
                      className={
                        "icon-img" +
                        ` ${
                          uploaded && !uploadError[uploadType] ? "radius" : ""
                        }`
                      }
                      src={uploadCloud}
                    />
                  </div>
                  <h3>Upload an image, video or audio file</h3>
                  <span className="upl-type-label">
                    Click to upload or drag and drop a JPG, PNG, GIF, SVG, MOV,
                    MP4 or MP3 file. Max size is 25mb
                  </span>
                </div>
              )}
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
          {/* {uploaded && <img className={"uploaded-img"} src={uploaded} />} */}
        </form>
      </>
    );
  };
  const nft_collections = useMemo(
    () =>
      nftCollectionsAtomData ||
      JSON.parse(localStorage.getItem("dbUser")).nft_collections,
    [localStorage.getItem("dbUser")]
  );
  const submit = async () => {
    const userObject = JSON.parse(localStorage.getItem("dbUser"));
    const selectedCollectionId =
      collection_id ||
      window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/") + 1
      );

    const updatedNftCollections = [...nft_collections];
    const seriesPerCollectionIndex = nft_collections?.findIndex(
      (coll) => coll.collection_id === selectedCollectionId
    );
    updatedNftCollections?.[seriesPerCollectionIndex]?.series.push({
      ...data,
      claim_cap_per_fan: 1,
      id: `${selectedCollectionId}_${
        updatedNftCollections?.[seriesPerCollectionIndex].series.length + 1
      }`,
    });
    const newUser = { ...userObject, nft_collections: updatedNftCollections };
    setNftCollectionsAtomData(updatedNftCollections);
    await artistUpdate({ object: newUser, artistId: userObject.artist_id });
    await checkIfArtistExists(userObject.work_email);
    closeModal();
  };

  const handleChangeCap = (e) => {
    let { value, min, max } = e.target;
    if (data.edition_count !== -1) {
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      setData({ ...data, claim_cap_per_fan: value });
    } else {
      setData({ ...data, claim_cap_per_fan: value });
    }
  };

  //
  return (
    <Modal
      onCancel={closeModal}
      open={isVisible}
      footer={null}
      title={"Add a Collectible"}
      className="collectible-modal"
      destroyOnClose
    >
      <div className="overflow-part">
        <label>Upload a media file</label>
        <DragDropFileNFT
          value={data.asset}
          update={(e) => {
            // updateValues(i, e, field);
          }}
          steps={[]}
          activeStep={0}
          field={0}
        />
        <label>Name</label>
        <input
          placeholder="Example: Sole Hunter"
          value={data.name}
          onChange={(e) => setData({ ...data, series_name: e.target.value })}
        />
        <label>Description (optional)</label>
        <textarea
          placeholder="Enter a description..."
          value={data.description}
          onChange={(e) =>
            setData({ ...data, series_description: e.target.value })
          }
        />
        <div
          className={`supply-box ${
            data.edition_count === -1 ? " not-allowed-supply" : ""
          }`}
        >
          <div className="supply-label">
            <label>Supply</label>
            <SwitchComponent
              switchOnChange={(value) =>
                setData({
                  ...data,
                  edition_count: !value ? "" : -1,
                })
              }
              opactiyChange={data.edition_count === -1 ? "" : "-opacity"}
              labelName={"Unlimited"}
              setData={setData}
              data={data.edition_count}
            />
          </div>
          <input
            placeholder={
              data.edition_count === -1 ? "Unlimited Supply" : "Example: 1"
            }
            value={
              data.edition_count === -1
                ? "Unlimited Supply"
                : data.edition_count
            }
            disabled={data.edition_count === -1}
            min={1}
            type="number"
            onChange={(e) => {
              return setData({
                ...data,
                edition_count:
                  data.edition_count === -1 ? "" : parseInt(e.target.value),
                claim_cap_per_fan:
                  parseInt(e.target.value) < data.claim_cap_per_fan
                    ? 1
                    : data.claim_cap_per_fan,
              });
            }}
          />
        </div>
        {/* TODO: add this back at some point */}
        {/* <div
          className={`cap-box ${
            data.no_cap === -1 ? " not-allowed-supply" : ""
          }`}
        >
          <div className="supply-label">
            <label>Cap for fan</label>
            <SwitchComponent
              labelName={"No cap"}
              data={data.claim_cap_per_fan}
              setData={setData}
              opactiyChange={data.no_cap === -1 ? "" : "-opacity"}
              switchOnChange={(value) => {
                return setData({
                  ...data,
                  claim_cap_per_fan: value ? parseInt(data.edition_count) : 1,
                  no_cap: value ? -1 : 1,
                });
              }}
            />
          </div>
          <input
            placeholder="Unlimited"
            type="number"
            max={data.edition_count}
            disabled={data.no_cap === -1}
            value={
              data.claim_cap_per_fan === -1
                ? ""
                : data.claim_cap_per_fan === undefined
                ? ""
                : parseInt(data.claim_cap_per_fan)
            }
            onChange={handleChangeCap}
          />
        </div> */}
      </div>
      <div className="buttons">
        <Button
          label={"CANCEL"}
          border="none"
          background="#ECECED"
          height="44px"
          action={closeModal}
        />
        <Button
          label={"CREATE"}
          border="none"
          height="44px"
          action={submit}
          disabled={
            !(data.series_name && data.edition_count && data.series_asset_path)
          }
        />
      </div>
    </Modal>
  );
}
