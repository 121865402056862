// const mimeTypes = {
//     '.txt': 'text/plain',
//     '.html': 'text/html',
//     '.css': 'text/css',
//     '.js': 'text/javascript',
//     '.json': 'application/json',
//     '.png': 'image/png',
//     '.jpg': 'image/jpeg',
//     '.jpeg': 'image/jpeg',
//     '.gif': 'image/gif',
//     '.pdf': 'application/pdf',
//     '.mp3': 'audio/mpeg',
//     '.wav': 'audio/wav',
//     '.ogg': 'audio/ogg',
//     '.mp4': 'video/mp4',
//     '.avi': 'video/x-msvideo',
//     '.mov': 'video/quicktime',
//     '.obj': 'model/obj',
//     '.fbx': 'model/fbx',
//     '.gltf': 'model/gltf+json',
//     '.glb': 'model/gltf-binary',
//     '.zip': 'model/maybe-zip'
//     // Add more mappings as needed
// };

export const mimeTypes = {
    '.png': 'image/png',
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.gif': 'image/gif',
    '.mp3': 'audio/mpeg',
    '.wav': 'audio/wav',
    '.ogg': 'audio/ogg',
    '.mp4': 'video/mp4',
    '.avi': 'video/x-msvideo',
    '.mov': 'video/quicktime',
    '.glb': 'model/gltf-binary',
    // Add more mappings as needed
};
export const getMimeType = (url) => {
    if (!url) {
        return 'image/jpeg'
    }
    const fileExtension = url.substring(url.lastIndexOf('.'))
    // Define a mapping of file extensions to MIME types


    // Retrieve the MIME type based on the file extension
    const mimeType = mimeTypes[fileExtension.toLowerCase()] || 'application/octet-stream';

    return mimeType;
}






