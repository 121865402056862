import React from "react";
import "./CustomQuestion.scss";
import {
  AiOutlineCheckSquare,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from "react-icons/ai";
import Switch from "react-switch";

import { GrClose } from "react-icons/gr";
import { Tooltip } from "antd";

export default function CustomQuestion({
  isStopped,
  raffleData,
  setRaffleData,
}) {
  const updateOption = (i, val) => {
    let raffleCustomOptions = [...raffleData.raffleCustomOptions];
    raffleCustomOptions[i]
      ? (raffleCustomOptions[i].option = val)
      : raffleCustomOptions.push({ option: "" });
    setRaffleData({ ...raffleData, raffleCustomOptions: raffleCustomOptions });
  };
  const removeOption = (i) => {
    let raffleCustomOptions = [...raffleData.raffleCustomOptions];
    raffleCustomOptions.splice(i, 1);
    setRaffleData({ ...raffleData, raffleCustomOptions: raffleCustomOptions });
  };
  return (
    <div
      className={`custom-question ${
        raffleData.enableCustomQuestion ? "" : "disabled-section"
      }`}
    >
      <div className="title-section">
        <AiOutlineCheckSquare />
        <input
          className="title-input"
          value={raffleData.raffleCustomTitle}
          placeholder="Your question"
          disabled={!!isStopped}
          onChange={(e) =>
            setRaffleData({ ...raffleData, raffleCustomTitle: e.target.value })
          }
        />
        <div className="switch-container">
          <div
            className={
              "switchComponent" +
              (raffleData.isCustomQuestionRequired ? " enabled-switch" : "")
            }
          >
            <Switch
              disabled={!!isStopped}
              onChange={(value) => {
                setRaffleData({
                  ...raffleData,
                  isCustomQuestionRequired: value,
                });
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={"#F3EC00"}
              onHandleColor={"#000"}
              checked={raffleData.isCustomQuestionRequired}
              className={`react-switch`}
            />
            <div>
              <b>Required</b>
            </div>
          </div>
        </div>
        <div className="vertical-separator" />
        <Tooltip
          title={`Question ${
            raffleData.enableCustomQuestion ? "visible" : "hidden"
          }`}
        >
          <div
            className="visibility"
            onClick={() =>
              !isStopped
                ? setRaffleData({
                    ...raffleData,
                    enableCustomQuestion: !raffleData.enableCustomQuestion,
                    ...(!!raffleData.enableCustomQuestion && {
                      isCustomQuestionRequired: false,
                    }),
                  })
                : ""
            }
          >
            {raffleData.enableCustomQuestion ? (
              <AiOutlineEye />
            ) : (
              <AiOutlineEyeInvisible />
            )}
          </div>
        </Tooltip>
      </div>
      <div className="alternatives">
        {raffleData.raffleCustomOptions.map(({ option }, i) => (
          <div className="alternative" key={`alternative_${i}`}>
            <div className="radio-btn" />
            <input
              disabled={!!isStopped}
              placeholder={`Option ${i + 1}`}
              className="alternative-input"
              onChange={(e) => updateOption(i, e.target.value)}
              value={option}
            />
            <button onClick={() => (!isStopped ? removeOption(i) : "")}>
              <GrClose />
            </button>
          </div>
        ))}
        <div className="alternative to-add">
          <div className="radio-btn" />
          <button
            onClick={() =>
              !isStopped
                ? updateOption(raffleData.raffleCustomOptions.length, "")
                : ""
            }
            className="alternative-input"
          >
            Add option
          </button>
        </div>
      </div>
    </div>
  );
}
