import request from '../request'
export const shlink = async (link) => {
    try {
        const token = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid profile email`))?.body?.access_token

        const response = await request({
            longUrl: link
        }, '/dmme/shlink', 'create', token)
        return response
    } catch (error) {
        ;
        return { error }
    }
}