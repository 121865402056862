import React, { useContext, useEffect, useState } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import GoldenTicket from "../../components/GoldenTicket/GoldenTicket";
import { useHistory, useLocation } from "react-router";
import ticket from "../../assets/ticket.png";
import axios from "axios";
import QueryString from "qs";
import facebookIcon from "../../assets/facebook.svg";
import instaIcon from "../../assets/insta.svg";
import whatsappIcon from "../../assets/whatsapp.svg";
import emailIcon from "../../assets/email.svg";
import twitterIcon from "../../assets/twitter.svg";
import clipboardIcon from "../../assets/clipboard.svg";
import messengerIcon from "../../assets/messenger.svg";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { FadeLoader } from "react-spinners";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

import "./TextMeSuccess.scss";
import {
  getGoogleUser,
  checkUser,
  logoutUser,
  loginWithCredentials,
} from "../../magic";
import { updateUser } from "../../api/endpoints/createUser";
import { AppContext } from "../../contexts/AppContext";
import sendTwilioSMS from "../../api/endpoints/sendTwilioSMS";
import { getArtist } from "../../helpers/getArtist";
import { landing } from "../../config/Config";
import { flowStart } from "../../helpers/flowStart";

export default function TextMeSuccess({ data }) {
  const {
    hotlineSuccessImg,
    hotlineSuccessH1,
    hotlineSuccessH1Already,
    hotlineSuccessH2Already,
    hotlineSuccessH2,
    hotlineSuccessH3,
    hotlineSuccessSMS1,
    hotlineSuccessSMS2,
  } = data;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const artistId = getArtist();
  const artistJSON = landing[artistId];
  const twilioFlowNumber = artistJSON?.["phoneCampaign"]?.["twilioFlowNumber"];
  return (
    <>
      <div
        style={{
          background: `url(${hotlineSuccessImg}) no-repeat center`,
        }}
        className="desktop-wrapper back-position"
      >
        {/* <GoldenTicket /> */}
        <div className="textmesuccess-text-part">
          <h1 className="textmesuccess-explain-h1">
            {location?.state?.dontSendSMS
              ? hotlineSuccessH1Already
              : hotlineSuccessH1}
          </h1>
          <h2 className="textmesuccess-explain-h2">
            {location?.state?.dontSendSMS
              ? hotlineSuccessH2Already
              : hotlineSuccessH2}
          </h2>
          <h2 className="textmesuccess-explain-h2">{hotlineSuccessH3}</h2>
        </div>
      </div>
    </>
  );
}
