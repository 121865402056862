import logo from './logo.svg';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import './App.scss';
import Landing from './screens/Landing/Landing';
import { landing } from './config/Config'
import VerifyOTP from './screens/VerifyOTP/VerifyOTP';
import Success from './screens/Success/Success';
import {
  AppContextProvider
} from './contexts/AppContext';
import MediaServices from './screens/MediaServices/MediaServices';
import MediaServicesSuccess from './screens/MediaServicesSuccess/MediaServicesSuccess';
import WhatsYourEmail from './screens/WhatsYourEmail/WhatsYourEmail';
import EmailSent from './screens/EmailSent/EmailSent';
import LandingEmail from './screens/LandingEmail/LandingEmail';
import GetAccess from './screens/GetAccess/GetAccess';
import Redirector from './screens/Redirector/Redirector';
import LandingPhone from './screens/LandingPhone/LandingPhone';
import HotlineSuccess from './screens/HotlineSuccess/HotlineSuccess';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Polls from './screens/Polls/Polls';
import PollSuccess from './screens/PollSuccess/PollSuccess';
import { getArtist } from './helpers/getArtist';
import { Helmet } from 'react-helmet';
import Welcome from './SignupProcess/Welcome/Welcome';
import Footer from './components/SignupProcess/Footer/Footer';
import HotlineBuilder from './SignupProcess/realtimebuilder/HotlineBuilder/HotlineBuilder'
import GetYourNumber from './SignupProcess/GetYourNumber/GetYourNumber';
import MessageFlowBuilder from './SignupProcess/MessageFlowBuilder/MessageFlowBuilder';
import LandingTemplate1 from './screens/LandingTemplate1/LandingTemplate1';
import Dashboard from './screens/Dashboard/Dashboard';
import ReactGA from "react-ga4";
import 'antd/dist/antd.min.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0 } from './auth_config';
import { auth } from './firebase/firebase';
import history from './helpers/history'
import ProfileDetails from './SignupProcess/ProfileDetails/ProfileDetails';
import FAVICON from './assets/iiNDYVERSE.ico'
import JoinOurWaitlist from './SignupProcess/JoinOurWaitlist/JoinOurWaitlist';
import TextMeSuccess from './screens/TextMeSuccess/TextMeSuccess';
import UnlockAccess from './UnlockAccess/UnlockAccess';
import RaffleForm from './RaffleForm/RaffleForm';
import GetVerifiedRaffle from './screens/GetVerifiedRaffle/GetVerifiedRaffle';
import GetVerified from './screens/GetVerified/GetVerified';
import RaffleSubmitted from './screens/RaffleSubmitted/RaffleSubmitted';


if (window.location.origin.includes('iindy.co')) {
  ReactGA.initialize('G-8C6W2ZEMLY');
  Sentry.init({
    dsn: "https://8bec81fed60a4c0ab10f499d51124eaa@o1176671.ingest.sentry.io/6274680",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function App() {

  useEffect(() => {
    if (window.location.origin.includes('iindy.co')) {
      ReactGA.set({ page: window.location.pathname, appVersion: 1.0 });
      ReactGA.ga('set', 'checkProtocolTask', null);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.document.title });

    }
  }, [window.location.pathname])

  const artistId = getArtist()
  const artistJson = landing[artistId]
  useEffect(() => {
    if (artistId)
      document.title = landing[artistId].pageTitle || landing[artistId].artist
  }, [artistId])

  const iindyFavicon = ['/welcome', '/get-your-number', '/dashboard', '/profile-details', '/message-flow-builder']
  useEffect(() => {
    if (window.location.pathname) {
      const filter = iindyFavicon.filter((f) => (window.location.pathname.includes(f)))
      if (filter.length > 0) {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = FAVICON;
        document.head.appendChild(link);
      }
    }
  }, [window.location.pathname])

  return (
    <AppContextProvider>
      <Helmet>
        <link rel="icon" type="image/png" href={artistJson.favicon} sizes="16x16" />
      </Helmet>
      <Router>
        <Switch>
          <Route path="/ticket">
            <Success />
          </Route>
          <Route path="/verify">
            <VerifyOTP />
          </Route>
          <Route path="/getconnected">
            <MediaServices />
          </Route>
          <Route path="/success1">
            <MediaServicesSuccess />
          </Route>
          <Route path="/get-verified/:id">
            <GetVerifiedRaffle />
          </Route>
          <Route path="/raffle/:id">
            <RaffleForm />
          </Route>
          {/* <Route path="/merch-wdyt/:id/success">
            <PollSuccess data={{ ...landing[artistId], ...landing[artistId].emailCampaign }} />
          </Route> */}
          <Route path="/merch-wdyt/:id">
            {!landing[artistId].emailCampaign ? <Redirect to="/textme" /> :
              <Polls data={{ ...landing[artistId], ...landing[artistId].emailCampaign }} />}
          </Route>
          <Route path="/success">
            <GetAccess data={{ ...landing[artistId], ...landing[artistId].emailCampaign }} />
          </Route>
          <Route path="/hotline-success">
            <HotlineSuccess data={{ ...landing[artistId], ...landing[artistId].phoneCampaign }} />
          </Route>
          <Route path="/email-sent">
            <EmailSent data={{ ...landing[artistId], ...landing[artistId].emailCampaign }} />
          </Route>
          <Route path="/whats-your-email">
            <WhatsYourEmail data={landing} />
          </Route>
          <Route path="/authenticate">
            <GetVerified />
          </Route>
          <Route path="/not-found">
            <GetVerified notFound={true} />
          </Route>
          <Route path="/already-submitted">
            <RaffleSubmitted />
          </Route>
          <Route path="/hotline-builder">
            <HotlineBuilder />
          </Route>
          <Route path="/hotline">
            {artistId == '-1' && <Redirect to="/welcome" />}
            {(!landing[artistId].phoneCampaign) ? <Redirect to="/join" /> :
              <LandingPhone data={{ ...landing[artistId], ...landing[artistId].phoneCampaign }} />
            }
          </Route>
          <Route path="/textme">
            {landing[artistId].emailCampaign ? <Redirect to="/join" /> : !landing[artistId].textMe ? <Redirect to="/hotline" /> :
              <LandingTemplate1 data={{ ...landing[artistId], ...landing[artistId].textMe }} />
            }
          </Route>
          <Route path="/textme-success">
            <TextMeSuccess data={{ ...landing[artistId], ...landing[artistId].textMe }} />
          </Route>
          <Route path="/join">
            {!landing[artistId].emailCampaign ? (!landing[artistId].phoneCampaign ? <Redirect to="/textme" /> : <Redirect to="/hotline" />) :
              <LandingEmail data={{ ...landing[artistId], ...landing[artistId].emailCampaign }} />
            }
          </Route>
          <Route path="/welcome">
            {artistId != '-1' ? <Redirect to={'/'} /> : <Welcome />}
            <Footer />
          </Route>
          <Route path="/join-our-waitlist">
            {artistId != '-1' ? <Redirect to={'/'} /> : <JoinOurWaitlist />}
            <Footer />
          </Route>
          <Route path="/profile-details">
            {artistId != '-1' ? <Redirect to={'/'} /> : <ProfileDetails />}
            <Footer />
          </Route>
          <Route path="/get-your-number">
            {artistId != '-1' ? <Redirect to={'/'} /> : <GetYourNumber />}
            <Footer />
          </Route>
          <Route path="/message-flow-builder">
            {artistId != '-1' ? <Redirect to={'/'} /> : <MessageFlowBuilder />}
          </Route>
          <Route path="/dashboard">
            {artistId != '-1' ? <Redirect to={'/'} /> : <Dashboard />}
          </Route>
          <Route path="/:id">
            <UnlockAccess />
          </Route>
          <Route path="/">
            <Redirect to={artistId == '-1' ? '/welcome' : '/textme'} />
          </Route>

          {/* <Route path="/:ref">
            <Redirector />
          </Route> */}
        </Switch>
        {ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.document.title })}
      </Router>
    </AppContextProvider>
  );
}

export default App;
