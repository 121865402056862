import React from "react";
import { copyToClipboard } from "../../../../helpers/copyToClipboard";
import { Modal, notification } from "antd";
import jumpman from "../../../../assets/Dashboard/jumpman.svg";
import { MdOutlineContentCopy } from "react-icons/md";
import "./PlaybookSuccessModal.scss";
import { customNotification } from "../../../../helpers/customNotification";

export default function PlaybookSuccessModal({
  showSuccessModal,
  activeTab,
  setShowSuccessModal,
}) {
  return (
    <Modal
      open={showSuccessModal && activeTab === 1}
      className="playbook-success"
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(0,0,0,0.3)",
        backdropFilter: "blur(10px)",
      }}
      onCancel={() => {
        window.history.replaceState({}, document.title);
        setShowSuccessModal(false);
      }}
    >
      <img src={jumpman} />
      <h3>{showSuccessModal?.title}</h3>
      <b>{showSuccessModal?.description}</b>
      <div className="link-and-copy">
        <b>{showSuccessModal?.link}</b>
        <div
          className="copy-to-clipboard"
          onClick={() =>
            copyToClipboard(showSuccessModal?.link, () =>
              customNotification(
                "Link copied to clipboard",
                <MdOutlineContentCopy />
              )
            )
          }
        >
          <MdOutlineContentCopy />
        </div>
      </div>
    </Modal>
  );
}
