import { getArtist } from '../../helpers/getArtist'
import request from '../request'
const artistId = getArtist()

export const getPollData = async ({ poll_id }) => {
    var response
    try {
        response = await request(null, `/poll/artist/${artistId}?poll_id=${poll_id}`, 'index')
        return response
    } catch (error) {

        return false
    }
}
export const getPollVotes = async ({ poll_id, user_id }) => {
    var response
    try {
        response = await request(null, `/poll/uservotes/${artistId}?user_id=${user_id}&poll_id=${poll_id}`, 'index')
        return response
    } catch (error) {

        return false
    }
}
export const votePoll = async ({ data, poll_id, story_id }) => {
    var response
    try {
        response = await request(data, `/poll/${artistId}?story_id=${story_id}&poll_id=${poll_id}`, 'update')
        return response
    } catch (error) {

        return false
    }
}
export const linkAnonymousUserId = async ({ anonymous_id, user_id }) => {
    var response
    try {
        response = await request(null, `/poll/anonymous/${artistId}?old_id=${anonymous_id}&new_id=${user_id}`, 'update')
        localStorage.removeItem('anonymousId')
        return response
    } catch (error) {

        return false
    }
}