import React from "react";
import styles from "./Button.module.scss";

export default function Button({
  label = null,
  action,
  noBackground = false,
  rightIcon = false,
  leftIcon = false,
  disabled = false,
  background = false,
  textColor = false,
  borderColor = false,
  border = "1px solid black",
  height = "48px",
  iconOrImage = "img",
}) {
  return (
    <div
      className={
        styles.wrapper +
        ` ${noBackground ? styles.noBackground : ""} ${
          rightIcon ? styles.leftAligned : ""
        }
        ${leftIcon ? styles.rightAligned : ""}
        ${label == null ? styles.noLabel : ""}
        ${disabled ? styles.disabled : ""}
        `
      }
    >
      <button
        style={{
          backgroundColor: background,
          color: !disabled && (textColor || "#000"),
          borderColor: !disabled && (borderColor || "#000"),
          border: border,
          height: height,
        }}
        onClick={disabled ? null : action}
      >
        {leftIcon &&
          (iconOrImage === "img" ? (
            <img className={styles.leftIcon} src={leftIcon} />
          ) : (
            leftIcon
          ))}
        {label}
        {rightIcon &&
          (iconOrImage === "img" ? (
            <img className={styles.rightIcon} src={rightIcon} />
          ) : (
            rightIcon
          ))}
      </button>
    </div>
  );
}
