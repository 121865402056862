import React from "react";
import Button from "../../components/SignupProcess/Button/Button";
import Copyright from "../../components/Copyright/Copyright";
import IOSLikeLoader from "../../components/IOSLikeLoader/IOSLikeLoader";
import { Redirect } from "react-router-dom";
import { SubmittedGuyIcon } from "../../assets/Dashboard";
import "./RaffleSubmitted.scss";
import { useHistory } from "react-router-dom";

const RaffleSubmitted = () => {
  const history = useHistory();

  const goBackAction = () => {
    const raffle_id = history.location.state.raffle_id;
    if (raffle_id) {
      history.push(`/${raffle_id}`);
    }
  };
  return (
    <div
      className="raffle-submitted"
      style={{
        backgroundColor: "black",
        color: "white",
      }}
    >
      <div className="raffle-submitted-content">
        <SubmittedGuyIcon />
        <h1>You're already In.</h1>
        <h3>Looks like you have already entered this raffle.</h3>
        <Button
          border="none"
          action={goBackAction}
          label={"GO BACK"}
          height="44px"
        />
        <Copyright />
      </div>
    </div>
  );
};

export default RaffleSubmitted;
