import { LogoutOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu as AntMenu, Dropdown } from "antd";
import { useAtom } from "jotai";
import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { MdCheck, MdOutlineEdit, MdOutlineSave } from "react-icons/md";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import { artistUpdate } from "../../../api/dmmeendpoints/artistUpdate";
import { checkIfArtistExists } from "../../../api/dmmeendpoints/checkIfUserExists";
import { updateBotFlow } from "../../../api/dmmeendpoints/updateBotFlow";
import Logo from "../../../assets/Dashboard/iindy.png";
import { ReactComponent as PauseIcon } from "../../../assets/Dashboard/pause.svg";
import { ReactComponent as RestoreIcon } from "../../../assets/Dashboard/restore.svg";
import { ReactComponent as ChevronLeft } from "../../../assets/SignUpProcess/i-chevron-left.svg";
import loadingcheck from "../../../assets/loading-check.json";
import {
  isNftValid as isNftValidAtom,
  newSeriesAtom,
} from "../../../atoms/newSeries";
import Button from "../../../components/SignupProcess/Button/Button";
import StatusTags from "../../../components/StatusTags/StatusTags";
import YourNumber from "../../../components/YourNumber/YourNumber";
import { customNotification } from "../../../helpers/customNotification";
import ActivationTiles from "./ActivationTiles/ActivationTiles";
import "./Community.scss";
import MessageCompiler from "./MessageCompiler/MessageCompiler";
import MessageTiles from "./MessageTiles/MessageTiles";
import PauseModal from "./PauseModal/PauseModal";
import RestoreModal from "./RestoreModal/RestoreModal";
import UpdateBot from "./UpdateBot/UpdateBot";
import WhatsappBuilder from "./WhatsappBuilder/WhatsappBuilder";
import {
  createWhatsappFlow,
  updateWhatsappFlow,
} from "../../../api/dmmeendpoints/whatsappFlowsRequests/whatsappFlows";

export default function Community() {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [showUpdater, setShowUpdater] = useState(false);
  const [showWhatsappBuilder, setShowWhatsappBuilder] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [shouldShowRestore, setShouldShowRestore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newSeries, setNewSeries] = useAtom(newSeriesAtom);
  const [isNftValid] = useAtom(isNftValidAtom);
  const [flowName, setFlowName] = useState("Name your flow");
  const [canEditFlowName, setCanEditFlowName] = useState(false);
  const { user, logout } = useAuth0();

  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));
  const [flowToStoreData, setFlowToStoreData] = useState({});

  delete dbUser?.bot_config?.contact_card_link;
  const logoutWithRedirect = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };

  const tabs = ["BROADCAST", "CONVERSATIONS"];
  const dropdownMenu = (
    <AntMenu>
      <AntMenu.Item onClick={logoutWithRedirect}>
        <LogoutOutlined /> Logout
      </AntMenu.Item>
    </AntMenu>
  );
  useEffect(() => {
    if (showUpdater) {
      history.push(`?conversation_id=${showUpdater}`);
    }
  }, [showUpdater]);
  useEffect(() => {
    const params = queryString.parse(history.location.search);
    if (history.location.pathname.includes("/conversations")) {
      setActiveTab(1);
    }
    if (params.conversation_id) {
      setShowUpdater(params.conversation_id);
    }
  }, [history.location.search, history.location.pathname]);

  useEffect(() => {
    const nft_collections = JSON.parse(
      localStorage.getItem("nft_collections")
    )?.[0]?.series;
    const nft_collectionsLength = nft_collections?.length;
    if (
      !_.isEqual(
        { ...dbUser.bot_config, active_nft_series_id: null },
        { ...dbUser.unsaved_bot_config, active_nft_series_id: null }
      ) ||
      !_.isEqual(
        { ...nft_collections?.[nft_collectionsLength - 2], id: null },
        { ...nft_collections?.[nft_collectionsLength - 1], id: null }
      )
    ) {
      setShouldShowRestore(true);
    } else {
      setShouldShowRestore(false);
    }
  }, [dbUser]);

  const publishChanges = async () => {
    const updatedCollections = [...(dbUser.nft_collections || [])];
    if (updatedCollections[0]) {
      updatedCollections[0].series = [
        ...(updatedCollections?.[0]?.series || []),
        newSeries,
      ];
    } else {
      updatedCollections.push({
        collection_id: "welcome_bot_nft_collection", // this is the id of the collection
        collection_name: `${dbUser.artist_name} Welcome Collection`, // this is the name of the collection
        collection_description:
          "This is the collection of NFTs that are given to users when they join the welcome bot", // this is the description of the collection
        series: [newSeries],
      });
    }
    const nft_collections = JSON.parse(
      localStorage.getItem("nft_collections")
    )?.[0]?.series;
    const nft_collectionsLength = nft_collections?.length;

    const isCollectibleUpdated = !_.isEqual(
      { ...nft_collections?.[nft_collectionsLength - 2], id: null },
      { ...nft_collections?.[nft_collectionsLength - 1], id: null }
    );

    if (!isCollectibleUpdated) {
      updatedCollections[0].series = updatedCollections[0].series.slice(
        0,
        updatedCollections[0].series.length - 1
      );
    }
    // console.log({
    //   object: {
    //     bot_config: {
    //       ...dbUser.unsaved_bot_config,
    //       active_nft_series_id: isCollectibleUpdated
    //         ? newSeries.id
    //         : dbUser.bot_config.active_nft_series_id,
    //     },
    //     unsaved_bot_config: {
    //       ...dbUser.unsaved_bot_config,
    //       active_nft_series_id: newSeries.id,
    //     },
    //     nft_collections: updatedCollections,
    //   },
    //   artistId: user.sub,
    // });

    await artistUpdate({
      object: {
        bot_config: {
          ...dbUser.unsaved_bot_config,
          active_nft_series_id: isCollectibleUpdated
            ? newSeries.id
            : dbUser.bot_config.active_nft_series_id,
        },
        unsaved_bot_config: {
          ...dbUser.unsaved_bot_config,
          active_nft_series_id: newSeries.id,
        },
        nft_collections: updatedCollections,
      },
      artistId: user.sub,
    });

    await updateBotFlow();
    await checkIfArtistExists(user.email);
    setShowUpdater(false);
    history.push("/dashboard/community/conversations");
    customNotification("Published", null, "This Conversation is now live! ");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const showAnyUpdater = showUpdater || showWhatsappBuilder;

  const saveWhatsappFlow = async () => {
    if (showWhatsappBuilder.flowId) {
      console.log(flowToStoreData, "FLOWTOSTORE");
      await updateWhatsappFlow({
        flowId: showWhatsappBuilder.flowId,
        name: flowName,
        description: "",
        json: flowToStoreData.data,
      });
      return setShowWhatsappBuilder(false);
    }
    await createWhatsappFlow({
      name: flowName,
      description: "",
      json: flowToStoreData.data,
    });
    setShowWhatsappBuilder(false);
  };

  useEffect(() => {
    console.log(showWhatsappBuilder, "FRANKOP{RIFT");
    if (showWhatsappBuilder.flowId) {
      setFlowName(showWhatsappBuilder.name);
      setFlowToStoreData(showWhatsappBuilder.json);
    }
  }, [showWhatsappBuilder]);

  return (
    <div
      className={`community-wrapper ${
        showWhatsappBuilder ? "pannel-move-left" : ""
      }`}
    >
      <div className="logo-and-logout">
        <img className="logo-mobile" src={Logo} />
        <Dropdown menu={dropdownMenu}>
          <img src={user?.picture} />
        </Dropdown>
      </div>
      <div className="sub-header">
        {showAnyUpdater && (
          <div
            className="back-action"
            onClick={() => {
              setShowUpdater(false);
              setShowWhatsappBuilder(false);
              history.push("/dashboard/community/conversations");
            }}
          >
            <ChevronLeft />
          </div>
        )}
        {showWhatsappBuilder ? (
          <div className={"whatsapp-builder-header"}>
            <input
              className={`flow-name-input ${
                canEditFlowName ? "active-flow-name-input" : ""
              }`}
              disabled={!canEditFlowName}
              value={flowName}
              onChange={(e) => setFlowName(e.target.value)}
            />
            <div
              className="editflowname-icon"
              onClick={() => {
                setCanEditFlowName(!canEditFlowName);
              }}
            >
              {canEditFlowName ? <MdCheck /> : <MdOutlineEdit />}
            </div>
            <div className="whatsapp-save">
              <Button label={"Save"} border="none" action={saveWhatsappFlow} />
            </div>
          </div>
        ) : (
          <h2>
            {showUpdater
              ? dbUser?.flowNames?.[1].title || "Welcome new members"
              : "Community"}
          </h2>
        )}
        {showUpdater && (
          <div className="header-items">
            <StatusTags
              status={
                dbUser.bot_config.customise_your_welcome_message
                  ? "ongoing"
                  : "paused"
              }
            />
            <div className="header-items-right">
              <div>
                <div className="is-saving">
                  {isLoading && (
                    <Lottie
                      options={{
                        animationData: loadingcheck,
                        autoplay: true,
                        loop: false,
                      }}
                      height={40}
                      width={40}
                    />
                  )}
                </div>
              </div>

              {shouldShowRestore &&
                dbUser.bot_config.customise_your_welcome_message && (
                  <div
                    className="restore"
                    onClick={() => setShowRestoreModal(true)}
                  >
                    <RestoreIcon />
                  </div>
                )}
              {dbUser.bot_config.customise_your_welcome_message && (
                <div className="pause" onClick={() => setShowPauseModal(true)}>
                  <PauseIcon />
                </div>
              )}
              <Button
                label={"Publish"}
                disabled={!shouldShowRestore || !isNftValid}
                textColor={!shouldShowRestore && "#D4BD00"}
                borderColor={"transparent"}
                action={publishChanges}
              />
            </div>
          </div>
        )}
      </div>
      {!showAnyUpdater && (
        <div className="tabs-header">
          {tabs.map((tab, i) => (
            <div
              key={i}
              onClick={() => {
                setActiveTab(i);
                if (i === 1) {
                  history.push("/dashboard/community/conversations");
                } else {
                  history.push("/dashboard/community");
                }
              }}
              className={`tab ${i == activeTab ? "active-tab" : ""}`}
            >
              {tab}
            </div>
          ))}
        </div>
      )}

      {activeTab === 0 && !showAnyUpdater && (
        <div className="broadcast" style={{ position: "relative" }}>
          <MessageCompiler />
          <MessageTiles />
          <YourNumber />
        </div>
      )}
      {activeTab === 1 && !showAnyUpdater && (
        <div className="activations">
          <ActivationTiles
            setShowUpdater={setShowUpdater}
            setShowWhatsappBuilder={setShowWhatsappBuilder}
          />
        </div>
      )}
      {showUpdater && (
        <UpdateBot
          setShowUpdater={setShowUpdater}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          shouldShowRestore={shouldShowRestore}
        />
      )}
      {showWhatsappBuilder && (
        <WhatsappBuilder
          flowName={flowName}
          flowToStoreData={flowToStoreData}
          setFlowToStoreData={setFlowToStoreData}
        />
      )}

      <PauseModal
        showPauseModal={showPauseModal}
        setShowPauseModal={setShowPauseModal}
        setShowUpdater={setShowUpdater}
      />
      <RestoreModal
        showRestoreModal={showRestoreModal}
        setShowRestoreModal={setShowRestoreModal}
      />
    </div>
  );
}
