import React from "react";
import styles from "./SuccessModal.module.scss";
import PotatoHead from "../../assets/SignUpProcess/potato-head.svg";
import PapperHead from "../../assets/SignUpProcess/papper-head.svg";
import Spikey from "../../assets/SignUpProcess/spikey-1.svg";
import x from "../../assets/SignUpProcess/x.svg";

import rightArrow from "../../assets/SignUpProcess/arrow-right.svg";
import copy from "../../assets/SignUpProcess/copy.svg";
import { useHistory, useLocation } from "react-router";

export default function ConfirmPopUp({ visible, type, setVisible }) {
  const history = useHistory();
  const RightButton = ({ type }) => {
    return (
      <button
        className={styles.arrowBtn}
        onClick={() => {
          if (type === "error") {
            setVisible(false);
          } else {
            history.push("/message-flow-builder");
          }
        }}
      >
        <b>{type === "error" ? `TRY AGAIN` : `ADD tO INSTAGRAM`}</b>
      </button>
    );
  };
  if (!visible) {
    return null;
  }
  return (
    <div className={styles.popUpWrapper}>
      <div className={styles.popUp + ` ${styles[type]}`}>
        <h1>{type === "error" ? "SOMETHING WENT WRONG" : "YOU’Re SET! "}</h1>

        <div>
          <b className={styles.phoneNr}>USE IT ANYWHERE! </b>
          {type !== "error" && (
            <div className={styles.phoneNrAndCopy}>
              <b className={styles.phoneNr}>+44 798 765 4321 </b>
              <img src={copy} />
            </div>
          )}
        </div>
        <img src={Spikey} className={styles.spikey} />
        <img src={x} className={styles.x} onClick={() => setVisible(false)} />

        <RightButton type={type} />
      </div>
    </div>
  );
}
