import requestDM from '../requestDM'

export const getUserByArtist = async (id) => {
    let realId = null;
    if (id === 'auth0|63b54c9a933ed9733c8ee2e9') {
        realId = 1;
    } else if (id === 'auth0|63b54e013a95d517efd89096') {
        realId = 2;
    }
    const number = JSON.parse(localStorage.getItem('dbUser'))?.claimed_phone_number?.replace('+', '')
    var response;
    try {
        response = await requestDM({}, `/dmme/fan/artist/${realId || id.split('|')[1]}?artist_number=${number}`, 'GET')
            ;
        return response.data
    } catch (error) {

        return []
    }
}