import React, { useEffect, useState } from "react";
import { Avatar, notification } from "antd";
import userReplyIcon from "../../../../assets/Dashboard/user-reply.svg";
import "./ChatView.scss";
import { FilePicker } from "react-file-picker";
import faceSmile from "../../../../assets/Dashboard/face-smile.svg";
import attach from "../../../../assets/Dashboard/attach.svg";
import send from "../../../../assets/Dashboard/send.svg";
import imagePicker from "../../../../assets/Dashboard/image-picker.svg";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import Button from "../../../../components/SignupProcess/Button/Button";
import { useRef } from "react";
import Lightbox from "../../../../components/Lightbox/Lightbox";
import { getBase64 } from "../../../../helpers/getBase64";
import MessageImage from "../../../../components/MessageImage/MessageImage";
import { useFilePicker } from "use-file-picker";
import { s3Upload } from "../../../../helpers/s3Upload";
import { customNotification } from "../../../../helpers/customNotification";
import { BiErrorAlt } from "react-icons/bi";

export default function ChatView({ setLightboxState }) {
  const [openFileSelector, { plainFiles }] = useFilePicker({
    accept: [".png", ".jpg", "jpeg"],
    multiple: false,
  });
  const chatBody = useRef();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const [broadcastImages, setBroadcastImages] = useState([]);
  const [progress, setProgress] = useState([0]);

  const chat = [
    {
      message: `SPECIAL ANNOUNCEMENT*Clearing begins this FALL + WINTER ➰🍁❄️🌐 If
        you feel like 🔥 or act like 🧊 you will see yourself in this show.
        Tickets tinyurl.com/5n8zyhh7`,
      type: `my`,
      date: moment().subtract(2, "days"),
    },
    {
      message: `Hi HYD! 👋 I’m a big fan of yours`,
      type: `others`,
      date: moment().subtract(1, "days").add(4, "hours"),
    },
    {
      message: `Message that contains images`,
      images: [
        "https://picsum.photos/536/354",
        "https://picsum.photos/536/354?i=1",
      ],
      type: `my`,
      date: moment().subtract(2, "days"),
    },
  ];
  useEffect(() => {
    chatBody.current.scrollTop = chatBody.current.scrollHeight;
  }, []);

  useEffect(() => {
    const getImgData = async () => {
      if (plainFiles?.[0]?.size >= 2 * 1e6) {
        customNotification(
          `Image can't be more than 2MB`,
          <BiErrorAlt />,
          "File size too big"
        );
      } else if (plainFiles.length !== 0 && broadcastImages.length < 4) {
        const base64 = await getBase64(plainFiles[0]);
        setBroadcastImages([...broadcastImages, { img: base64 }]);
        s3Upload(plainFiles[0], progress, setProgress, broadcastImages.length);
        const progressState = [...progress];
        setProgress(progressState);
      }
    };
    getImgData();
  }, [plainFiles]);
  return (
    <>
      <div className="chatview-wrapper" onClick={() => setEmojiOpen(false)}>
        <div className="chat-header">
          <Avatar
            icon={<img src={userReplyIcon} />}
            style={{ backgroundColor: "#F4F4F5", padding: "8px" }}
            size={40}
          />
          <span className="contact">123 456 789</span>
        </div>
        <div
          ref={chatBody}
          className={
            "chat-body" + (broadcastImages.length > 0 ? " body-images" : "")
          }
        >
          {chat.map(({ message, images = null, type, date }) => (
            <div className={`${type}-bubble`}>
              <div className="bubble-container">
                <div
                  className={"bubble"}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
                {images && (
                  <div
                    className="images-msg"
                    onClick={() =>
                      setLightboxState({
                        slideIndex: 0,
                        images,
                        visible: true,
                      })
                    }
                  >
                    <img src={images[0]} />
                    <span>{images?.length} photos</span>
                  </div>
                )}
                <span>
                  {moment(date).diff(moment()) / 1000 / 3600 < -47
                    ? moment(date).format("MMM DD, YYYY, hh:mm A")
                    : moment(date).fromNow()}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div
          className={
            "chat-footer" + (broadcastImages.length > 0 ? " footer-images" : "")
          }
        >
          <img
            className="tool-icon"
            src={faceSmile}
            onClick={(e) => {
              e.stopPropagation();
              setEmojiOpen(!emojiOpen);
            }}
          />
          {broadcastImages.length < 4 && (
            <>
              <div className="tool-icon" onClick={openFileSelector}>
                <img src={imagePicker} onClick={() => {}} />
              </div>
              <div className="tool-icon" onClick={openFileSelector}>
                <img src={attach} onClick={() => {}} />
              </div>
            </>
          )}
          <div className="chatinput-container">
            <input
              placeholder="Start a new message"
              value={broadcastMessage}
              onChange={(e) => setBroadcastMessage(e.target.value)}
              onClick={() => setEmojiOpen(false)}
            />
            {broadcastImages && (
              <MessageImage imgs={broadcastImages} progressSt={progress} />
            )}
          </div>
          <Button leftIcon={send} />
          {emojiOpen && (
            <div className="emoji-picker">
              <EmojiPicker
                height={400}
                onEmojiClick={({ emoji }) => {
                  setBroadcastMessage(broadcastMessage + `${emoji}`);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
