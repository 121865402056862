import requestDM from '../requestDM'

export const downloadConversationAsCSV = async (id) => {
    var response;
    try {
        response = await requestDM('', `/apiv2/customer/whatsapp/messagebird/${id}/converstaions/download_conversation_csv`, 'GET')
        return response
    } catch (error) {
        return { error }
    }
}