import React, { useState, useEffect, useContext, useCallback } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { useHistory, useLocation } from "react-router";
import "./GetAccess.scss";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import { AppContext } from "../../contexts/AppContext";
import { sendSMS } from "../../api/twilio";
import { createPhoneCheck, getPhoneCheckResult } from "../../api/truPhoneCheck";
import {
  checkUser,
  getGoogleUser,
  loginUser,
  loginWithCredentials,
  logoutUser,
} from "../../magic";
import DynamicInput from "../../components/DynamicInput/DynamicInput";
import mailIcon from "../../assets/mailIcon.svg";
import chevronRight from "../../assets/chevron-right.svg";
import { Modal } from "react-responsive-modal";
import whatsappIcon from "../../assets/whatsapp.svg";
import emailIcon from "../../assets/email.svg";
import twitterIcon from "../../assets/twitter.svg";
import clipboardIcon from "../../assets/clipboard.svg";
import messengerIcon from "../../assets/messenger.svg";
import QueryString from "qs";
import { isMobile } from "react-device-detect";
import createUser, { checkAPIUser } from "../../api/endpoints/createUser";
import { signOutGoogle } from "../../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { linkAnonymousUserId } from "../../api/endpoints/polls";
import { getArtist } from "../../helpers/getArtist";
import { landing } from "../../config/Config";

const accountSid = "AC6b23b91b120dfaa151c54561c1a19969";
const authTokenWP = "e7e93d58c511265afe4fcf2cda0d473b";

export default function GetAccess({ data }) {
  const {
    backgroundImg,
    getAccessHeading1Already,
    getAccessHeading1,
    getAccessHeading2,
    getAccessHeading3,
    getAccessHeading4,
  } = data;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");
  const [obj, setObj] = useState(null);
  const [referralLink, setReferralLink] = useState(null);
  const [refData, setRefData] = useState(null);
  const { authToken, setUser, user, referred_by } = useContext(AppContext);
  const artistId = getArtist();
  const artistJSON = landing[artistId];
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (user) {
      const referral = user.user_data?.artists?.[artistId]?.referral;
      if (referral === undefined) {
        forceLogout();
      }
      if (localStorage.getItem("anonymousId")) {
        linkAnonymousUserId({
          anonymous_id: localStorage.getItem("anonymousId"),
          user_id: user.user_id,
        });
      }
      setReferralLink(window.location.origin + "/" + referral);
    } else if (data) {
      const referral = data.user_data?.artists?.[artistId]?.referral;
      if (referral === undefined) {
        forceLogout();
      }
      setReferralLink(window.location.origin + "/" + referral);
    }
    if (localStorage.getItem("anonymousId")) {
      linkAnonymousUserId({
        anonymous_id: localStorage.getItem("anonymousId"),
        user_id: data.user_id,
      });
    }
  }, [localStorage.getItem("userData"), user]);

  useEffect(() => {
    const loginFunc = async () => {
      if (window.location?.search?.includes("magic_credential")) {
        await loginWithCredentials();
        checkUser(isThereAnyUser);
      }
    };
    loginFunc();
  }, [window.location]);

  const location = useLocation();
  const linkToShare = referralLink;
  const messengerShare = () => {
    window.open(
      `http://www.facebook.com/dialog/send?app_id=578540233222013&link=${linkToShare}&redirect_uri=${window.location.origin}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const whatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send/?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const twitterShare = () => {
    window.open(
      `https://twitter.com/messages/compose?text=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const emailShare = () => {
    window.open(
      `mailto:?subject=Be part of this Family!&body=${linkToShare}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const copyLink = async () => {
    await navigator.clipboard.writeText(linkToShare);
    toast("Copied to Clipboard", { type: "success" });
    setIsModalVisible(false);
  };
  const shareOptions = [
    // { name: "Share to Instagram", icon: instaIcon, action: instagramShare },
    { name: "Share to Messenger", icon: messengerIcon, action: messengerShare },
    { name: "Share to WhatsApp", icon: whatsappIcon, action: whatsappShare },
    { name: "Share to Twitter", icon: twitterIcon, action: twitterShare },
    { name: "Share via Email", icon: emailIcon, action: emailShare },
    { name: "Copy Link", icon: clipboardIcon, action: copyLink },
  ];

  const shareDetails = { url: linkToShare };
  const handleSharing = async () => {
    if (isMobile) {
      if (navigator.share) {
        try {
          await navigator.share(shareDetails).then(() => {});
        } catch (error) {}
      } else {
        showModal();
      }
    } else {
      showModal();
    }
  };
  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setObj({ ...obj, network_data: data });
      })
      .catch((error) => {
        setObj({ ...obj, network_data: null });
      });
  }, [axios]);
  useEffect(() => {
    if (obj != null) {
      checkForUser();
    }
  }, [location, obj]);

  const checkForUser = async () => {
    if (location?.state?.googleUser) {
      const googleData = location.state.googleUser;
      var reqObj = {
        ...obj,
        email: googleData.email,
        email_verified: googleData.emailVerified,
        firstname: googleData.givenName,
        lastname: googleData.familyName,
        google: googleData,
        artists: {
          [artistId]: {
            artist_name: artistJSON.artist,
            referred_by: localStorage.getItem("referred_by") || "",
            referral_count: 0,
          },
        },
      };
      const data = await createUser(reqObj);
      localStorage.removeItem("referred_by");
      if (data.success) {
        const userData = {
          ...data.success,
          isLoggedIn: true,
        };
        setUser(userData);
        localStorage.setItem("userData", JSON.stringify(userData));
        setLoading(false);
        localStorage.setItem("googleProfile", JSON.stringify(googleData));
      } else if (data.error) {
      }
    } else {
      // await loginWithCredentials();
      checkUser(isThereAnyUser);
    }
  };
  const isThereAnyUser = async (data) => {
    if (data?.isLoggedIn) {
      if (location?.search?.includes("magic_credential")) {
        setLoading(true);
        var data = await checkAPIUser({ email: data.user.email });
        setUser(data);
        setRefData(data);
      }
      localStorage.setItem("emailProfile", JSON.stringify(data));
      setLoading(false);
    } else if (location?.state?.googleUser) {
      localStorage.setItem(
        "googleProfile",
        JSON.stringify(location?.state?.googleUser)
      );
    } else {
      if (
        localStorage.getItem("userData") ||
        localStorage.getItem("emailProfile") ||
        localStorage.getItem("googleProfile")
      ) {
        setLoading(false);
      } else {
        forceLogout();
      }
    }
  };
  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  useEffect(() => {
    document.title = data.artist;
  }, [document]);
  const forceLogout = () => {
    try {
      signOutGoogle();
      logoutUser();
      localStorage.clear();
      history.push("/getaccess");
    } catch (error) {}
    history.push("/getaccess");
  };
  useEffect(() => {
    if (
      localStorage.getItem("redirect_to") &&
      localStorage.getItem("userData")
    ) {
      history.push(localStorage.getItem("redirect_to"));
      localStorage.removeItem("redirect_to");
    }
  }, [localStorage.getItem("redirect_to"), localStorage.getItem("userData")]);
  return (
    <div className="landing-get-access-styles">
      <div
        style={{
          background: `url(${backgroundImg})`,
        }}
        className="desktop-wrapper"
      ></div>
      {/* TESTING ONLY  */}
      <button
        style={{ display: "none" }}
        onClick={() => {
          forceLogout();
        }}
      >
        Logout
      </button>
      {/* TESTING ONLY  */}
      {loading && (
        <div className="overlay-loading">
          <FadeLoader color={"#fff"} />
        </div>
      )}
      <div className="landing-get-access-background">
        <div
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 35.08%, #000000 100%), url(${backgroundImg}) no-repeat`,
          }}
          className="landing-get-access-wrapper"
        >
          <div className="content-wrapper">
            <h1 className="bigger-h1"></h1>
            <div className="buttons-wrapper">
              <DynamicButton
                icon={""}
                text="INVITE A FRIEND"
                action={handleSharing}
              />
              <div style={{ height: 30 }} />
            </div>
            <div className="text-wrapper">
              <h1>
                {JSON.parse(localStorage?.getItem("userData"))
                  ?.alreadyRegistered || location?.state?.alreadyRegistered
                  ? getAccessHeading1Already
                  : getAccessHeading1}
              </h1>
              <h2>
                {JSON.parse(localStorage?.getItem("userData"))
                  ?.alreadyRegistered || location?.state?.alreadyRegistered
                  ? ""
                  : getAccessHeading2}
              </h2>
              <h3>
                {JSON.parse(localStorage?.getItem("userData"))
                  ?.alreadyRegistered || location?.state?.alreadyRegistered
                  ? ""
                  : getAccessHeading3}
              </h3>
            </div>
            {(JSON.parse(localStorage?.getItem("userData"))
              ?.alreadyRegistered ||
              location?.state?.alreadyRegistered) && (
              <div onClick={forceLogout} className="copyright">
                <p>Sign me out</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal open={isModalVisible} onClose={handleCancel} footer={null} center>
        <div className="share-modal-title">Share to...</div>
        {shareOptions.map((el, i) => {
          return (
            <ShareComponent
              key={i}
              text={el.name}
              icon={el.icon}
              action={el.action}
            />
          );
        })}
      </Modal>
    </div>
  );
}
const ShareComponent = ({ text, icon, action }) => {
  return (
    <div onClick={action} className="share-component">
      <img src={icon} alt="share-icon" />
      {text}
    </div>
  );
};
