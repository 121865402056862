import requestDM from '../requestDM'

export const claimPhoneNumber = async ({ number, artistId }) => {
    var response;
    try {
        response = await requestDM({ phone_number: number }, `/sms/purchase/${artistId}`, 'POST')
        return response
    } catch (error) {

        return { error }
    }
}