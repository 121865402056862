import { Modal } from "antd";
import React from "react";
import Button from "../SignupProcess/Button/Button";
import "./TipsModal.scss";

export default function TipsModal({ toggleModal, isOpen, setIsCalendlyOpen }) {
  return (
    <Modal
      className="tips-modal"
      onCancel={() => toggleModal(false)}
      open={isOpen}
      footer={null}
    >
      <h2>Engagement tips</h2>
      <p>
        <li>
          1. Create authentic, fun conversations: Fan vibe off of authenticity
          and personal interactions. Share personalised voice notes, engage your
          members in challenges and have fun with it.
        </li>
        <li>
          2. Reward your members: Consider offering early access to content,
          guest list passes, or behind-the-scenes access to your creative
          process. And ask your members what they would like.
        </li>
        <li>
          3. Create scarcity: Sometimes playing hard to get pays off. Limit
          rewards by time (e.g. 24 hrs only) or supply (e.g. only 100 items
          available).
        </li>
        <br />
        Remember, we’re here to help you. So if you need any more help just hit
        us up one of our growth experts at team@iindyverse.com
      </p>
      <Button
        border="transparent"
        label={"LET'S CHAT"}
        height={40}
        action={() => {
          setIsCalendlyOpen(true);
        }}
      />
    </Modal>
  );
}
