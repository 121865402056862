import React, { useEffect, useState } from "react";
import "./GetVerified.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/SignupProcess/Button/Button";
import Copyright from "../../components/Copyright/Copyright";
import { getContrastColor } from "../../helpers/getContrastColor";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { verifyMembership } from "../../api/dmmeendpoints/verifyOwnership";
import IOSLikeLoader from "../../components/IOSLikeLoader/IOSLikeLoader";
import { notification } from "antd";
import { ReactComponent as LighterHead } from "../../assets/Dashboard/lighter-head.svg";
import { ReactComponent as PotatoHead } from "../../assets/SignUpProcess/potato-head.svg";

export default function GetVerified({ notFound = false }) {
  const [loadingState, setLoadingState] = useState(null);
  const [isNotAMember, setIsNotAMember] = useState(false);
  const [notValid, setNotValid] = useState(notFound);
  const [number, setNumber] = useState();
  const history = useHistory();
  const params = useParams();
  const verifyNumber = () => {};

  if (isNotAMember) {
    return (
      <div
        className="error-not-a-member"
        style={{
          backgroundColor: "black",
          color: "white",
          fill: "white",
        }}
      >
        <LighterHead style={{ fill: "currentcolor" }} />
        <h1>Uhm, I can’t find you </h1>
        <b>
          If you think something is wrong, make sure you provided the right
          mobile number.
        </b>
        <div className="not-a-member-actions">
          {/* <Button
            border="none"
            action={() => {
              setIsNotAMember(false);
            }}
            background={appearance.button_color}
            textColor={appearance.button_text_color}
            label={"ASK TO JOIN"}
          /> */}
          <Button
            border="none"
            action={() => {
              setIsNotAMember(false);
            }}
            background={"#26272B"}
            textColor={"#fff"}
            label={"GO BACK"}
          />
        </div>
        <Copyright />
      </div>
    );
  } else if (notValid) {
    return (
      <div
        className="error-not-valid"
        style={{
          backgroundColor: "black",
          color: "white",
          fill: "white",
        }}
      >
        <PotatoHead style={{ fill: "currentcolor" }} />
        <h1>Oops, Not So Fast! </h1>
        <b>Your link is no longer valid</b>
        <div className="not-a-member-actions">
          {/* <Button
            border="none"
            action={() => {
              setIsNotAMember(false);
            }}
            background={appearance.button_color}
            textColor={appearance.button_text_color}
            label={"ASK TO JOIN"}
          /> */}
          {/* <Button
            border="none"
            action={() => {
              setNotValid(false);
              history.push("/authenticate");
            }}
            label={"RESEND"}
          /> */}
        </div>
        <Copyright />
      </div>
    );
  }

  return (
    <div
      className="get-verified"
      style={{
        backgroundColor: "black",
        color: "white",
      }}
    >
      <div className="get-verified-content">
        <img
          src="https://go.iindy.co/LGi2J"
          alt="artist-image"
          className="get-verified-img"
        />
        <h1>Access your collection</h1>
        <PhoneNumber
          phoneNr={""}
          getPhoneNumber={setNumber}
          phoneInputPlaceholder={"Phone number"}
          dontShowLabel={true}
        />
        <Button
          border="none"
          action={verifyNumber}
          disabled={!number}
          label={"VERIFY"}
          height="44px"
        />
        <b className="copyright-text" style={{ color: "#A0A0AB" }}>
          By continuing, you agree to our{" "}
          <a
            style={{
              textDecorationColor: "#A0A0AB",
            }}
            href="https://iindy.co/terms"
            target="_blank"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            style={{
              textDecorationColor: "#A0A0AB",
            }}
            href="https://iindy.co/privacy"
            target="_blank"
          >
            privacy policy
          </a>
          .
        </b>
        <Copyright />
      </div>
      <IOSLikeLoader
        loadingState={"loading"}
        loadingText={"Sending message"}
        successText={"Message sent"}
      />
    </div>
  );
}
