import React, { useState } from "react";
import "./PlaybookTile.scss";
import { useHistory, useLocation } from "react-router-dom";
import { BsFilePerson, BsThreeDotsVertical } from "react-icons/bs";

import { LuLink2 } from "react-icons/lu";
import { AiOutlineSend, AiOutlineStop } from "react-icons/ai";
import { RxPerson } from "react-icons/rx";
import { RiDeleteBinLine } from "react-icons/ri";
import { Dropdown } from "antd";
import { copyToClipboard } from "../../../../helpers/copyToClipboard";
import { Md10K, MdOutlineContentCopy } from "react-icons/md";
import PlaybookStopModal from "../PlaybookStopModal/PlaybookStopModal";
import {
  deletePlaybooks,
  sendPlaybookResults,
  stopPlaybooks,
} from "../../../../api/dmmeendpoints/playbook";
import moment from "moment";
import { BiErrorAlt } from "react-icons/bi";
import { customNotification } from "../../../../helpers/customNotification";

export default function PlaybookTile(props) {
  const [showStopModal, setShowStopModal] = useState(false);
  const {
    color,
    badgeColor,
    badgeTextColor,
    type,
    title,
    enabled,
    setCreatingPlaybook,
    path,
    live,
    submission_count,
    link,
    getActivePlaybooks,
    status,
  } = props;
  const autoClose =
    props?.metadata?.metadata?.raffle_details?.raffle_config?.auto_close;
  const autoCloseTime =
    props?.metadata?.metadata?.raffle_details?.raffle_config?.auto_close_date;

  const history = useHistory();
  const location = useLocation();
  const [isStopped, setIsStopped] = useState(
    status === "stopped" ||
      (autoClose && moment(Number(autoCloseTime)).isBefore(moment()))
  );
  const deletePlaybook = async () => {
    const data = await deletePlaybooks(props.metadata.playbook_key);
    if (data) {
      setShowStopModal(false);
      if (getActivePlaybooks) {
        getActivePlaybooks();
      }
    }
  };
  const stopPlaybook = async () => {
    setShowStopModal(false);
    const response = await stopPlaybooks(props.metadata.playbook_key);
    if (response) {
      setIsStopped(true);
    } else {
      customNotification("An error happened while stoping the playbook");
    }
  };
  const sendResults = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isStopped) {
      if (submission_count === 0) {
        customNotification(
          "Sorry, we can’t find any results to send",
          <BiErrorAlt />
        );
      } else {
        const data = await sendPlaybookResults(props.metadata.playbook_key);
        if (data === "error") {
          customNotification("Error while sending results", <BiErrorAlt />);
        } else {
          customNotification("Results were sent to your email");
        }
      }
      // window.open(
      //   `mailto:customer.raffle@iindyverse.com?subject=Raffle ID = ${props.metadata.playbook_key}`
      // );
    } else {
      setShowStopModal("stop");
    }
  };

  const items = [
    {
      label: (
        <div
          className="tile-dropdown-item"
          onClick={(e) =>
            copyToClipboard(link, () => {
              e.stopPropagation();
              customNotification(
                "Link copied to clipboard",
                <MdOutlineContentCopy />
              );
            })
          }
        >
          <LuLink2 />
          Copy link
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="tile-dropdown-item" onClick={sendResults}>
          {!isStopped ? (
            <>
              <AiOutlineStop /> Stop
            </>
          ) : (
            <>
              <AiOutlineSend /> Send Results
            </>
          )}
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          className="tile-dropdown-item"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowStopModal("delete");
          }}
        >
          <RiDeleteBinLine />
          Delete
        </div>
      ),

      key: "3",
    },
  ];

  return (
    <>
      <div
        onClick={() => {
          const stoppedChecked = isStopped ? { stopped: isStopped } : false;
          if (enabled) {
            setCreatingPlaybook(path);
            history.push(`${location.pathname}/${path}`, {
              ...(props.metadata ? { metadata: props.metadata } : false),
              stoppedChecked,
            });
          }
        }}
        className={`playbook-tile-wrapper ${
          color === "#000000" || color === "#5975FF" || isStopped
            ? "blacked"
            : ""
        }
      ${!enabled ? "disabled-playbook" : ""}
      `}
        style={{ background: isStopped ? "#A0A0AB" : color }}
      >
        <div>
          <div className="playbook-icon">
            <props.icon />
          </div>
          <h3>{title}</h3>
          {(!status || status === "live") && !props.preview && (
            <span>
              Edited&nbsp;
              {moment(props.metadata?.updatedAt).fromNow()}
            </span>
          )}
        </div>
        {!isStopped && (
          <div className="chip-holder">
            <div
              className="chip"
              style={{ backgroundColor: badgeColor, color: badgeTextColor }}
            >
              {type}
            </div>
            {(!status || status === "live") && !props.preview && (
              <div
                className="chip"
                style={{ backgroundColor: "#ECFDF3", color: "#027A48" }}
              >
                Live
              </div>
            )}
            {!props.preview && (
              <div
                className="chip"
                style={{ backgroundColor: "#ECFDF3", color: "#027A48" }}
              >
                <RxPerson color="#12B76A" />
                {submission_count}
              </div>
            )}
          </div>
        )}
        {isStopped && (
          <div className="chip-holder">
            <div
              className="chip"
              style={{ color: "black", background: "#fff" }}
            >
              Stopped
            </div>
            {!props.preview && (
              <div
                className="chip"
                style={{ backgroundColor: "#ECFDF3", color: "#027A48" }}
              >
                <RxPerson className="green-svg" color="#12B76A" />{" "}
                {submission_count}
              </div>
            )}
          </div>
        )}
        {!props.preview && (
          <div className="more-options">
            <Dropdown
              menu={{
                items,
              }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <BsThreeDotsVertical />
              </a>
            </Dropdown>
          </div>
        )}
      </div>
      <PlaybookStopModal
        showStopModal={showStopModal}
        setShowStopModal={setShowStopModal}
        stopPlaybook={stopPlaybook}
        deletePlaybook={deletePlaybook}
      />
    </>
  );
}
