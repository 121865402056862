import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./MessageCompiler.scss";
import faceSmile from "../../../../assets/Dashboard/face-smile.svg";
import attach from "../../../../assets/Dashboard/attach.svg";
import imagePicker from "../../../../assets/Dashboard/image-picker.svg";
import sendLater from "../../../../assets/Dashboard/send-later.svg";
import close from "../../../../assets/SignUpProcess/x.svg";
import calendar from "../../../../assets/Dashboard/calendar.svg";
import clock from "../../../../assets/Dashboard/clock.svg";
import language from "../../../../assets/language.svg";
import person from "../../../../assets/Dashboard/person.svg";
import globe from "../../../../assets/Dashboard/globe-pick.svg";
import chevron from "../../../../assets/chevron-right.svg";
import closeIcon from "../../../../assets/close.svg";
import { GrClose } from "react-icons/gr";

import { FilePicker } from "react-file-picker";
import Button from "../../../../components/SignupProcess/Button/Button";

import {
  DatePicker,
  Select,
  Avatar,
  Progress,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import { tz } from "../../../../helpers/tz";
import Carousel from "nuka-carousel";
import Lightbox from "../../../../components/Lightbox/Lightbox";
import { useFilePicker } from "use-file-picker";
import { getBase64 } from "../../../../helpers/getBase64";
import { s3Upload } from "../../../../helpers/s3Upload";
import { useAuth0 } from "@auth0/auth0-react";
import { sendMessage } from "../../../../api/dmmeendpoints/broadcastMessage";
import YourNumber from "../../../../components/YourNumber/YourNumber";
import { getUserByArtist } from "../../../../api/dmmeendpoints/getUserByArtist";
import ConfirmBroadcastModal from "../../ConfirmBroadcastModal/ConfirmBroadcastModal";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import ImportModal from "./ImportModal/ImportModal";
import SegmentDropdown from "./SegmentDropdown/SegmentDropdown";
import {
  getNFTSegments,
  segmentData,
} from "../../../../api/dmmeendpoints/segmentData";
import { useLocation } from "react-router-dom";
import { customNotification } from "../../../../helpers/customNotification";
import { BiErrorAlt } from "react-icons/bi";

const { Option } = Select;

export default function MessageCompiler() {
  const [openFileSelector, { plainFiles }] = useFilePicker({
    accept: [".png", ".jpg", "jpeg"],
    multiple: false,
  });
  const [isComposing, setIsComposing] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [broadcastTitle, setBroadcastTitle] = useState("");
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const [sendLaterOpen, setSendLaterOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [shortUrls, setShortUrls] = useState([]);
  const [progress, setProgress] = useState([0]);
  const [isSending, setIsSending] = useState(false);
  const [fans, setFans] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [totalMembers, setTotalMembers] = useState([]);
  const [segmentDataSt, setSegmentData] = useState([]);
  const [nftSegmentsData, setNFTSegmentsData] = useState([]);
  const [isTotalChecked, setIsTotalChecked] = useState(true);
  const [checkedSegments, setCheckedSegments] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const location = useLocation();
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  useEffect(() => {
    if (location.state?.linkToShare) {
      setIsComposing(true);
      setBroadcastMessage(location.state?.linkToShare);
    }
  }, [location.state?.linkToShare]);

  useEffect(() => {
    if (Object.values(checkedSegments).includes(false)) {
      setIsTotalChecked(false);
    } else {
      setIsTotalChecked(true);
    }
  }, [checkedSegments]);

  const [lightboxState, setLightboxState] = useState({
    visible: false,
    slideIndex: 0,
    images: [],
  });

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleChange = (value) => {};
  const handleChangeTime = (value) => {
    setSelectedTime(value);
  };
  const renderTimes = () => {
    const arrayOfTimes = [];
    const times = moment(selectedDate)
      ?.startOf("day")
      .isSame(moment().startOf("day"))
      ? (24 - Number(moment().format("HH"))) * 4 - 1
      : 24 * 4;
    const remainder = 15 - (moment().minute() % 15);
    const start = moment(selectedDate)
      ?.startOf("day")
      .isSame(moment().startOf("day"))
      ? moment().add(remainder + 15, "minute")
      : moment().startOf("day");
    for (let i = 0; i < times; i++) {
      arrayOfTimes.push(
        moment(start)
          .add(15 * i, "minutes")
          .format("hh:mm A")
      );
    }
    if (moment(selectedDate)?.startOf("day").isSame(moment().startOf("day"))) {
      arrayOfTimes[0] = moment().add(20, "minute").format("hh:mm A");
    }
    return arrayOfTimes;
  };

  useEffect(() => {
    const updateImages = async () => {
      if (plainFiles?.[0]?.size >= 2 * 1e6) {
        customNotification(
          `Image can't be more than 2MB`,
          <BiErrorAlt />,
          `File size too big`
        );
      } else if (plainFiles.length !== 0 && selectedImages.length < 4) {
        const base64 = await getBase64(plainFiles[0]);
        s3Upload(
          plainFiles[0],
          progress,
          setProgress,
          selectedImages.length,
          shortUrls,
          setShortUrls
        );
        setSelectedImages([...selectedImages, base64]);
        const progressState = [...progress];
        setProgress(progressState);
      }
    };
    updateImages();
  }, [plainFiles]);
  const { user } = useAuth0();
  const submitMessage = async () => {
    setIsSending(true);
    setConfirmModal(false);
    customNotification("Started sending messages...");
    try {
      await sendMessage({
        title: broadcastTitle,
        message:
          broadcastMessage + " " + shortUrls.toString().replaceAll(",", ", "),
        artistId: user.sub,
        totalMembers,
      });
      setIsSending(false);
      customNotification("Messages sent!");
      setIsComposing(false);
      setBroadcastMessage("");
      setBroadcastTitle("");
      setSelectedImages([]);
      setShortUrls([]);
      setProgress([0]);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      customNotification("Error while sending messages. Please try again!");
    }
  };
  const removeImageAtIndex = (i) => {
    const cloneSelectedImages = [...selectedImages];
    cloneSelectedImages.splice(i, 1);
    const cloneShortUrls = [...shortUrls];
    cloneShortUrls.splice(i, 1);
    const cloneProgress = [...progress];
    cloneProgress.splice(i, 1);
    setSelectedImages([...cloneSelectedImages]);
    setShortUrls([...cloneShortUrls]);
    setProgress([...cloneProgress]);
  };

  useEffect(() => {
    const getUsers = async () => {
      const fansResponse = await getUserByArtist(user.sub);
      setFans(fansResponse);
    };
    getUsers();
  }, [isComposing]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const getTotalMembers = async () => {
    const { artist_id } = JSON.parse(localStorage.getItem("dbUser"));
    let members = [];
    const segmentInfo = (await segmentData(artist_id)) || [];
    const nftSegmentInfo = (await getNFTSegments()) || [];
    const modifySegmentsData = nftSegmentInfo?.map((item) => ({
      members: item.members.map((phoneNumber) => ({
        metadata: { primary_mobile: phoneNumber },
      })),
      metadata: item.series,
      segment_id: `${item.series.seriesId}`,
      segment_name: `${item?.series.seriesName || "Blank"} (Version ${
        item?.series.seriesId?.split("_")[
          item?.series.seriesId?.split("_").length - 1
        ]
      })`,
      segment_owner_iindy_id: item.series.seriesId,
    }));
    const segmentNewData = segmentInfo?.concat(modifySegmentsData);
    setNFTSegmentsData(nftSegmentInfo);
    let checkedInfo = {};
    members = [...members];

    for (let i = 0; i <= segmentNewData.length; i++) {
      const el = segmentNewData[i];
      const innerMembers = [];
      if (el) {
        if (el.members.length > 0) {
          checkedInfo = { ...checkedInfo, [el.segment_id]: true };
        }
        for (let j = 0; j < el.members.length; j++) {
          const innerEl = el.members[j];
          innerMembers.push(
            (!innerEl?.metadata?.primary_mobile.includes("+") ? "+" : "") +
              innerEl?.metadata?.primary_mobile
          );
        }
        members = [...members, ...innerMembers];
      }
    }
    setSegmentData(segmentNewData);

    const uniqueNumbers = members.filter(
      (value, index, array) => array.indexOf(value) === index
    );

    setTotalMembers(uniqueNumbers);
    Object.keys(checkedSegments).length === 0 &&
      setCheckedSegments(checkedInfo);
  };
  useEffect(() => {
    getTotalMembers();
  }, []);

  return (
    <div
      className={`message-compiler-wrapper ${
        isComposing ? "is-composing" : ""
      }`}
    >
      <div className="row">
        <Avatar
          src={
            dbUser?.bot_config?.contact_info?.profile_picture === undefined
              ? user?.picture
              : dbUser?.bot_config?.contact_info?.profile_picture
          }
          style={{ width: 48, height: 48 }}
        />
        <div className={"col"}>
          {isComposing && (
            <>
              <div
                className="segment-data"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                {!Object.values(checkedSegments).includes(false) ? (
                  <div className="tag">
                    <div className="segment-icon">
                      <img src={language} />
                    </div>
                    <span>
                      <b>Everyone</b>
                    </span>
                  </div>
                ) : (
                  <>
                    {Object.keys(checkedSegments)
                      .filter((sgm) => checkedSegments[sgm])
                      .map((key, i) => {
                        if (
                          checkedSegments[key] &&
                          i < (windowSize[0] >= 1540 ? 3 : 2)
                        ) {
                          const sgmName =
                            segmentDataSt?.find(
                              (sgm) => sgm?.segment_id.toString() === key
                            )?.segment_name || "";
                          const parsed =
                            sgmName.length > 15
                              ? sgmName.substring(0, 12) + "..."
                              : sgmName;
                          return (
                            <Tooltip key={key} title={sgmName}>
                              <div className="tag-manual">
                                {parsed}
                                <GrClose
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const newObj = {
                                      ...checkedSegments,
                                      [key]: false,
                                    };
                                    if (!Object.values(newObj).includes(true)) {
                                      const innerNew = {};
                                      for (
                                        let i = 0;
                                        i < Object.keys(newObj).length;
                                        i++
                                      ) {
                                        const el = Object.keys(newObj)[i];
                                        innerNew[el] = true;
                                      }
                                      setCheckedSegments(innerNew);
                                      setIsTotalChecked(true);
                                    } else {
                                      setCheckedSegments(newObj);
                                    }
                                  }}
                                />
                              </div>
                            </Tooltip>
                          );
                        }
                        return null;
                      })}
                    {Object.values(checkedSegments).filter((sgm) => sgm)
                      .length > (windowSize[0] >= 1540 ? 3 : 2) && (
                      <div className="tag-manual">
                        +
                        {Object.values(checkedSegments).filter((sgm) => sgm)
                          .length - 2}{" "}
                        more
                        <GrClose />
                      </div>
                    )}
                  </>
                )}
                <div className="segment-icon-dropdown">
                  <FaChevronDown />
                </div>
                <div className="csv-import-btn">
                  <Button
                    iconOrImage={"icon"}
                    leftIcon={<FaPlus />}
                    label={"IMPORT"}
                    height="40px"
                    background="rgba(0, 0, 0, 0.06)"
                    border="none"
                    action={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setImportModal(true);
                    }}
                  />
                </div>
              </div>
              {showDropdown && (
                <SegmentDropdown
                  nftSegmentsData={nftSegmentsData}
                  segmentNewData={segmentDataSt}
                  setShowDropdown={setShowDropdown}
                  totalMembers={totalMembers}
                  setTotalMembers={setTotalMembers}
                  segmentDataSt={segmentDataSt}
                  setSegmentData={setSegmentData}
                  isTotalChecked={isTotalChecked}
                  setIsTotalChecked={setIsTotalChecked}
                  checkedSegments={checkedSegments}
                  setCheckedSegments={setCheckedSegments}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                />
              )}
            </>
          )}
          <input
            placeholder={isComposing ? "Title" : "Broadcast a message"}
            className="title"
            value={broadcastTitle}
            onChange={(e) => setBroadcastTitle(e.target.value)}
            onClick={() => {
              !isComposing && setIsComposing(true);
              setEmojiOpen(false);
            }}
          />
          {isComposing && (
            <>
              <div className="broadcast-message">
                <textarea
                  className="broadcast"
                  placeholder="Broadcast a message"
                  value={broadcastMessage}
                  onClick={() => setEmojiOpen(false)}
                  onChange={(e) => setBroadcastMessage(e.target.value)}
                />
                {selectedImages.length > 0 && (
                  <div className="carousel">
                    <Carousel
                      renderCenterLeftControls={({
                        previousDisabled,
                        previousSlide,
                      }) =>
                        !previousDisabled && (
                          <div
                            className="carousel-controls control-left"
                            onClick={previousSlide}
                            disabled={previousDisabled}
                          >
                            <img src={chevron} />
                          </div>
                        )
                      }
                      renderCenterRightControls={({
                        nextDisabled,
                        nextSlide,
                      }) =>
                        !nextDisabled && (
                          <div
                            className="carousel-controls"
                            onClick={nextSlide}
                            disabled={nextDisabled}
                          >
                            <img src={chevron} />
                          </div>
                        )
                      }
                    >
                      {selectedImages.map((image, i) => (
                        <div
                          key={`carousel-slide-${image + "-" + i}`}
                          className="carousel-slide"
                        >
                          <img
                            key={`carousel-slide-img-${image + "-" + i}`}
                            src={image}
                            onClick={() =>
                              setLightboxState({
                                ...lightboxState,
                                visible: true,
                                images: selectedImages,
                                slideIndex: i,
                              })
                            }
                          />
                          <div
                            key={`broadcast-indicator-${i}`}
                            className="broadcast-indicator"
                          >
                            {progress[i] == 100 ? (
                              <img
                                className="closeIcon"
                                src={closeIcon}
                                onClick={() => removeImageAtIndex(i)}
                              />
                            ) : (
                              <Progress
                                type="circle"
                                width={20}
                                percent={progress[i]}
                                strokeWidth={15}
                                trailColor={"rgba(255, 255, 255, 0.4)"}
                                strokeColor={"#fff"}
                                format={() => null}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )}
                {160 - broadcastMessage.length <= 20 && (
                  <Tooltip
                    color="#7A91FF"
                    title={
                      "A sms message longer that 160 characters may be split into two or more messages"
                    }
                  >
                    <div className="char-counter">
                      <Progress
                        type="circle"
                        width={30}
                        percent={(broadcastMessage.length / 160) * 100}
                        strokeWidth={15}
                        trailColor={"rgba(0, 0, 0, 0.06)"}
                        strokeColor={
                          160 - broadcastMessage.length < 0
                            ? "#F04438"
                            : "#5975FF"
                        }
                        format={(format) => `${160 - broadcastMessage.length}`}
                      />
                    </div>
                  </Tooltip>
                )}
                <div className="tools">
                  <div className="left-actions">
                    <img
                      src={faceSmile}
                      onClick={() => setEmojiOpen(!emojiOpen)}
                    />
                    {selectedImages.length < 4 && (
                      <>
                        <div onClick={openFileSelector}>
                          <img src={imagePicker} onClick={() => {}} />
                        </div>
                        {/* <div onClick={openFileSelector}>
                        <img src={attach} onClick={() => {}} />
                      </div> */}
                      </>
                    )}
                  </div>
                  <div className="right-actions">
                    {/* <img
                    src={sendLater}
                    onClick={() => {
                      setSendLaterOpen(true);
                    }}
                  /> */}
                    <Button
                      disabled={
                        !(broadcastTitle && broadcastMessage) ||
                        selectedImages.length != shortUrls.length ||
                        isSending ||
                        totalMembers.length === 0
                      }
                      action={() => setConfirmModal(true)}
                      label={"SEND"}
                    />
                  </div>
                </div>
                {sendLaterOpen && (
                  <div className="send-later-popup">
                    <div className="popup-title">
                      <h4>Schedule later</h4>
                      <img
                        src={close}
                        onClick={() => setSendLaterOpen(false)}
                      />
                    </div>
                    <div className="datepicker">
                      <DatePicker
                        suffixIcon={<img src={calendar} />}
                        format="DD/MM/YYYY"
                        disabledDate={disabledDate}
                        onSelect={setSelectedDate}
                      />
                      <div style={{ width: "12px" }} />
                      <Select
                        suffixIcon={<img src={clock} />}
                        disabled={!selectedDate}
                        placeholder={"Select time"}
                        onChange={handleChangeTime}
                      >
                        {selectedDate &&
                          renderTimes().map((time) => (
                            <Option value={time}>{time}</Option>
                          ))}
                      </Select>
                      <div style={{ width: "12px" }} />
                      <Select
                        suffixIcon={<img src={globe} />}
                        onChange={handleChange}
                      >
                        {tz.map((t) => (
                          <Option value={t.value}>{t.label}</Option>
                        ))}
                      </Select>
                    </div>
                    <div className="popup-footer">
                      <Button
                        disabled={!(selectedDate && selectedTime)}
                        label={"SCHEDULE"}
                      />
                    </div>
                  </div>
                )}
                {emojiOpen && (
                  <div className="emoji-picker">
                    <EmojiPicker
                      height={400}
                      onEmojiClick={({ emoji }) => {
                        setBroadcastMessage(broadcastMessage + `${emoji}`);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="total-members-info">
                Send to {totalMembers?.length} members
              </div>
            </>
          )}
        </div>
      </div>
      <Lightbox {...lightboxState} setLightboxState={setLightboxState} />
      <ConfirmBroadcastModal
        isOpen={confirmModal}
        setIsOpen={setConfirmModal}
        submitMessage={submitMessage}
        members={totalMembers.length}
      />
      <ImportModal
        isOpen={importModal}
        setIsOpen={setImportModal}
        segmentDataSt={segmentDataSt}
        setSegmentData={setSegmentData}
        checkedSegments={checkedSegments}
        setCheckedSegments={setCheckedSegments}
      />
    </div>
  );
}
