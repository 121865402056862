import React, { useEffect, useState } from "react";
import CountryList from "country-list-with-dial-code-and-flag";
import styles from "./DigitSearch.module.scss";
import Dropdown from "react-dropdown";
import axios from "axios";
import "react-dropdown/style.css";
import InputBox from "../InputBox/InputBox";
import Search from "../../../assets/SignUpProcess/search.svg";
import rightArrow from "../../../assets/SignUpProcess/arrow-right.svg";

export default function DigitSearch({
  contains,
  setContains,
  country,
  setCountry,
}) {
  const [selectedCountryCode, setSelectedCountryCode] = useState(1);
  const onSelect = (e) => {
    const label = CountryList.find(
      (ctr) => ctr.dial_code == e.value && e.label.includes(ctr.flag)
    );
    setCountry({ code: e.value, label: label.code });
  };
  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setSelectedCountryCode(
          CountryList.findIndex((ctr) => ctr.code == data.country_code)
        );
      })
      .catch((error) => {
        setSelectedCountryCode(1);
      });
  }, [axios, CountryList]);
  const RightButton = () => {
    return (
      <button className={styles.arrowBtn}>
        <img alt="go" src={rightArrow} />
      </button>
    );
  };
  return (
    <div className={styles.wrapper}>
      <Dropdown
        options={CountryList.filter(
          (c) => c.code === "GB" || c.code === "US"
        ).map((country) => ({
          label: country.flag + "\u00A0\u00A0" + country.dial_code,
          value: country.dial_code,
        }))}
        onChange={onSelect}
        value={country.code}
        menuClassName={styles.myMenuClassName}
        controlClassName={styles.myControlClassName}
        arrowClassName={styles.myArrowClassName}
        className={styles.CountryList}
      />
      <InputBox
        leftIcon={Search}
        label={"Search for specific digits"}
        // rightButton={RightButton}
        value={contains}
        onChange={(e) => setContains(e.target.value)}
      />
    </div>
  );
}
