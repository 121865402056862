import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { artistUpdate } from "../../../../api/dmmeendpoints/artistUpdate";
import { debounce } from "../../../../helpers/debounce";
import MessageFlowBuilder from "../../../../SignupProcess/MessageFlowBuilder/MessageFlowBuilder";
import "./UpdateBot.scss";

function UpdateBot({
  setShowUpdater,
  setIsLoading,
  isLoading,
  shouldShowRestore,
}) {
  //
  const { user } = useAuth0();
  const { sub } = user;
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  const debounceUpdate = debounce(async (obj) => {
    if (obj.artist_name) {
      setIsLoading(true);
      const update = await artistUpdate({
        object: {
          unsaved_bot_config: { ...obj, tags: obj.tags || [] },
        },
        artistId: sub,
      });
      localStorage.setItem(
        "dbUser",
        JSON.stringify({
          ...dbUser,
          unsaved_bot_config: { ...obj, tags: obj.tags || [] },
        })
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, 1000);

  return (
    <div className="update-bot-wrapper">
      <MessageFlowBuilder
        getUpdatedValues={(obj) => {
          debounceUpdate(obj);
        }}
        isLoading={isLoading}
        shouldShowRestore={shouldShowRestore}
        stopWhenLoading
        updating
      />
    </div>
  );
}
export default React.memo(UpdateBot);
