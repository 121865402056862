import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";

const magic = new Magic("pk_live_179DAB1E1A2809F4", {
    extensions: [new OAuthExtension()],
});
export const checkUser = async (cb) => {
    const isLoggedIn = await magic.user.isLoggedIn();
    if (isLoggedIn) {
        const user = await magic.user.getMetadata();
        return cb({ isLoggedIn: true, user: user });
    }
    return cb({ isLoggedIn: false });
};

export const loginUser = async (email, redirectURI) => {
    // await magic.auth.loginWithMagicLink({ email, showUI: false });
    try {
        await magic.auth.loginWithMagicLink({ email, showUI: false, redirectURI: redirectURI });
    } catch (error) {
    }
};

export const loginWithCredentials = async () => { await magic.auth.loginWithCredential() }
export const googleLogin = async (route) => {
    await magic.oauth.loginWithRedirect({
        provider: "google",
        redirectURI: `${window.location.origin}${route}`,
    });
}
export const getGoogleUser = async () => {
    try {
        const result = await magic.oauth.getRedirectResult();
        const profile = JSON.stringify(result.oauth.userInfo, undefined, 2);
        return profile
    } catch {
        // window.location.href = window.location.origin + '/oauth';
        return false
    }
}

export const logoutUser = async () => {
    localStorage.removeItem('emailProfile');
    localStorage.removeItem('googleProfile');
    await magic.user.logout();

};