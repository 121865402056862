import React, { useState } from "react";
import "./MessagePopUp.scss";
import sentIcon from "../../../../../assets/Dashboard/send.svg";
import eyeIcon from "../../../../../assets/Dashboard/eye.svg";
import clicksIcon from "../../../../../assets/Dashboard/clicks.svg";
import repliesIcon from "../../../../../assets/Dashboard/replies.svg";
import clock from "../../../../../assets/Dashboard/clock.svg";
import close from "../../../../../assets/SignUpProcess/x.svg";
import userReplyIcon from "../../../../../assets/Dashboard/user-reply.svg";
import { Avatar } from "antd";
import Carousel from "nuka-carousel";
import Lightbox from "../../../../../components/Lightbox/Lightbox";
import ChatView from "../../ChatView/ChatView";
import chevron from "../../../../../assets/chevron-right.svg";

export default function MessagePopUp({ visible, setVisible, images }) {
  const [chatView, setChatView] = useState(false);
  const [lightboxState, setLightboxState] = useState({
    visible: false,
    slideIndex: 0,
    images: [],
  });
  const Reply = ({}) => {
    return (
      <div className="reply">
        <Avatar
          icon={<img src={userReplyIcon} />}
          style={{ backgroundColor: "#F4F4F5", padding: "8px" }}
          size={40}
        />
        <div className="reply-body">
          <span>123 456 789</span>
          <div>Message message message message message message</div>
        </div>
        <img
          className="replies-icon"
          src={repliesIcon}
          onClick={() => setChatView(true)}
        />
      </div>
    );
  };
  if (visible) {
    return (
      <>
        <div
          className="message-popup-wrapper"
          onClick={() => (chatView ? setChatView(false) : setVisible(false))}
        >
          <div className="message-popup" onClick={(e) => e.stopPropagation()}>
            {chatView ? (
              <ChatView setLightboxState={setLightboxState} />
            ) : (
              <>
                <div className="column-1">
                  <div className="message-header">
                    <span>Message #1</span>
                    <div>Sent to All members</div>
                  </div>
                  <div className="message-body">
                    SPECIAL ANNOUNCEMENT*
                    <br />
                    Clearing begins this FALL + WINTER ➰🍁❄️🌐 <br />
                    <br />
                    If you feel like 🔥 or act like 🧊 you will see yourself in
                    this show. Tickets tinyurl.com/5n8zyhh7
                    {images.length > 0 && (
                      <div className="carousel">
                        <Carousel
                          renderCenterLeftControls={({
                            previousDisabled,
                            previousSlide,
                          }) =>
                            !previousDisabled && (
                              <div
                                className="carousel-controls control-left"
                                onClick={previousSlide}
                                disabled={previousDisabled}
                              >
                                <img src={chevron} />
                              </div>
                            )
                          }
                          renderCenterRightControls={({
                            nextDisabled,
                            nextSlide,
                          }) =>
                            !nextDisabled && (
                              <div
                                className="carousel-controls"
                                onClick={nextSlide}
                                disabled={nextDisabled}
                              >
                                <img src={chevron} />
                              </div>
                            )
                          }
                        >
                          {images.map((image, i) => (
                            <div className="carousel-slide">
                              <img
                                src={image}
                                onClick={() =>
                                  setLightboxState({
                                    ...lightboxState,
                                    visible: true,
                                    images: images,
                                    slideIndex: i,
                                  })
                                }
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  <div className="message-footer">
                    <div className="tile-stat">
                      <div>
                        <img src={sentIcon} /> Sent to
                      </div>
                      180
                    </div>
                    <div className="tile-stat">
                      <div>
                        <img src={eyeIcon} /> Views
                      </div>
                      40
                    </div>
                    <div className="tile-stat">
                      <div>
                        <img src={clicksIcon} /> Clicks
                      </div>
                      20
                    </div>
                  </div>
                </div>
                <div className="column-2">
                  <div className="message-header">
                    <h1>200 Replies</h1>
                  </div>
                  <div className="message-body">
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((el, i) => (
                      <Reply key={i} />
                    ))}
                  </div>
                </div>
              </>
            )}
            <img
              className="message-popup-close"
              src={close}
              onClick={() =>
                chatView ? setChatView(false) : setVisible(false)
              }
            />
          </div>
        </div>
        <Lightbox {...lightboxState} setLightboxState={setLightboxState} />
      </>
    );
  } else return null;
}
