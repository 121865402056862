const moment = require('moment-timezone');

export const getTZ = () => {
    // Get current timezone offset in minutes
    const timezoneOffsetInMinutes = moment().utcOffset();

    // Convert offset to hours and minutes
    const hours = Math.floor(Math.abs(timezoneOffsetInMinutes) / 60);
    const minutes = Math.abs(timezoneOffsetInMinutes) % 60;

    // Determine the sign of the offset
    const sign = timezoneOffsetInMinutes >= 0 ? '+' : '-';

    // Format the offset as a string in GMT format (e.g., GMT+05:30)
    const gmtOffset = `GMT${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    ;
    return gmtOffset
}
