import React from "react";
import IndicatorButton from "../IndicatorButton/IndicatorButton";
import "./InstructionOverlay.scss";

export default function InstructionOverlay({ navigate }) {
  return (
    <div className="instruction-overlay">
      <div
        className="indicator left-indicator"
        onClick={() => {
          navigate("prev");
        }}
      >
        <IndicatorButton type={"Back"} action={() => navigate("prev")} />
      </div>
      <div
        className="indicator right-indicator"
        onClick={() => {
          navigate("next");
        }}
      >
        <IndicatorButton type={"Next"} action={() => navigate("next")} />
      </div>
    </div>
  );
}
