import React from "react";
import "./LinksEditor.scss";

export default function LinksEditor({ links, editLinks }) {
  return (
    <div className="links-editor">
      <label>Add your links</label>
      <div className="links">
        <div className="link-item">
          <input
            value={links?.WhatsApp}
            onChange={(e) => editLinks("WhatsApp", `${e.target.value}`)}
            placeholder="WhatsApp ex: +44111111111"
          />
        </div>
        <div className="link-item">
          <input
            value={links?.Spotify}
            onChange={(e) => editLinks("Spotify", `${e.target.value}`)}
            placeholder="Spotify ex: https://spotify.com"
          />
        </div>
        <div className="link-item">
          <input
            value={links?.["Apple Music"]}
            onChange={(e) => editLinks("Apple Music", `${e.target.value}`)}
            placeholder="Apple Music ex: https://https://music.apple.com/"
          />
        </div>
        <div className="link-item">
          <input
            value={links?.Merch}
            onChange={(e) => editLinks("Merch", `${e.target.value}`)}
            placeholder="Merch ex: https://example.com"
          />
        </div>
        <div className="link-item">
          <input
            value={links?.YouTube}
            onChange={(e) => editLinks("YouTube", `${e.target.value}`)}
            placeholder="YouTube ex: https://youtube.com/"
          />
        </div>
        <div className="link-item">
          <input
            value={links?.Events}
            onChange={(e) => editLinks("Events", `${e.target.value}`)}
            placeholder="Events ex: https://events.com/"
          />
        </div>
      </div>
    </div>
  );
}
