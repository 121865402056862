import requestDM from '../requestDM'

export const getWhatsappFlows = async () => {
    var response
    try {
        response = await requestDM(null, `/apiv2/customer/whatsapp/messagebird/flowStats`, 'GET');
        if (response.result) {
            return response.result
        }
        return response;
    } catch (error) {
        return []
    }
}

export const getWhatsappFlowMessage = async (flowId) => {
    var response
    try {
        response = await requestDM(null, `/apiv2/customer/whatsapp/messagebird/${flowId}/converstaions`, 'GET');
        if (response.result) {
            return response.result
        }
        return response;
    } catch (error) {
        return false
    }
}

export const getWhatsappMessagesPerPhoneNumber = async (flowId, phoneNr) => {
    var response
    try {
        response = await requestDM(null, `/apiv2/customer/whatsapp/messagebird/${flowId}/converstaions/${phoneNr}`, 'GET');
        if (response.result) {
            return response.result
        }
        return response;
    } catch (error) {
        return false
    }
}