import React, { useRef, useState } from "react";
import { SketchPicker } from "react-color";
import "./RaffleForm.scss";
import { Tooltip, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { s3Upload } from "../../../../../helpers/s3Upload";
import { Editor, EditorState } from "draft-js";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlinesButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  HeadlineOneButton,
} from "draft-js-buttons";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import { MdAlternateEmail, MdShortText } from "react-icons/md";
import { IoCallOutline, IoCloseOutline } from "react-icons/io5";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import EventQuestion from "./EventQuestion/EventQuestion";
import CustomQuestion from "./CustomQuestion/CustomQuestion";
import ButtonPreview from "./ButtonPreview/ButtonPreview";
import Button from "../../../../../components/SignupProcess/Button/Button";
import AutomaticallyClose from "./AutomaticallyClose/AutomaticallyClose";

export default function RaffleForm({
  raffleData,
  setRaffleData,
  showPicker,
  setShowPicker,
  isStopped,
}) {
  const [loading, setLoading] = useState(false);
  const toolbarPlugin = createToolbarPlugin({
    structure: [
      BoldButton,
      ItalicButton,
      UnderlineButton,
      CodeButton,
      HeadlineOneButton,
      UnorderedListButton,
      OrderedListButton,
      BlockquoteButton,
    ],
  });
  const { Toolbar } = toolbarPlugin;
  const editorRef = useRef();

  const notEditableFields = [
    { id: "email", name: "Email", icon: MdAlternateEmail },
    { id: "full-name", name: "Full Name", icon: MdShortText },
    {
      id: "phone-number",
      name: "Phone Number (this field is auto populated)",
      icon: IoCallOutline,
    },
  ];
  const beforeUpload = (file) => {
    setLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    s3Upload(
      file,
      {},
      () => {},
      0,
      [],
      (url) => {
        setRaffleData({ ...raffleData, raffleFormImg: url[0] });
      }
    );
    setLoading(false);
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div className="raffle-form-editor" onClick={(e) => e.stopPropagation()}>
      <div className="raffle-details">
        <h3>Raffle details</h3>
        <div className="access-page-info">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            disabled={!!isStopped}
          >
            {raffleData.raffleFormImg ? (
              <img
                src={raffleData.raffleFormImg}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
            {raffleData.raffleFormImg && !isStopped && (
              <div
                className={"remove-access-img"}
                onClick={(e) => {
                  if (!isStopped) {
                    e.stopPropagation();
                    e.preventDefault();
                    setRaffleData({ ...raffleData, raffleFormImg: null });
                  }
                }}
              >
                <IoCloseOutline />
              </div>
            )}
          </Upload>
          <input
            disabled={!!isStopped}
            className="access-title"
            placeholder="Name of raffle"
            value={raffleData.name}
            onChange={(e) =>
              setRaffleData({ ...raffleData, name: e.target.value })
            }
          />
          <div
            className={
              !isStopped ? "richtext-raffle" : "richtext-raffle-blacked"
            }
            onClick={() => (!isStopped ? editorRef.current.focus() : "")}
          >
            <Editor
              editorState={raffleData.raffleDetails}
              placeholder="Add details about the raffle."
              plugins={[toolbarPlugin]}
              ref={editorRef}
              onChange={(e) =>
                !isStopped
                  ? setRaffleData({
                      ...raffleData,
                      raffleDetails: e,
                    })
                  : ""
              }
            />
            <div className="toolbar">
              <Toolbar>
                {(externalProps) => {
                  externalProps.getEditorState = () => {
                    return raffleData.raffleDetails;
                  };
                  externalProps.setEditorState = (state) =>
                    !isStopped
                      ? setRaffleData({ ...raffleData, raffleDetails: state })
                      : "";
                  return (
                    <>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <HeadlineOneButton {...externalProps} />
                      <CodeButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      <BlockquoteButton {...externalProps} />
                    </>
                  );
                }}
              </Toolbar>
            </div>
          </div>
        </div>
      </div>

      {notEditableFields.map((field) => (
        <div key={field.id} className="not-editable-fields">
          <Tooltip title={"Not editable"}>
            <div className="not-editable-field">
              <field.icon />
              <div className="placeholder">{field.name}</div>
            </div>
          </Tooltip>
        </div>
      ))}
      <EventQuestion
        isStopped={isStopped}
        raffleData={raffleData}
        setRaffleData={setRaffleData}
      />
      <CustomQuestion
        isStopped={isStopped}
        raffleData={raffleData}
        setRaffleData={setRaffleData}
      />
      <AutomaticallyClose
        isStopped={isStopped}
        raffleData={raffleData}
        setRaffleData={setRaffleData}
      />
    </div>
  );
}
