import requestDM from '../requestDM'

export const addBotConfig = async ({ data, artistId }) => {
    var response;
    try {
        response = await requestDM({ bot_config: { ...data }, unsaved_bot_config: { ...data }, artist_id: artistId }, `/dmme/artist`, 'PATCH')
        return response
    } catch (error) {

        return { error }
    }
}