import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Tooltip } from "antd";
import moment from "moment";
import Carousel from "nuka-carousel/lib/carousel";
import React, { useEffect, useState } from "react";
import Loading from "../../../../SignupProcess/Loading/Loading";
import { getThreadMessages } from "../../../../api/dmmeendpoints/getThreadMessages";
import clicksIcon from "../../../../assets/Dashboard/clicks.svg";
import clock from "../../../../assets/Dashboard/clock.svg";
import eyeIcon from "../../../../assets/Dashboard/eye.svg";
import sentIcon from "../../../../assets/Dashboard/send.svg";
import chevron from "../../../../assets/chevron-right.svg";
import Lightbox from "../../../../components/Lightbox/Lightbox";
import { isImageLink } from "../../../../helpers/detectIfImage";
import MessagePopUp from "./MessagePopUp/MessagePopUp";
import "./MessageTiles.scss";
import { getPlaybookById } from "../../../../api/dmmeendpoints/playbook";
import Tile from "./Tile/Tile";

export default function MessageTiles() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popUpImages] = useState([]);
  const [data, setData] = useState([]);
  const { user } = useAuth0();

  useEffect(() => {
    const getThreadData = async () => {
      if (user) {
        setLoading(true);
        const response = await getThreadMessages(user.sub);
        setData(response);
        setLoading(false);
      }
    };
    getThreadData();
  }, [user]);

  return (
    <>
      <div className="message-tiles">
        {loading ? (
          <Loading />
        ) : (
          data
            ?.sort(
              (a, b) =>
                moment(b.createdAt).format("x") -
                moment(a.createdAt).format("x")
            )
            ?.map((a) => {
              const linkStart = a?.thread_metadata?.message.indexOf("http");
              const linksNotChecked = a?.thread_metadata?.message
                .substring(linkStart)
                .split("\n")
                .join(",")
                .split(" ")
                .join(",")
                .split(",");

              const images = [];
              const raffleIds = [];
              for (let i = 0; i < linksNotChecked.length; i++) {
                const el = linksNotChecked[i];
                if (el.includes("unlock")) {
                  const raffleId = el.substring(el.lastIndexOf("/") + 1);
                  raffleIds.push(raffleId);
                } else if (linkStart != -1) {
                  images.push(el);
                }
              }

              return (
                <Tile
                  key={a.msg_thread_id}
                  id={a.msg_thread_id}
                  avatar={user?.picture}
                  title={a?.thread_metadata?.title}
                  message={a?.thread_metadata?.message}
                  sent={a?.thread_metadata?.sendTo || []}
                  eyes={a?.thread_metadata?.delivered || 0}
                  clicks={a?.thread_metadata?.clicks || 0}
                  replies={a?.thread_metadata?.fan_replies?.length || 0}
                  date={moment(new Date(a?.createdAt))}
                  metadata={a?.thread_metadata}
                  images={
                    images.length !== 0
                      ? images.filter((link) => link.includes("iindy"))
                      : null
                  }
                  raffleIds={raffleIds.length !== 0 ? raffleIds : null}
                />
              );
            })
        )}
      </div>
      <MessagePopUp
        visible={visible}
        setVisible={setVisible}
        images={popUpImages}
      />
    </>
  );
}
