import React from "react";
import Upload from "rc-upload";
import "./ImageSelector.scss";

export default function ImageSelector({ label, uploadFunction }) {
  return (
    <Upload beforeUpload={uploadFunction} className={"image-selector"}>
      <i class="fa-solid fa-plus"></i>
      <label>{label}</label>
    </Upload>
  );
}
