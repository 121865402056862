import React, { useState, useEffect } from "react";
import "./ArtistsLinks.scss";
import Whatsapp from "../../assets/whatsapp-link.svg";
import AppleMusic from "../../assets/apple-music-link.svg";
import MerchLink from "../../assets/merch-link.svg";
import YouTubeLink from "../../assets/youtube-link.svg";
import SpotifyLink from "../../assets/spotify-link.svg";
import Ticket from "../../assets/ticket-link.svg";

export default function ArtistsLinks({ links }) {
  const [length, setLength] = useState(0);
  useEffect(() => {
    if (links) {
      setLength(Object.keys(links).filter((a) => links[a] != "").length);
    }
  }, [links]);

  const renderLinks = () => {
    if (links) {
      const Arr = Object.keys(links);
      return Arr.map((link) => {
        if (links[link]) {
          return <LinkItem logo={link} url={links[link]} />;
        }
      });
    }
  };
  return (
    <div className="artists-links-wrapper">
      <b className="links-header">discover my other spaces </b>
      <div className={"links" + `${length <= 5 ? " center" : ""}`}>
        {renderLinks()}
      </div>
    </div>
  );
}

const LinkItem = ({ logo, url }) => {
  const getLogo = (logo) => {
    switch (logo) {
      case "Spotify":
        return <img src={SpotifyLink} />;
      case "WhatsApp":
        return <img src={Whatsapp} />;
      case "Events":
        return <img src={Ticket} />;
      case "Merch":
        return <img src={MerchLink} />;
      case "YouTube":
        return <img src={YouTubeLink} />;
      case "Apple Music":
        return <img src={AppleMusic} />;
      default:
        break;
    }
  };
  return (
    <div className="artist-link-item">
      <div
        className="artist-link-item-logo"
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        {getLogo(logo)}
      </div>
      <b>{logo}</b>
    </div>
  );
};
