import React from "react";
import CountryList from "country-list-with-dial-code-and-flag";

function FlagComponent({ phoneNumber }) {
  const countryCode =
    CountryList.findLast((el) => phoneNumber?.includes(el.dial_code))?.code ||
    "GB";
  const importSvg = (countryCode) => {
    try {
      return require(`../../assets/flags/${countryCode}.svg`);
    } catch (error) {
      console.error(
        `Failed to load flag for country code ${countryCode}:`,
        error
      );
      return null;
    }
  };

  const Flag = (
    <img className="flag-img" src={importSvg(countryCode)} alt="flag" />
  );

  if (!Flag) {
    return <div>Flag not found</div>;
  }

  return Flag;
}

export default FlagComponent;
