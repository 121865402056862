import requestDM from '../requestDM'

export const createArtist = async (data) => {
    var response;
    try {
        response = await requestDM(data, `/dmme/artist`, 'POST')
        return response
    } catch (error) {

    }
}