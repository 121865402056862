import axios from "axios";
export const baseURL = process.env.REACT_APP_BASE_URL;
// PROD https://yp8vs4qfw8.execute-api.eu-west-2.amazonaws.com/prod
// DEV https://otvj8nlu5k.execute-api.eu-west-2.amazonaws.com/dev

const customAxios = axios.create({
  timeout: 130000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status == 422;
  }
});

customAxios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default function HandleRequest(data, resourceName, action, token = null) {
  customAxios.defaults.baseURL = baseURL;

  var config = requestBuilder(data, resourceName, action);
  if (token) {
    customAxios.defaults.headers = { ...customAxios.defaults.headers, "Authorization": `Bearer ${token}` }
  }
  const request = customAxios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
  return request;
}
function requestBuilder(payload, resourceName, action) {
  var method = methodAction(action);

  var request = {
    method: methodAction(action),
    url: resourceName,
    data: payload
  };
  if (method === "GET") {
    delete request.data;
  }

  return request;
}

function methodAction(action) {
  switch (action) {
    case "index":
      return "GET";
    case "create":
      return "POST";
    case "update":
      return "PATCH";
    case "send":
      return "PUT";
    case "destroy":
      return "DELETE";
    default:
      return "OPTIONS";
  }
}
