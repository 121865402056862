import { Modal } from "antd";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { BsReply } from "react-icons/bs";
import { FaMicrophone } from "react-icons/fa6";
import {
  FiCamera,
  FiChevronDown,
  FiChevronLeft,
  FiMic,
  FiPlus,
  FiX,
} from "react-icons/fi";
import { IoIosDocument } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { MdPhotoSizeSelectActual } from "react-icons/md";
import { ReactComponent as Signal } from "../../../../../assets/signal-levels.svg";
import { ReactComponent as VerifySign } from "../../../../../assets/verify-sign.svg";
import Button from "../../../../../components/SignupProcess/Button/Button";
import Bubble from "./Bubble/Bubble";
import "./WhatsappUI.scss";

const attachmentOptions = [
  { label: "Photos", icon: MdPhotoSizeSelectActual },
  { label: "Document", icon: IoIosDocument },
  { label: "Audio", icon: FaMicrophone },
];

const replyOptions = [
  { label: "Send as Receiver", icon: <BsReply color="#25D366" /> },
  { label: "Send as myself", icon: <AiOutlineSend color="#007AFF" /> },
];
const replyOptionsType = ["Buttons", "Menu", "Text"];

export default function WhatsappUI({
  getUpdatedPathways,
  getDataToStore,
  flowToStoreData,
}) {
  const [textarea, setTextarea] = useState("");
  const [showAttachments, setShowAttachments] = useState(false);
  const [replyOptionsModal, setReplyOptionsModal] = useState(false);
  const [showSenderReceiverModal, setShowSenderReceiverModal] = useState(false);
  const [sendAs, setSendAs] = useState(replyOptions[1].label);
  const [replyOptionType, setReplyOptionType] = useState({ 0: "Buttons" });
  const [activePathway, setActivePathway] = useState(false);
  const [replyPathways, setReplyPathways] = useState({});
  const [isEditing, setIsEditing] = useState(null);
  const [bubbleMessages, setBubbleMessages] = useState([]);

  useEffect(() => {
    if (
      (flowToStoreData.bubbleMessages?.length > 0 ||
        flowToStoreData.replyPathways) &&
      bubbleMessages.length === 0
    ) {
      setReplyPathways(flowToStoreData.replyPathways);
      setBubbleMessages(flowToStoreData.bubbleMessages);
    }
  }, [flowToStoreData]);

  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  const replyPathRef = useRef();

  const autoGrow = (e) => {
    const el = e.target;
    if (!el.value) {
      return (el.style.height = "28px");
    }
    el.style.height = el.scrollHeight + "px";
  };

  const showAttachmentsToggle = (e) => {
    e.stopPropagation();
    setShowAttachments(true);
  };

  const addReplyPath = () => {
    if (replyPathRef.current.value) {
      setReplyPathways({
        ...replyPathways,
        [replyOptionsModal]: [
          ...(replyPathways[replyOptionsModal] || []),
          { text: replyPathRef.current.value, messages: [] },
        ],
      });
      replyPathRef.current.value = "";
    }
  };
  const deleteReplyPath = (index) => {
    const newReplyPathways = { ...replyPathways }[replyOptionsModal];
    newReplyPathways.splice(index, 1);
    setReplyPathways({
      ...replyPathways,
      [replyOptionsModal]: newReplyPathways,
    });
  };

  const deleteBubble = (id) => {
    const newBubbles = [...bubbleMessages];
    const index = newBubbles.findIndex((msg) => msg.id === id);
    newBubbles.splice(index, 1);
    setReplyPathways({ ...replyPathways, [id]: [] });
    setBubbleMessages(newBubbles);
  };

  const addNewBubble = () => {
    if (activePathway) {
      const newPathways = { ...replyPathways };
      const indexToAdd = newPathways[activePathway[0]].findIndex(
        (msg) => msg.text === activePathway[1]
      );
      newPathways[activePathway[0]][indexToAdd].messages = [
        ...(newPathways[activePathway[0]][indexToAdd]?.messages || []),
        { id: nanoid(4), message: textarea, from: sendAs },
      ];
      setReplyPathways(newPathways);
      document
        .getElementById(
          `whatsapp-reply-option-${activePathway[0]}-${indexToAdd}`
        )
        .click();
      setTextarea("");
      setIsEditing(null);
      return;
    }
    if (isEditing !== null) {
      const newMessages = [...bubbleMessages];
      const index = bubbleMessages.findIndex((msg) => msg.id === isEditing);
      newMessages[index].message = textarea;
      setBubbleMessages(newMessages);
      setIsEditing(null);
      setTextarea("");
    } else {
      const newBubble = {
        id: bubbleMessages.length,
        message: textarea,
        from: sendAs,
        options: [],
      };
      setBubbleMessages([...bubbleMessages, newBubble]);
      setTextarea("");
    }
  };

  useEffect(() => {
    console.log(replyPathways, bubbleMessages, "FRANKOPATH");
    const timer = setTimeout(() => {
      getUpdatedPathways(replyPathways);
      getDataToStore({ data: { replyPathways, bubbleMessages } });
    }, 350);
    return () => {
      clearTimeout(timer);
    };
  }, [replyPathways, bubbleMessages]);

  console.log({ replyPathways });

  return (
    <>
      <div
        className="whatsapp-ui-container"
        onClick={() => setShowAttachments(false)}
      >
        <div className="whatsapp-header">
          <div className="status-bar">
            <b>{moment().format("HH:mm")}</b>
            <Signal />
          </div>
          <div className="whatsapp-contact-header">
            <div>
              <FiChevronLeft />
            </div>
            <div className="whatsapp-profile-picture">
              <img
                src={dbUser.bot_config.contact_info.profile_picture}
                alt="profile"
              />
            </div>
            <div className="whatsapp-profile-name">
              <h3>
                {dbUser.full_name}
                <VerifySign />
              </h3>
              <b>online</b>
            </div>
          </div>
        </div>
        <div className="whatsapp-bubble-holders">
          {bubbleMessages.map((msg, i) => (
            <Bubble
              key={i}
              id={msg.id}
              index={i}
              message={msg.message}
              options={replyPathways[msg.id]}
              setReplyOptionsModal={setReplyOptionsModal}
              setIsEditing={setIsEditing}
              setTextToEdit={setTextarea}
              deleteBubble={deleteBubble}
              from={msg.from}
              bubbleMessages={bubbleMessages}
              setBubbleMessages={setBubbleMessages}
              activePathway={activePathway}
              setActivePathway={setActivePathway}
            />
          ))}
        </div>
        <div className="whatsapp-inputs">
          <div className="whatsapp-input-tools">
            <div className="whatsapp-action">
              <FiPlus color="#007AFF" onClick={showAttachmentsToggle} />
            </div>
            <textarea
              rows={1}
              onInput={autoGrow}
              value={textarea}
              onChange={(e) => setTextarea(e.target.value)}
            />
            {textarea ? (
              <div
                className={`pill-shape-send ${
                  sendAs === replyOptions[0].label ? "receiver-pill" : ""
                }`}
              >
                {sendAs === replyOptions[0].label ? (
                  <BsReply color="white" size={13} onClick={addNewBubble} />
                ) : (
                  <IoSend color="white" size={13} onClick={addNewBubble} />
                )}
                <FiChevronDown
                  color="white"
                  size={13}
                  onClick={() => setShowSenderReceiverModal(true)}
                />
                {showSenderReceiverModal && (
                  <div className="sender-receiver-modal">
                    {replyOptions.map((option, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setSendAs(option.label);
                          setShowSenderReceiverModal(false);
                        }}
                      >
                        {option.label}
                        {option.icon}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  className="whatsapp-action"
                  onClick={showAttachmentsToggle}
                >
                  <FiCamera color="#007AFF" />
                </div>
                <div
                  className="whatsapp-action"
                  onClick={showAttachmentsToggle}
                >
                  <FiMic color="#007AFF" />
                </div>
              </>
            )}
          </div>
          {showAttachments && (
            <div className="attach-options">
              {attachmentOptions.map((option, i) => (
                <div key={i} className="attach-option">
                  <div className="attach-icon">
                    <option.icon color="#007AFF" size={24} />
                  </div>
                  {option.label}
                </div>
              ))}
            </div>
          )}
          <div className="iphone-gesture-bar" />
        </div>
        {showSenderReceiverModal && (
          <div
            className="sender-receiver-backdrop"
            onClick={() => setShowSenderReceiverModal(false)}
          />
        )}
      </div>
      <Modal
        open={replyOptionsModal !== false}
        onCancel={() => setReplyOptionsModal(false)}
        className="reply-options-modal"
        footer={null}
      >
        <h3>REPLY OPTIONS</h3>
        <div className="reply-options">
          {replyOptionsType.map((option, i) => (
            <div
              key={i}
              className={`reply-option ${
                replyOptionType[replyOptionsModal] === option
                  ? "active-reply-option"
                  : ""
              }`}
              onClick={() => {
                setReplyOptionType({
                  ...replyOptionType,
                  [replyOptionsModal]: option,
                });
                if (option === "Buttons") {
                  setReplyPathways({
                    ...replyPathways,
                    [replyOptionsModal]: replyPathways[
                      replyOptionsModal
                    ]?.slice(0, 2),
                  });
                }
              }}
            >
              {option}
            </div>
          ))}
        </div>
        <div className="reply-pathways">
          {replyPathways[replyOptionsModal]?.map((reply, i) => (
            <div key={i} className="reply-path">
              <input
                value={reply.text}
                onChange={(e) => {
                  const newPathways = { ...replyPathways };
                  newPathways[replyOptionsModal][i].text = e.target.value;
                  setReplyPathways(newPathways);
                }}
              />
              <div
                className="reply-path-icon"
                onClick={() => deleteReplyPath(i)}
              >
                {<FiX size={24} />}
              </div>
            </div>
          ))}
          {(replyPathways[replyOptionsModal] === undefined ||
            replyPathways[replyOptionsModal]?.length <
              (replyOptionType[replyOptionsModal] === "Buttons" ? 2 : 10)) && (
            <div className="reply-path">
              <input ref={replyPathRef} />
              <div className="reply-path-icon" onClick={addReplyPath}>
                {<FiPlus color="#007AFF" size={24} />}
              </div>
            </div>
          )}
        </div>
        <div className="reply-path-save">
          <Button
            label={"SAVE"}
            border="none"
            action={() => setReplyOptionsModal(false)}
          />
        </div>
      </Modal>
    </>
  );
}
