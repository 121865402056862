import React, { useEffect, useRef, useState } from "react";
import "./ChatView.scss";
import moment from "moment-timezone";
import {
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
  BiLockAlt,
  BiSmile,
  BiX,
} from "react-icons/bi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { Input } from "antd";
import Carousel from "nuka-carousel/lib/carousel";
import Lightbox from "../../../../../components/Lightbox/Lightbox";
import { getPlaybookById } from "../../../../../api/dmmeendpoints/playbook";
import { Bubble } from "./ChatBubble";
import { s3Upload } from "../../../../../helpers/s3Upload";
import { Circle } from "rc-progress";
import EmojiPicker from "emoji-picker-react";
import _ from "lodash";
import sendTwilioSMS, {
  newSmsSend,
} from "../../../../../api/endpoints/sendTwilioSMS";
import FlagComponent from "../../../../../components/Flag/Flag";
import { getWhatsappMessagesPerPhoneNumber } from "../../../../../api/dmmeendpoints/getWhatsappFlows";

export default function ChatView({
  messages,
  artistBroadcast,
  broadcastDate,
  images,
  raffleIds,
  id,
  phoneNumber,
  formattedReplies,
  setActiveChat,
  isFlow,
  contact,
  contacts,
}) {
  const formattedMessages = messages || [];
  console.log(formattedMessages, "FORMATTTTTT");
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [attached, setAttached] = useState([]);
  const [progress, setProgress] = useState([]);
  const [shortUrls, setShortUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [newMessages, setNewMessages] = useState([]);
  const [flowMessages, setFlowMessages] = useState([]);
  const [showPrevBtn, setShowPrevBtn] = useState(true);
  const [showNextBtn, setShowNextBtn] = useState(false);

  const inputFile = useRef(null);
  const uploadFile = async (e) => {
    const files = e.target.files;
    const dataUrl = URL.createObjectURL(files[0]);
    setAttached([...attached, dataUrl]);
    s3Upload(
      files[0],
      progress,
      setProgress,
      attached.length,
      shortUrls,
      setShortUrls
    );
  };
  const removeImageAtIndex = (index) => {
    const clonedAttached = [...attached];
    const clonedProgress = [...progress];
    const clonedShortUrls = [...shortUrls];
    clonedAttached.splice(index, 1);
    clonedShortUrls.splice(index, 1);
    clonedProgress.splice(index, 1);
    setAttached(clonedAttached);
    setProgress(clonedProgress);
    setShortUrls(clonedShortUrls);
  };

  const resetState = () => {
    setMessage("");
    setShortUrls([]);
    setProgress([]);
    setAttached([]);
  };
  useEffect(() => {
    if (phoneNumber) {
      resetState();
      setNewMessages([]);
    }
  }, [phoneNumber]);
  const canBeSent = shortUrls.length > 0 || message.length > 0;
  const sendSMS = () => {
    if (canBeSent) {
      const newMsg = `${message} ${shortUrls}`;
      newSmsSend(
        JSON.parse(localStorage.getItem("dbUser")).claimed_phone_number,
        phoneNumber,
        newMsg
      );
      setNewMessages([
        ...newMessages,
        { message: newMsg, date: moment(), images: [...shortUrls] },
      ]);
      resetState();
    }
  };
  let counter = 0;
  const debounceDown = _.debounce((element) => {
    navigate("next", element);

    counter = 0;
  }, 10);
  const debounceUp = _.debounce((element) => {
    navigate("prev", element);
    counter = 0;
  }, 10);

  const onWheel = (e, element) => {
    if (
      element.scrollHeight ===
      Math.round(element.scrollTop + element.clientHeight)
    ) {
      if (!showNextBtn) {
        setShowNextBtn(true);
      }
    } else {
      setShowNextBtn(false);
    }
    if (element.scrollTop === 0) {
      if (!showPrevBtn) {
        setShowPrevBtn(true);
      }
    } else {
      setShowPrevBtn(false);
    }
  };

  const navigate = (where, element) => {
    const numbers = isFlow
      ? contacts.map((c) => c.contact_phone_number)
      : Object.keys(formattedReplies);
    const oldPosition = numbers.indexOf(
      isFlow ? contact.contact_phone_number : phoneNumber
    );
    let newPosition = oldPosition;

    const listItemId = `number-list-item-${contacts[newPosition]["contact_phone_number"]}`;

    if (where === "prev") {
      newPosition = newPosition - 1 >= 0 ? newPosition - 1 : 0;
    } else {
      newPosition =
        newPosition + 1 <= numbers.length - 1
          ? newPosition + 1
          : numbers.length - 1;
    }
    if (newPosition !== oldPosition) {
      if (where === "prev") {
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 0);
      } else if (where === "next") {
        setTimeout(() => {
          element.scrollTop = 0;
        }, 0);
      }
      setActiveChat(numbers[newPosition]);
    }

    const itemToScroll = document.getElementById(listItemId);
    if (itemToScroll) {
      itemToScroll.scrollIntoView();
    }
  };
  const type = "whatsapp";
  const isWhatsapp = isFlow && type === "whatsapp";
  const isInstagram = isFlow && type === "instagram";

  const {
    last_msg_text,
    contact_phone_number,
    message_count,
    contact_display_name,
    msg_received_time,
  } = contact || {};

  useEffect(() => {
    const loadMessages = async () => {
      const response = await getWhatsappMessagesPerPhoneNumber(
        id,
        contact_phone_number
      );
      if (response.converstaions) {
        setFlowMessages(
          response.converstaions
            .sort((a, b) =>
              moment(a.msg_received_time).diff(moment(b.msg_received_time))
            )
            .map((item) => {
              return {
                id: item.contact_phone_number, // Generate a random ID or use a unique identifier from your data
                from: item.direction === "incoming" ? "them" : "me", // Set a default "from" number or extract it from your data
                to: item.contact_phone_number, // Set the "to" number to the contact phone number
                account_sid: "AC343fee77a67890d0117a2109a76b2cec", // Set a default account SID or extract it from your data
                message_sid: "", // Set a default message SID or leave it empty
                from_country: "US", // Set a default "from" country or extract it from your data
                from_state: "",
                video: item.content_json?.video?.url || "",
                audio: item.content_json?.audio?.url || "",
                buttons: item.content_json?.interactive?.action?.buttons || [],
                list:
                  item.content_json?.interactive?.type === "list"
                    ? {
                        button: item.content_json?.interactive?.action?.button,
                        listItems:
                          item.content_json?.interactive?.action?.sections?.[0]
                            ?.rows,
                      }
                    : null,
                images: item.content_json?.image
                  ? [item.content_json.image.url]
                  : false, // Set a default "from" state or leave it empty
                data: {
                  To: item.contact_phone_number, // Set the "To" number to the contact phone number
                  Body:
                    item.msg_text ||
                    item?.content_json?.image?.caption ||
                    item?.content_json?.video?.caption ||
                    item?.content_json?.audio?.caption ||
                    item.content_json?.interactive?.body?.text ||
                    "", // Set the "Body" to the message text
                  From: item.direction === "incoming" ? "them" : "me", // Set a default "from" number or extract it from your data
                  // Add more properties from your data as needed
                },
                createdAt: item.msg_received_time, // Set the createdAt timestamp to the msg_received_time
                updatedAt: item.msg_received_time, // Set the updatedAt timestamp to the msg_received_time
              };
            })
        );
      }
    };

    if (isFlow && contact_phone_number) {
      loadMessages();
      setShowNextBtn(false);
    }
  }, [isFlow, contact_phone_number]);

  return (
    <>
      <div
        className={`chat-view-container ${
          isWhatsapp ? "whatsapp-ui" : isInstagram ? "instagram-ui" : ""
        }`}
        onClick={() => setEmojiOpen(false)}
      >
        {
          <div className="hot-corner" style={{ opacity: showPrevBtn ? 1 : 0 }}>
            <div
              className="chat-navigate-btn"
              onClick={() =>
                navigate("prev", document.getElementById("chat-view-scroll"))
              }
            >
              <BiChevronUp />
            </div>
          </div>
        }
        <div className={`chat-header`}>
          <FlagComponent phoneNumber={contact_phone_number || phoneNumber} />
          <div className="chat-header-content">
            <div className="chat-header-name">
              {contact_display_name || contact_phone_number || phoneNumber}
            </div>
            <div className="chat-header-number">
              {contact_phone_number || phoneNumber}
            </div>
          </div>
        </div>
        <div
          id="chat-view-scroll"
          className="chat-view-scroll"
          onWheelCapture={(e) =>
            onWheel(e, document.getElementById("chat-view-scroll"))
          }
        >
          {!isFlow && (
            <>
              <div class="chat-separator">
                {moment(broadcastDate).format("MMM DD, YYYY")}
              </div>
              <Bubble
                message={artistBroadcast}
                date={broadcastDate}
                from="me"
                images={images}
                raffleIds={raffleIds}
              />
            </>
          )}
          {[...(isFlow ? flowMessages : formattedMessages)]?.map(
            (msg, i) =>
              (msg.images || msg.data.Body || msg.video || msg.audio) && (
                <>
                  {moment(msg.createdAt).diff(
                    moment(
                      (isFlow ? flowMessages : formattedMessages)[i - 1]
                        ?.createdAt
                    ),
                    "day"
                  ) !== 0 && (
                    <div class="chat-separator">
                      {moment(msg?.createdAt).format("MMM DD, YYYY")}
                    </div>
                  )}
                  <Bubble
                    message={msg.data.Body}
                    date={msg.createdAt}
                    from={msg.from}
                    images={msg.images || null}
                    video={msg.video || null}
                    audio={msg.audio || null}
                    buttons={msg.buttons || []}
                    list={msg.list}
                  />
                </>
              )
          )}
          {newMessages.map((msg) => (
            <Bubble
              message={msg.message}
              date={msg.date}
              from="me"
              images={msg.images}
              video={msg.video}
              audio={msg.audio}
              buttons={msg.buttons || []}
              list={msg.list || null}
            />
          ))}
          {isFlow && <div style={{ height: 50 }} />}
        </div>
        <div
          className="hot-corner bottom-hot-corner"
          style={{ opacity: showNextBtn ? 1 : 0 }}
        >
          <div
            className="chat-navigate-btn"
            onClick={() =>
              navigate("next", document.getElementById("chat-view-scroll"))
            }
          >
            <BiChevronDown />
          </div>
        </div>
        {!isFlow && (
          <div
            className={`chat-input-container ${
              attached.length > 0 ? "with-attachments" : ""
            }`}
          >
            <div className="chat-input-actions">
              <BiSmile
                size={24}
                color="#51525C"
                onClick={(e) => {
                  e.stopPropagation();
                  setEmojiOpen(!emojiOpen);
                }}
              />
              <MdAttachFile
                size={24}
                color="#51525C"
                onClick={() => inputFile.current.click()}
              />
            </div>
            <div className="input-with-attachments">
              <Input
                className="reply-chat-input"
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {attached.length > 0 && (
                <div className="input-attachments">
                  {attached.map((img, i) => (
                    <div key={i} className="reply-attachment">
                      <img src={img} />

                      {progress[i] === 100 ? (
                        <div
                          className="input-attachment-action"
                          onClick={() => removeImageAtIndex(i)}
                        >
                          <BiX color="white" size={20} />
                        </div>
                      ) : (
                        <div className="input-attachment-action">
                          <Circle
                            percent={progress[i]}
                            strokeWidth={15}
                            trailWidth={15}
                            trailColor="#f9f9f940"
                            strokeColor="#f9f9f9"
                            style={{ width: 20, height: 20 }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              className={`chat-send ${!canBeSent ? "chat-send-disabled" : ""}`}
              onClick={sendSMS}
            >
              <MdSend size={20} />
            </button>
          </div>
        )}
        {emojiOpen && (
          <div className="emoji-picker" onClick={(e) => e.stopPropagation()}>
            <EmojiPicker
              height={400}
              onEmojiClick={({ emoji }) => {
                setMessage(`${message} ${emoji}`);
              }}
            />
          </div>
        )}
      </div>

      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={uploadFile}
      />
    </>
  );
}
