import React, { useEffect, useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import poweredBy from "../assets/powered-by.svg";
import "./UnlockAccess.scss";
import { GateLogo, LockKey } from "../assets/Dashboard/index";
import { sampleConfig } from "../helpers/sampleConfig";
import Button from "../components/SignupProcess/Button/Button";
import { useHistory } from "react-router-dom";
import IOSLikeLoader from "../components/IOSLikeLoader/IOSLikeLoader";
import { getPlaybookById } from "../api/dmmeendpoints/playbook";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Loading from "../SignupProcess/Loading/Loading";
import { verifyOwnership as verification } from "../api/dmmeendpoints/verifyOwnership";
import { ReactComponent as PaperHead } from "../assets/Dashboard/papper-head.svg";
import Copyright from "../components/Copyright/Copyright";
import { getCookies } from "../helpers/getCookies";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { getContrastColor } from "../helpers/getContrastColor";

export default function UnlockAccess({ preview, raffleData }) {
  const [loadingState, setLoadingState] = useState(false);
  const [config, setConfig] = useState({});
  const [error, setError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [showErrorState, setShowErrorState] = useState(false);
  const { raffle_details, collectibles_needed } = config;
  const { raffle_page_appearance: appearance, access_page } =
    raffle_details || {};
  const params = useParams();
  const history = useHistory();
  const verifyOwnership = async () => {
    setLoadingState("loading");
    const verified = await verification(
      collectibles_needed.map((coll) => coll.fqcn),
      config.raffle_iindy_owner
    );

    setTimeout(() => {
      if (verified?.error?.response?.data?.err_msg === "Unauthorized") {
        history.push(`/get-verified/${params.id}`, { config });
      } else if (verified.hasError || !verified.allowed) {
        setShowErrorState(true);
        return;
      } else if (verified.allowed) {
        setLoadingState("success");
        setTimeout(() => {
          if (access_page.access_page_button_link) {
            window.open(access_page.access_page_button_link, "_self");
          } else {
            history.push(`/raffle/${params.id}`, { config });
          }
        }, 1000);
      }
    }, collectibles_needed.length * 2000);
  };
  useEffect(() => {
    if (preview && raffleData) {
      setConfig({ ...raffleData });
    }
  }, [preview, raffleData]);

  useEffect(() => {
    const cookies = getCookies();
    const sessionToken = cookies.iindySessionToken || undefined;
    const decoded = (sessionToken && jwtDecode(sessionToken)) || {};
    const getData = async () => {
      const data = await getPlaybookById(params.id);
      if (data.playbook_obj.playbook === null) {
        setError(true);
        return;
      }
      if (data?.playbook_obj?.hasError) {
        setError(true);
        return;
      }
      const meta = data.playbook_obj.playbook.metadata;
      const autoClose = meta?.raffle_details?.raffle_config?.auto_close;
      const autoCloseTime =
        meta?.raffle_details?.raffle_config?.auto_close_date;
      if (
        ((meta.status && meta.status !== "live") ||
          (autoClose && moment(Number(autoCloseTime)).isBefore(moment()))) &&
        !preview
      ) {
        setIsClosed(true);
      } else if (!sessionToken || moment(decoded?.exp).isBefore(moment())) {
        history.push(`/get-verified/${params.id}`, { config: meta });
      }
      setConfig({
        ...meta,
        artistImg: data.playbook_obj.playbook?.profile_picture,
      });
    };
    !preview && getData();
  }, [preview]);

  if (error) {
    return <h2 style={{ color: "#fff" }}>Error</h2>;
  } else if (!raffle_details) {
    return <Loading />;
  } else if (isClosed) {
    return (
      <div
        className="is-closed-unlock-access"
        style={{
          backgroundColor: appearance.background_color,
          color: appearance.text_color,
          fill: appearance.text_color,
        }}
      >
        <img className={"raffle-img"} src={raffle_details.raffle_image} />
        <h1>Hey! This raffle is now closed :(</h1>
        <b> If you think this is an error, please contact us.</b>

        <Copyright />
      </div>
    );
  } else if (showErrorState) {
    return (
      <div
        className="error-unlock-access"
        style={{
          backgroundColor: appearance.background_color,
          color: appearance.text_color,
          fill: appearance.text_color,
        }}
      >
        <PaperHead style={{ fill: "currentcolor" }} />
        <h1>Hey, Hold a Sec!</h1>
        <b>You don’t own the Collectible to access this one.</b>
        <Button
          border="none"
          action={() => {
            setShowErrorState(false);
            setLoadingState(false);
          }}
          background={appearance.button_color}
          textColor={appearance.button_text_color}
          label={"GO BACK"}
        />
        <Copyright />
      </div>
    );
  }
  return (
    <div
      className="unlock-access-wrapper"
      style={{
        backgroundColor: appearance.background_color,
        color: appearance.text_color,
      }}
    >
      <div className="content-access">
        <img className="artist-picture" src={config.artistImg} />
        <div className="asset-to-access">
          <img src={access_page.access_page_image} />
          <div className="lock-icon-container">
            <LockKey />
          </div>
        </div>
        <h2>{access_page.access_page_title}</h2>
        <span className="asset-sub-text">
          {access_page.access_page_description}
        </span>
        {/* <div
          className={`nft-container  ${
            collectibles_needed.length > 1 ? "scrollable" : ""
          }`}
        >
          {collectibles_needed.map((nft, i) => (
            <div
              key={i}
              className={`nft-card`}
              style={{
                backgroundColor: getContrastColor(appearance.background_color),
              }}
            >
              {isImageError ? (
                <video src={nft.asset_image} controls={true} autoPlay={true} />
              ) : (
                <img
                  alt="collection-asset"
                  src={nft.asset_image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    setIsImageError(true);
                  }}
                />
              )}
              <b>{nft.asset_name}</b>
            </div>
          ))}
        </div> */}
        <Button
          border="none"
          action={verifyOwnership}
          background={appearance.button_color}
          textColor={appearance.button_text_color}
          label={"CONTINUE"}
        />
        <Copyright />
      </div>
      <IOSLikeLoader
        appearance={appearance}
        collectibles_needed={collectibles_needed}
        loadingState={loadingState}
        loadingText={"Verifying ownership"}
        successText={"Access Unlocked"}
      />
    </div>
  );
}
