import {
  getFullURL,
  backendApiCall
} from './backend';


export async function sendSMS (phoneNumber, message, authToken) {
  const url = getFullURL('/send-sms');

  return backendApiCall(url, {
    method: 'POST',
    body: JSON.stringify({
      phoneNumber,
      message
    }),
    authToken
  });
}
