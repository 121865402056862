import React, { useEffect } from "react";
import Switch from "react-switch";
import "./AutomaticallyClose.scss";
import { DatePicker } from "antd";
import { AiOutlineCalendar } from "react-icons/ai";
import { getTZ } from "../../../../../../helpers/getTZ";
import moment from "moment";

export default function AutomaticallyClose({
  isStopped,
  raffleData,
  setRaffleData,
}) {
  const TZ = getTZ();

  const onChange = (value, dateString) => {
    setRaffleData({
      ...raffleData,
      autoCloseTime: value.format("x"),
      timezone: TZ,
    });
  };
  const disabledTime = (date) => {
    if (moment(date).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) {
      return {
        disabledHours: () =>
          Array.from(Array(Number(moment().format("HH"))).keys()),
        disabledMinutes: () =>
          moment(date).format("HH") === moment().format("HH") &&
          Array.from(Array(Number(moment().format("mm")) + 10).keys()),
      };
    }
  };

  return (
    <div className="automatically-close-wrapper">
      <div className="descriptive-part">
        <div className="text-part">
          <h3>Automatically Close Raffle</h3>
          <div className="subtext">Schedule an end date for the raffle.</div>
        </div>
        <div className="switch-container">
          <div
            className={
              "switchComponent" +
              (raffleData.autoClose ? " enabled-switch" : "")
            }
          >
            <Switch
              disabled={!!isStopped}
              onChange={(value) => {
                setRaffleData({
                  ...raffleData,
                  autoClose: value,
                });
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor={"#F3EC00"}
              onHandleColor={"#000"}
              checked={raffleData.autoClose}
              className={`react-switch`}
            />
          </div>
        </div>
      </div>
      {raffleData.autoClose && (
        <div className="date-time-part">
          <DatePicker
            disabled={!!isStopped}
            suffixIcon={<AiOutlineCalendar />}
            disabledDate={(current) =>
              current.isBefore(moment().subtract(1, "day"))
            }
            disabledTime={disabledTime}
            value={
              raffleData.autoCloseTime
                ? moment(Number(raffleData.autoCloseTime))
                : moment().add(7, "days")
            }
            format={`YYYY-MM-DD HH:mm [${TZ}]`}
            showTime
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}
