import React from "react";
import { useState } from "react";
import clipboardSvg from "../../assets/SignUpProcess/copy.svg";
import ReactTextTransition from "react-text-transition";
import { MdContentCopy, MdOutlineContentCopy } from "react-icons/md";
import { FiLink2 } from "react-icons/fi";
import "./YourNumber.scss";
import { Tooltip } from "antd";
import ShareHotlineLink from "../../screens/Dashboard/Playbook/ShareHotlineLink/ShareHotlineLink";
import { customNotification } from "../../helpers/customNotification";

export default function YourNumber() {
  const [buttonState, setButtonState] = useState("Copy");
  const [showModal, setShowModal] = useState(false);

  const number = JSON.parse(
    localStorage.getItem("dbUser")
  )?.claimed_phone_number;

  const copyToClipboard = () => {
    setButtonState("Copied");
    navigator.clipboard.writeText(number);
    setTimeout(() => {
      setButtonState("Copy");
    }, 2000);
    customNotification("Copied to clipboard!", <MdOutlineContentCopy />);
  };

  return (
    <>
      <div className="your-number-container">
        <b>Here’s your hotline number</b>
        <div className="actual-number">
          <div className="number">{number}</div>
          <Tooltip title={`${buttonState}`}>
            <button className="copy-btn" onClick={copyToClipboard}>
              <MdContentCopy />
            </button>
          </Tooltip>
          <Tooltip title={"Get link"}>
            <button className="copy-btn" onClick={() => setShowModal(true)}>
              <FiLink2 />
            </button>
          </Tooltip>
        </div>
        <p>
          Share it on your socials, via email or even as a QR code so your fans
          can sign up.
        </p>
      </div>
      <ShareHotlineLink
        isOpen={showModal}
        setIsOpen={setShowModal}
        number={number}
      />
    </>
  );
}
