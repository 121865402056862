import React, { useState, useEffect } from "react";
import Button from "../../components/SignupProcess/Button/Button";
import Header from "../../components/SignupProcess/Header/Header";
import InputBox from "../../components/SignupProcess/InputBox/InputBox";
import Phone from "../../assets/SignUpProcess/phone.png";
import LightBulb from "../../assets/SignUpProcess/lightbulb-alt.png";
import styles from "./GetYourNumber.module.scss";
import DigitSearch from "../../components/SignupProcess/DigitSearch/DigitSearch";
import TutorialPopUp from "../../components/SignupProcess/TutorialPopUp/TutorialPopUp";
import ConfirmPopUp from "../../components/SignupProcess/ConfirmPopUp/ConfirmPopUp";
import requestDM from "../../api/requestDM";
import Loading from "../Loading/Loading";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { checkIfArtistExists } from "../../api/dmmeendpoints/checkIfUserExists";
import { getAvailableNumbers } from "../../api/dmmeendpoints/getAvailableNumbers";
import { createArtist } from "../../api/dmmeendpoints/createArtist";
import { claimPhoneNumber } from "../../api/dmmeendpoints/claimPhoneNumber";
import { updateArtist } from "../../api/dmmeendpoints/updateArtist";

export default function GetYourNumber() {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [country, setCountry] = useState({ code: "+44", label: "GB" });
  const [contains, setContains] = useState("");
  const [loginUser, setLoginUser] = useState(null);
  const [visibleConfirmPopUp, setVisibleConfirmPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState("success");
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      const localUser = JSON.parse(localStorage.getItem("loginUser"));
      setLoginUser(localUser);
    } else {
      localStorage.clear();
      logout();
    }
  }, []);

  const getNumbers = async (country, contains) => {
    if (user) {
      setLoading(true);
      const data = await getAvailableNumbers(country.label, contains);
      setPhoneNumbers(data);
      setLoading(false);
    }
  };
  useEffect(() => {
    getNumbers(country, contains);
    setSelectedNumber(null);
  }, [country, contains]);
  useEffect(() => {
    if (selectedNumber) {
      const localUser = JSON.parse(localStorage.getItem("loginUser"));
      localUser.selectedNumber = selectedNumber;
      localStorage.setItem("loginUser", JSON.stringify(localUser));
    }
  }, [selectedNumber]);
  const history = useHistory();
  useEffect(() => {
    const isLogged = localStorage.getItem("loginUser");
    if (!isLogged) {
      // history.push("/welcome");
    }
  }, []);
  useEffect(() => {
    const effectFunc = async () => {
      const localUser = JSON.parse(localStorage.getItem("loginUser"));
      if (user?.name && localUser) {
        const response = await checkIfArtistExists(user.name);
        if (response) {
          localStorage.setItem("dbUser", JSON.stringify(response.config_json));
          if (response.config_json.claimed_phone_number) {
            history.push("/message-flow-builder");
          }
        } else {
          await createArtist({
            work_email: user.name,
            artist_id: user.sub,
            artist_name: localUser.artistName,
            company_name: localUser.companyName,
            full_name: localUser.fullName,
          });
          const newResponse = await checkIfArtistExists(user.name);
          if (newResponse) {
            localStorage.setItem(
              "dbUser",
              JSON.stringify(newResponse.config_json)
            );
          }
        }
        const data = await getAvailableNumbers(country.label, contains);

        setPhoneNumbers(data);
      }
    };
    effectFunc();
  }, [user]);

  const claimNumber = async () => {
    const sub = user.sub.split("|")[1];
    const response = await claimPhoneNumber({
      number: selectedNumber,
      artistId: sub,
    });
    setVisibleConfirmPopUp(true);
    if (response.error) {
      setPopUpType("error");
    } else {
      await updateArtist({ artistId: user.sub, number: selectedNumber });
      const dbUser = JSON.parse(localStorage.getItem("dbUser"));
      dbUser.claimed_phone_number = selectedNumber;
      localStorage.setItem("dbUser", JSON.stringify({ ...dbUser }));
      setPopUpType("success");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header title={""}  button={'Logout'} btnAction={logout}/>
      <div className={styles.row}>
        <div className={styles.signupForm}>
          <h1>
            Hi {loginUser?.fullName?.split(" ")[0]}! Grab your Hotline <br />
            number{" "}
          </h1>
          <h6>
            Use your Hotline to build your own community, excite fans with
            challenges and reward their engagement with special access to new
            releases, merch & tickets to your events.
          </h6>
          <div className={styles.inputs}>
            <DigitSearch
              country={country}
              setCountry={setCountry}
              contains={contains}
              setContains={setContains}
            />
            <b className={styles.explainDigits}>
              Search by area code, prefix, or specific digits you want in your
              number.
            </b>
            <div className={styles.phoneNumberList}>
              {loading ? (
                <Loading />
              ) : (
                phoneNumbers.map((nr, i) => (
                  <div className={styles.phoneNrDiv}>
                    <input
                      type="radio"
                      id={nr.phoneNumber}
                      name="age"
                      onChange={(e) => {
                        setSelectedNumber(e.target.value);
                      }}
                      value={nr.phoneNumber}
                    />
                    <label for={nr.phoneNumber}>
                      {country.code !== "+44" && country.code} {nr.friendlyName}
                    </label>
                  </div>
                ))
              )}
            </div>
            <div className={styles.ClaimBtn}>
              <Button
                label={"CLAIM MY NUMBER"}
                disabled={!selectedNumber}
                action={claimNumber}
              />
            </div>
          </div>
        </div>
        <div className={styles.illustration}>
          <img alt="artwork" src={Phone} />
          <div className={styles.description}>
            <div className={styles.icon}>
              <div className={styles.lampBack}>
                <img alt={"lamp-icon"} src={LightBulb} />
              </div>
              Did you know?
            </div>
            A HOTLINE CAN HAVE UP TO <b>15X higher conversions</b> than SOCIAL
            MEDIA
          </div>
        </div>
      </div>
      <TutorialPopUp />
      <ConfirmPopUp
        visible={visibleConfirmPopUp}
        setVisible={setVisibleConfirmPopUp}
        type={popUpType}
      />
    </div>
  );
}
