import React from "react";
import "./EmptyPlaybook.scss";
import chair from "../../../../assets/Dashboard/chair.svg";

export default function EmptyPlaybook() {
  return (
    <div className="empty-playbook-wrapper">
      <img src={chair} />
      <h1>No Playbooks yet</h1>
      <span>
        Your Playbooks will show up here once <br />
        you publish it
      </span>
    </div>
  );
}
