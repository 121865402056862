import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown } from "react-icons/fa6";
import { replyPathwaysFormat } from "../../../../../helpers/replyPathwaysFormat";
import "./Treeview.scss";

const RenderElement = ({
  data,
  isOpenedTracker,
  setIsOpenedTracker,
  index,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const expandAndNavigate = (e, text, id, i) => {
    e.stopPropagation();
    setIsOpened(`${text}-${id}-${i}`);
    setIsOpenedTracker(`${text}-${id}-${i}`);
    const elToClick = document.getElementById(
      `whatsapp-reply-option-${id}-${i}`
    );
    if (elToClick) {
      elToClick.click();
    }
  };
  return (
    <ul>
      {data?.map((item, i) => (
        <li key={item.text}>
          <div
            id={`sidebar-pathway-option-${item.id}-${i}`}
            style={{
              marginLeft: index * -14,
              paddingLeft: index * 14,
              width: `calc(100% + ${(index - 1) * 14}px)`,
            }}
            className={`branch ${
              isOpened === `${item.text}-${item.id}-${i}` &&
              isOpened === isOpenedTracker
                ? "active-branch"
                : ""
            }`}
            onClick={(e) => expandAndNavigate(e, item.text, item.id, i)}
          >
            <FaChevronDown /> {item.text}
          </div>
          <Collapse isOpened={isOpened === `${item.text}-${item.id}-${i}`}>
            {item.subElements && (
              <RenderElement
                data={item.subElements}
                isOpenedTracker={isOpenedTracker}
                setIsOpenedTracker={setIsOpenedTracker}
                index={index + 1}
              />
            )}
          </Collapse>
        </li>
      ))}
    </ul>
  );
};

export const TreeView = ({ treeData, flowName }) => {
  const [isOpenedTracker, setIsOpenedTracker] = useState(false);

  const formatted = replyPathwaysFormat(treeData);
  return (
    <div className="tree-view">
      <h3>{flowName}</h3>
      <RenderElement
        data={formatted}
        isOpenedTracker={isOpenedTracker}
        setIsOpenedTracker={setIsOpenedTracker}
        index={1}
      />
    </div>
  );
};
