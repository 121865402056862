import { ReactComponent as CheckCircle } from "../../assets/check_circle.svg";
import "./IOSLikeLoader.scss";
import AnimatedListNft from "./AnimatedListNft";
import { TickSuccess } from "../../assets/Dashboard";
import { BiSolidInvader } from "react-icons/bi";

export default function IOSLikeLoader({
  loadingText,
  loadingState,
  successText,
  collectibles_needed,
  appearance,
}) {
  if (!loadingState) {
    return null;
  }
  return (
    <div className="ios-like-loader">
      <div
        className={`blured-container ${
          collectibles_needed?.length > 0 ? "isNft" : ""
        }`}
      >
        {loadingState === "loading" && collectibles_needed?.length > 0 ? (
          <AnimatedListNft
            collectibles_needed={collectibles_needed}
            appearance={appearance}
          />
        ) : (
          <div
            className={`loading-animation${
              loadingState === "success" ? "Tick" : ""
            }`}
          >
            {loadingState === "success" ? (
              <>
                <TickSuccess />
                <span>{successText ?? "Access Unlocked"}</span>
              </>
            ) : loadingState === "error" ? (
              <div className="error-state">
                <BiSolidInvader size={60} />
                <span>Please try again</span>
              </div>
            ) : (
              loadingState === "loading" && (
                <div class="loading-body">
                  <div class="loader-square-10"></div>
                  <span>{loadingText}</span>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}
