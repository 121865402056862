import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Hammer from "react-hammerjs";

export default function AbsoluteNavigator({
  action,
  navigate,
  paused,
  setpaused,
  showInstructionOverlay,
}) {
  const [pauseOrNavigate, setPauseOrNavigate] = useState("navigate");

  return (
    <Hammer
      onTap={() => {
        navigate(action);
      }}
      onPress={() => {
        setpaused(true);
      }}
      onPressUp={() => {
        setpaused(false);
      }}
      onSwipe={(act) => {
        if (paused == false) {
          showInstructionOverlay();
        }
      }}
    >
      <div
        id={`absolute-${action}`}
        className={`absolute-navigator ${action}`}
      ></div>
    </Hammer>
  );
}
