import { useCallback, useEffect, useState } from "react";
import { getPlaybookById } from "../../../../../api/dmmeendpoints/playbook";
import Carousel from "nuka-carousel";
import { BiChevronLeft, BiChevronRight, BiLockAlt } from "react-icons/bi";
import Lightbox from "../../../../../components/Lightbox/Lightbox";
import moment from "moment";
import { BsReply } from "react-icons/bs";
import { BiX } from "react-icons/bi";

import { Modal } from "antd";

export const Bubble = ({
  message,
  date,
  from = "them",
  images = [],
  raffleIds = [],
  video = null,
  audio = null,
  buttons = [],
  list = null,
}) => {
  const [lightboxState, setLightboxState] = useState({
    visible: false,
    slideIndex: 0,
    images: [],
  });
  const [raffleData, setRaffleData] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);

  const getRaffleData = useCallback(async () => {
    const data = [];
    if (raffleIds?.length > 0) {
      for (let i = 0; i < raffleIds?.length; i++) {
        const el = raffleIds?.[i];
        const response = await getPlaybookById(el);
        data.push(response?.playbook_obj?.playbook);
      }
      setRaffleData(data);
    }
  }, [raffleIds]);

  useEffect(() => {
    getRaffleData();
  }, []);

  if (list) {
    console.log(list, "LISTFPFPFP");
  }

  return (
    <div
      className={`chat-bubble-container ${
        from === "me" ? "chat-my-bubble-container" : ""
      }`}
    >
      {message && (
        <div className={`chat-bubble`}>
          <div className="chat-bubble-main-content">
            {(images?.length > 0 || raffleData?.length > 0 || video) && (
              <div className="chat-bubble-img">
                <Carousel
                  renderCenterLeftControls={({
                    previousDisabled,
                    previousSlide,
                  }) =>
                    !previousDisabled && (
                      <div
                        className="carousel-controls control-left"
                        onClick={(e) => {
                          e.stopPropagation();
                          previousSlide();
                        }}
                        disabled={previousDisabled}
                      >
                        <BiChevronLeft color="white" size={24} />
                      </div>
                    )
                  }
                  renderCenterRightControls={({ nextDisabled, nextSlide }) =>
                    !nextDisabled && (
                      <div
                        className="carousel-controls"
                        onClick={(e) => {
                          e.stopPropagation();
                          nextSlide();
                        }}
                        disabled={nextDisabled}
                      >
                        <BiChevronRight color="white" size={24} />
                      </div>
                    )
                  }
                >
                  {images &&
                    images?.map((image, i) => (
                      <div className="carousel-slide">
                        <img
                          src={image}
                          onClick={(e) => {
                            e.stopPropagation();
                            setLightboxState({
                              ...lightboxState,
                              visible: true,
                              images: images,
                              slideIndex: i,
                            });
                          }}
                          alt=""
                        />
                      </div>
                    ))}
                  {video && (
                    <div className="carousel-slide">
                      <video src={video} controls />
                    </div>
                  )}
                  {raffleData &&
                    raffleData?.map((data, i) => (
                      <div key={data?.playbook_key} className="carousel-slide">
                        <div
                          className="raffle-preview"
                          style={{
                            background:
                              data?.metadata?.raffle_details
                                ?.raffle_page_appearance?.background_color,
                            color:
                              data?.metadata?.raffle_details
                                ?.raffle_page_appearance?.text_color,
                          }}
                        >
                          <div className="asset-container">
                            <img
                              className="raffle-img"
                              alt=""
                              src={
                                data?.metadata?.raffle_details?.access_page
                                  ?.access_page_image
                              }
                            />
                            <div className="lock-icon-container">
                              <BiLockAlt />
                            </div>
                          </div>

                          <b
                            style={{
                              color:
                                data?.metadata?.raffle_details
                                  ?.raffle_page_appearance?.text_color,
                            }}
                          >
                            {
                              data?.metadata?.raffle_details?.access_page
                                ?.access_page_title
                            }
                          </b>
                        </div>
                      </div>
                    ))}
                </Carousel>
                <Lightbox
                  {...lightboxState}
                  setLightboxState={setLightboxState}
                />
                <div className="no-items">
                  {video
                    ? 1
                    : Number(images?.length ?? 0) +
                      Number(raffleIds?.length ?? video ? 1 : 0)}{" "}
                  item
                  {Number(images?.length ?? 0) +
                    Number(raffleIds?.length ?? 0) >
                  1
                    ? "s"
                    : ""}
                </div>
              </div>
            )}
            {audio && (
              <div className="carousel-slide">
                <audio src={audio} controls />
              </div>
            )}
            {message}
            <div className="chat-bubble-date">
              {moment(date).format("h:mm A")}
            </div>
          </div>
          {buttons.length > 0 && (
            <div className="button-replies-container">
              {buttons.map((btn, i) => (
                <div key={i} className="button-reply">
                  <BsReply size={20} color="#007BFF" />
                  <span>{btn.title}</span>
                </div>
              ))}
            </div>
          )}
          {list && (
            <div className="button-replies-container">
              <div
                className="button-reply list-item"
                onClick={() => setIsListOpen(true)}
              >
                <BsReply size={20} color="#007BFF" />
                <span>{list.button}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {list && (
        <Modal
          open={isListOpen}
          onCancel={() => setIsListOpen(false)}
          className="whatsapp-list-option-modal"
          footer={null}
          closeIcon={
            <div className="whatsapp-list-option-modal-close">
              <BiX size={24} />
            </div>
          }
        >
          <h1>{list.button}</h1>
          {list.listItems.map((el) => (
            <div key={el.id} className="whatsapp-list-row">
              {el.title}
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};
