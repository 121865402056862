import React, { useEffect, useState } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import GoldenTicket from "../../components/GoldenTicket/GoldenTicket";
import { useHistory, useLocation } from "react-router";
import spotifyAppleBackground from "../../assets/spotifyAppleBackground.png";
import axios from "axios";
import QueryString from "qs";

import instaIcon from "../../assets/insta.svg";
import whatsappIcon from "../../assets/whatsapp.svg";
import emailIcon from "../../assets/email.svg";
import twitterIcon from "../../assets/twitter.svg";
import clipboardIcon from "../../assets/clipboard.svg";
import messengerIcon from "../../assets/messenger.svg";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./MediaServices.scss";

export default function MediaServices() {
  const [appleUserToken, setAppleUserToken] = useState("...");
  const [spotifyTokenData, setSpotifyTokenData] = useState({});
  const [spotifyCode, setSpotifyCode] = useState("...");
  const history = useHistory();
  const heightChange = () => {
    if (window.innerWidth >= 600) {
      const mainContent = document.getElementsByClassName(
        "MediaServices-wrapper"
      )[0];
      mainContent.style.height = window.innerHeight - 150 + "px";
    }
  };
  let music;

  useEffect(() => {
    // window.MusicKit.configure({
    //   developerToken: process.env.REACT_APP_APPLE_DEV_TOKEN,
    //   app: {
    //     name: "iiNDY",
    //     build: "2.1.1",
    //   },
    // });
    // music = window.MusicKit.getInstance();
  }, []);
  const appleLogin = () => {
    music.authorize().then(function (res) {
      setAppleUserToken(res);

      history.push("/join");
    });
  };
  const scopes = [
    "user-top-read",
    "user-read-recently-played",
    "user-read-email",
    "user-follow-read",
    "playlist-read-private",
  ];
  const loginUrl = `https://accounts.spotify.com/authorize?client_id=${
    process.env.REACT_APP_SPOTIFY_CLIENT_ID
  }&response_type=code&redirect_uri=${
    process.env.REACT_APP_SPOTIFY_REDIRECT_URI
  }&scope=${scopes.join("%20")}&show_dialog=true`;

  useEffect(() => {
    getSpotifyToken(window.location.href);
  }, [window.location.href]);
  const getSpotifyToken = (hash) => {
    if (hash.includes("code")) {
      const code = hash.substring(hash.indexOf("code=") + "code=".length);
      setSpotifyCode(code);
      axios
        .post("https://accounts.spotify.com/api/token", null, {
          params: {
            grant_type: "authorization_code",
            code: code,
            redirect_uri: process.env.REACT_APP_SPOTIFY_REDIRECT_URI,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic " +
              Buffer.from(
                process.env.REACT_APP_SPOTIFY_CLIENT_ID +
                  ":" +
                  process.env.REACT_APP_SPOTIFY_CLIENT_SECRET
              ).toString("base64"),
          },
        })
        .then((res) => {
          setSpotifyTokenData(res.data);
        });
    }
  };
  const getSpotifyNewAccessToken = () => {
    axios
      .post("https://accounts.spotify.com/api/token", null, {
        params: {
          grant_type: "refresh_token",
          refresh_token: spotifyTokenData.refresh_token,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic " +
            Buffer.from(
              process.env.REACT_APP_SPOTIFY_CLIENT_ID +
                ":" +
                process.env.REACT_APP_SPOTIFY_CLIENT_SECRET
            ).toString("base64"),
        },
      })
      .then((res) => {
        setSpotifyTokenData({
          ...spotifyTokenData,
          access_token: res.data.access_token,
        });
      });
  };
  return (
    <>
      <div
        style={{
          background: `linear-gradient(360deg, #14141A 59.48%, #2F6B00 100%)`,
        }}
        className="desktop-wrapper"
      ></div>
      <div
        style={{
          background: `linear-gradient(360deg, #14141A 59.48%, #2F6B00 100%)`,
        }}
        className="MediaServices-wrapper"
      >
        {" "}
        <img
          src={spotifyAppleBackground}
          className="MediaServices-background"
        />
        <h1 className="MediaServices-heading"></h1>
        {/* <GoldenTicket /> */}
        <div className="MediaServices-text-part">
          <h1 className="MediaServices-explain-h1">
            Connect your music to stay in the know!
          </h1>
          <h2 className="MediaServices-explain-h2">
            Connect your Spotify account to get notified when your favourite
            artists join the iiNDY community.
          </h2>
        </div>
        <div className="MediaServices-login-btns">
          <DynamicButton
            icon={"spotifyIcon"}
            text="Spotify"
            action={() => {
              window.location.href = loginUrl;
            }}
          />
          <div style={{ height: 40 }} />
          {/* <DynamicButton
            type={"secondary"}
            icon={"appleMusic"}
            text="Apple Music"
            action={appleLogin}
          /> */}
        </div>
        <div className="copyright">
          <p>
            By continuing, you agree to our{" "}
            <a href="https://iindy.co/terms" target="_blank">
              Terms
            </a>{" "}
            and{" "}
            <a href="https://iindy.co/privacy" target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
