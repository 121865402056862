import './wdyr'; // <--- first import
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0 } from './auth_config';
import history from './helpers/history'
import './assets/Fonts/Sequel 100 Wide 45 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 46 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 56 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 65 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 66 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 75 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 76 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 85 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 86 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 95 Wide.otf';
import './assets/Fonts/Sequel 100 Wide 96 Wide.otf';
import './assets/Fonts/Swis721_Cn_BT_Roman.ttf'
import './assets/Fonts/UniversNextPro-Regular.ttf'
import './assets/Fonts/UniversNextPro-BoldExt.ttf'
import './assets/Fonts/Plain-Medium.otf'
import './assets/Fonts/DOVELOVE.otf'
import './assets/Fonts/KINKEE.otf'
import './assets/Fonts/KINKEE.otf'
import './assets/Fonts/ClashGrotesk-Variable.woff'
import { noArtistUrl } from './helpers/getArtist';



const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: auth0.domain,
  clientId: auth0.clientId,
  ...(auth0.audience ? { audience: auth0.audience } : null),
  redirectUri: window.location.origin + '/profile-details',
  onRedirectCallback,
  cacheLocation: "localstorage"
};
ReactDOM.render(
  <React.StrictMode>
    {window.location.origin.includes('unlock.') || !noArtistUrl.some(el => window.location.origin.includes(el)) ? <App /> : <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals( 
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
