import Lottie from "react-lottie";
import loading from "../../assets/loading-circle.json";
import React from "react";
import "./Loading.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

export default function Loading() {
  return (
    <div className="dm-loading">
      <Lottie options={defaultOptions} height={150} width={150} />
    </div>
  );
}
