import { useAuth0 } from "@auth0/auth0-react";
import { notification } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { artistUpdate } from "../../../../api/dmmeendpoints/artistUpdate";
import { checkIfArtistExists } from "../../../../api/dmmeendpoints/checkIfUserExists";
import { updateBotFlow } from "../../../../api/dmmeendpoints/updateBotFlow";
import { ReactComponent as CloseIcon } from "../../../../assets/close.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/Dashboard/pause.svg";
import Button from "../../../../components/SignupProcess/Button/Button";

import "./PauseModal.scss";
import { customNotification } from "../../../../helpers/customNotification";

export default function PauseModal({
  showPauseModal,
  setShowPauseModal,
  setShowUpdater,
}) {
  const { user } = useAuth0();
  const history = useHistory();
  if (showPauseModal) {
    return (
      <div className="pause-backdrop">
        <div className="pause-modal">
          <div className="close-icon" onClick={() => setShowPauseModal(false)}>
            <CloseIcon />
          </div>
          <div className="pause-icon">
            <PauseIcon />
          </div>
          <h4>Are you sure?</h4>
          <h6>
            This will stop this Activation flow, and your members won’t receive
            it. You can still publish again later.
          </h6>
          <div className="pause-modal-action">
            <Button
              label={"CANCEL"}
              background={"#0000000F"}
              textColor={"#3F3F46"}
              borderColor={"transparent"}
              action={() => {
                setShowPauseModal(false);
              }}
            />
            <Button
              label={"PAUSE"}
              background={"#B42318"}
              textColor={"#fff"}
              borderColor={"transparent"}
              action={async () => {
                await artistUpdate({
                  object: {
                    bot_config: {},
                    unsaved_bot_config: JSON.parse(
                      localStorage.getItem("dbUser")
                    )?.unsaved_bot_config,
                  },
                  artistId: user.sub,
                });
                setShowPauseModal(false);
                await updateBotFlow();
                await checkIfArtistExists(user.email);
                setShowPauseModal(false);
                setShowUpdater(false);
                customNotification(
                  "Paused",
                  null,
                  "This Activation is now paused, your members won’t receive it."
                );
                history.push("/dashboard/community/activations");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
}
