import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
} from "react-pro-sidebar";
import Logo from "../../assets/Dashboard/iindy.png";
import GoalsIcon from "../../assets/Dashboard/goals.svg";
import { ReactComponent as CommunityIcon } from "../../assets/Dashboard/community.svg";
import { ReactComponent as PlaybookIcon } from "../../assets/Dashboard/playbook.svg";
import { ReactComponent as InsightsIcon } from "../../assets/Dashboard/insights.svg";
import GiftIcon from "../../assets/Dashboard/gift.svg";
import Avatar from "../../assets/Dashboard/Avatar.svg";
import Footer from "../../components/SignupProcess/Footer/Footer";
import Goals from "./Goals/Goals";
import Platform from "./Platform/Platform";
import { useHistory, useLocation } from "react-router-dom";
import { Insights } from "./Insights/Insights";
import Community from "./Community/Community";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown, Menu as AntMenu } from "antd";
import { LogoutOutlined, MailOutlined } from "@ant-design/icons";
import { createArtist } from "../../api/dmmeendpoints/createArtist";
import { checkIfArtistExists } from "../../api/dmmeendpoints/checkIfUserExists";
import Loading from "../../SignupProcess/Loading/Loading";
import Center from "../../components/Center/Center";
import Playbook from "./Playbook/Playbook";
import { BiCube } from "react-icons/bi";
import moment from "moment";
import Collections from "./Collections/Collections";

export default function Dashboard() {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } =
    useAuth0();
  const [activePanelIndex, setActivePanelIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [loginUser, setLoginUser] = useState();
  const dbUser = JSON.parse(localStorage.getItem("dbUser"));

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("loginUser"));
    setLoginUser(localUser);
  }, []);

  const menu = [
    // { label: "Goals", icon: GoalsIcon, path: "/dashboard/goals/select" },
    { label: "Community", icon: CommunityIcon, path: "/dashboard/community" },
    {
      label: "Playbooks",
      icon: PlaybookIcon,
      path: "/dashboard/playbooks",
    },
    {
      label: "Collections",
      icon: () => <BiCube size={24} />,
      path: "/dashboard/collections",
    },
    { label: "Insights", icon: InsightsIcon, path: "/dashboard/insights" },
    // { label: "75 £", icon: GiftIcon, special: true, path: "/dashboard/promo" },
  ];
  useEffect(() => {
    //
    setActivePanelIndex(
      menu.findIndex((item) => location.pathname.includes(item.path))
    );
    if (location.pathname === "/dashboard/goals/platform") {
      setActiveStep("platform");
      setActivePanelIndex(0);
    } else if (location.pathname === "/dashboard") {
      history.push("/dashboard/community");
    } else {
      setActiveStep(0);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (!isAuthenticated && !isLoading && !user) {
      history.push("/");
    }
  }, [isAuthenticated, user, isLoading]);
  const logoutWithRedirect = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };
  useEffect(() => {
    let idleTimeout;
    if (isLoading) {
      idleTimeout = setTimeout(() => {
        logout({ returnTo: window.location.origin });
        localStorage.clear();
      }, 5000);
    }
    return () => {
      clearTimeout(idleTimeout);
    };
  }, [isLoading]);

  useEffect(() => {
    const checkIfExists = async () => {
      if (user) {
        const { config_json } = await checkIfArtistExists(user.name);
        if (config_json) {
          if (config_json.status?.twilioStatus === "closed_on_twilio") {
            history.push("/welcome", { status: "closed-account" });
          } else if (!config_json.bot_config) {
            history.push("/message-flow-builder");
          } else if (!config_json.claimed_phone_number) {
            history.push("/get-your-number");
          }
        } else {
          history.push("/");
        }
      }
    };
    checkIfExists();
  }, [user, isLoading]);

  const items = [
    {
      label: (
        <div className="tile-dropdown-item">
          <MailOutlined /> {user?.email || ""}
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="tile-dropdown-item" onClick={logoutWithRedirect}>
          <LogoutOutlined /> Logout
        </div>
      ),
      key: "1",
    },
  ];

  useEffect(() => {
    const checkIfExists = async () => {
      const localUser = JSON.parse(localStorage.getItem("loginUser"));
      if (user?.name && localUser) {
        const response = await checkIfArtistExists(user.name);
        if (!response) {
          createArtist({
            work_email: user.name,
            artist_id: user.sub,
            artist_name: localUser.artistName,
            company_name: localUser.companyName,
            full_name: localUser.fullName,
          });
        }
      }
    };
    checkIfExists();
  }, [user, isLoading, isAuthenticated]);

  useEffect(() => {}, [isLoading]);

  return (
    <div className={"dashboard-wrapper"}>
      {isLoading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <div className="row">
          <ProSidebar collapsed={true} onToggle={(e) => {}}>
            <SidebarHeader>
              <img src={Logo} />
            </SidebarHeader>
            <Menu iconShape="square">
              {menu.map((item, i) => (
                <MenuItem
                  key={i}
                  onClick={() => history.push(item.path)}
                  icon={
                    <div
                      className={`my-menu-item ${
                        item.special ? " special" : ""
                      }${activePanelIndex === i ? " my-active" : ""}`}
                    >
                      <item.icon />
                      {item.label}
                    </div>
                  }
                ></MenuItem>
              ))}
            </Menu>
            <SidebarFooter>
              <Dropdown
                menu={{ items }}
                placement="topRight"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <img
                  src={
                    dbUser?.bot_config?.contact_info?.profile_picture ===
                    undefined
                      ? user?.picture
                      : dbUser?.bot_config?.contact_info?.profile_picture
                  }
                />
              </Dropdown>
            </SidebarFooter>
          </ProSidebar>
          <div className="panel">
            {/* {activePanelIndex == 0 && <h2>Choose your Goal</h2>} */}
            {/* {activePanelIndex == 0 &&
            (activeStep === 0 ? (
              <Goals
                setActiveStep={() => history.push("/dashboard/goals/platform")}
              />
            ) : (
              <Platform />
            ))} */}
            {activePanelIndex === 0 && <Community />}
            {activePanelIndex === 1 && <Playbook />}
            {activePanelIndex === 2 && <Collections />}
            {activePanelIndex === 3 && <Insights />}
            {/* <Footer noBackground={true} /> */}
          </div>
        </div>
      )}
    </div>
  );
}
