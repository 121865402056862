import requestDM from '../requestDM'

export const updateArtist = async ({ number, artistId }) => {
    var response;
    try {
        response = await requestDM({ claimed_phone_number: number, artist_id: artistId }, `/dmme/artist`, 'PATCH')
        return response
    } catch (error) {

        return { error }
    }
}